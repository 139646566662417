import { ActionType, Entity, Document } from "@utils/interface";
import { useGetOne } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { useDisplayToast } from "@utils/hooks";
import Loader from "@components/utils/Loader";
import { getHtmlString } from "@playground/editors/slate/exports";
import {
  ellipsis,
  includesString,
  queryEmbeds,
  replaceTag,
  setURLQuery,
  tailwindHelpers,
} from "@utils/index";
import { useContext, useEffect, useRef } from "react";
import Overlay from "@components/Overlay";
import CloseBtn from "@components/CloseButton";
import { ContextStore } from "@components/utils/Context";
import parseHtml from "html-react-parser";
import { getEditorHtml } from "@/playground/editors/helper";

interface Props {
  children?: React.ReactNode;
  // documentId?: string;
  // setAppModal?: (props: { show: boolean }) => void;
}

const ViewDocument = (props: Props) => {
  const navigate = useNavigate();
  const { user, documents, appModal, setAppModal } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  let _document = documents.find((p) => p.id === appModal?.documentId)!;

  const handleClose = () =>
    setAppModal((prev) => ({
      ...prev,
      views: { ...prev.views, [ActionType.view]: false },
    }));
  const isShared = includesString(["share"], window.location.pathname);
  const onClose = () => {
    if (!isShared) {
      handleClose();
      setURLQuery({ documentId: null });
    }
  };

  /// get is from url path
  const { id } = useParams<{ id: string }>();

  const redirectHome = () => {
    setTimeout(() => navigate("/"), 500);
  };

  if (isShared) {
    if (!id) {
      errorToast("No document found");
      redirectHome();
      return null;
    }

    // useGetOne with grant
    const { data, isLoading, error } = useGetOne<Document>(
      `${Entity.documents}/share`,
      {
        id,
        meta: {
          _embed: queryEmbeds.shareDoc,
        },
      },
      {
        enabled: isShared,
        onSuccess(data) {
          if (user) successToast("Copy the URL link to share!");
        },
        onError(error) {
          // console.log("onError", error);
          errorToast("No document found");
        },
      }
    );

    if (isLoading) {
      return (
        <Overlay>
          <Loader size={"xl"} />
        </Overlay>
      );
    }
    if (error) {
      console.error("error", error);
      redirectHome();
      return null;
    }

    if (data) {
      _document = data;
    }
  }

  const originalHtml = getEditorHtml(_document);
  // getHtmlString(_proposal as Proposal)
  // console.log("originalHtml", originalHtml);

  const htmlString = originalHtml
    // .replace(replaceTag("h1", "i"), "")
    // .replace(
    //   /<h1[^>]*>.*<\/h1>/g,
    //   ""
    //   // /<h1 style="text-align: center;">/g,
    //   // '<h1 style="text-align: center;font-size: 1.5rem;line-height: 2rem;">'
    // )
    .replace(/<h1>/g, "<h1 style='font-size: 20px;line-height: 5rem;'><b><u>")
    .replace(/<\/h1>/g, "</u>:</b></h1>")
    .replace(/<h2>/g, "<h2 style='font-size: 20px;line-height: 5rem;'><b><u>")
    .replace(/<\/h2>/g, "</u>:</b></h2>");
  // .replace(/<\/h2>/g, "</u></h2>")
  // .replace(/<p> <\/p>/g, "<br/>");

  // console.log("htmlString", htmlString);
  // console.log("isShare", isShare);

  return (
    <Overlay onDone={onClose}>
      <div
        className={`${tailwindHelpers?.responsiveModal} py-10 flex flex-col flex-1 rounded-lg mx-8`}
      >
        <div className="px-5 h-14 bg-[#B794F4] text-white flex flex-shrink-0 justify-between rounded-t-lg items-center">
          <p className="text-2xl font-semibold -sm:text-lg">
            {ellipsis(_document?.title || "View Document", 60)}
          </p>
          {!isShared && <CloseBtn variant="outline" closeModal={onClose} />}
        </div>

        <div className="px-5 pb-16 overflow-y-scroll custom-scrollbar text-sm bg-white relative overflow-hidden">
          <pre
            style={{
              fontFamily: "Helvetica, Arial, sans-serif",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {parseHtml(htmlString)}
          </pre>
        </div>
      </div>
    </Overlay>
  );
};

export default ViewDocument;
