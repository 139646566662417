import AppButton from "@components/AppButton";
import { FiInfo, FiPlus } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa";
import joinIcon from "@assets/dashboard/join-icon.png";
import copyIcon from "@assets/dashboard/copy-icon.png";
import proposalIcon from "@assets/dashboard/proposal-icon.png";
import uploadIcon from "@assets/dashboard/upload-icon.png";
import calendarIcon from "@assets/dashboard/calendar-icon.png";
import { useNavigate } from "react-router-dom";
import { ContextStore } from "@components/utils/Context";
import { useContext, useEffect } from "react";
import { ActionType, AddTabType, Entity, LinkType } from "@utils/interface";
import Banner from "@/components/Banner-1";
import AppBanner from "@components/AppBanner";
import { GrantResources, isDev, openInNewTab } from "@utils/index";
import { useDisplayToast, useGet } from "@utils/hooks";
import ModalSearch from "@/components/modal/ModalSearch";
import { ActionsBtn } from "@/components/Actions";

const Dashboard = () => {
  const {
    user,
    //  account,  showAddGrantModal,
    flags,
    actionModal,
    setActionModal,
    setAppModal,
  } = useContext(ContextStore);
  const navigate = useNavigate();
  // const { successToast, errorToast } = useDisplayToast();

  // const test = useGet<any>(
  //   'error',
  //   {
  //     id: ''
  //   },
  //   () => console.log('error'),
  //   (error) => errorToast(error?.body?.message)
  // );

  // useEffect(() => {
  //   test.refetch();
  // }, []);

  const actions = [
    {
      heading: "Invite Your Team to Join",
      desc: "Collaboration makes the grant process even more efficient and effective.",
      img: { icon: joinIcon, alt: "Join Icon" },
      fn: () => navigate("/organization?page=team"),
    },
    {
      heading: "Copy and Paste Grant Requirements",
      desc: "Simplify the grant application process by copying and pasting grant requirements directly into your workspace. Build the grant writing foundation, save time and ensure accuracy by effortlessly importing guidelines, criteria, and submission details.",
      img: { icon: copyIcon, alt: "Copy Icon" },
      fn: () =>
        setAppModal((prev) => ({
          ...prev,
          views: { ...prev.views, [ActionType.add]: true },
          type: LinkType.grant,
          addTab: AddTabType.copy,
        })),
    },
    {
      heading: "Create a New Grant Proposal",
      desc: "Start your grant journey by crafting a new grant proposal. Don't worry if you're new to this – our AI assistance is here to help you refine, streamline, and simplify the process, making it easier than ever.",
      img: { icon: proposalIcon, alt: "Proposal Icon" },
      fn: () => navigate(`/${Entity.proposals}`),
    },
    {
      heading: "Upload Important Files",
      desc: "Organize and centralize all your essential grant-related files in here. Easily upload, categorize, and access documents like project plans, financial reports, and supporting materials.",
      img: { icon: uploadIcon, alt: "Upload Icon" },
      fn: () => navigate(`/${Entity.files}`),
    },
    {
      heading: "Sync Your Calendar With Your Application Deadlines",
      desc: "Sync your calendar with the grant dashboard to never miss an important deadline. Receive timely reminders for submissions and follow-ups",
      img: { icon: calendarIcon, alt: "Calendar Icon" },
      fn: () => navigate("/calendar"),
    },
  ];

  return (
    <>
      {
        actionModal?.resourceList &&
        <ModalSearch
          position={"top-[6.5rem] right-5"}
          items={GrantResources || []}
          renderItem={(resource) => (
            <p className="text-wrap flex items-center">
              <resource.icon className="mr-2 min-w-5" />
              {resource?.label}
            </p>
          )}
          onDone={() =>
            setActionModal((prev) => ({ ...prev, resourceList: false }))
          }
          onClick={(resource) => openInNewTab(resource?.link)}
        />
      }
      <div className="h-full pt-2 pb-8">
        {isDev || (!!flags?.v2?.isEnabled && <AppBanner />)}
        <div className="flex px-5 pb-2 mb-5 border-b justify-between">
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <ActionsBtn className="bg-primary-light text-primary-dark hover:bg-secondary-light" onClick={() => setActionModal((prev) => ({ ...prev, resourceList: true }))}>
            <FiInfo /> <p className="ml-2">Grant Resources</p>
          </ActionsBtn>
        </div>

        <div className="px-5">
          {flags?.v3?.isEnabled ? (
            <p className="font-bold italic mb-8">
              You don’t have anything on your Dashboard yet? Here are some ways to
              get your dashboard filled.
            </p>
          ) : (
            <p className="font-bold text-center italic mb-8">
              Welcome to your Dashboard,{" "}
              <span className="text-[#6a54b1]">{user?.firstName}</span> 🎉 Here
              are some things you can do.
            </p>
          )}

          <div className="xl:flex">
            <div className="w-[330px] h-[280px] mx-auto mb-10 xl:mr-10 px-12 py-7 text-center text-white flex flex-col justify-between rounded-md bg-gradient">
              <p>Maximize the power of AI to enhance your grant application</p>

              <p className="text-xs">
                Ready to harness the power of AI for your grant application? Start
                by creating a grant proposal below
              </p>

              <AppButton
                onClick={() => navigate(`/${Entity.proposals}`)}
                text="Compose New Proposal"
                icon={FiPlus}
                variant="secondary"
                iconPosition="right"
                mb={3}
                mt={2}
                py={3}
                w={"max"}
                mx={"auto"}
              />
            </div>

            <div className="pb-10">
              {actions?.map((action, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => action.fn()}
                    className="mb-5 py-5 px-7 flex-between cursor-pointer rounded-xl border-2 bg-[#FCFAFF] hover:bg-slate-100"
                  >
                    <div className="flex-center mr-3">
                      <img
                        className="mr-3"
                        src={action.img.icon}
                        alt={action.img.icon}
                      />
                      <div>
                        <h2 className="font-bold">{action.heading}</h2>
                        <p className="text-sm">{action.desc}</p>
                      </div>
                    </div>
                    <div className="text-3xl">
                      <FaAngleRight />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
