import {
  IFile,
  includesString,
  isProd,
  isDev,
  tailwindHelpers,
} from "@utils/index";
import Overlay from "./Overlay";
import { useEffect, useState } from "react";
import Loader from "./utils/Loader";
import AgentChat from "@pages/AgentChat";
import CloseBtn from "./CloseButton";
import CenterLoader from "./utils/CenterLoader";

interface Props {
  onDone: () => void;
  file: IFile;
}

const IFrameViewer = (props: Props) => {
  const [loading, setLoading] = useState(true);

  // const [retryCount, setRetryCount] = useState(0);
  // const handleLoadError = () => {
  //   if (retryCount < 3) {
  //     // Limit retries to prevent infinite loops
  //     setRetryCount(retryCount + 1);
  //     // Optionally, delay the retry to avoid rapid retries
  //     setTimeout(() => {
  //       setLoading(true); // Trigger a re-render to attempt loading again
  //     }, 2000);
  //   }
  // };

  useEffect(() => {
    if (!loading) return;
    // set timeout to turn off loading after 5 seconds
    const timeout = setTimeout(() => {
      // console.log("turning off file loader...");
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const { onDone, file } = props;
  const { publicUrl: url, name } = file;
  // console.log("IFrameViewer", file);

  const isOfficeDoc = new RegExp(/.(doc|ppt|xls)(x|)$/).test(name);
  // includesString([".doc", ".ppt", ".xls"], name) &&
  // !includesString([".pdf"], name);
  const isImage = includesString([".png", ".jpg", ".jpeg", ".gif"], url);

  // https://stackoverflow.com/a/62705521
  return (
    <Overlay onDone={onDone}>
      <div
        className={`${tailwindHelpers?.responsiveModal2} pt-2 pb-2 flex flex-row`}
      >
        <div className="">
          <CloseBtn
            variant="outline"
            closeModal={onDone}
            className={`-sm:absolute -sm:left-5`}
          />
        </div>
        <div
          className={`xl:w-[80%] w-full h-screen flex justify-center border border-neutral-100`}
        >
          {loading ? <CenterLoader /> : null}
          {isImage ? (
            <img
              // loading="lazy"
              src={url}
              alt={name}
              className="w-full h-full object-contain"
              onLoad={() => setLoading(false)}
            />
          ) : (
            <div className="flex h-full w-full">
              <iframe
                // loading="lazy"
                onLoad={() => setLoading(false)}
                // onError={handleLoadError}
                src={
                  // isOfficeDoc
                  //   ? `https://view.officeapps.live.com/op/view.aspx?src=${url}` :
                  `https://docs.google.com/gview?url=${encodeURIComponent(
                    url
                  )}&embedded=true`
                }
                title={name}
                className="min-w-[100%]"
              />
              <AgentChat entity={file} />
            </div>
          )}
        </div>
      </div>
    </Overlay>
  );
};

export default IFrameViewer;
