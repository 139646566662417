import React, { useState, useEffect } from "react";

interface Props {
  color?: string;
  messages?: string[];
}

const TextLoader = (props: Props) => {
  const messages = props?.messages || [
    "Working",
    "Just a little while longer",
    "Almost done",
    "Hang in there",
  ];
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  let index = 0;
  let dotCount = 0;

  useEffect(() => {
    const switchInterval = setInterval(() => {
      setCurrentMessage(messages[index]);
      index = (index + 1) % messages.length;
    }, 5000);

    const dotInterval = setInterval(() => {
      dotCount++;
      // if (dotCount <= 5) {
      setCurrentMessage((prevMessage) => prevMessage + ".");
      // } else {
      //   dotCount = 0;
      // }
    }, 1000); // Add a dot every second

    return () => {
      clearInterval(switchInterval); // Clear interval on unmount
      clearInterval(dotInterval);
    };
  }, []);

  return (
    <div className="flex justify-center">
      <p
        className={`text-sm italic font-bold ${
          props?.color ? `text-[${props?.color}]` : ""
        }`}
      >
        {currentMessage}
      </p>
    </div>
  );
};

export default TextLoader;
