import React, { useContext, useEffect, useRef, useState } from "react";
import "@asseinfo/react-kanban/dist/styles.css";
import Board from "@asseinfo/react-kanban";
import {
  BoardItem,
  Dict,
  IResponse,
  Board as IBoard,
  LinkTypes,
} from "@utils/interface";
import { ContextStore } from "@components/utils/Context";
import { useStoreEntity, useDisplayToast } from "@utils/hooks";
import ActionModal from "@/components/modal/ActionModal";
import { BiArchive, BiTrash } from "react-icons/bi";
import { dragCard, dragColumn, removeColumn } from "./helpers";
// import ViewNotes from "./Notes";
import Header from "./Header";
import Card from "./Card";
import { ControlledBoardProps } from "./interface";
import ModalSearch from "@/components/modal/ModalSearch";

const ControlledBoard = (props: ControlledBoardProps) => {
  const { board, actionModal, setActionModal } = useContext(ContextStore);
  // const { successToast, errorToast, warningToast } = useDisplayToast();
  const { onUpdate, scrollRef } = props;

  const resetBoardModal = (key: string) => {
    setActionModal({
      ...actionModal,
      [key]: false,
      columnId: undefined,
      cardId: undefined,
      cb: undefined,
    });
  };

  // console.log("actionModal", actionModal);

  return (
    <>
      {/* {actionModal?.viewNotes && <ViewNotes />} */}
      {(actionModal?.deleteColumn ||
        actionModal?.deleteCard ||
        actionModal?.archiveCard) && (
        <ActionModal
          overlay
          icon={actionModal?.archiveCard ? <BiArchive /> : <BiTrash />}
          handleClose={() => {
            if (actionModal?.deleteColumn) {
              resetBoardModal("deleteColumn");
            } else {
              resetBoardModal(
                actionModal?.deleteCard ? "deleteCard" : "archiveCard"
              );
            }
          }}
          handleConfirm={() => {
            if (actionModal?.deleteColumn) {
              removeColumn(board, actionModal?.columnId, onUpdate);
              resetBoardModal("deleteColumn");
            } else {
              actionModal?.cb();
              resetBoardModal(
                actionModal?.deleteCard ? "deleteCard" : "archiveCard"
              );
            }
          }}
          title={"Confirm"}
        >
          <p>Are you sure?</p>
        </ActionModal>
      )}

      {actionModal?.modalSearch && (
        <ModalSearch
          position={"top-[10rem] left-[6rem]"}
          items={useStoreEntity(actionModal?.modalSearchType)}
          onDone={() =>
            setActionModal((prev) => ({ ...prev, modalSearch: false }))
          }
          onClick={(item) => actionModal?.modalSearchAction(item)}
        />
      )}

      <Board
        onCardDragEnd={(card, source, destination) =>
          dragCard(board, card, source, destination, onUpdate)
        }
        onColumnDragEnd={(column, from, to) =>
          dragColumn(board, column, from, to, onUpdate)
        }
        // renderCard={console.log} //card ,{dragging:boolean}
        renderCard={({ title, id, ...rest }, { dragging }) => {
          // console.log("renderCard",rest);
          return (
            <Card
              key={id}
              id={id}
              text={title}
              card={{ ...rest, id, title } as BoardItem}
              onUpdate={onUpdate}
            />
          );
        }}
        // renderColumnHeader={(...args) => console.log(args)}
        renderColumnHeader={({ title, id, cards }) => {
          // console.log("renderColumnHeader", title, id, cards);
          return (
            <div
              ref={
                id == board?.columns[board?.columns.length - 1].id
                  ? scrollRef
                  : null
              }
            >
              <Header id={id} key={id} title={title} onUpdate={onUpdate} />
            </div>
          );
        }}
      >
        {board}
      </Board>
    </>
  );
};

export default ControlledBoard;
