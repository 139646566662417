import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  localStorageKey,
  getAddress,
  Dict,
  PLANS,
  isPlanIncluded,
  upgradeMsg,
  copyToClipboard,
  isDev,
  Activity,
  Event,
  BoardItem,
  Grant,
  ID,
  Document,
  User,
  queryEmbeds,
  IFile,
  IResponse,
  IDict,
  DATE_TIME_FORMAT,
  isNullOrEmpty,
  pluralize,
  uniqueObjects,
  Account,
  FeatureFlag,
  PRODUCT_UPDATE_VIDEOS,
  shouldUpgrade,
  APP_CONST,
} from ".";
import { isEmpty, orderBy, startCase } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useToast } from "@chakra-ui/react";
import { createStandaloneToast } from "@chakra-ui/react";
import { ContextStore } from "@components/utils/Context";
import {
  useCreate,
  useDelete,
  useGetList,
  useGetMany,
  useGetOne,
  useUpdate,
} from "react-admin";
import moment from "moment";
import {
  AccountType,
  Chat,
  Entity,
  Folder,
  LinkType,
  LinkTypes,
  Recommendation,
  Snippet,
} from "@utils/interface";
import { toast } from "sonner";
// import { Editor } from "inkejs";
import { TipTapEditor } from "@/playground/editors/interface";

export function useGPSLocation() {
  const [values, setValues] = useState({
    street1: "",
    city: "",
    state: "",
    longState: "",
    zip: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGetLocation = async () => {
    setLoading(true);
    setError("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (data) => {
          const currentAddress: any = await getAddress(
            data.coords.latitude,
            data.coords.longitude
          );

          setValues({
            ...values,
            street1: `${currentAddress?.address_components[0]?.long_name} ${currentAddress?.address_components[1]?.long_name}`,
            city: currentAddress?.address_components[2]?.long_name,
            state: currentAddress?.address_components[6]?.short_name,
            longState: currentAddress?.address_components[6]?.long_name,
            zip: currentAddress?.address_components[8]?.long_name,
          });

          setLoading(false);
        },
        () => {
          setError(APP_CONST.locationErrorMessage);
          setLoading(false);
        }
      );
    } else {
      setError(APP_CONST.locationErrorMessage);
    }
  };

  return { values, error, loading, handleGetLocation };
}

export const useLocalStorage = () => {
  function setLocalStorageItem(key: string, payload: any, prefixId?: string) {
    localStorage.setItem(
      localStorageKey(key, prefixId),
      typeof payload === "string" ? payload : JSON.stringify(payload)
    );
  }
  function getLocalStorageItem(key: string, prefixId?: string): any {
    let res;
    const value = localStorage.getItem(localStorageKey(key, prefixId));
    try {
      res = JSON.parse(value || "{}");
    } catch (error) {
      res = value;
    }
    const response = !isEmpty(res) ? res : null;
    return response;
  }
  return { setLocalStorageItem, getLocalStorageItem };
};

export const useURLParams = (search: string = window.location.search) => {
  const queryParams = useQueryParams(search);
  const pathParams = useParams();
  return { queryParams, pathParams };
};

export const useQueryParams = (search?: string) => {
  // https://webtips.dev/solutions/get-query-params-in-react
  const location = search != undefined ? { search } : useLocation();
  // console.log("location", location);
  const searchParams = new URLSearchParams(location.search);

  // https://www.dhiwise.com/post/unlock-the-power-of-react-router-query-params
  const params: Dict = {};

  for (const param of searchParams) {
    const isBoolType = ["true", "false"].includes(param[1]);
    params[param[0]] = isBoolType ? /true/i.test(param[1]) : param[1];
  }

  return params;
};

export const useScroll = () => {
  const elRef = useRef<HTMLDivElement>(null);
  const executeScroll = () =>
    elRef && elRef?.current && elRef?.current.scrollTo(0, 0);
  const handleScroll = () => {
    window.scrollTo({
      top: elRef?.current?.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  return { executeScroll, elRef, handleScroll };
};

export const useDisplayToast = () => {
  // const toast = useToast();
  const { toast } = createStandaloneToast();
  const errorToast = (message: string, title?: string, duration = 5000) => {
    toast({
      title: title || "Uhh Ohh!",
      description: message,
      status: "error",
      duration: duration || 2000,
      position: "top-right",
      isClosable: true,
      containerStyle: {
        zIndex: 9999999,
      },
    });
  };
  const warningToast = (message: string, title?: string, duration = 2000) => {
    toast({
      title: title || APP_CONST.DEFAULT_ERROR,
      description: message,
      status: "warning",
      duration: duration || 2000,
      position: "top-right",
      isClosable: true,
      containerStyle: {
        zIndex: 9999999,
      },
    });
  };
  const successToast = (message: string, title?: string, duration = 2000) => {
    toast({
      title: title || "Success!",
      description: message,
      status: "success",
      duration: duration || 2000,
      position: "top-right",
      isClosable: true,
      containerStyle: {
        zIndex: 9999999,
      },
    });
  };
  return { errorToast, successToast, warningToast };
};

export const useTopScroll = () => {
  const scrollToTop = () => window.scrollTo(0, 0);
  return { scrollToTop };
};

export const usePlanFeatureCheck = () => {
  const { account, globalState, setGlobalState } = useContext(ContextStore);
  const { warningToast } = useDisplayToast();
  function needAccess(
    plans: string[],
    showWarning: boolean | null = true,
    message?: string | null,
    customCheck: boolean | null = true,
    cb?: (() => void) | null
  ) {
    if (
      //isDev ||
      globalState?.isAgency
    )
      return false;
    // console.log("usePlanFeatureCheck", check, isPlanIncluded(account, plans));
    const _check = customCheck !== null ? customCheck : true;
    const _showWarning = showWarning !== null ? showWarning : true;
    if (_check && isPlanIncluded(account, plans)) {
      cb && cb();
      if (_showWarning) warningToast(message || upgradeMsg, "Upgrade Required");
      // if (account?.planName == PLANS.free) {
      //   setTimeout(() => {
      //     setGlobalState((prev) => ({ ...prev, showUpgradeToPaid: true }));
      //   }, 1000);
      // }
      return true;
    }
  }
  return { needAccess };
};

export const useCopyToClipboard = () => {
  const { successToast, errorToast } = useDisplayToast();
  async function copyToClipboardWithToast(
    text: string,
    msg?: string,
    cb?: () => void
  ) {
    try {
      await copyToClipboard(text);
      successToast(msg || "Copied to clipboard");
      cb && cb();
    } catch (error) {
      errorToast("Failed to copy to clipboard");
    }
  }
  return { copyToClipboardWithToast };
};

export const usePut = <T>() => {
  return useUpdate<T & IDict, IResponse>();
};

export const usePost = <T>() => {
  return useCreate<T & IDict, IResponse>();
};

export const useDel = <T>() => {
  return useDelete<T & IDict, IResponse>();
};

export const useGet = <T>(
  path: string,
  payload: Dict,
  successCb?: (data: T) => void,
  errorCb?: (error: IResponse) => void
) => {
  // export const useGet = (path: string) => {
  const { errorToast } = useDisplayToast();

  return useGetOne<T & IDict>(path, payload as IDict, {
    enabled: false,
    onSuccess: (data) => {
      // console.log("useGet:onSuccess", data);
      successCb && successCb(data);
    },
    onError: (error) => {
      // console.log("useGet:onError", error);
      errorToast((error as IResponse)?.body?.message);
      errorCb && errorCb(error as IResponse);
    },
  });
};

export const useCommonQueries = (
  successCb?: (data?: any[]) => void,
  errorCb?: (error?: Error) => void
) => {
  const {
    user,
    globalState,
    account,
    boardItems,
    setBoardItems,
    setFiles,
    setTeam,
    setDocuments,
    setActivity,
    setGrants,
    setEvents,
    setGlobalState,
    setChats,
    setFolders,
    setAccount,
    setRecommendations,
    setSnippets,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();

  const fetchBoardItems = useGetList<BoardItem>(
    Entity.boardItems,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        accountId: account?.id,
      },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setBoardItems(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchTeam = useGetList<User>(
    Entity.users,
    {
      sort: { field: "status", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: { accountId: account?.id },
    },
    {
      enabled: false,
      onError: (error) => {
        toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setTeam(res?.data);
          // setTeam(!user?.onBehalfOfId ? res?.data : globalState?.agency?.users.concat(res?.data));
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchFiles = useGetList<IFile>(
    Entity.files,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        _embed: queryEmbeds.user,
        // type: 'SOURCE,VOICE',
      },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message, "Error Retrieving Files");
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setFiles(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchGrants = useGetMany<Grant>(
    Entity.grants,
    {
      ids: boardItems
        ?.filter((x) => x?.grantId)
        ?.map((item) => item?.grantId) as string[],
      // meta: { query: "" },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        // console.log("fetchGrants", res);
        if (res) {
          setGrants(res);
          successCb && successCb(res);
        }
      },
    }
  );

  const fetchDocuments = useGetList<Document>(
    Entity.documents,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        _embed: queryEmbeds.documents,
      },
    },
    {
      // https://stackoverflow.com/a/68173806/3562407
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setDocuments(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchAccountActivity = useGetList<Activity>(
    Entity.activity,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        read: { not: true },
        user: { is: null },
      },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        // console.log("fetchAccountActivity", res);
        if (res) {
          setActivity((prev) => ({
            user: prev?.user,
            account: res?.data,
          }));
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchUserActivity = useGetList<Activity>(
    Entity.activity,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        _embed: queryEmbeds.user,
        read: { not: true },
        userId: user?.id,
      },
    },
    {
      enabled: false,
      onError(error) {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message || "Error fetching activity");
      },
      onSuccess(resp) {
        setActivity((prev) => ({
          user: resp?.data,
          account: prev?.account,
        }));
      },
    }
  );

  const fetchCalendarEvents = (
    yearMonth: string,
    placeholders: { before: any[]; after: any[] }
  ) => {
    const fetchEvents = useGetList<Event>(
      Entity.events,
      {
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 100 },
        filter: {
          accountId: account?.id,
          _embed: pluralize(queryEmbeds.user),
          AND: {
            start: {
              gte: moment(yearMonth)
                .startOf("M")
                .subtract(placeholders?.before?.length, "d")
                .format(DATE_TIME_FORMAT),
              lte: moment(yearMonth)
                .endOf("M")
                .add(placeholders?.after?.length, "d")
                .format(DATE_TIME_FORMAT),
            },
          },
        },
      },
      {
        enabled: !yearMonth || !placeholders,
        onError(error) {
          // toast.error(error?.message, { duration: 5000 });
          errorToast(error?.message || "Error fetching Calender events");
          errorCb && errorCb(error);
        },
        onSuccess(resp) {
          // console.log("fetchEvents", resp);
          if (resp?.data?.length) {
            // TODO: handle cal events for agency
            // setEvents((prev) => [
            //   ...uniqueObjects(
            //     prev.concat(orderBy(resp?.data, ["start"], ["asc"]) as Event[])
            //   ),
            // ]);
            setEvents(orderBy(resp?.data, ["start"], ["asc"]) as Event[]);
          }
        },
      }
    );

    return fetchEvents;
  };

  const fetchAgency = useGet<Account>(
    Entity.accounts,
    {
      id: globalState?.agency?.id,
      meta: {
        _embed: "_count,primaryContact,linkedAccounts._count,linkedTo._count",
      },
    },
    (data) => {
      setGlobalState((prev) => ({ ...prev, agency: data }));
    },
    (error) => {
      // toast.error(error?.body?.message, { duration: 5000 });
      errorToast(error?.body?.message || "Error fetching agency");
      errorCb && errorCb(new Error(error?.body?.message));
    }
  );

  const fetchFeatureFlags = (names: string[]) => {
    const fetchFlags = useGetMany<FeatureFlag & ID>(
      Entity.flags,
      {
        ids: names?.map((name) => name?.toLowerCase()),
        meta: { query: "" },
      },
      {
        enabled: false,
        onError: (error: any) => {
          console.log(error);
          // toast.error(error?.message, {duration: 5000});
          errorCb && errorCb(error);
          // errorToast(error?.message || error?.body?.message)
        },
      }
    );
    return fetchFlags;
  };

  const fetchChats = useGetList<Chat>(
    Entity.chats,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        type: "CHAT",
        // userId: user?.id,  // pulling all account chats for now
        _embed: Entity.embeddings,
      },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res) {
          setChats(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchFolders = useGetList<Folder>(
    Entity.folders,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        accountId: account?.id,
        parentId: null,
        _embed: queryEmbeds.folders,
      },
    },
    {
      // https://stackoverflow.com/a/68173806/3562407
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setFolders(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchAccount = useGet<Account>(
    Entity.accounts,
    {
      id: account?.id,
      meta: {
        _embed: "_count,primaryContact,linkedAccounts._count,linkedTo._count",
      },
    },
    (data) => setAccount(data)
  );

  const fetchRecommendations = useGetList<Recommendation>(
    Entity.recommendations,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        accountId: account?.id,
        //  viewed: { not: true },
      },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res?.data) {
          setRecommendations(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  const fetchSnippets = useGetList<Snippet>(
    Entity.snippets,
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: { accountId: account?.id, },
    },
    {
      enabled: false,
      onError: (error) => {
        // toast.error(error?.message, { duration: 5000 });
        errorToast(error?.message);
        errorCb && errorCb(error);
      },
      onSuccess(res) {
        if (res) {
          setSnippets(res?.data);
          successCb && successCb(res?.data);
        }
      },
    }
  );

  return {
    fetchGrants,
    fetchBoardItems,
    fetchTeam,
    fetchFiles,
    fetchDocuments,
    fetchAccountActivity,
    fetchUserActivity,
    fetchCalendarEvents,
    fetchAgency,
    fetchFeatureFlags,
    fetchChats,
    fetchFolders,
    fetchAccount,
    fetchRecommendations,
    fetchSnippets,
  };
};

export const useStoreEntity = (entity: LinkTypes) => {
  const { grants, documents, projects, reports } = useContext(ContextStore);

  switch (entity) {
    case LinkType.document:
      return documents;
    case LinkType.project:
      return projects;
    case LinkType.report:
      return reports;
    default:
    case LinkType.grant:
      // return boardItems.filter((x) => x?.grant).map((i) => i.grant);
      return grants;
  }
};

export const useFeatureFlags = (names: string[]) => {
  // const { successToast, errorToast } = useDisplayToast();
  return useGetMany<FeatureFlag & ID>(
    Entity.flags,
    {
      ids: names.map((name) => name.toLowerCase()),
      meta: { query: "" },
    },
    {
      enabled: false,
      onError: (error: any) => {
        console.log(error);
        // errorToast(error?.message || error?.body?.message)
      },
    }
  );
};

export const useConfetti = () => {
  const { setAppModal } = useContext(ContextStore);
  const showConfetti = (min = 0.5) => {
    setAppModal((prev) => ({ ...prev, showConfetti: true }));
    setTimeout(() => {
      setAppModal((prev) => ({ ...prev, showConfetti: false }));
    }, 1000 * 30 * min);
  };
  return { showConfetti };
};

export const useAccountUpdate = (
  account: Account,
  payload: Partial<Account>,
  cb: (data: Account) => void
) => {
  const [update] = usePut<Account>();

  const handleUpdate = () => {
    update(
      Entity.accounts,
      {
        id: account.id,
        data: payload,
      },
      {
        onSuccess: (data) => {
          cb && cb(data);
        },
      }
    );
  };

  return { handleUpdate };
};

export const useFetchAccount = () => {
  const { account } = useContext(ContextStore);
  const { fetchAccount } = useCommonQueries();
  const refreshAccount = () => {
    if (
      fetchAccount &&
      account &&
      shouldUpgrade(account, AccountType.REGULAR)
    ) {
      fetchAccount.refetch();
    }
  };
  return { refreshAccount };
};

export const useToggleBubbleMenu = (editor?: TipTapEditor) => {
  const { setGlobalState } = useContext(ContextStore);
  const handleToggleBubble = (val: boolean) => {
    setGlobalState(prev => ({ ...prev, showBubbleMenu: val }))
    if (editor) editor?.setEditable(val);
  }
  return { handleToggleBubble }
}
