import { GrantAddProps } from "../interfaces";
import { useEffect, useRef } from "react";

const UrlTab = (props: GrantAddProps) => {
  const { grantInfo, setGrantInfo, setHasChanges } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  // check if is url regex
  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      // "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      // "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      // "(\\#[-a-z\\d_]*)?$",
      "",
    "i"
  );

  useEffect(() => {
    if (inputRef.current && !inputRef.current?.value) {
      inputRef.current.value = grantInfo;
      setHasChanges(false);
    }
  }, [inputRef]);

  return (
    <div className="text-sm px-5 pb-10">
      <div>
        <label htmlFor="grant-link">Enter the grant link below</label>
        <input
          ref={inputRef}
          autoFocus
          type="url"
          className="h-10 py-2 px-3 mt-1 w-full block outline-none rounded border"
          placeholder="Grant url here"
          name="grant-link"
          id="grant-link"
          onBlur={() => {
            const val = inputRef.current?.value || "";
            setGrantInfo(val);
            setHasChanges(!!val.trim() && urlRegex.test(val));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              inputRef.current?.blur();
            }
          }}
        />
      </div>
    </div>
  );
};

export default UrlTab;
