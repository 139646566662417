import { Switch } from "@chakra-ui/react";
import { FcAlarmClock } from "react-icons/fc";
import { AiTwotoneMail } from "react-icons/ai";
import { TbMailX, TbMailCheck } from "react-icons/tb";
import { GrAlarm } from "react-icons/gr";
import { useContext, useEffect, useState } from "react";
import { ContextStore } from "@components/utils/Context";
import { useDisplayToast, usePut } from "@utils/hooks";
import { Account, Dict, Entity } from "@utils/interface";
import { CgBell } from "react-icons/cg";
import Loader from "@components/utils/Loader";
import { GrCertificate } from "react-icons/gr";
import { BsRobot } from "react-icons/bs";
import { ellipsis, isPaidAccount } from "@utils/index";

enum PrefKey {
  pauseDeadlineReminderEmails = "pauseDeadlineReminderEmails",
  pauseTransactionalEmails = "pauseTransactionalEmails",
  pauseGrantRecsEmails = "pauseGrantRecsEmails",
  enablePrivateAi = "enablePrivateAi",
}

interface Pref {
  pauseGrantRecsEmails?: boolean;
  pauseDeadlineReminderEmails?: boolean;
  pauseTransactionalEmails?: boolean;
  enablePrivateAi?: boolean;
  deadlineInterval?: string;
}

const Preferences = () => {
  const { globalState, account, setAccount } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const [updateAccount, { isLoading }] = usePut<Account>();
  const [pref, setPref] = useState<Pref>();

  const handleUpdate = () => {
    updateAccount(
      Entity.accounts,
      {
        id: account?.id,
        data: {
          additionalData: pref,
        },
      },
      {
        onSuccess: (data) => {
          setAccount(data);
          successToast("Account updated!");
        },
        onError: (error) => {
          errorToast(error?.body?.message || "Error switching account");
        },
      }
    );
  };

  const handleToggle = (key: string) => {
    // setHasChanges((prev) => ({ ...prev, preferences: true }));
    setPref({
      ...pref,
      [key]: !pref?.[key],
    });
  };

  const options = [
    {
      icon: <BsRobot size={40} />,
      title: "Private AI",
      description: "Enable Private Secure AI instead of ChatGPT.",
      subText: `Our private model doesn't train from your data, conversations, or usage.`,
      onToggle: () => {
        handleToggle(PrefKey.enablePrivateAi);
      },
      key: PrefKey.enablePrivateAi,
      visible: pref?.[PrefKey.enablePrivateAi] || isPaidAccount(globalState?.isAgency ? globalState?.agency : account),
      defaultOff: true,
    },
    {
      icon: <GrAlarm size={40} />,
      title: "Grant Deadline Reminders",
      description: "Receive reminders for grant deadlines",
      onToggle: () => {
        handleToggle(PrefKey.pauseDeadlineReminderEmails);
      },
      key: PrefKey.pauseDeadlineReminderEmails,
      children: (
        <div className="mt-8">
          {/* <hr className="w-1/4 mx-auto border-t-[2px] mb-4" /> */}
          <div className="flex justify-between w-1/2 mx-auto -sm:flex-wrap -sm:justify-center">
            {/* <CgBell size={40} /> */}
            <span className="text-center">
              {/* <p className="text-xl ml-5 mr-5">Notification Intervals</p> */}
              <p className="text-sm text-neutral-400 sm:w-[90%]">
                How many <b>days</b> from deadline should notifications go out?{" "}
                <b>(max:30, min:1)</b>
              </p>
            </span>
            <input
              type="text"
              className="border border-primary text-sm rounded-md p-2 w-1/3 min-w-[100px] -sm:mt-5"
              placeholder="30,15,7,2"
              value={pref?.deadlineInterval || ""}
              onChange={(e) => {
                // setHasChanges((prev) => ({ ...prev, preferences: true }));
                setPref({
                  ...pref,
                  deadlineInterval: e.target.value,
                });
              }}
            />
          </div>
        </div>
      ),
    },
    {
      icon: <TbMailCheck size={40} />,
      title: "Transactional Emails",
      description:
        "Receive regular email communications (notifications, updates, etc.)",
      onToggle: () => {
        handleToggle(PrefKey.pauseTransactionalEmails);
      },
      key: PrefKey.pauseTransactionalEmails,
    },
    {
      icon: <GrCertificate size={40} />,
      title: "Grant Recommendations",
      description:
        "Receive tailored grant recommendations and other related emails",
      onToggle: () => {
        handleToggle(PrefKey.pauseGrantRecsEmails);
      },
      key: PrefKey.pauseGrantRecsEmails,
    },
  ];

  useEffect(() => {
    // {
    //   return () => {
    //     setHasChanges((prev) => ({ ...prev, preferences: false }));
    //   }
    // }
    setPref((account?.additionalData || {}) as Pref);
  }, [account]);

  const hasChanges =
    account?.additionalData &&
    JSON.stringify(account?.additionalData) !== JSON.stringify(pref);

  // console.log("pref", hasChanges, pref, account);

  return (
    <div className="mt-10 p-5 flex flex-col ">
      <p className="text-2xl uppercase text-center ">
        Manage Account Preferences
      </p>
      <p className="text-center text-neutral-400 mt-5">
        Customize <b>{ellipsis(account?.name, 60)}'s</b> account preferences
      </p>
      <button
        disabled={!hasChanges || isLoading}
        className={`bg-primary text-white w-1/4 -sm:w-[90%] mx-auto mt-10 p-2 rounded-md ${!hasChanges && "opacity-50"
          }`}
        onClick={handleUpdate}
      >
        {isLoading ? <Loader size="md" /> : "Save Changes"}
      </button>
      <hr className="my-5 pb-5" />
      {options?.map((item, index) => (
        <div key={index}>
          <div className="flex justify-between w-1/2 mx-auto -sm:flex-wrap -sm:justify-center -sm:children:pt-2">
            {item?.icon}
            <span className="text-center">
              <p className="text-xl ml-5 mr-5">{item?.title}</p>
              <p className="text-sm text-neutral-400 ">{item?.description}</p>
              {item?.subText && (
                <p className="text-xs text-neutral-400 font-bold">
                  {item?.subText}
                </p>
              )}
            </span>
            <Switch
              size="lg"
              isChecked={
                item?.defaultOff ? pref?.[item?.key] : !pref?.[item?.key]
              }
              colorScheme="purple"
              className="ml-2"
              onChange={item?.onToggle}
              disabled={item?.visible === false}
            />
          </div>
          {item?.children}
          <hr className="w-1/2 mx-auto border-t-[2px] my-10" />
        </div>
      ))}
    </div>
  );
};

export default Preferences;
