"use client";

import {
  useState,
  useEffect,
  Suspense,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { motion, useAnimation } from "framer-motion";
import { ContentItem } from "../lib/types/note";
import { exportAsJson, fetcher } from "../lib/utils";
// import { Collaboration, ShareNote } from "@prisma/client";
import SearchInput from "../ui/search-input";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  Download,
  DownloadCloud,
  Edit,
  ExternalLink,
  Minus,
  Plus,
  Trash2,
  Shapes,
  FolderClosed,
  FolderOpen,
  FolderEdit,
} from "lucide-react";
import Tooltip from "../ui/shared/tooltip";
import useWindowSize from "../lib/hooks/use-window-size";
// import toast from "react-hot-toast";
import { toast } from "sonner";
import { addItem, deleteItem, bulkAddItems, updateItem } from "../store/db.model";
// import useLocalStorage from "../lib/hooks/use-local-storage";
// import { Note_Storage_Key, VERSION_LIMIT } from "../lib/consts";
import { Document, newUUID } from "@/utils";
import { TipTapEditor } from "@/playground/editors/interface";
import { JSONContent } from "@tiptap/react";

interface Props {
  doc: Document;
  versions: ContentItem[];
  itemId?: string;
  setItemId: Dispatch<SetStateAction<string | undefined>>;
  editor?: TipTapEditor;
  handleVersionDoc: (updatedContent: JSONContent, itemIndex: number, forceSave?: boolean) => void;
}

export default function Sidebar(props: Props) {
  const { doc, versions, itemId, setItemId, editor, handleVersionDoc } = props;
  const { id: docId } = doc;

  const { isMobile } = useWindowSize();

  const [active, setActive] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [showEditCate, setShowEditCate] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const controls = useAnimation();
  const controlText = useAnimation();
  const controlTitleText = useAnimation();

  // const [contentsCache, setContentsCache] = useState<ContentItem[]>([]);
  const [categorizedData, setCategorizedData] = useState<{
    [key: string]: ContentItem[];
  }>();

  const [openHistory, setOpenHistory] = useState(true);
  const [openFolder, setOpenFolder] = useState(true);

  const editCateRef = useRef<HTMLInputElement>(null);
  const editTitleRef = useRef<HTMLInputElement>(null);

  // const [oldContents, setOldContents] = useLocalStorage<ContentItem[]>(Note_Storage_Key, []);

  const showMore = () => {
    controls.start({
      width: "270px",
      transition: { duration: 0.001 },
    });
    controlText.start({
      opacity: 1,
      display: "block",
      transition: { delay: 0.3 },
    });
    controlTitleText.start({
      opacity: 1,
      transition: { delay: 0.3 },
    });

    setActive(true);
    setOpenFolder(true);
  };

  const showLess = () => {
    controls.start({
      width: "0px",
      transition: { duration: 0.001 },
    });

    controlText.start({
      opacity: 0,
      display: "none",
    });

    controlTitleText.start({
      opacity: 0,
    });

    setActive(false);
  };

  // // patch
  // useEffect(() => {
  //   if (oldContents.length > 0) {
  //     bulkAddItems(oldContents);
  //     setOldContents([]);
  //   }
  // }, [oldContents]);

  useEffect(() => {
    showLess();
  }, []);

  useEffect(() => {
    if (isMobile) {
      showLess();
    }
  }, [isMobile]);

  useEffect(() => {
    if (searchKey === "") {
      // setContentsCache(versions);
      setCategorizedData(() => {
        return (
          versions
            // .sort((a, b) => b.updated_at - a.updated_at)
            .reduce((acc, item) => {
              const tag = item.tag || ""; // If tag is undefined, default it to an empty string
              if (!acc[tag]) {
                acc[tag] = [];
              }
              acc[tag].push(item);
              return acc;
            }, {} as { [key: string]: ContentItem[] })
        );
      });
    }
  }, [searchKey, versions]);

  const handleDeleteItem = (id: string) => {
    deleteItem(id);
    // const last2 = versions?.slice(-2) || [];
    // const newItem = last2[0]?.id !== id ? last2[0] : last2[1];
    // setItemId(newItem?.id);
  };

  const handleEditTitle = (id: string) => {
    if (showEditInput) {
      setShowEditInput(false);
      const index = versions.findIndex((item) => item.id === id);
      if (index !== -1) {
        updateItem({
          ...versions[index],
          title: editTitleRef.current!.value,
        });
      }
    } else {
      setShowEditInput(true);
      setItemId(id);
    }
  };

  const handleEditCate = (id: string) => {
    if (showEditCate) {
      setShowEditCate(false);
      const index = versions.findIndex((item) => item.id === id);
      if (index !== -1) {
        updateItem({
          ...versions[index],
          tag: editCateRef.current!.value,
        });
      }
    } else {
      setShowEditCate(true);
      setItemId(id);
    }
  };

  const handleInputSearch = (value: string) => {
    if (value.length > 0) {
      setSearchKey(value);
      const local_res = versions.filter((item) => {
        if (
          item.title.includes(value) ||
          JSON.stringify(item.content).includes(value) ||
          (item.tag && item.tag.includes(value))
        ) {
          return item;
        }
      });
      // setContentsCache(local_res);
      setCategorizedData(() => {
        return (
          local_res
            // .sort((a, b) => b.updated_at - a.updated_at)
            .reduce((acc, item) => {
              const tag = item.tag || ""; // If tag is undefined, default it to an empty string
              if (!acc[tag]) {
                acc[tag] = [];
              }
              acc[tag].push(item);
              return acc;
            }, {} as { [key: string]: ContentItem[] })
        );
      });
    } else {
      setSearchKey("");
    }
  };

  const handleSyncPublishToLocal = (localId: string, remoteDate: string) => {
    const data = JSON.parse(remoteDate || "{}");
    if (remoteDate && data) {
      addItem(data);
    }
  };

  const handleToggleCollapse = (tag: string) => {
    // setCategorizedData((prevData) => {
    //   const updatedData = { ...prevData };
    //   updatedData[tag].forEach((item) => {
    //     item.collapsed = !item.collapsed;
    //   });
    //   return updatedData;
    // });
    setOpenFolder(!openFolder);
    if (openFolder) showLess();
  };

  const handleSwitchVersion = (id: string) => {
    if (editor) {
      const usersList = (editor.storage?.collaborationCursor?.users)
        .filter(u => u?.name !== undefined)
        .filter((u, i, s) => s.findIndex(t => (t.clientId === u.clientId)) === i);
      if (usersList?.length > 1) {
        toast.warning("There are other users currently editing. Switching versions is disabled.");
        return;
      }
    }

    // save version
    if (editor && itemId === undefined) {
      handleVersionDoc(editor.getJSON(), versions.length - 1);
    }
    setItemId(id);
  }

  return (
    <div className="relative">
      <motion.div
        animate={controls}
        className={
          `${active ? "border-r" : ""}` +
          " animate group flex h-screen w-[270px] flex-col gap-3 overflow-y-auto border-slate-200/60 py-6 duration-300"
        }
      >
        {active ? (
          <button
            onClick={showLess}
            className="absolute -right-4 top-28 z-[5] cursor-pointer rounded-r bg-slate-100 py-2 shadow transition-all hover:bg-slate-200 "
          >
            <ChevronLeft className="h-4 w-4 text-purple-400" />
          </button>
        ) : (
          <button
            onClick={showMore}
            className="absolute -right-4 top-28 z-[5] cursor-pointer rounded-r bg-slate-100 py-2 shadow transition-all hover:bg-slate-200"
          >
            <ChevronRight className="h-4 w-4 text-purple-400" />
          </button>
        )}

        {/* <div className="mx-3 flex flex-col gap-2">
          <SearchInput onChange={handleInputSearch} />
        </div>
        <div className="border-b border-slate-200/70" /> */}

        <div className="h-[40%] w-full grow overflow-y-auto px-3">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => {
              setOpenHistory(!openHistory);
            }}
          >
            <p className="font-mono text-sm font-semibold text-slate-400">
              Snapshots({versions.length})
            </p>
            {/* <button className="rounded bg-slate-100 hover:bg-slate-200">
              {openHistory ? (
                <Minus className="h-5 w-5 cursor-pointer p-1 text-purple-500" />
              ) : (
                <Plus className="h-5 w-5 cursor-pointer p-1 text-purple-500" />
              )}
            </button> */}
          </div>

          {openHistory &&
            categorizedData &&
            Object.keys(categorizedData).map((tag) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                key={tag}
              >
                <h2
                  className={
                    `${categorizedData[tag].findIndex((i) => i.docId === docId) !== -1
                      ? "text-purple-500"
                      : "text-gray-500"
                    }` +
                    " flex cursor-pointer items-center justify-start gap-1 pt-2 font-mono text-xs font-semibold transition-all  hover:text-slate-300"
                  }
                  onClick={() => handleToggleCollapse(tag)}
                >
                  {openFolder ? (
                    <FolderOpen className="h-3 w-3 text-slate-400" />
                  ) : (
                    <FolderClosed className="h-3 w-3 text-slate-400" />
                  )}
                  {doc?.title || "Uncategorized"}
                </h2>
                {openFolder &&
                  categorizedData[tag].map((item, idx) => (
                    <div
                      className="group/item my-2 mb-2 flex items-center justify-between gap-2 pl-4 transition-all"
                      key={item.id}
                    >
                      {showEditInput && itemId === item.id ? (
                        <input
                          ref={editTitleRef}
                          type="text"
                          className="rounded border px-2 py-1 text-xs text-slate-500"
                          defaultValue={item.title}
                          placeholder="Enter note title"
                        />
                      ) : showEditCate && itemId === item.id ? (
                        <input
                          ref={editCateRef}
                          type="text"
                          className="rounded border px-2 py-1 text-xs text-slate-500"
                          defaultValue={item.tag}
                          placeholder="Enter note category"
                        />
                      ) : (
                        <p
                          className={
                            "flex cursor-pointer items-center justify-start gap-2 truncate font-mono text-xs hover:opacity-80 " +
                            `${itemId === item.id ? "text-purple-500" : "text-gray-500"
                            }`
                          }
                          onClick={() => handleSwitchVersion(item.id)}
                        >
                          {item.title.length > 0 ? item.title : "Untitled"} {(idx === versions.length - 1) ? '(latest)' : ''}
                        </p>
                      )}

                      <div className="ml-auto hidden group-hover/item:block">
                        <div className="flex items-center justify-end gap-2">
                          {docId === item.docId && (
                            <button onClick={() => handleEditTitle(item.id)}>
                              {showEditInput ? (
                                <Check className="h-4 w-4 text-green-500" />
                              ) : (
                                <Edit className="h-4 w-4 text-slate-300 hover:text-slate-500" />
                              )}
                            </button>
                          )}
                          {/* {docId === item.docId && (
                            <button onClick={() => handleEditCate(item.id)}>
                              {showEditCate ? (
                                <Check className="h-4 w-4 text-green-500" />
                              ) : (
                                <FolderEdit className="h-4 w-4 text-slate-300 hover:text-slate-500" />
                              )}
                            </button>
                          )} */}
                          {docId === item.docId && (
                            <button onClick={() => handleDeleteItem(item.id)}>
                              <Trash2 className="h-4 w-4 text-slate-300" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </motion.div>
            ))}

        </div>
        <div className="border-b border-slate-200/70" />
      </motion.div>
    </div>
  );
}
