import { FiX } from "react-icons/fi";
import { Dict, } from "@utils/interface";
import { useContext, useEffect, useRef, useState } from "react";
import AssistantChat from "@components/document/AssistantChat";
import { BiCalendarAlt, BiMessageRoundedDots, BiSave } from "react-icons/bi";
import { debug } from "@utils/logging";
import { useOnClickOutside } from "usehooks-ts";
import { pick } from "lodash";
import { ContextStore } from "@components/utils/Context";

interface Props {
  context?: string;
  entity: Dict & Partial<{ id: string; $kind: string }>;
  handleCb?: (data: Dict) => void;
}

const AgentChat = (props: Props) => {
  // const { globalState } = useContext(ContextStore);

  const { context, entity, handleCb } = props;

  const [isChatOpen, setIsChatOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => setIsChatOpen(false));

  // const handleInsert = (text: string) => {
  //   // console.log("handleInsert", text);
  //   insertText.current = text;
  // };

  return (
    <div className="p-4 sticky top-[12rem] flex flex-col">
      <div className="flex flex-1 overflow-y-hidden overflow-x-hidden h-full">
        <div className="h-full flex-shrink-0 ml-10 hidden lg:block bg-white">
          <AssistantChat
            entity={entity}
            context={context}
            handleCb={handleCb}
          />
        </div>

        {isChatOpen ? (
          <div
            ref={modalRef}
            className="lg:hidden h-[550px] bg-white absolute bottom-2 right-0 z-[1001]"
          >
            <button
              onClick={() => setIsChatOpen(false)}
              className=" absolute z-20 top-2 left-1 p-1 bg-[#eee8f8] hover:bg-[#6a54b1] text-[#6a54b1] hover:text-white rounded-full"
            >
              <FiX />
            </button>
            <AssistantChat
              entity={entity}
              context={context}
              handleCb={handleCb}
            />
          </div>
        ) : (
          <button
            onClick={() => {
              // if (needAccess([PLANS.basic])) return;
              setIsChatOpen(true);
            }}
            className="fixed z-50 bottom-7 right-7 lg:hidden h-max p-1 text-3xl rounded bg-[#eee8f8] hover:bg-[#6a54b1] text-[#6a54b1] hover:text-white"
          >
            <BiMessageRoundedDots />
          </button>
        )}
      </div>
    </div>
  );
};

export default AgentChat;
