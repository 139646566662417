// import { stringToSlate } from ".";
// https://github.com/thompsonsj/slate-serializers/tree/main/packages/html
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import * as pdfMake from "pdfmake/build/pdfmake";
import pdfMake from "pdfmake/build/pdfmake"; //https://github.com/bpampuch/pdfmake/issues/1877#issuecomment-962446474
import htmlToPdf from "html-to-pdfmake";
import slateToHtml from "./slateToHtml";
import { Node } from "slate";

// // https://vitejs.dev/guide/assets.html
// // https://github.com/foliojs/pdfkit/tree/master/js/data
// // https://github.com/blikblum/pdfkit-webpack-example/issues/1
import Courier_n from "pdfkit/js/data/Courier.afm?url";
import Courier_b from "pdfkit/js/data/Courier-Bold.afm?url";
import Courier_i from "pdfkit/js/data/Courier-Oblique.afm?url";
import Courier_bi from "pdfkit/js/data/Courier-BoldOblique.afm?url";
import Helvetica_n from "pdfkit/js/data/Helvetica.afm?url";
import Helvetica_b from "pdfkit/js/data/Helvetica-Bold.afm?url";
import Helvetica_bi from "pdfkit/js/data/Helvetica-BoldOblique.afm?url";
import Helvetica_i from "pdfkit/js/data/Helvetica-Oblique.afm?url";
import Times_n from "pdfkit/js/data/Times-Roman.afm?url";
import Times_i from "pdfkit/js/data/Times-Italic.afm?url";
import Times_bi from "pdfkit/js/data/Times-BoldItalic.afm?url";
import Times_b from "pdfkit/js/data/Times-Bold.afm?url";
import ZapfDingbats_n from "pdfkit/js/data/ZapfDingbats.afm?url";
import Symbol_n from "pdfkit/js/data/Symbol.afm?url";
import Roboto_i from "@assets/fonts/Roboto-Italic.ttf?url";
import Roboto_b from "@assets/fonts/Roboto-Medium.ttf?url";
import Roboto_bi from "@assets/fonts/Roboto-MediumItalic.ttf?url";
import Roboto_n from "@assets/fonts/Roboto-Regular.ttf?url";
import { PdfAssetsLoader } from "pdfmake-utils";
import { Dict, notifyError } from "@utils/index";
import { TDocumentDefinitions } from "pdfmake/interfaces";

const assetsLoader = new PdfAssetsLoader();

// all possible standard fonts
assetsLoader.registerFont({
  name: "Times",
  fileName: "Times-Roman",
  // URL: "node_modules/pdfkit/js/data/Times-Roman.afm",
  URL: Times_n,
  styles: ["normal"],
});
assetsLoader.registerFont({
  name: "Times",
  fileName: "Times-Italic",
  // URL: "node_modules/pdfkit/js/data/Times-Italic.afm",
  URL: Times_i,
  styles: ["italics"],
});
assetsLoader.registerFont({
  name: "Times",
  fileName: "Times-Bold",
  // URL: "node_modules/pdfkit/js/data/Times-Bold.afm",
  URL: Times_b,
  styles: ["bold"],
});
assetsLoader.registerFont({
  name: "Times",
  fileName: "Times-BoldItalic",
  // URL: "node_modules/pdfkit/js/data/Times-BoldItalic.afm",
  URL: Times_bi,
  styles: ["bolditalics"],
});

assetsLoader.registerFont({
  name: "Courier",
  fileName: "Courier",
  // URL: "node_modules/pdfkit/js/data/Courier.afm",
  URL: Courier_n,
  styles: ["normal"],
});
assetsLoader.registerFont({
  name: "Courier",
  fileName: "Courier-Oblique",
  // URL: "node_modules/pdfkit/js/data/Courier-Oblique.afm",
  URL: Courier_i,
  styles: ["italics"],
});
assetsLoader.registerFont({
  name: "Courier",
  fileName: "Courier-Bold",
  // URL: "node_modules/pdfkit/js/data/Courier-Bold.afm",
  URL: Courier_b,
  styles: ["bold"],
});
assetsLoader.registerFont({
  name: "Courier",
  fileName: "Courier-BoldOblique",
  // URL: "node_modules/pdfkit/js/data/Courier-BoldOblique.afm",
  URL: Courier_bi,
  styles: ["bolditalics"],
});

assetsLoader.registerFont({
  name: "Symbol",
  // URL: "node_modules/pdfkit/js/data/Symbol.afm",
  URL: Symbol_n,
  fileName: "Symbol",
});
assetsLoader.registerFont({
  name: "ZapfDingbats",
  // URL: "node_modules/pdfkit/js/data/ZapfDingbats.afm",
  URL: ZapfDingbats_n,
  fileName: "ZapfDingbats",
});

assetsLoader.registerFont({
  name: "Helvetica",
  fileName: "Helvetica",
  // URL: "node_modules/pdfkit/js/data/Helvetica.afm",
  URL: Helvetica_n,
  styles: ["normal"],
});
assetsLoader.registerFont({
  name: "Helvetica",
  fileName: "Helvetica-Oblique",
  // URL: "node_modules/pdfkit/js/data/Helvetica-Oblique.afm",
  URL: Helvetica_i,
  styles: ["italics"],
});
assetsLoader.registerFont({
  name: "Helvetica",
  fileName: "Helvetica-Bold",
  // URL: "node_modules/pdfkit/js/data/Helvetica-Bold.afm",
  URL: Helvetica_b,
  styles: ["bold"],
});
assetsLoader.registerFont({
  name: "Helvetica",
  fileName: "Helvetica-BoldOblique",
  // URL: "node_modules/pdfkit/js/data/Helvetica-BoldOblique.afm",
  URL: Helvetica_bi,
  styles: ["bolditalics"],
});

// https://github.com/blikblum/pdfmake-utils/blob/master/example/webpack.config.js
assetsLoader.registerFont({
  name: "Roboto",
  fileName: "Roboto-Regular.ttf",
  URL: Roboto_n,
  styles: ["normal"],
});
assetsLoader.registerFont({
  name: "Roboto",
  fileName: "Roboto-Italic.ttf",
  URL: Roboto_i,
  styles: ["italics"],
});
assetsLoader.registerFont({
  name: "Roboto",
  fileName: "Roboto-Medium.ttf",
  URL: Roboto_b,
  styles: ["bold"],
});
assetsLoader.registerFont({
  name: "Roboto",
  fileName: "Roboto-MediumItalic.ttf",
  URL: Roboto_bi,
  styles: ["bolditalics"],
});

function encode_utf8(s: string) {
  return btoa(unescape(encodeURIComponent(s)));
}

function getBase64ImageFromURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  }).catch((error) => {
    // console.log("getBase64ImageFromURL", error);
    throw new Error(
      "Image load has been blocked by CORS policy. Please link image from a server that permits loading from another origin."
    );
  });
}

export const exportToPdf = (
  pages: string | Node[],
  filename: string,
  cb: (success: boolean, error?: Error) => void
) => {
  // console.log("exportToPdf", filename);
  // console.log(pages);

  //1. convert the SlateJS nodes to HTML.
  //2. convert the HTML to a PDF document.
  //3. generate the PDF document

  const options = {
    defaultStyles: {
      mark: { background: "yellow" },
      pre: {
        font: "Courier",
        background: "gray",
        fontSize: 10,
        margin: [0, 0, 0, 10] as any,
      },
      blockquote: { margin: [0, 10, 0, 0] as any },
      sub: { fontSize: 8, baseline: -2 },
      sup: { fontSize: 8, baseline: 2 },
      del: { decoration: "lineThrough" as any },
    },
  };

  assetsLoader
    .load()
    .then(async () => {
      assetsLoader.configurePdfMake(pdfMake);

      // try {
      //   // https://github.com/bpampuch/pdfmake/issues/2628
      //   //   // https://pdfmake.github.io/docs/0.1/getting-started/client-side/
      //   // (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
      //   // pdfMake.vfs = pdfFonts.pdfMake.vfs;
      //   // (pdfMake as any).vfs = pdfFonts;
      // } catch (e) {
      //   console.log(e);
      // }

      const results: any[] = [];

      try {
        if (Array.isArray(pages) && pages.length > 0) {
          for (const page of pages) {
            if (page?.type === "image" || page?.type === "img") {
              const imageDataURL = await getBase64ImageFromURL(page.url!);
              results.push({
                image: imageDataURL,
                width: 150,
                height: 150,
              });
            } else {
              const html = slateToHtml(page);
              // console.log(html);
              const content = htmlToPdf(html, options);
              // console.log("docDefinition:", content);
              results.push(content);
            }
          }
        } else if (typeof pages === "string") {
          const content = htmlToPdf(pages, options);
          // console.log("docDefinition:", content);
          results.push(content);
        }

        const docDefinition = {
          content: results,
        };

        const pdfDocGenerator = pdfMake
          .createPdf(docDefinition as unknown as TDocumentDefinitions)
          .download(`${filename}.pdf`);

        // // pdfDocGenerator.download(`${filename}.pdf`);
        // pdfDocGenerator.getBlob((blob) => {
        //   const url = URL.createObjectURL(blob);

        //   // Create download link element
        //   const a = document.createElement("a");
        //   a.href = url;
        //   a.download = `${filename}.pdf`;
        //   a.click();
        // });

        cb(true);
      } catch (error: any) {
        console.log("error:", error);
        cb(false, error);
      }
    })
    .catch((error) => {
      // will fail if one of the files fails to load
      // configure pdfMake with the files that loaded correctly
      // assetsLoader.configurePdfMake(pdfmake)
      console.error("PDF ERROR", error);
      // notifyError(`EXPORT PDF: ERROR:${JSON.stringify(error.stack)}\n`);
    });
};

// // // https://github.com/bpampuch/pdfmake/issues/1877#issuecomment-1661574981
// // // https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/url/
// // // https://github.com/bpampuch/pdfmake/blob/master/build/vfs_fonts.js
// // // https://pdfmake.github.io/docs/0.1/fonts/standard-14-fonts/
// // // PDF Fonts
// const pdfFonts = {
//   // Roboto: {
//   //   normal: 'Roboto-Regular.ttf',
//   //   bold: 'Roboto-Medium.ttf',
//   //   italics: 'Roboto-Italic.ttf',
//   //   bolditalics: 'Roboto-Italic.ttf'
//   // },
//   // OpenSans: {
//   //   normal: 'OpenSans-Regular.ttf',
//   //   bold: 'OpenSans-Medium.ttf',
//   //   italics: 'OpenSans-Italic.ttf',
//   //   bolditalics: 'OpenSans-Italic.ttf'
//   // },

//   // download default Roboto font from cdnjs.com
//   // Roboto: {
//   //   normal:
//   //     "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf",
//   //   bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Medium.ttf",
//   //   italics:
//   //     "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Italic.ttf",
//   //   bolditalics:
//   //     "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-MediumItalic.ttf",
//   // },
//   // Roboto: {
//   //   ...Roboto
//   // },
//   // // https://pdfmake.github.io/docs/0.1/fonts/standard-14-fonts/
//   // Courier: {
//   //   normal: encode_utf8(Courier_n),
//   //   bold: encode_utf8(Courier_b),
//   //   italics: encode_utf8(Courier_i),
//   //   bolditalics: encode_utf8(Courier_bi),
//   // },
//   // "Helvetica.afm": {
//   //   normal: encode_utf8(Helvetica_n),
//   //   bold: encode_utf8(Helvetica_b),
//   //   italics: encode_utf8(Helvetica_i),
//   //   bolditalics: encode_utf8(Helvetica_bi),
//   // },
//   // Times: {
//   //   normal: encode_utf8(Times_n),
//   //   bold: encode_utf8(Times_b),
//   //   italics: encode_utf8(Times_i),
//   //   bolditalics: encode_utf8(Times_bi),
//   // },
//   // Symbol: {
//   //   normal: 'Symbol'
//   // },
//   // ZapfDingbats: {
//   //   normal: 'ZapfDingbats'
//   // }
// };
