import React, { useContext, useRef, useState } from "react";
import { ControlledHeaderProps } from "./interface";
import { BoardItem, Dict, IResponse, MenuAction } from "@utils/interface";
import { ContextStore } from "@components/utils/Context";
import Actions from "@components/Actions";
import { BiArchiveIn, BiTrash, BiX } from "react-icons/bi";
import { PiPlus } from "react-icons/pi";
import { addEmptyCard, renameColumn } from "./helpers";

const Header = ({ title, id, onUpdate }: ControlledHeaderProps) => {
  // console.log("Header", title, id);
  const { flags, board, actionModal, setActionModal } =
    useContext(ContextStore);
  const [renameCol, setRenameCol] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  const handleAddCard = () => {
    addEmptyCard(board, onUpdate);
  };

  const handleRename = () => {
    setRenameCol(false);
    renameColumn(board, id, newTitle, onUpdate);
    setNewTitle("");
  };

  const isFirstBoard = board?.columns[0]?.id === id;

  const columnActions: MenuAction = {
    top: [
      {
        label: "Add Card",
        iconBefore: <PiPlus />,
        onSelect: handleAddCard,
        visible: isFirstBoard,
      },
    ],
    bottom: [
      {
        label: "Archive All",
        iconBefore: <BiArchiveIn />,
        onSelect: () => alert("option 1"),
        visible: !!flags?.v2?.isEnabled,
      },
      {
        label: "Delete All",
        iconBefore: <BiTrash />,
        onSelect: () => alert("option 2"),
        visible: !!flags?.v2?.isEnabled,
      },
    ],
  };

  return (
    <>
      <div className=" px-3 top-0 mb-3 flex-y-center justify-between">
        {renameCol ? (
          <input
            type="text"
            value={newTitle || title}
            onChange={(e) => setNewTitle(e.target.value)}
            onBlur={handleRename}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRename();
              }
            }}
          />
        ) : (
          <span className="font-bold flex" onClick={() => setRenameCol(true)}>
            {title}
            <span className=" ml-1 mt-1 w-4 h-4 text-xs bg-[#D0D5DD] flex justify-center items-center rounded-full">
              {board?.columns?.find((x) => x.id === id)?.cards?.length}
            </span>
          </span>
        )}
        {/* {renderAddCard()} */}
        <div className="flex-y-center">
          <button
            className="flex-center w-5 h-5 hover:bg-neutral-300 bg-red-300 rounded-full text-xl"
            onClick={() => {
              setActionModal({
                ...actionModal,
                deleteColumn: true,
                columnId: id,
              });
            }}
          >
            <BiX />
          </button>
          {isFirstBoard && (
            <Actions
              isVertical={true}
              actions={columnActions}
              emptyClass={true}
              className={`flex-center w-5 h-5 hover:bg-neutral-300 bg-[#c2aef8] rounded-full`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
