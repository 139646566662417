import { useContext, useState } from "react";
import { Entity, User } from "@utils/interface";
import { ContextStore } from "@components/utils/Context";
import { useDisplayToast, usePut } from "@utils/hooks";
import CustomSidebar from "./CustomSidebar";
import RenderNext from "./RenderNext";
import { useNavigate } from "react-router-dom";

interface Props {
  setViewedOnboarding: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const Onboarding = ({ setViewedOnboarding }: Props) => {
  const navigate = useNavigate();
  const { user, flags, setUser } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const [currentStep, setCurrentStep] = useState(1);

  // update user
  const [updateUser, { isLoading: isUpdating }] = usePut<User>();
  const handleFinish = (done: boolean) => {
    setViewedOnboarding(true);
    // console.log("handleFinish:done:", done);
    if (done) {
      updateUser(
        Entity.users,
        {
          id: user?.id,
          data: {
            additionalData: {
              ...user?.additionalData,
              viewedOnboarding: true,
            },
          },
        },
        {
          onSuccess(data, variables, context) {
            // console.log("data", data);
            successToast("Onboarding Completed!");
            setUser(data);
          },
          onError(error, variables, context) {
            // console.log("error", JSON.stringify(error));
            errorToast(error?.body?.message);
          },
          onSettled(data, error, variables, context) {
            navigate("/");
          },
        }
      );
    }
  };

  return (
    <div className="fixed z-[1200] overscroll-none top-0 left-0 h-screen w-screen flex">
      <CustomSidebar currentStep={currentStep} flags={flags} />

      <div className="w-full flex-1 flex-shrink-0">
        <div className="h-[3.5rem] bg-opacity-0"></div>
        <div className="h-full bg-white">
          <RenderNext
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleFinish={handleFinish}
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
