// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/README.md
// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/props.ts
// https://github.com/colbyfayock/my-astro-ai-editor/blob/main/src/components/FormNewPost.tsx#L73
// https://spacejelly.dev/posts/how-to-add-an-ai-powered-wysiwyg-editor-in-react-astro-with-novel
// https://github.com/kavinvalli/novel-demo/blob/main/src/app/api/generate/route.ts
// https://github.com/steven-tey/novel/tree/0.1.22
// https://github.com/ZanyuanYang/Text-Editor-Tiptap
// https://github.com/hasparus/novel/blob/main/ui/editor/extensions/collaboration/useCollaborationExtensions.tsx |     "novel-collab": "https://github.com/hasparus/novel.git",
// https://vikramthyagarajan.medium.com/how-to-build-a-notion-like-text-editor-in-react-and-tiptap-7f394c36ed9d
import "./editor.css";
import { useContext, useEffect, useState } from "react";
// import { Editor as NovelEditor } from "novel";
import { Editor as NovelEditor } from "movel";
import type { CommandListProps } from "movel";
// import { Editor as NovelEditor } from "@movel";
// import NovelCollab from "novel-collab/ui/editor/index";
import { JSONContent } from "@tiptap/react";
// import type { SyntheticEvent } from "react";
import { TipTapEditor } from "@/playground/editors/interface";
import Toolbar from "./Toolbar";
// import { Dict, User } from "@utils/interface";
import extensions from "./extensions";
// import { useEditor } from "@tiptap/react";
import { useCompletion } from "ai/react";
import {
  APP_CONST,
  Dict,
  Entity,
  // API_URL,
  // APP_URL,
  LOCAL_STORE_KEYS,
  getApiUrl,
  isDev,
  notifyError,
} from "@utils/index";
import { toast } from "sonner";
// import { Cursors, Collaboration } from 'tiptap-extension-collaboration'
// import { makeClientColorStyles, mapClientsToColors } from "./helper";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import randomColor from "randomcolor";
// import { WebrtcProvider } from 'y-webrtc'
// import "tippy.js/dist/tippy.css";
// import "tailwindcss/tailwind.css";
import { defaultDocWithId, getCompletionHeaders } from "../helper";
import FocusPlugin from "./extensions/focus";
import { useFetchAccount } from "@utils/hooks";
import Loader from "@components/utils/Loader";
import { ContextStore } from "@components/utils/Context";
import { BiMessageRoundedDots } from "react-icons/bi";
import { BsRobot } from "react-icons/bs";
import { useAlwaysScrollToBottom } from "@/utils/helpers";

interface Props {
  document?: { userName: string; accountId: string; documentId: string };
  defaultValue?: JSONContent;
  onEditorUpdate?: (editor: TipTapEditor) => void;
  showFeedback?: () => void;
  customProvider?: HocuspocusProvider;
}

// autocomplete
const completionApi = getApiUrl(`/${Entity.ai}/stream`);

const Novel = (props: Props) => {
  const { globalState, setGlobalState } = useContext(ContextStore);
  const { AlwaysScrollToBottom } = useAlwaysScrollToBottom();

  const { defaultValue, document, showFeedback, customProvider } = props;
  // https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/default-content.tsx
  const defaultDoc = { type: "doc", content: [] };
  // const defaultDoc = defaultDocWithId;
  const { refreshAccount } = useFetchAccount();
  const [editorInstance, setEditorInstance] = useState<TipTapEditor>();
  const [counts, setCounts] = useState<Dict>({});
  const canCollaborate = !!document?.documentId && !!customProvider;
  // // && JSON.parse(process.env.ENABLE_NOVEL_COLLAB || "false");

  // useEffect(() => {
  //   //reset default value
  //   if (editorInstance) {
  //     if (defaultValue) {
  //       editorInstance.commands.setContent(defaultValue);
  //     } else {
  //       editorInstance.commands.setContent(defaultDoc);
  //     }
  //     console.log("editor", (editorInstance as TipTapEditor).getJSON());
  //   }
  // }, [defaultValue, editorInstance]);

  const handleEditorUpdate = (editor) => {
    if (props?.onEditorUpdate && editor) {
      props.onEditorUpdate(editor);
    }
  };

  const handleTypeAhead = (props: CommandListProps) => {
    return useCompletion({
      id: "novel",
      api: completionApi,
      headers: {
        ...getCompletionHeaders(),
      },
      body: {
        // context: props.editor.getJSON(),
        // cursor: props.editor.state.selection.from,
        documentId: document?.documentId,
      },
      onResponse: async (response) => {
        if (response.status === 429) {
          const resp = await response?.json();
          toast.error(resp?.message, { duration: 5000 });
          return;
        }
        if (!props?.editor || !props?.range) return;
        props?.editor.chain().focus().deleteRange(props?.range).run();
      },
      onFinish: (_prompt, completion) => {
        try {
          refreshAccount();
          // highlight the generated text
          if (props?.range) {
            props?.editor?.commands.setTextSelection({
              from: props.range.from,
              to: props.range.from + completion.length,
            });
          } else if (completion) {
            props?.editor?.commands.setTextSelection({
              from: props?.editor.state.selection.from - completion.length,
              to: props?.editor.state.selection.from,
            });
          }
        } catch (e) {
          console.log("error", completion, (e as Error).stack);
        }
      },
      onError: (e) => {
        console.log("error", e.stack);
        let msg = APP_CONST.DEFAULT_ERROR;

        try {
          msg = JSON.parse(e?.message)?.message;
        } catch (_e) {
          notifyError("handleTypeAhead: " + e?.stack);
          msg = e?.message || APP_CONST.DEFAULT_ERROR;
        }

        toast.error(msg, { duration: 5000 });
      },
    });
  };

  const handleCollabExts = (document, provider) => {
    if (!document || !provider) return [];
    return [
      // https://tiptap.dev/docs/editor/guide/collaborative-editing
      // https://github.com/naept/tiptap-collab-server/tree/master
      // https://github.com/naept/tiptap-extension-collaboration/tree/master
      // https://tiptap.dev/docs/editor/api/extensions/collaboration
      // https://tiptap.dev/docs/editor/api/extensions/collaboration-cursor
      // https://github.com/ueberdosis/tiptap-collab-replit/blob/main/src/components/Editor.vue
      Collaboration.configure({
        document: provider.document,
        // document: ydoc,
      }),
      CollaborationCursor.configure({
        provider: provider,
        user: {
          name: `@${document?.userName}`,
          color: randomColor(), //'#f783ac',
          // avatar: document?.user?.imageUrl,
        },
      }),
    ];
  };

  // console.log("content", editorInstance?.getJSON());
  // console.log("defaultDoc", defaultDoc);
  // console.log(
  //   "editorInstance?.state?.selection",
  //   editorInstance?.state?.selection
  // );

  useEffect(() => {
    // on unmount/exit clear novel__content
    return () => {
      localStorage?.removeItem("novel__content");
    };
  }, []);

  // // Listen for updates to the editor content
  // editorInstance?.on('update', () => {
  //   const characterCount = editorInstance?.state.doc.textContent.length;
  //   console.log(`Current character count: ${characterCount}`);
  // })

  useEffect(() => {
    if (!editorInstance) return;

    const totalCounts = async () => {
      const { from, to } = editorInstance.state.selection;
      // const currentText = editorInstance.state.doc.nodesBetween(from !== to ? from : 0, to).map(n => n.text).join("\n");
      const currentText = new Set<string>();

      await new Promise((resolve) => {
        editorInstance.state.doc.nodesBetween(
          from !== to ? from : 1,
          to,
          (node) => {
            if (node?.isText) {
              currentText.add(
                from === to
                  ? node.text!
                  : editorInstance.state.doc.textBetween(from, to)
              );
            }
          }
        );
        resolve(true);
      });

      const totalText = Array.from(currentText);

      // console.log("totalCounts: from", from, "to", to, ",currentText", totalText, ",length", editorInstance.state.doc.textContent.length);

      const fullText = totalText.join("");
      const totalChars = fullText.length;
      // const totalWords = currentText.split(/\s+/).filter(x => !!x)?.length;
      const totalWords = totalText
        .join(" ")
        .split(/\s+/)
        .filter((x) => !!x).length;

      setCounts({
        chars: totalChars,
        words: totalWords,
      });
    };

    editorInstance.on("update", totalCounts);
    editorInstance.on("selectionUpdate", totalCounts);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      editorInstance.off("update", totalCounts);
      editorInstance.off("selectionUpdate", totalCounts);
    };
  }, [editorInstance]);

  useEffect(() => {
    if (!globalState?.isStreaming) return;
    // set timeout to turn off loading after 5 seconds
    const timeout = setTimeout(() => {
      // console.log("turning off doc loader...");
      setGlobalState((prev) => ({ ...prev, isStreaming: false }));
    }, 5000);

    return () => clearTimeout(timeout);
  }, [globalState?.isStreaming]);

  return (
    <div className="flex">
      <div className="sticky">
        <Toolbar editor={editorInstance} isCollab={canCollaborate} />
      </div>
      <div className="editor w-full">
        {/* <NovelCollab /> */}
        <NovelEditor
          // https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/index.tsx
          className={`novel-relative novel-min-h-[500px] novel-w-full novel-max-w-screen-lg novel-border-stone-200 novel-bg-white sm:novel-rounded-lg sm:novel-border sm:novel-shadow-lg`}
          // https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/extensions/index.tsx
          extensions={
            [
              ...extensions,
              ...handleCollabExts(document, customProvider),
              FocusPlugin(props?.onEditorUpdate),
            ] as any[]
          }
          // https://github.com/steven-tey/novel/blob/0.1.22/apps/web/app/api/generate/route.ts
          completionApi={completionApi}
          disableLocalStorage={true}
          // https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/default-content.tsx
          defaultValue={defaultValue || defaultDoc}
          onDebouncedUpdate={handleEditorUpdate}
          debounceDuration={1000 * 60 * (canCollaborate ? 10 : 5)}
          grabEditor={(e) => setEditorInstance(e as unknown as TipTapEditor)}
          lastTextKey={"++"}
          useCustomCompletion={(e) => handleTypeAhead(e as any)}
          disableHistory={canCollaborate}
          feedbackCallback={() => showFeedback && showFeedback()}
          isFetching={globalState?.isStreaming}
          Loader={
            <div className="fixed left-[50%] top-[50%]">
              <Loader size={"xl"} />
            </div>
          }
        />
        {globalState?.isStreaming ? <AlwaysScrollToBottom /> : null}
        <div className="flex justify-end items-center mt-1 fixed bottom-[10px] right-[40px] z-50">
          <button
            onClick={() => {
              if (!editorInstance) return;
              // editorInstance?.chain()?.insertContent('??')?.run();
              // editorInstance?.chain()?.focus()?.run();
              editorInstance.chain().focus().insertContent(" ??").run();
            }}
            className=" p-1 text-3xl rounded bg-[#eee8f8] hover:bg-[#6a54b1] text-[#6a54b1] hover:text-white"
          >
            <BsRobot />
          </button>
          <div className="ml-2 text-[#868e96]">
            {/* {editorInstance?.storage?.characterCount?.characters(
            // editorInstance?.state?.selection?.from
            //   ? {
            //     node: editorInstance.state.doc.nodeAt(
            //       editorInstance?.state?.selection?.from
            //     ),
            //   }
            //   : {}
          ) - 4}{" "} */}
            {counts?.chars || 0} character{counts?.chars !== 1 ? "s" : ""}
            <br />
            {/* {editorInstance?.storage?.characterCount?.words(
            // editorInstance?.state?.selection?.from
            //   ? {
            //     node: editorInstance.state.doc.nodeAt(
            //       editorInstance?.state?.selection?.from
            //     ),
            //   }
            //   : {}
          )} */}
            {counts?.words || 0} word{counts?.words !== 1 ? "s" : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Novel;
