import { useState } from "react";

interface BadgeInputProps {
  values: string[];
  onChange: (e: React.ChangeEvent<any>) => void;
  id: string;
}

const BadgeInput = ({ id, values, onChange }: BadgeInputProps) => {
  const [labels, setLabels] = useState<string[]>(values?.length ? values : []);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key !== "Enter") return;
    if (inputValue?.trim() !== "") {
      setLabels([...labels, inputValue?.trim()]);
      setInputValue("");
    }
    // console.log('event.key', inputValue)
  };

  // console.log('labels', labels)

  const handleOnBlur = () => {
    onChange({ target: { id, value: labels } } as React.ChangeEvent<any>);
  };

  const handleRemoveLabel = (index: number) => {
    const newLabels = labels?.filter((l, idx) => idx !== index);
    setLabels(newLabels);
  };

  return (
    <div className={`mt-1 overflow-hidden`}>
      {/* <div className={`mt-1 w-full`}>  */}
      <input
        type="text"
        id={id}
        placeholder="Type then press Enter"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleEnterKeyPress}
        onBlur={handleOnBlur}
        className="h-10 w-full px-3 outline-none rounded border"
      />

      {!!labels.length && (
        <div className="flex overflow-x-scroll no-scrollbar bg-gray-100">
          {/* <div className="flex flex-wrap no-scrollbar"> */}
          {labels.map((label, index) => (
            <div
              key={index}
              className="bg-neutral-200 mt-2 flex-shrink-0 py-1 px-2 mr-2 rounded-lg"
            >
              {label}
              <button
                onClick={() => handleRemoveLabel(index)}
                className="text-neutral-500 text-[10px] ml-1"
              >
                X
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BadgeInput;
