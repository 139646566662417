import { SearchIcon } from "@chakra-ui/icons";
import {
  Dict,
  Entity,
  ID,
  IDict,
  SearchCategory,
  SearchResult,
  isNullOrEmpty,
} from "@utils/index";
import { useContext, useEffect, useRef, useState } from "react";
import { useGetOne } from "react-admin";
import { BiX } from "react-icons/bi";
import { useOnClickOutside } from "usehooks-ts";
import { ContextStore } from "../utils/Context";
import { useDisplayToast } from "@utils/hooks";
import Loader from "../utils/Loader";
import { omit } from "lodash";
import { useNavigate } from "react-router-dom";
import SearchResults from "./Results";

interface Props {
  setIsSearchMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar = ({ setIsSearchMode }: Props) => {
  const navigate = useNavigate();
  const { account, hasChanges, setHasChanges } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResult>(
    {} as SearchResult
  );

  // https://stackoverflow.com/a/68173806/3562407
  const { isFetching: isSearching, refetch: executeSearch } =
    useGetOne<SearchResult>(
      `${Entity.accounts}/search`,
      {
        id: account?.id,
        meta: { query },
      },
      {
        enabled: false,
        onError: (error: any) => {
          errorToast(error?.message || error?.body?.message);
        },
        onSuccess(data) {
          handleSearchResults(data);
        },
      }
    );

  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => setIsSearchMode(false));

  const handleSearch = () => {
    if (!isNullOrEmpty(query) && hasChanges?.searchBar) {
      executeSearch();
    }
  };

  const handleAction = (category: string, result?: IDict) => {
    setIsSearchMode(false);
    // console.log("handleAction", category, result);
    switch (category) {
      case Entity.files:
        return navigate(`/files/?${result?.id ? `fileId=${result?.id}` : ""}`);
      case Entity.grants:
        return navigate(
          `/board/?${
            result?.grantId
              ? `grantId=${result?.grantId}`
              : result?.documentId
              ? `documentId=${result?.documentId}`
              : ""
          }`
        );
      case Entity.documents:
        return navigate(
          `/documents/?${result?.id ? `documentId=${result?.id}` : ""}`
        );
      case Entity.prompts:
      case Entity.responses:
        return navigate(
          `/documents/?documentId=${result?.prompt?.documentId}&promptId=${result?.prompt?.id}`
        );
      // case "snippets":
      //   return navigate("/documents");
    }
  };

  const handleSearchResults = (data: SearchResult) => {
    setHasChanges({ ...hasChanges, searchBar: false });
    setSearchResults(data);
  };

  return (
    <div className=" w-screen h-screen z-[1600] top-0 left-0 fixed bg-black bg-opacity-30">
      <div
        ref={ref}
        className=" max-h-[90vh] max-w-[1000px] overflow-y-scroll custom-scrollbar my-2 mx-auto -xl:mx-[150px] -md:mx-20 -sm:mx-10 px-5 pb-14 bg-white rounded-lg"
      >
        <div className="pt-5 flex-y-center sticky top-0 bg-white">
          <div className=" sm:mr-10 mr-4 flex-1 relative">
            <div className="absolute top-2 left-2 text-neutral-400">
              <SearchIcon />
            </div>
            <input
              placeholder="Type ... then Press Enter"
              type="text"
              autoFocus
              className="-sm:text-sm w-full border-[#B794F4] outline-4 outline-offset-0 outline-[#E9D8FD] rounded-lg border outline-none pl-8 p-2"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setHasChanges({ ...hasChanges, searchBar: true });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              onBlur={handleSearch}
            />
          </div>

          <button
            className=" w-6 h-6 flex rounded-full text-3xl"
            onClick={() => setIsSearchMode(false)}
          >
            <BiX />
          </button>
        </div>

        {isSearching ? (
          <Loader className="mt-10" size={"xl"} />
        ) : (
          <div className="mt-7 text-sm">
            {Object.keys(omit(searchResults, "id"))?.map((category, idx) => {
              return !searchResults[category]?.length ? null : (
                <SearchResults
                  key={idx}
                  category={category as SearchCategory}
                  results={searchResults[category] || []}
                  action={(result) => handleAction(category, result)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
