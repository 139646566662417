import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  // Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { APP_CONST, primaryColor, signupUrl } from "@/utils";
import Loader from "@components/utils/Loader";

interface SignInProps {
  onSubmit: (e: React.SyntheticEvent) => void;
  email: string;
  onChange: (e: React.SyntheticEvent) => void;
  isLoading: boolean;
}

export default function SignInPage(props: SignInProps) {
  const { onSubmit, onChange, email, isLoading } = props;
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign in to your account
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of the cool{" "}
            <Link target="_self" href={`//${APP_CONST.LANDING_PAGE}#features`} color={`${primaryColor}.400`}>features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                height={"40px"}
                width={"100%"}
                border={"1px"}
                borderRadius={"5px"}
                paddingStart={"10px"}
                onChange={onChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
              />
            </FormControl>
            {/* <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" />
            </FormControl> */}
            <Stack spacing={10}>
              {/* <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Link color={`${primaryColor}.400`}>Forgot password?</Link>
              </Stack> */}
              <Button
                bg={`${primaryColor}.400`}
                color={"white"}
                _hover={{
                  bg: `${primaryColor}.500`,
                }}
                height={"40px"}
                borderRadius={"5px"}
                onClick={onSubmit}
                isDisabled={isLoading}
              >
                {isLoading ? <Loader size={"md"} /> : "Sign in"}
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Want to register?{" "}
                <Link color={`${primaryColor}.400`} target="_self" href={`//${APP_CONST.LANDING_PAGE}#pricing`}>
                  Signup
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
