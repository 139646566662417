// https://www.reddit.com/r/reactjs/comments/x0uetq/how_do_i_add_a_stripe_pricing_table_to_my_react/
import React, { useEffect } from "react";
export const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1NMFxgEdMGilw8qrq8MHdeFM",
    "publishable-key": "pk_test_593zbYRN9w6DgS9hKAxZQFaY",
    // "customer-email": "",
  });
};

{
  /* <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
<stripe-pricing-table pricing-table-id="prctbl_1NMFxgEdMGilw8qrq8MHdeFM"
publishable-key="pk_test_593zbYRN9w6DgS9hKAxZQFaY">
</stripe-pricing-table> */
}
