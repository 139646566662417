interface Props {
  src: string;
  title?: string;
  text?: string;
}

const Video = ({ src, title, text }: Props) => {
  return (
    <div className="">
      {title && (
        <h1 className="text-2xl text-center font-bold mb-3">{title}</h1>
      )}
      <iframe
        className="w-full h-full min-h-[400px]"
        // https://stackoverflow.com/a/66946714
        src={src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {text && <p className="text-center text-xl font-semibold mt-4">{text}</p>}
    </div>
  );
};

export default Video;
