import { useEffect, useRef } from "react";
import { GrantAddProps } from "../interfaces";

const CopyPasteTab = (props: GrantAddProps) => {
  const { grantInfo, setGrantInfo, setHasChanges } = props;
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current && !inputRef.current?.value) {
      inputRef.current.value = grantInfo;
      setHasChanges(false);
    }
  }, [inputRef]);

  return (
    <div className="text-sm px-5 pb-10">
      <div>
        <label htmlFor="grant-copy">
          Copy and Paste the grant information below
        </label>
        <textarea
          ref={inputRef}
          autoFocus
          className="h-60 py-2 px-3 mt-1 w-full block outline-none rounded border"
          placeholder="Grant information here"
          name="grant-copy"
          id="grant-copy"
          onBlur={() => {
            const val = inputRef.current?.value || "";
            setGrantInfo(val);
            setHasChanges(!!val.trim() && val.length > 10);
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     e.preventDefault();
          //     inputRef.current?.blur();
          //   }
          // }}
        />
      </div>
    </div>
  );
};

export default CopyPasteTab;
