import { Editor, Content } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { JSONContent } from "@tiptap/react";
import { LOCAL_STORE_KEYS, canParse } from "@utils/index";
import { PromptSection, Document, SlateNode } from "@utils/interface";
import { slateToString } from "./slate/exports";
import { DOMSerializer } from "prosemirror-model";
// import randomColor from "randomcolor";

export const tones = [
  "Conversational",
  "Formal",
  "Informal",
  "Professional",
  "Technical",
  "Motivational",
  "Sarcastic",
  "Informative"
];

// https://github.com/ZanyuanYang/Text-Editor-Tiptap/blob/master/src/pages/TextEditor/components/Tiptap.tsx#L469C4-L469C58
export const handleInsert = (editor: Editor, content: string) => {
  editor?.chain().focus().insertContent(content).run();
};

interface TextOptions {
  chars: number;
  offset?: number;
  lastChar?: string;
}

// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/lib/editor.ts
export const getPrevText = (
  editor: Editor,
  { chars, offset = 0, lastChar }: TextOptions
) => {
  const fromPosition = editor.state.selection.from;
  let start = Math.max(0, fromPosition - chars);

  if (lastChar) {
    const lastCharIndex = editor.state.doc?.text?.lastIndexOf(lastChar);
    start = lastCharIndex && lastCharIndex != -1 ? lastCharIndex : start;
  }

  const end = fromPosition - offset;
  // for now, we're using textBetween for now until we can figure out a way to stream markdown text
  // with proper formatting: https://github.com/steven-tey/novel/discussions/7
  return editor.state.doc.textBetween(start, end, "\n");
  // complete(editor.storage.markdown.getMarkdown());
};

// export function mapClientsToColors(clientsIDs, clientID, colorsMap) {
//   clientsIDs
//     .filter((id) => id !== clientID)
//     .filter((id) => !Object.keys(colorsMap).includes(id))
//     .forEach((id) => {
//       colorsMap[id] = randomColor();
//     });
// }

// export function makeClientColorStyles(colorsMap) {
//   let clientsColorsStyle = document.getElementById("client-colors");
//   if (clientsColorsStyle) clientsColorsStyle.remove();

//   clientsColorsStyle = document.createElement("style");
//   clientsColorsStyle["type"] = "text/css";
//   clientsColorsStyle.id = "client-colors";

//   Object.entries(colorsMap).forEach(([clientID, color]) => {
//     clientsColorsStyle!.innerHTML += `.cursor.client-${clientID}::before { background-color: ${color} }\n`;
//     clientsColorsStyle!.innerHTML += `.selection.client-${clientID} { background-color: ${color}20 }\n`;
//   });

//   document.getElementsByTagName("head")[0].appendChild(clientsColorsStyle);
// }

export const getNewDoc = (content?: string) => {
  const doc: { type: string; content: any[] } = { type: "doc", content: [] };

  if (content) {
    const nodes = content
      .split("\n")
      .filter((x) => x) //  Error: RangeError: Empty text nodes are not allowed
      .map((line) => {
        return line?.startsWith("h2:")
          ? {
            type: "heading",
            attrs: { level: 2 },
            content: [
              {
                type: "text",
                text: line?.replace("h2:", ""),
              },
            ],
          }
          : {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: line,
              },
            ],
          };
      });

    doc.content = nodes;
  }

  return doc;
};

export const slatePromptsToNovelDocument = (prompts?: PromptSection[]) => {
  if (!prompts || !prompts?.length) return getNewDoc();
  return getNewDoc(
    prompts
      ?.map((p) => {
        // console.log("p?.currentResponse", p?.currentResponse);
        const content = canParse<SlateNode[]>(p?.currentResponse) as any;
        // console.log("content", content);
        const prompt = `h2:${p?.title || p?.embeddings?.[0]?.content || "Untitled Prompt"
          }`;
        const response = content ? slateToString(content) : p?.currentResponse;
        return prompt + `\n` + response;
      })
      .join("\n")
  );
};

export const getCompletionHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem(
      LOCAL_STORE_KEYS.accessToken
    )}`,
  };
};

export const novelToString = (content: typeof defaultDocWithId.content) => {
  return content
    ?.map((node) => node?.content?.map((c) => c?.text)?.join(" "))
    .join("\n");
};

export const defaultDocWithId = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "8c8e30b5-fcd4-4ab8-94b3-4459748fa8fb",
        textAlign: "left",
        level: 2,
      },
      content: [
        {
          type: "text",
          text: "Welcome to Novel",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b9935b3c-196f-46ab-9969-14349809b92e",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          text: "This is a novel editor. You can write your novel here.",
        },
      ],
    },
    // {
    //   type: "paragraph",
    //   content: [  //empty paragraph
    //     {
    //       type: 'text',
    //       text: ' ',
    //     },
    //   ],
    // },
  ],
};

export const handleUpdateDoc = (editor, prompt: string, response?: string) => {
  editor.commands.insertContent([
    {
      type: "heading",
      attrs: { level: 2 },
      content: [
        {
          type: "text",
          text: cleanContent(prompt),
        },
      ],
    },
    ...(response || "").split("\n").map((line) => ({
      type: "paragraph",
      content: [
        {
          type: "text",
          text: cleanContent(line),
        },
      ],
    })),
  ]);
};

export function cleanContent(content: string) {
  // clean up empty lines
  // FIX: Error: RangeError: Empty text nodes are not allowed
  return content?.trim() || " ";
}

export const editorFromContent = (content: Content) => {
  const editor = new Editor({ content, extensions: [StarterKit] });
  return editor;
};

export const getEditorHtml = (document: Document) => {
  const content = getEditorContent(document);
  const editor = editorFromContent(content);
  return editor.getHTML();
};

export const getEditorContent = (document: Document): JSONContent => {
  let defaultValue = canParse<JSONContent>(document?.content);
  if (!defaultValue) {
    defaultValue = document?.prompts?.length
      ? slatePromptsToNovelDocument(document?.prompts)
      : getNewDoc(document?.content);
  }
  return defaultValue;
};

export const processEscapedChars = (text: string) => {
  if (!text) return text;
  return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
  // .replace(/-/g, "-")
  // .replace(/\\r/g, "\r")
  // .replace(/\\b/g, "\b")
  // .replace(/\\f/g, "\f")
  // .replace(/\\v/g, "\v")
  // .replace(/\\0/g, "\0")
  // .replace(/\\'/g, "'")
  // .replace(/\\"/g, '"')
  // .replace(/\\`/g, "`")
  // .replace(/\\\\/g, "\\")
  // .replace(/\\&/g, "&")
};

// Function to insert text safely
export function insertTextTx(editor: Editor, text: string) {
  const transaction = editor.state.tr.insertText(text);
  editor.view.dispatch(transaction);
}

export function handleStreamingContent(editor: Editor, streamingWordParts) {
  // https://www.phind.com/search?cache=vot2p0g7h3742kr8c8kp5w1w
  let reconstructedWord = "";
  for (const part of streamingWordParts) {
    // Check if the part is a hyphenated word starting with "non-"
    if (/^non-\w+/.test(part)) {
      reconstructedWord += part + "-";
    } else {
      reconstructedWord += part;
    }
  }

  // Now, insert the reconstructed word
  insertTextTx(editor, reconstructedWord);
}

export const getFormattedSelection = (editor: Editor) => {
  // https://github.com/ueberdosis/tiptap/issues/369
  const { state } = editor;
  if (state.selection.empty) return '';
  const { from, to } = state.selection;
  const slice = state.doc.slice(from, to);

  // Serialize the slice to HTML
  const fragment = DOMSerializer.fromSchema(state.schema).serializeFragment(slice.content);

  // Convert the serialized fragment to a string
  const div = document.createElement('div');
  div.appendChild(fragment);
  return div.innerHTML;
}


export function getHTMLContentBetween(editor: Editor) {
  // https://github.com/ueberdosis/tiptap/issues/369
  const { state } = editor;
  const nodesArray: string[] = [];

  if (!state.selection.empty) {
    const { from, to } = state.selection;
    state.doc.nodesBetween(from, to, (node, pos, parent) => {
      if (parent === state.doc) {
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);
        const tempDiv = document.createElement('div');
        tempDiv.appendChild(dom);
        nodesArray.push(tempDiv.innerHTML);
      }
    });
  }

  return nodesArray.join('');
}

export function getStringFromHTML(html: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.innerText;
}

