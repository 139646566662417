import { AddIcon } from "@chakra-ui/icons";
import ModalSearch from "@/components/modal/ModalSearch";
import { ContextStore } from "@components/utils/Context";
import { Account } from "@utils/interface";
import { useContext } from "react";
import { ellipsis } from "@utils/index";

interface AccountSwitcherProps {
  showAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSwitchAccount: (acct: Account) => void;
}

const AccountSwitcher = (props: AccountSwitcherProps) => {
  const { account, actionModal, setActionModal, globalState } =
    useContext(ContextStore);
  const { showAccountModal, handleSwitchAccount } = props;

  //pull all accounts for agency
  const agencyAccounts = !globalState?.agency
    ? []
    : [globalState?.agency].concat(globalState?.agency?.linkedAccounts || []);
  // ?.filter((a) => a?.id !== account?.id);

  return (
    <ModalSearch
      position={"left-5"}
      items={agencyAccounts}
      onDone={() => showAccountModal(false)}
      onClick={(acct) => {
        if (acct?.id !== account?.id) {
          handleSwitchAccount(acct as Account);
        } else {
          showAccountModal(false);
        }
      }}
      renderItem={(item) => (
        <ClientItem
          item={item as Account}
          isActive={item?.id === account?.id}
          isAgency={item?.id === globalState?.agency?.id}
        />
      )}
      icon={
        <>
          <button
            className="ml-1 rounded-lg border border-purple-500 text-purple-500 px-2 py-1 font-bold text-xs hover:bg-purple-500 hover:text-white transition-all duration-300 ease-in-out"
            onClick={() => {
              setActionModal({ ...actionModal, agencyAddClient: true });
            }}
          >
            <AddIcon /> Add Client
          </button>
        </>
      }
    />
  );
};

export default AccountSwitcher;

const ClientItem = ({
  item,
  isActive,
  isAgency,
}: {
  item: Account;
  isActive: boolean;
  isAgency?: boolean;
}) => {
  return (
    <div className={`flex items-center justify-between w-full`}>
      <div className={`flex items-center ${isAgency ? "text-purple-500" : ""}`}>
        <div
          className={`flex items-center justify-center min-w-[2rem] w-8 h-8 ${
            isActive ? "bg-purple-500 text-white" : "bg-neutral-200"
          } rounded-full`}
        >
          {item?.name?.charAt(0)?.toUpperCase()}
        </div>
        <div className="flex flex-col ml-3">
          <div className={`font-bold`}>{ellipsis(item?.name, 40)}</div>
          <div className="text-xs text-gray-500">
            {item?._count?.users || 0}{" "}
            {item?._count?.users !== 1 ? "users" : "user"}
          </div>
        </div>
      </div>
    </div>
  );
};
