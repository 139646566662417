// https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
// https://github.com/marmelab/ra-in-memory-jwt

import jwtDecode, { JwtPayload } from "jwt-decode";
import { httpClient } from "../client";
import { APP_CONST } from "@/utils";

const inMemoryJWTManager = () => {
  let inMemoryJWT: string | null = null;
  let inMemoryJWT_REFRESH: string | null = null;
  let isRefreshing: Promise<boolean> | null = null;
  let logoutEventName = "ra-logout";
  let refreshEndpoint = "/refresh_token";
  let refreshTimeOutId: number | null;

  const setLogoutEventName = (name: string) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint: string) =>
    (refreshEndpoint = endpoint);

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const refreshToken = (delay: number) => {
    const timeoutPeriod = delay * 1000 - 15 * 1000; // Validity period of the token in seconds, minus 5 seconds
    const tOut = new Date(timeoutPeriod).getTime() - Date.now(); // https://stackoverflow.com/a/42956013
    // console.log(
    //   "refreshing token at: ",
    //   new Date(timeoutPeriod).toLocaleString()
    // );
    refreshTimeOutId = window.setTimeout(getRefreshedToken, tOut);
  };

  const clearRefreshTokenTimeout = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  // The method make a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
  const getRefreshedToken = () => {
    // console.log("Refreshing Token....");
    // const request = new Request(refreshEndpoint, {
    //   method: 'GET',
    //   headers: new Headers({ 'Content-Type': 'application/json' }),
    //   credentials: 'include',
    // });

    isRefreshing = httpClient(`${APP_CONST.API_URL}${refreshEndpoint}`, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ refreshToken: inMemoryJWT_REFRESH }),
    })
      .then((response) => {
        console.log("getRefreshedToken", response);
        if (response.status !== 200) {
          eraseToken();
          global.console.log("Token renewal failure");
          return { accessToken: null };
        }
        return response.json;
      })
      .then(({ accessToken }) => {
        if (accessToken) {
          setToken(accessToken);
          return true;
        }
        eraseToken();
        return false;
      });

    return isRefreshing;
  };

  const getToken = () => {
    // console.log("getToken", inMemoryJWT);
    return inMemoryJWT;
  };

  const getRefreshToken = () => {
    // console.log("getRefreshToken", inMemoryJWT_REFRESH);
    return inMemoryJWT_REFRESH;
  };

  const setToken = (token: string) => {
    inMemoryJWT = token;
    const expiry = jwtDecode<JwtPayload>(token).exp!;
    // https://stackoverflow.com/a/65999244
    // console.log("Token expiring at:", new Date(expiry * 1000).toLocaleString());
    refreshToken(expiry);
    return true;
  };

  const setRefreshToken = (token: string) => {
    inMemoryJWT_REFRESH = token;
    return true;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    clearRefreshTokenTimeout();
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    getRefreshToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    setRefreshToken,
    waitForTokenRefresh,
  };
};

export default inMemoryJWTManager();
