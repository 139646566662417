import { Editor } from "@tiptap/core";
import { ChevronDown, } from "lucide-react";
import React, { FC, SyntheticEvent, useContext, useEffect, useRef } from "react";
import { Command } from "cmdk";
import { useOnClickOutside } from "usehooks-ts";
import { VscNotebookTemplate } from "react-icons/vsc";
import { LuBookTemplate } from "react-icons/lu";
import {
  template3,
  budget,
  checklist,
  org,
  project,
  timeline,
} from "@pages/document/templates";

interface OutlinesProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const Outlines: FC<OutlinesProps> = (props: OutlinesProps) => {
  const { editor, isOpen, setIsOpen } = props;

  const items = [
    {
      name: "Full Proposal",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        // editor.chain().focus().insertContent("Proposal").run();
        // console.log(editor.getJSON());
        // //insert JSON
        editor.commands.insertContent(template3);
      },
      visible: true,
    },
    {
      name: "Organization",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        editor.commands.insertContent(org);
      },
      visible: true,
    },
    {
      name: "Budget",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        editor.commands.insertContent(budget);
      },
      visible: true,
    },
    {
      name: "Checklist",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        editor.commands.insertContent(checklist);
      },
      visible: true,
    },
    {
      name: "Project Plan",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        editor.commands.insertContent(project);
      },
      visible: true,
    },
    {
      name: "Timeline",
      icon: VscNotebookTemplate,
      onSelect: () => {
        if (!editor) return;
        editor.commands.insertContent(timeline);
      },
      visible: true,
    },
  ];

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  const hasSelection = editor?.state?.selection?.empty === false;

  return (
    !hasSelection ?
      <div className="flex" ref={ref}>
        <div className={`flex h-full items-center gap-1 text-sm font-medium hover:bg-stone-100 active:bg-stone-200 ${isOpen ? 'text-purple-500' : 'text-stone-600'}`}>
          <button
            className="p-2 flex h-full items-center gap-2"
            onClick={() => {
              setIsOpen(!isOpen);
              editor.chain().blur().run();
            }}>
            <LuBookTemplate className="h-5 w-5" /> Insert
            <ChevronDown className="h-4 w-4" />
          </button>
        </div>

        {isOpen && (
          <>
            <Command className="fixed top-full z-[99999] w-60 overflow-hidden rounded border border-stone-200 bg-white p-2 shadow-xl animate-in fade-in slide-in-from-top-1">
              <Command.List>
                {items?.filter(i => i.visible !== false)?.map((item, index) => (
                  <Command.Item
                    key={index}
                    onSelect={() => {
                      item.onSelect();
                    }}
                    className="flex group cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-sm text-gray-600 active:bg-stone-200 aria-selected:bg-stone-100">
                    <div className="flex items-center space-x-2">
                      <item.icon className="h-4 w-4 text-purple-500" />
                      <span>{item.name}</span>
                    </div>
                    {/* <CornerDownLeft className="hidden h-4 w-4 group-hover:block" /> */}
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </>
        )}
      </div> : null
  );
};
