import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  InputLeftAddon,
  InputGroup,
  Textarea,
  Center,
  FormErrorMessage,
  InputRightAddon,
} from "@chakra-ui/react";
import { OrgFormProps } from "./interface";
import { Account, Dict, Entity, IResponse } from "@utils/interface";
import Loader from "@components/utils/Loader";
import { useDisplayToast, usePlanFeatureCheck, usePost } from "@utils/hooks";
import { PLANS } from "@utils/index";
import ActionModal from "@/components/modal/ActionModal";
import { IoWarning } from "react-icons/io5";
import { ContextStore } from "@components/utils/Context";
import { BiImport } from "react-icons/bi";
import TextLoader from "@components/TextLoader";
import { pick } from "lodash";

const FormBasicInfo = (props: OrgFormProps) => {
  const { setHasChanges, handleSubmit } = props;
  const [formErrors, setFormErrors] = useState<Dict>({});
  const { successToast, errorToast, warningToast } = useDisplayToast();
  const { needAccess } = usePlanFeatureCheck();
  const { actionModal, setActionModal, account, setAccount, globalState } =
    useContext(ContextStore);

  const handleChanges = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLSelectElement, Event>
  ) => {
    const target =
      (e?.target as HTMLInputElement) ||
      (e?.currentTarget as HTMLSelectElement);
    const key = target?.id;
    const value = target?.value;
    setAccount({ ...account, [key]: value });
    setFormErrors((prev) => ({ ...prev, [target.id]: "" }));
    setHasChanges({ orgProfile: true });
    if (globalState?.isAgency && key === "name") {
      setHasChanges({ fetchAgency: true });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement, Element>) => {
    if (isValid(e)) {
      handleSubmit(false);
    }
  };

  const [create, { isLoading: isImporting }] = usePost<Account>();

  const handleAccountImport = () => {
    // if (needAccess([PLANS.basic, PLANS.pro])) return;
    if (needAccess([PLANS.FREE, PLANS.BASIC])) return;
    if (!account?.websiteUrl && !account?.name) {
      warningToast(
        "Please add your organization's name & website first.",
        "One-Click Import"
      );
      return;
    }
    return create(
      `${Entity.accounts}/url`,
      { data: pick(account, ["websiteUrl", "name", "id"]) },
      {
        onSuccess(data, variables, context) {
          successToast("Account Imported");
          setAccount(data);
          // console.log("handleCreateGrant", data);
          setActionModal((prev) => ({ ...prev, accountImport: false }));
        },
        onError(error, variables, context) {
          warningToast(error?.body?.message, "Issue Importing Account");
        },
      }
    );
  };

  // console.log("account", account);

  const isValid = (
    e: React.ChangeEvent<HTMLElement> | React.SyntheticEvent<HTMLElement, Event>
  ) => {
    return true;
    const target =
      (e?.target as HTMLInputElement) ||
      (e?.currentTarget as HTMLSelectElement);

    switch (target.id) {
      case "name":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, name: "Name is missing" }));
        }
        break;
      case "phone":
        if (!target.value.length) {
          setFormErrors((prev) => ({
            ...prev,
            phone: "Phone number is missing",
          }));
        } else if (!target.value.match(/^\d{10}$/)) {
          setFormErrors((prev) => ({
            ...prev,
            phone: "Phone number must be 10 digits",
          }));
        }
        break;
      case "websiteUrl":
        if (!target.value.length) {
          setFormErrors((prev) => ({
            ...prev,
            websiteUrl: "Website is missing",
          }));
        }
        break;
      case "address":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, address: "Address is missing" }));
        }
        break;
      case "city":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, city: "City is missing" }));
        }
        break;
      case "state":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, state: "State is missing" }));
        }
        break;
      case "zip":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, zip: "Zip is missing" }));
        } else if (target.value.length !== 5) {
          setFormErrors((prev) => ({ ...prev, zip: "Zip must be 5 digits" }));
        }
        break;
      case "country":
        if (!target.value.length) {
          setFormErrors((prev) => ({ ...prev, country: "Country is missing" }));
        }
        break;
    }
  };

  const disableImport = !account?.name || !account?.websiteUrl;

  return (
    <>
      {actionModal?.accountImport && (
        <ActionModal
          title={"One-Click Import"}
          icon={<BiImport />}
          disableClose={isImporting}
          disableConfirm={isImporting}
          handleConfirm={() => {
            handleAccountImport();
          }}
          handleClose={() =>
            setActionModal((prev) => ({ ...prev, accountImport: false }))
          }
        >
          {isImporting ? (
            <>
              <Loader size={"xl"} />
              <TextLoader color={"#6b4bbe"} />
            </>
          ) : (
            <>
              <p className="text-sm">
                You can provide your root url{" "}
                <pre className="italic">www.org.com</pre> or a list of comma
                separated urls{" "}
                <pre className="italic">www.org.com,www.org.com/about</pre>
              </p>
              <p className="pt-3 font-bold">
                Importing will overwrite existing data
              </p>
              <p className="font-bold">Are you ready to proceed?</p>
            </>
          )}
        </ActionModal>
      )}
      <h1 className="my-8 pl-3 py-1 text-lg w-full text-[#475467] bg-[#EAECF0] ">
        Basic Information
      </h1>

      <div className="grid grid-cols-2 gap-x-[5%] gap-y-6">
        <FormControl className="-sm:col-span-2">
          <FormLabel htmlFor="name" fontSize="sm" fontWeight={"normal"}>
            Organization Legal Name
          </FormLabel>
          <Input
            id="name"
            value={account?.name}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.name}</p>
        </FormControl>

        <FormControl className="-sm:col-span-2">
          <FormLabel htmlFor="websiteUrl" fontSize="sm" fontWeight={"normal"}>
            Website
          </FormLabel>
          {/* <Input id="website" /> */}
          <InputGroup size="md">
            <Input
              type="url"
              id="websiteUrl"
              placeholder={"https://www.example.com"}
              // focusBorderColor="brand.400"
              rounded="md"
              // value={account?.websiteUrl?.replace(/http(s|):\/\//, "") || ""}
              value={account?.websiteUrl || ''}
              onChange={(e) => handleChanges(e)}
              onBlur={handleBlur}
              isDisabled={isImporting}
              // pattern="^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            />
            <InputRightAddon
              onClick={() =>
                setActionModal((prev) => ({ ...prev, accountImport: true }))
              }
              as="button"
              disabled={disableImport}
              className={`text-white bg-purple-700 -sm:max-w-[80px] -sm:text-xs -sm:text-wrap -sm:p-2 ${
                disableImport ? "cursor-default bg-purple-400" : ""
              }`}
            >
              1-Click Import
            </InputRightAddon>
          </InputGroup>
          <p className={`text-red-500 text-sm`}>{formErrors?.websiteUrl}</p>
        </FormControl>

        {/* <FormControl className="-sm:col-span-2">
          <FormLabel htmlFor="name" fontSize="sm" fontWeight={"normal"}>
            Org Account ID
          </FormLabel>
          <Input id="accountId" value={account?.id} disabled />
          <p className={`text-red-500 text-sm`}>{formErrors?.name}</p>
        </FormControl> */}

        <FormControl className="-sm:col-span-2">
          <FormLabel htmlFor="phone" fontSize="sm" fontWeight={"normal"}>
            Phone Number
          </FormLabel>
          {/* <Input id="phone" /> */}
          <InputGroup size="md">
            <Input
              type="number"
              placeholder="1234567890"
              id="phone"
              // focusBorderColor="brand.400"
              rounded="md"
              value={account?.phone || ""}
              onChange={(e) => {
                //if regex is not number
                if (!e.target.value.match(/^\d{0,10}$/)) {
                  return;
                } else {
                  handleChanges(e);
                }
              }}
              onBlur={handleBlur}
              isDisabled={isImporting}
              pattern="^[+]?[0-9]{9,12}$"
            />
          </InputGroup>
          <p className={`text-red-500 text-sm`}>{formErrors?.phone}</p>
        </FormControl>

        <FormControl className="-sm:col-span-2">
          <FormLabel
            htmlFor="street_address"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
            // mt="2%"
          >
            Street Address
          </FormLabel>
          <Input
            type="text"
            id="address"
            autoComplete="street-address"
            focusBorderColor="brand.400"
            value={account?.address || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
            // shadow="sm"
            // size="sm"
            // w="full"
            // rounded="md"
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.address}</p>
        </FormControl>

        <FormControl className="-sm:col-span-2">
          <FormLabel
            htmlFor="city"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
            // mt="2%"
          >
            City
          </FormLabel>
          <Input
            type="text"
            id="city"
            autoComplete="city"
            focusBorderColor="brand.400"
            value={account?.city || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
            // shadow="sm"
            // size="sm"
            // w="full"
            // rounded="md"
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.city}</p>
        </FormControl>

        <FormControl>
          <FormLabel
            htmlFor="state"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
            // mt="2%"
          >
            State / Province
          </FormLabel>
          <Input
            type="text"
            name="state"
            id="state"
            autoComplete="state"
            focusBorderColor="brand.400"
            value={account?.state || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
            // shadow="sm"
            // size="sm"
            // w="full"
            // rounded="md"
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.state}</p>
        </FormControl>

        <FormControl>
          <FormLabel
            htmlFor="postal_code"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
            // mt="2%"
          >
            ZIP / Postal
          </FormLabel>
          <Input
            type="text"
            id="zip"
            autoComplete="zip-code"
            focusBorderColor="brand.400"
            value={account?.zip || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
            // shadow="sm"
            // size="sm"
            // w="full"
            // rounded="md"
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.zip}</p>
        </FormControl>

        <FormControl className="-sm:col-span-2">
          <FormLabel
            htmlFor="country"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            Country / Region
          </FormLabel>
          <Input
            type="text"
            id="country"
            autoComplete="country"
            focusBorderColor="brand.400"
            placeholder="United States"
            value={account?.country || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
            isDisabled={isImporting}
          />
          <p className={`text-red-500 text-sm`}>{formErrors?.country}</p>
        </FormControl>
      </div>
    </>
  );
};

export default FormBasicInfo;
