import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import {
  useLogin,
  useNotify,
  useRedirect,
  // Notification,
  // useNotificationContext,
} from "react-admin";
// import {
//   useLocation,
//   useNavigate,
//   useParams,
//   useSearchParams,
// } from "react-router-dom";
import { useLocalStorage, useQueryParams } from "@utils/hooks";
import { LOCAL_STORE_KEYS, ObjectProps, isNullOrEmpty } from "@utils/index";
import inMemoryJWT from "../providers/auth/inMemoryJWT";
import SignInPage from "./SignInPage";
import WithChakra from "../components/utils/ChakraWrapper";
import AppLoader from "@components/utils/AppLoader";

const Login = () => {
  // const navigate = useNavigate();
  const redirect = useRedirect();
  const { accessToken, refreshToken, id, firstName, error } = useQueryParams();

  const { setLocalStorageItem } = useLocalStorage();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const [password, setPassword] = useState('');
  const login = useLogin(); // calls frontend/src/providers/auth/authProvider.ts
  const notify = useNotify();

  if (accessToken && refreshToken) {
    // console.log("accessToken", accessToken);
    // console.log("refreshToken", refreshToken);
    // use w/ localStorage
    setLocalStorageItem(
      LOCAL_STORE_KEYS.accessToken,
      decodeURIComponent(accessToken)
    );
    setLocalStorageItem(
      LOCAL_STORE_KEYS.refreshToken,
      decodeURIComponent(refreshToken)
    );
    setLocalStorageItem(LOCAL_STORE_KEYS.userId, id);
    // use w/ inMemoryJWT
    // inMemoryJWT.setToken(decodeURIComponent(accessToken));
    // inMemoryJWT.setRefreshToken(decodeURIComponent(refreshToken));
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!email) {
      notify("Email Required", {
        type: "warning",
        autoHideDuration: 1000 * 30 * 1,
      });
      return;
    }
    setIsLoading(true);
    setLocalStorageItem(LOCAL_STORE_KEYS.userId, email);

    login({ email })
      .then((res: ObjectProps) => {
        // console.log("res", res);
        // resetNotifications();
        notify(res?.msg, { type: "success", autoHideDuration: 1000 * 60 * 1 });
        // return { redirectTo: false };
      })
      .catch((error) => {
        // console.log("error", JSON.stringify(error));
        notify(error?.body?.message || "Something went wrong. Try again", {
          type: "warning",
          autoHideDuration: 1000 * 60 * 1,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (accessToken) {
      redirect("/");
    }
  }, [accessToken]);

  useEffect(() => {
    switch (error) {
      case "EXPIRED_LINK": {
        notify(
          `Invalid Login or Expired Link. Please check that you're using the most recent link sent to your email.`,
          { type: "warning", autoHideDuration: 1000 * 60 * 3 }
        );
        break;
      }
      case "EXISTING_CUSTOMER": {
        const msg = (
          <div className="bg-secondary-light p-2">
            <p>You already have an account. Please login to continue.</p>
            <p>
              Or Need to reinstate your subscription?{" "}
              <a
                href="https://billing.stripe.com/p/login/6oE8yk39C9e7fteaEE"
                target="blank"
                className="text-secondary-dark"
              >
                Visit Stripe Billing
              </a>{" "}
            </p>
          </div>
        );
        notify(msg, { type: "warning", autoHideDuration: 1000 * 60 * 10 });
        break;
      }
    }
  }, [error]);

  if (
    // true
    accessToken &&
    refreshToken &&
    id
  ) {
    return <AppLoader name={firstName} />;
  }

  return (
    <WithChakra>
      <SignInPage
        onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
        email={email}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </WithChakra>
  );
};

export default Login;
