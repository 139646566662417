import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import { useDel, useDisplayToast, usePost, usePut } from "@utils/hooks";
import {
  Dict,
  Entity,
  GetList,
  IResponse,
  Task as ITask,
} from "@utils/interface";
import { useContext, useEffect, useRef, useState } from "react";
import { useGetList } from "react-admin";
import { BiX, BiPlus, BiArrowBack } from "react-icons/bi";
import ActionCard from "@components/ActionCard";
import { isNullOrEmpty } from "@utils/index";
import { Avatar } from "@chakra-ui/react";
import CustomDropdown from "@components/CustomDropdown";
import { FiUserPlus } from "react-icons/fi";

interface Props {
  itemId: string;
  setShowTasks: React.Dispatch<React.SetStateAction<boolean>>;
}

const Task = (props: Props) => {
  const { setShowTasks, itemId } = props;
  const { successToast, errorToast } = useDisplayToast();
  const { tasks: _tasks, setTasks, team, flags } = useContext(ContextStore);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<Dict>({});

  const [createOne, { isLoading: isCreating }] = usePost<ITask>();
  const [updateOne, { isLoading: isUpdating }] = usePut<ITask>();
  const [deleteOne, { isLoading: isDeleting }] = useDel<ITask>();

  // console.log("Task", itemId);
  const tasks = _tasks[itemId];

  //get tasks
  const {
    // data,
    isLoading,
    // error,
    refetch,
  } = useGetList<ITask>(
    Entity.tasks,
    {
      sort: { field: "createdAt", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
      filter: { boardItemId: itemId },
    },
    {
      // node_modules/react-query/types/core/types.d.ts:66
      // enabled: tasks?.length === 0 ? true : false,
      enabled: true,
      // initialData: tasks,
      // retryOnMount: false,
      onSuccess(data) {
        // console.log("taskData", data?.data);
        setTasks((prev) => ({ ...prev, [itemId]: data?.data }));
      },
      onError(error) {
        errorToast(error?.message);
      },
    }
  );

  const handleAdd = () => {
    if (isNullOrEmpty(taskData)) {
      errorToast("Task cannot be empty");
      return;
    }
    createOne(
      Entity.tasks,
      {
        data: {
          ...taskData,
          boardItem: {
            connect: {
              id: itemId,
            },
          },
        },
      },
      {
        onSuccess: (data) => {
          // console.log("onSuccess", data);
          successToast(`Task Added`);
          setTasks((prev) => ({
            ...prev,
            [itemId]: [...prev[itemId], data as ITask],
          }));
          setTaskData({});
          setIsEdit(false);
        },
        onError: (error) => {
          console.log("onError", error);
          // console.log("createError", JSON.stringify(createError));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleDelete = (itemId: string) => {
    deleteOne(
      Entity.tasks,
      { id: itemId },
      {
        onSuccess(data, variables, context) {
          successToast("Task Deleted");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleUpdate = (itemId: string, data: any) => {
    updateOne(
      Entity.tasks,
      { id: itemId, data },
      {
        onSuccess(data, variables, context) {
          // console.log("Board Updated");
          successToast("Task Updated");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  return (
    <ActionCard
      setShowCard={setShowTasks}
      header={
        isEdit ? (
          <div className="flex">
            <button
              onClick={() => {
                setIsEdit(false);
              }}
              className="flex-y-center gap-1 hover:text-black pr-1"
            >
              <BiArrowBack />
            </button>
            <p>New Task</p>
          </div>
        ) : (
          <p>Task Checklist</p>
        )
      }
      footer={
        <>
          <p className="text-black">
            {tasks?.length === 1
              ? `${tasks?.length} Task`
              : `${tasks?.length || 0} Tasks`}
          </p>
          <button
            onClick={() => {
              if (isEdit) {
                handleAdd();
              } else {
                setIsEdit(true);
              }
            }}
            className="flex-y-center gap-1 hover:text-black"
          >
            <BiPlus />
            <span>Add Task</span>
          </button>
        </>
      }
    >
      {isLoading || isCreating || isUpdating || isDeleting ? (
        <Loader size={"md"} />
      ) : isEdit ? (
        <div className="h-20 ">
          <input
            className="w-full h-10 resize-none outline-none"
            placeholder="Add a task..."
            value={taskData?.text}
            onChange={(e) => setTaskData({ ...taskData, text: e.target.value })}
          />
          {!!flags?.v2?.isEnabled && (
            <div className="flex">
              <CustomDropdown
                options={
                  team?.map((user, idx) => (
                    <div key={idx} className="flex items-center">
                      <Avatar size="sm" src={user?.imageUrl} className="mr-2" />
                      <span>
                        {user?.firstName || user?.email} {user?.lastName || ""}
                      </span>
                    </div>
                  )) || []
                }
                id="assignment"
                className={`w-9 flex justify-center items-center border-r bg-white relative enabled:hover:text-[#553C9A] disabled:cursor-default`}
                value={<FiUserPlus />}
                title="Assign To"
                showDropdown={false}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    assignedUser: { connect: { id: team[e.target.value]?.id } },
                  })
                }
                // disableOption={(idx) => {
                //   // console.log("assignedTo", idx);
                //   return (
                //     assignedTo?.findIndex((x) => x.id === team[idx].id) !== -1 ||
                //     false
                //   );
                // }}
              />
              <input
                className="h-10 px-3 placeholder:text-neutral-200 outline-none mt-1 rounded border"
                type="date"
                id={`deadline`}
                value={taskData?.deadline || ""}
                onChange={(e) =>
                  setTaskData({ ...taskData, deadline: e.target.value })
                }
                // onBlur={() => { }}
              />
              <CustomDropdown
                options={["High", "Medium", "Low"]}
                value={""}
                id={`priority`}
                onChange={(e) =>
                  setTaskData({ ...taskData, priority: e.target.value })
                }
                title="Priority"
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {tasks?.length > 0 ? (
            <div className="h-40">
              {tasks?.map((task, idx) => {
                return (
                  <div
                    key={idx}
                    className="my-2 last:mb-0 flex items-start text-[#475467]"
                  >
                    {task?.id === taskData?.id ? (
                      <input
                        className="outline-none w-full h-10 pb-1"
                        placeholder="Edit Task..."
                        value={taskData?.text}
                        onChange={(e) =>
                          setTaskData({ ...taskData, text: e.target.value })
                        }
                        onBlur={(e) => {
                          handleUpdate(task?.id, { text: taskData?.text });
                          setTaskData({});
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate(task?.id, { text: taskData?.text });
                            setTaskData({});
                          }
                        }}
                      />
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          name="taskItem"
                          id={task?.id}
                          className="mt-1 mr-1 bg-red-500 accent-[#553C9A]"
                          checked={task?.completed}
                          // disabled={task?.completed}
                          // onChange={e => e.target.checked ? handleCompleteTask() : null}
                          onChange={(e) =>
                            handleUpdate(task?.id, {
                              completed: e.target.checked,
                            })
                          }
                        />
                        <div className="justify-between flex w-full">
                          <label
                            onClick={() => setTaskData({ ...task })}
                            className="block"
                            htmlFor="taskItem"
                          >
                            {task?.completed ? <s>{task?.text}</s> : task?.text}
                          </label>
                          {/* <div className="flex-grow" /> */}
                          <button
                            onClick={() => handleDelete(task?.id)}
                            className="mr-3 h-max text-[13px] text-gray-400 hover:text-black flex-center border border-gray-400 hover:border-black rounded-full"
                          >
                            <BiX />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex-center h-14">
              <p>What's your first task?</p>
            </div>
          )}
        </>
      )}
    </ActionCard>
  );
};

export default Task;
