import { ellipsis } from "@utils/index";
import { Dict } from "@utils/interface";
import { BiX } from "react-icons/bi";
import { isMobile } from "react-device-detect";

export type BadgeItem = Dict & { id: string };

interface BadgeProps {
  handleRemove?: (item: BadgeItem) => void;
  handleClick?: (item: BadgeItem) => void;
  items: BadgeItem[];
  className?: string;
}

const BadgeSection = (props: BadgeProps) => {
  const { handleRemove, handleClick, items, className, ...rest } = props;

  return (
    <div
      // className="mx-5 w-full overflow-scroll no-scrollbar flex-1 flex"
      className={`${isMobile ? "" : "flex-1 flex flex-col"} ${
        className || "w-full overflow-scroll no-scrollbar flex-1 flex"
      }`}
      {...rest}
    >
      {items?.map((item, idx) => {
        return (
          <div
            key={idx}
            className={`${
              items?.length > 1 ? "mt-1" : ""
            } mr-4 h-max px-2 py-[3px] w-max flex-shrink-0 text-sm text-[#7A5AF8] bg-[#F4F3FF] flex items-center rounded-full`}
          >
            <span
              className={`${handleClick ? "hover:cursor-pointer" : ""}`}
              onClick={() => handleClick && handleClick(item)}
            >
              {ellipsis(item?.name || item?.title || "", 40)}
            </span>
            {handleRemove && (
              <button
                onClick={() => handleRemove(item)}
                className={`ml-1 rounded-full p w-max h-max hover:bg-red-500 hover:text-white`}
              >
                <BiX />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BadgeSection;
