// import { Node } from "slate";
// import { isEmpty } from "lodash";
import moment from "moment";
import {
  Account,
  AccountType,
  Dict,
  Entity,
  FeatureFlag,
  FolderItemType,
  IDict,
  MenuActionItem,
  PromptSection,
  SlateNode,
  UTMParams,
} from "./interface";
export * from "./interface";
import { useWindowSize } from "usehooks-ts";
import { get, orderBy, mapValues } from "lodash";
import { slateToPlainString } from "@playground/editors/slate";
import { FiExternalLink, FiInfo, FiVideo } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

export const newUUID = () => uuidv4();

export const MAX_FILE_SIZE = 50;

export const LOCAL_STORE_KEYS = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  userId: "userId",
  query: "query",
  // user: "user",
};

export const DEFAULT_DATE = "YYYY-MM-DD";
export const DEFAULT_US_DATE_FORMAT = "MM/DD/YYYY";
// iso format: 2020-12-31T23:59:59.999Z
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const APP_CONST = {
  LANDING_PAGE: 'grantwriteai.com',
  DEFAULT_ERROR: "Something went wrong! Please try again",
  locationErrorMessage:
    "Please, enable location services in your application setting, and try again",
  UNKNOWN: "unknown",
  MONTH_AI_QUOTA: 10,
  BETA_PERIOD: 60,
  API_URL: "/api",
  AUTH_URL: `/api/auth`,
  BANNER_TEXT: `We're aware of and looking into issues with the Grant Search returning only 1 result, no matter the ask.`
};
export const appName = "GrantWrite";
export const isProd = window.location.href.includes("app.");
export const isDev =
  window.location.href.includes("localhost") ||
  window.location.href.includes("develop.");
export const primaryColor = "purple";
export const signupUrl = `${!isDev ? `https://${window.location?.host?.replace("app.", "")}` : ""
  }/signup`;

export const defaultProfileImage =
  "https://avatars2.githubusercontent.com/u/37842853?v=4";

export const APP_URL = new URL(window.location.origin).href;
export const API_URL = process.env.VITE_API_URL || "http://localhost:3000";
export const calendlyLink = "https://calendly.com/grantwriteai/30min";
export const PRODUCT_UPDATE_VIDEOS = {
  // demo2: "https://www.youtube.com/embed/gpNVA9yBq1o/?listType=playlist&rel=0&loop=1&controls=0",
  Clients: {
    title: "Manage Clients & their Grants 🤝",
    text: `Learn essential strategies for managing clients seamlessly within ${appName}!`,
    link: "https://www.youtube.com/embed/CzlDcHhYmMo/?listType=playlist&rel=0&loop=1",
    // visible: (state) => state?.isAgency,
  },
  Update2: {
    title: `(NEW) ${appName} 2.0: How to Unlock Funding Opportunities with AI-Powered Grant Writing`,
    // `Introducing ${appName} 2.0: AI Document editor, AI Autocomplete, and more! 🎉`
    text: `${appName} 2.0 is here! We've added a ton of new features to help you write better grants faster. Watch the video to learn more.`,
    link: "https://www.youtube.com/embed/_vSfQxqobl4/?listType=playlist&rel=0&loop=1",
  },
  Folders: {
    title: "Staying Organized With Folders 🗂",
    text: `Learn how to organize your grant applications with folders in ${appName}.`,
    link: "https://www.youtube.com/embed/S5wYjr2bcgQ/?listType=playlist&rel=0&loop=1",
  },
  Deadlines: {
    title: "Staying On Top Of Deadlines ⏰",
    text: `Learn how to stay on top of your grant deadlines with ${appName}.`,
    link: "https://www.youtube.com/embed/TInEK1SKNOY/?listType=playlist&rel=0&loop=1",
  },
  Templates: {
    title: "NEW: Templates and Word Counts 🚀",
    text: `Learn about Templates and Word Counts in ${appName}!`,
    link: "https://www.youtube.com/embed/_NJXwPJOB6g/?listType=playlist&rel=0&loop=1",
  },
  Files: {
    title: "Chat with Files 📎",
    text: `Chat with uploaded files for faster insights in ${appName}!`,
    link: "https://www.youtube.com/embed/Z8Q3eoHGwH0/?listType=playlist&rel=0&loop=1",
  },
  // Chrome: {
  //   title: "NEW: Chrome Extension 🚀",
  //   text: `Learn about the new Chrome Extension in ${appName}!`,
  //   link: "https://www.youtube.com/embed/pvZ0mP98PAA/?listType=playlist&rel=0&loop=1",
  // },
  // Affiliate: {
  //   title: "Affiliate Program",
  //   link: "https://www.youtube.com/embed/e26tszC-Qt0/?listType=playlist&rel=0&loop=1",
  // }
};

export const GrantResources = [
  // how to find grants for nonprofits
  {
    label: "Directory of grants to nonprofits",
    link: "https://www.causeiq.com/directory/grants/",
    icon: FiExternalLink,
  },
  {
    label: "Private Grants for Nonprofits",
    link: "https://www.instrumentl.com/browse-grants/private-grants-for-nonprofits",
    icon: FiExternalLink,
  },
  {
    label: "Grants.gov: SEARCH GRANTS",
    link: "https://grants.gov/search-grants",
    icon: FiExternalLink,
  },
  {
    label: "Community Foundation Locator",
    link: "https://cof.org/page/community-foundation-locator",
    icon: FiExternalLink,
  },
  {
    label: "State Grant Resources",
    link: "https://www.tgci.com/funding-sources",
    icon: FiExternalLink
  },
  {
    label: "International Grants",
    link: "https://www.nonprofitexpert.com/international-grants/",
    icon: FiExternalLink
  },
  {
    label: "How to Find Grants for Nonprofits: Basics and Best Practices",
    link: "https://www.causevox.com/blog/how-to-find-grants-for-nonprofit/",
    icon: FiExternalLink,
  },
  {
    label: "Top Tips and Sources to Find Grants for Your Nonprofit",
    link: "https://donorbox.org/nonprofit-blog/find-grants-for-your-nonprofit",
    icon: FiExternalLink,
  },
  {
    label: "How To Find Grants for Nonprofits",
    link: "https://youtu.be/Uep6IYUjbBs",
    icon: FiVideo,
  },
  {
    label: "9 Places to Search for Nonprofit Grants",
    link: "https://youtu.be/gjJ2DlxyxL4?si=RHpqe-tZrbh7pVvd",
    icon: FiVideo,
  },
];


export const PLANS = {
  // empty: null,
  FREE: "free",
  BASIC: "basic",
  PRO: "pro",
  AGENCY: "premium",
  // custom: "custom",
  // enterprise: "enterprise",
};
export const PRICING_LINKS = {
  // [PLANS.basic]: "https://buy.stripe.com/dR6cMQeqJ9FdaaYeUU",
  [PLANS.PRO]: "https://buy.stripe.com/fZe4gkfuN7x5aaY9AB",
  [PLANS.AGENCY]: "https://buy.stripe.com/aEU7swgyR7x55UI146",
};
export const PRICING_URL = (plan: string) => PRICING_LINKS[plan];

export const localStorageKey = (key: string, prefixId?: string) => {
  return prefixId ? `${prefixId}-${key}` : key;
};

export const getAddress = async (
  lat: string | number,
  lng: string | number
) => {
  return await new Promise((resolve, reject) => {
    const API_KEY = process.env.API_KEY;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng}&key=${API_KEY}`
    )
      .then(async (response) => await response.json())
      .then((responseJson) => {
        resolve(responseJson.results[0]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export type FormValue = { value: string };

export const clearForm = (id: string) => {
  const form = window.document.getElementById(id) as HTMLFormElement;
  form.reset();
};

export const ellipsis = (str: string, len = 20) => {
  if (!len) return str;
  if (str?.length > len) {
    return str.substring(0, len) + "...";
  } else {
    return str;
  }
};

const slackEndpoint = process.env.SLACK_ALERTS_URL;
// https://stackoverflow.com/q/45752537
export const notifySlack = async (
  data: any,
  endpoint?: string,
  slackOptions: Dict = {}
) => {
  if (isDev) return;
  // console.log("notifySlack", slackEndpoint);
  // if (slackEndpoint && !isDev) {
  if (slackEndpoint) {
    const text =
      "```" +
      (typeof data === "string"
        ? data
        : `(${data?.name || "Slack"}: ${data?.message || "Msg"
        })\n${JSON.stringify(data)}`) +
      "```";
    fetch(endpoint || (slackEndpoint as string), {
      method: "post",
      headers: {},
      body: JSON.stringify(
        Object.assign(
          {},
          { text },
          {
            username: `${appName}-F | ${isProd ? "prod" : "staging"}`,
            ...slackOptions,
          }
        )
      ),
    }).catch((error: Error) => {
      console.log(`ERROR POSTING TO SLACK: ${error}, stack: ${error.stack}`);
    });
  }
};

export const notifyFeedback = (data: Dict | string, opts?: Dict) => {
  notifySlack(data, process.env.SLACK_FEEDBACK_URL, opts);
};

export const notifyError = (data: Dict | string, opts?: Dict) => {
  notifySlack(data, process.env.SLACK_ALERTS_URL, opts);
};

export const appColors = {
  primaryBg: "#6b4bbe",
  primaryText: "white",
  secondaryText: "#6a54b1",
  secondaryBg: "#e9d9fc",
  tertiaryBg: "white",
  tertiaryText: "#af9ad3",
};

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// https://stackoverflow.com/a/49050668
export const uniqueObjArray = (a: any) =>
  ([...new Set(a.map((o) => JSON.stringify(o)))] as string[]).map((s) =>
    JSON.parse(s)
  );

export const isNullOrEmpty = (obj: any) => {
  return (
    obj === null ||
    obj === undefined ||
    obj === "" ||
    (typeof obj === "object" && Object.keys(obj)?.length == 0) ||
    (Array.isArray(obj) && obj?.length === 0)
  );
};

export const mapNullToUndefined = (obj: Dict) =>
  mapValues(obj, (value) => (value === null ? undefined : value));

export const getDaysAgoString = (dateString?: string) => {
  // const inputDate = dateString ? new Date(dateString) : new Date();
  // const now = new Date();
  // now.setHours(0, 0, 0, 0);
  // const timeDifference = Math.abs(now.getTime() - inputDate.getTime());
  // const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  // return daysDifference;

  // https://stackoverflow.com/a/43371991/3562407
  const date = moment(dateString || new Date());
  const daysDiff = moment().diff(date, "days");
  if (daysDiff >= 2 && daysDiff < 7) {
    return date.fromNow();
  } else if (daysDiff >= 7) {
    return getDateString(dateString);
  }
  return date.calendar().split(" ")[0];
};

const colors = [
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
];

export function swap<T>(arr: T[], i: number, j: number): T[] {
  const copy = [...arr];
  const tmp = copy[i];
  copy[i] = copy[j];
  copy[j] = tmp;
  return copy;
}

export function pickChakraRandomColor(variant = "") {
  const color = colors[Math.floor(Math.random() * colors?.length)];
  return color + variant;
}

export const getDateString = (date?: string, format = DEFAULT_US_DATE_FORMAT) =>
  moment(date ? date : new Date()).format(format);

export const getFileSize = (size: number) => {
  return size / 1024 > 1024
    ? `${(size / (1024 * 1024)).toFixed(2)} mb`
    : `${(size / 1024).toFixed(2)} kb`;
};

export function convertTextToSlate(str: string): SlateNode[] {
  const paragraphs = str?.split("\n\n");
  const nodes: SlateNode[] = [];
  // console.log("paragraphs", paragraphs);

  // //detect numbered list
  // const numberedRegex = /^\d+\./;
  // const numberedParagraphs: string[] = [];
  // paragraphs.forEach((paragraph) => {
  //   if (numberedRegex.test(paragraph)) {
  //     numberedParagraphs.push(paragraph);
  //   }
  // });
  // if (numberedParagraphs.length > 0) {
  //   nodes.push({
  //     type: "numbered-list",
  //     children: numberedParagraphs.map((paragraph) => {
  //       return {
  //         type: "list-item",
  //         children: [{ text: paragraph.replace(/^\d+.\s/, '') }],
  //       };
  //     }),
  //   });
  // }

  // //detect bulleted list
  // const bulletedRegex = /^(•|-)/;
  // const bulletedParagraphs: string[] = [];
  // paragraphs.forEach((paragraph) => {
  //   if (bulletedRegex.test(paragraph)) {
  //     bulletedParagraphs.push(paragraph);
  //   }
  // });

  // if (bulletedParagraphs.length > 0) {
  //   nodes.push({
  //     type: "bulleted-list",
  //     children: bulletedParagraphs.map((paragraph) => {
  //       return {
  //         type: "list-item",
  //         children: [{ text: paragraph.replace(/^(•|-)+\s/, '') }],
  //       };
  //     }),
  //   });
  // }

  //process text sequentially

  // //detect headings
  // const headingRegex = /^(\d+)\./;
  // const headingParagraphs: string[] = [];
  // paragraphs.forEach((paragraph) => {
  //   if (headingRegex.test(paragraph)) {
  //     headingParagraphs.push(paragraph);
  //   }
  // });

  // if (headingParagraphs.length > 0) {
  //   nodes.push({
  //     type: "heading-one",
  //     children: headingParagraphs.map((paragraph) => {
  //       return {
  //         type: "list-item",
  //         children: [{ text: paragraph }],
  //       };
  //     }),
  //   });
  // }

  let numberedParagraphs: string[] = [];
  let bulletedParagraphs: string[] = [];

  //detect paragraphs
  paragraphs?.forEach((paragraph) => {
    //detect numbered list
    const numberedRegex = /^\d+\./;
    if (numberedRegex.test(paragraph)) {
      numberedParagraphs.push(paragraph);
      return;
    }

    if (numberedParagraphs.length > 0) {
      nodes?.push({
        type: "numbered-list",
        children: numberedParagraphs.map((paragraph) => {
          return {
            type: "list-item",
            children: [{ text: paragraph.replace(/^\d+.\s/, "") }],
          };
        }),
      });
      nodes?.push({ type: "paragraph", children: [{ text: "" }] });
      numberedParagraphs = [];
    }

    //detect bulleted list

    const bulletedRegex = /^(•|-)/;

    if (bulletedRegex.test(paragraph)) {
      bulletedParagraphs?.push(paragraph);
      return;
    }

    if (bulletedParagraphs.length > 0) {
      nodes?.push({
        type: "bulleted-list",
        children: bulletedParagraphs.map((paragraph) => {
          return {
            type: "list-item",
            children: [{ text: paragraph.replace(/^(•|-)+\s/, "") }],
          };
        }),
      });
      nodes?.push({ type: "paragraph", children: [{ text: "" }] });
      bulletedParagraphs = [];
    }

    if (paragraph) {
      nodes?.push({ type: "paragraph", children: [{ text: paragraph }] });
    }
    nodes?.push({ type: "paragraph", children: [{ text: "" }] });
  });

  return nodes;
}

export const arrayToObject = (arr: any[], key: string) => {
  //array to object
  const obj = arr?.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
  return obj;
};

export const isMobileSize = () => {
  const { width } = useWindowSize();
  return width < 768; //600
};

export const includesString = (arr: string[], str: string) =>
  arr?.some((s) => str?.toLowerCase().includes(s?.toLowerCase()));

export const handleSort = (
  dir: "asc" | "desc" | undefined,
  path: string | string[],
  arr: Array<any>,
  cb: (...args) => void
) => {
  if (dir) {
    cb(
      orderBy(
        arr,
        [
          (item) =>
            typeof get(item, path) == "string"
              ? get(item, path).toLowerCase()
              : get(item, path),
        ],
        dir
      )
    );
  }
};

export const queryEmbeds = {
  user: `user`,
  documents: `user,prompts.embeddings,prompts.response.embeddings,prompts.assignedTo`,
  shareDoc: `prompts.embeddings,prompts.response.embeddings`,
  folders: `parent,subFolders,docs,grants,files`,
};

export function filterVisibleItems(items?: MenuActionItem[]) {
  return (
    items &&
    items?.filter((item) => {
      if (item?.visible === false) {
        return false;
      }
      if (item?.items && item?.items.length > 0) {
        item.items = filterVisibleItems(item?.items);
      }
      return true;
    })
  );
}

export const getDaysDiff = (date: string) => {
  const timeDifference = new Date(date || "").getTime() - new Date().getTime();
  // Calculate the difference in days using seconds
  const daysDifference = Math.floor(timeDifference / (24 * 3600 * 1000));
  return daysDifference;
};

export const copyToClipboard = async (text: string) => {
  return await navigator.clipboard.writeText(text).catch((err) => {
    const msg = "Failed to copy text to clipboard";
    // alert(msg);
    // console.log(msg, err);
    throw new Error(msg);
  });
};

export const openInNewTab = (url?: string, timeout?: number) => {
  setTimeout(() => {
    const win = window.open(url || "", "_blank");
    win?.focus();
  }, timeout || 0);
};

export const toQueryParams = (params: Dict) => {
  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
};

export const setURLQuery = (params: Dict) => {
  // https://stackoverflow.com/a/73740327
  const currentUrlParams = new URLSearchParams(window.location.search);
  for (const key in params) {
    if (!params[key]) {
      currentUrlParams.delete(key);
    } else {
      currentUrlParams.set(key, params[key]);
    }
  }

  const newUrl = window.location.pathname + "?" + currentUrlParams.toString();
  // console.log("newUrl", newUrl);
  window.history.replaceState(null, "", newUrl);
};

export type PlanType = keyof typeof PLANS;

export const upgradeMsg = "Please upgrade your account to use this feature.";

export const isPlanIncluded = (account: Account, plans: string[]) => {
  // return isDev || plans?.includes(account?.planName as PlanType);
  return plans?.includes(account?.planName as PlanType);
};

export const BASIC_FILE_LIMIT = 100;
export const BASIC_PROPOSAL_LIMIT = 50;

export const tailwindHelpers = {
  responsiveModal:
    "-sm:w-full -sm:min-w-[370px] -lg:w-[600px] lg:w-[900px] h-max max-w-[900px]",
  responsiveModal2:
    "-sm:w-full -sm:min-w-[370px] -lg:w-[600px] lg:w-[900px] max-w-[900px]",
};

export function pluralize(str: string) {
  return str + "s";
}
//create unique set of objects by json stringifying them
export const uniqueObjects = (arr: any[]) => {
  return [...new Set(arr.map((obj) => JSON.stringify(obj)))].map((str) =>
    JSON.parse(str)
  );
};

export const replaceTag = (t: string, flags: string) =>
  new RegExp(`<${t}[^>]*>(.*?)<\/${t}>`, flags);

export const getBaseUrl = () =>
  new URL(window.location.origin).href?.replace(/\/$/, "");

export const toUTMUrl = (url: string, utmParams: UTMParams) => {
  return url + "?" + toQueryParams(utmParams);
};

export const handleRenderResponse = (x: PromptSection) => {
  return x?.currentResponse
    ? slateToPlainString(x?.currentResponse)
    : x?.response?.embeddings?.[0]?.content || "";
};

export const handleRenderPrompt = (x: PromptSection) => {
  return (
    x?.title || ellipsis(x?.embeddings?.[0]?.content, 50) || "Untitled Prompt"
  );
};

export function processMarkdown(markdownString: string) {
  if (!markdownString || typeof markdownString !== "string") return "";
  // Regex to match markdown links and replace them with HTML links
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const processedString = markdownString?.replace(
    linkRegex,
    '<a target="blank" href="$2" class="underline text-white hover:text-blue-200 visited:text-purple-300">$1</a>'
  );

  // Additional regex for bold and underline (simplified example)
  const boldRegex = /\*\*(.*?)\*\*/g;
  const underlineRegex = /__([^_]+)__/g;

  const htmlString = processedString
    .replace(boldRegex, "<strong>$1</strong>")
    .replace(underlineRegex, "<u>$1</u>");

  return htmlString;
}

export const parseValue = <T>(val?: string) => {
  if (!val) return val;
  const parsed = canParse<T>(val);
  return parsed || val;
};

export const parseFlagValue = <T>(flag: FeatureFlag) => {
  return flag?.isEnabled
    ? parseValue<T>(flag?.flagName.split("_")[1])
    : undefined;
};

export const canParse = <T>(jsonString?: string): T | false => {
  if (!jsonString) return false;
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
};

export const getApiUrl = (
  path: string,
  skipAuth = !!process.env.VITE_UNAUTH_API
) => {
  return `/api${skipAuth ? "/unauth" : ""}${path}`;
};

export const handleMaintainFocus = (type: "input" | "textarea" = "input") => {
  document.addEventListener(
    "click",
    function (event) {
      const input = (event?.target as any)?.closest(type);
      if (input) {
        event.stopPropagation(); // Prevent the click event from bubbling up
      }
    },
    true
  );
};

export const base64EncodedObject = (obj: Dict) => {
  return btoa(JSON.stringify(obj));
};

export const getFolderItemType = (item: Partial<IDict>) => {
  return item.$kind === "File"
    ? FolderItemType.files
    : item.$kind === "Document"
      ? FolderItemType.docs
      : item.$kind === "Folder"
        ? FolderItemType.subFolders
        : FolderItemType.grants;
};

export const isAccountType = (account: Account, type: AccountType) =>
  account?.type === type;

export const isFreeAccount = (account: Account) =>
  [PLANS.FREE, PLANS.BASIC].includes(account?.planName || PLANS.FREE);

export const hasSubscription = (account: Account) =>
  !!account?.subscriptionId;

export const isPaidAccount = (account: Account) =>
  !isFreeAccount(account) && hasSubscription(account);

export const shouldUpgrade = (
  account: Account,
  type?: AccountType,
  check?: boolean
) => {
  type = type || account?.type;
  const isTypeCheck = isAccountType(account, type!);
  const isFreePlan = isFreeAccount(account);
  const result = isTypeCheck && isFreePlan && (check ? check : true);
  switch (type) {
    case AccountType.AGENCY:
      return result && getNumberOfActiveClients(account?.linkedAccounts) > 5;
    case AccountType.REGULAR:
      return result;
  }
};

export const getWhiteListAgency = () => {
  return process.env.AGENCY_WHITELIST?.split(",")[isDev ? 0 : 1];
};

export const isWhiteListAgency = (accountId: string) => {
  return includesString(
    (process.env.AGENCY_WHITELIST || "")?.split(","),
    accountId
  );
};

export const isNotAgencyCheck = (state: Dict, accountId: string) => {
  return !state?.isAgency || isWhiteListAgency(accountId);
};

export const getOriginUrl = (url: string) => {
  // https://stackoverflow.com/a/15979390
  try {
    const link = new URL(url);
    return link?.origin;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "#";
  }
};

export const getNumberOfActiveClients = (accounts: Account[]) => {
  // console.log("accounts", accounts)
  if (!accounts?.length) return 0;
  const results = accounts?.filter(
    (x) =>
      x?.documents?.length ||
      x?._count?.documents ||
      x?.files?.length ||
      x?._count?.files ||
      x?.notes?.length ||
      x?._count?.notes ||
      x?.tasks?.length ||
      x?._count?.tasks ||
      x?.boardItems?.length ||
      x?._count?.boardItems
  ).length;
  return results;
};
