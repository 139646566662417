interface Props {
  text?: string;
  color?: string;
  className?: string;
}

const NewBadge = (props: Props) => {
  return (
    <div
      className={`top-0 left-0 px-2 py-1 text-sm font-bold text-white ${props?.color ? `bg-${props?.color}-500` : "bg-purple-600"
        } rounded-br-md rounded-tl-md ${props?.className || ''}`}
    >
      {(props?.text || "new").toUpperCase()}
    </div>
  );
};

export default NewBadge;
