import { useRef, useState } from "react";
import { useContext } from "react";
import { useOnClickOutside } from "usehooks-ts";
import Blob from "@assets/blob-icons.svg";
import horizontalBlob from "@assets/horizontal-blob-icons.png";
import { StepProps } from "./interface";
import { ContextStore } from "@/components/utils/Context";
import { useDisplayToast, usePut } from "@/utils/hooks";
import { Account, Entity } from "@/utils";
import Loader from "@/components/utils/Loader";
import InputForm from "@/components/InputForm";
import { set } from "lodash";

const OrgSetup = ({ setCurrentStep, currentStep }: StepProps) => {
  const { account, setAccount } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const [newAccount, setNewAccount] = useState<Account>(account || {});

  const [updateAccount, { isLoading: isUpdating }] = usePut<Account>();

  const handleUpdate = () => {
    updateAccount(
      Entity.accounts,
      {
        id: account?.id,
        data: newAccount
      },
      {
        onSuccess(data, variables, context) {
          // console.log("data", data);
          // successToast("Org Updated!");
          setAccount(data);
          setCurrentStep(currentStep + 1);
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
        onSettled(data, error, variables, context) {
        },
      }
    );
  };

  return (
    <div className="bg-black bg-opacity-40 w-screen h-screen px-5 py-14 flex justify-center fixed top-0 left-0 z-[1000] overflow-y-scroll">
      <div
        className="w-full lg:w-[70%] mx-auto flex -md:flex-col bg-white children:block"
      // ref={tabRef}
      >
        <div className="w-full h-full lg:w-1/2 -lg:flex lg:max-w-[500px] justify-center flex-shrink relative">
          <img
            className=" -md:hidden h-full w-full object-cover max-w-[500px] "
            src={Blob}
            alt=""
          />
          <img
            className=" md:hidden absolute h-full w-full object-cover"
            src={horizontalBlob}
            alt=""
          />
          <div className="h-full px-5 w-full -lg:px-8 lg:bg-none flex-center flex-col text-center absolute z-10 left-0 top-0">
            <p className=" mb-7 text-4xl -sm:text-3xl font-bold text-black">
              Get personalized recommendations based on your organization
            </p>
            <p className=" text-lg -sm:text-base font-medium text-gray-600">
              Tell us a little about your organization and it's mission
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/2 mb-5 bg-white flex flex-col justify-center overflow-scroll ">
          <div className="p-5 pt-10 mx-auto w-full children:mb-5 children:w-full">
            <form onSubmit={(e) => {
              e.preventDefault();
              handleUpdate();
            }}>
              <InputForm
                cols={0}
                footer={
                  <button
                    className="h-9 w-1/2 py-1 text-[#553C9A] hover:text-white bg-[#E9D8FD] hover:bg-[#553C9A] transition-colors duration-100 rounded-lg"
                    type="submit"
                  >
                    {isUpdating ? <Loader size={'sm'} /> : "Complete"}
                  </button>
                }
                fields={[
                  {
                    label: "Organization Name",
                    type: "text",
                    key: "name",
                    placeholder: "Enter Organization's Name",
                    required: true,
                    visible: true,
                  },
                  {
                    label: "Street Address",
                    type: "text",
                    key: "address",
                    placeholder: "123 Main St",
                  },
                  {
                    label: "State / Province",
                    type: "text",
                    key: "state",
                    placeholder: "WA",
                  },
                  {
                    label: "Zip / Postal",
                    type: "text",
                    key: "zip",
                    placeholder: "98101",
                    pattern: "[0-9]{5}",
                  },
                  {
                    label: "Country",
                    type: "text",
                    key: "country",
                    placeholder: "United States",
                  },
                  {
                    label: "Phone",
                    type: "tel",
                    key: "phone",
                    placeholder: "1234567890",
                    pattern: "^[+]?[0-9]{9,12}$",
                  },
                  {
                    label: "Mission",
                    type: "textarea",
                    key: "mission",
                    placeholder: `${account?.name}'s mission is...`,
                    required: true,
                    h: "28",
                  },
                  {
                    label: "History",
                    type: "textarea",
                    key: "history",
                    placeholder: `${account?.name}'s history is...`,
                    required: true,
                    h: "28",
                  },
                  {
                    label: "Mandate",
                    type: "textarea",
                    key: "mandate",
                    placeholder: `${account?.name}'s goal is to...`,
                    required: true,
                    h: "28",
                  },
                ]?.filter((field) => field.visible !== false)}
                object={newAccount}
                onChange={(e) => {
                  const account = set(
                    { ...newAccount },
                    e?.target?.id || e?.target?.name,
                    e?.target?.value
                  );
                  setNewAccount(account);
                }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgSetup;
