import { Image } from "@chakra-ui/react";
import logo from "@assets/logo.svg";
import AppButton from "./AppButton";
import { useLogout } from "react-admin";
import {
  Account,
  appName,
  Entity,
  isDev,
  openInNewTab,
  PLANS,
  PRICING_URL,
} from "@utils/index";
import { useContext } from "react";
import { ContextStore } from "./utils/Context";
import { useAccountUpdate, usePut } from "@utils/hooks";

interface Props {
  forceLogout?: boolean;
  account: Account;
  cb: (data: Account) => void;
}

const TrialExpiration = (props: Props) => {
  const { forceLogout, account, cb } = props;
  const { globalState, setGlobalState } = useContext(ContextStore);
  const logout = useLogout();

  const { handleUpdate } = useAccountUpdate(
    account,
    {
      additionalData: {
        ...account.additionalData,
        shouldUpgrade: true,
      },
    },
    (data) => {
      cb && cb(data);
      forceLogout
        ? logout()
        : setGlobalState((prev) => ({ ...prev, showUpgradeToPaid: false }));
    }
  );
  // const [update] = usePut<Account>();

  // const handleUpdate = () => {
  //   update(
  //     Entity.accounts,
  //     {
  //       id: account.id,
  //       data: {
  //         additionalData: {
  //           ...account.additionalData,
  //           shouldUpgrade: true,
  //         },
  //       },
  //     },
  //     {
  //       onSuccess: (data) => {
  //         cb && cb(data);
  //         forceLogout
  //           ? logout()
  //           : setGlobalState((prev) => ({ ...prev, showUpgradeToPaid: false }));
  //       },
  //     }
  //   );
  // };

  return (
    <div className="w-screen h-screen -sm:p-5 flex flex-col justify-center items-center children:text-center">
      <div className="flex mb-20">
        <Image src={logo} h={8} w={8} />
        <p className="text-2xl ml-2 font-semibold">{appName}</p>
      </div>
      <p className="text-xl font-semibold">
        {/* Your previous access has expired. */}
        Looks like you've hit a limit! You must be getting a lot done!
      </p>
      <p className="text-2xl font-semibold text-purple-500">
        {/*  Select a pricing plan to continue access. */}
        If you'd like {forceLogout ? "to restore" : "unlimited"} access, just
        upgrade to a paid plan.
      </p>
      <p className="text-sm italic font-bold">
        Note: Use the same email address to preserve your account data.
      </p>
      <div className="mt-10 flex flex-col gap-5 children:w-40 sm:flex-row sm:gap-10 sm:w-auto">
        <AppButton
          variant="primary"
          text="Select Plan"
          onClick={() =>
            openInNewTab(
              PRICING_URL(globalState?.isAgency ? PLANS.AGENCY : PLANS.PRO)
            )
          }
        />
        {/* <AppButton variant="secondary" text="Logout" onClick={() => logout()} /> */}
        <AppButton
          variant="secondary"
          text={forceLogout ? "Logout" : "Maybe Later"}
          onClick={() => {
            handleUpdate();
          }}
        />
      </div>
    </div>
  );
};

export default TrialExpiration;
