"use client";

import { v4 as uuidv4 } from "uuid";
import Editor from "./editor";
import { ContentItem } from "../lib/types/note";
import { addItem, deleteItem, idbTable, updateItem } from "../store/db.model";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
/**
 * https://inke.app/post/3ca31e32-543a-47ca-8eaf-87f6e530c04e - ** Inke Guide **
 * https://inke.app/post/2ecf78a6-08a4-4a38-bf21-3ab84b5177fd
 */
// import { defaultInkeGuide } from "../lib/consts";
import { User, Document, isDev } from "@/utils";
// import { LoadingCircle } from "../ui/shared/icons";
import { JSONContent } from "@tiptap/react";
// import Loader from "@/components/utils/Loader";
// import { Session } from "next-auth";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { TipTapEditor } from "@/playground/editors/interface";
import { VERSION_DURATION, VERSION_LIMIT } from "../lib/consts";

interface Props {
  document: Document;
  defaultContent: JSONContent;
  user?: User,
  onEditorUpdate?: (editor: TipTapEditor) => void;
  customProvider?: HocuspocusProvider;
}

export default function EditorWrapper(props: Props) {
  const { document, user, defaultContent, customProvider } = props;
  const docId = document?.id || uuidv4();

  const [itemId, setItemId] = useState<string | undefined>(undefined);

  const versions = useLiveQuery<ContentItem[]>(async () => {
    return idbTable
      .orderBy("[docId+created_at]").and((item) => item.docId === docId).toArray()
    // .and((item) => item.docId === id).reverse().toArray()
    // .where({ docId: id }).sortBy('created_at')
    // .orderBy("[docId+created_at]").reverse().toArray()
  }, [docId, itemId]);

  const lastVersion = versions?.slice(-1)[0];

  const handleVersionDoc = (updatedContent: JSONContent, itemIndex: number, forceSave = false) => {
    if (!versions) return;

    const isNewDay = !!(lastVersion && lastVersion?.created_at && new Date().getTime() - new Date(lastVersion.created_at).getTime() > VERSION_DURATION);
    const shouldVersion = !versions.length || isNewDay;

    if (forceSave || shouldVersion) {
      const date = new Date();
      const randId = uuidv4();
      const newItem: ContentItem = {
        id: randId,
        docId,
        title: `Version-${randId.slice(0, 6)}-${date.getMonth() + 1}/${date.getDate()}`,
        content: updatedContent,
        created_at: date.getTime(),
      };
      addItem(newItem);
      // setItemId(randId);
      if (versions.length >= VERSION_LIMIT) {
        deleteItem(versions[0].id);
      }
    } else {
      if (itemIndex === -1) return;
      // console.log("Updating item", itemIndex);
      updateItem({ ...versions[itemIndex], content: updatedContent, });
    }
  };

  const allowVersioning = true;
  const saveToCloud = true;

  return (
    <div className="flex w-full">
      <Editor
        doc={document || { id: docId, title: "New Document" }}
        defaultContent={defaultContent}
        user={user}
        allowVersioning={allowVersioning}
        allowSaving={saveToCloud}
        versions={versions || []}
        itemId={itemId}
        setItemId={setItemId}
        onEditorUpdate={props.onEditorUpdate}
        customProvider={customProvider}
        lastVersion={lastVersion}
        handleVersionDoc={handleVersionDoc}
      />
    </div>
  );
}
