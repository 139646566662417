
import { ContextStore } from "@/components/utils/Context";
import { useContext, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const AutoWriteQuestions = () => {
  const { globalState, setGlobalState,} = useContext(ContextStore);
  // const [questions, setQuestions] = useState<string[]>([""]);
  const questions = globalState.autoWriteOptions?.questions || [""];
  const setQuestions = (questions: string[]) => setGlobalState({ ...globalState, autoWriteOptions: { ...globalState.autoWriteOptions, questions } });

  return (
    <div className="">
      <label htmlFor="question">Custom Questions</label>
      {questions?.map((question, idx) => (
        <div key={idx} className={`flex`}>
          <button
            type="button"
            className="text-lg mt-1 w-max mr-2 text-[#B784F4]"
            onClick={() => {
              setQuestions(questions?.filter((_, i) => i !== idx))
            }}
          >
            <FiMinus />
          </button>
          <input
            key={idx}
            className="w-full h-10 px-3 placeholder:text-neutral-400 outline-none mt-1 rounded border"
            type="text"
            id={`question-${idx}`}
            placeholder="Enter your grant question here..."
            value={question || ""}
            onChange={(e) => {
              // setDeadlines([...questions.slice(0, idx), e.target.value, ...questions.slice(idx + 1)])
              const copy = [...questions];
              copy[idx] = e.target.value;
              setQuestions([...copy])
            }}
          />
        </div>
      ))}

      <div className="flex">
        <button
          type="button"
          className="text-lg mt-1 w-max text-purple-700"
          onClick={(e) => {
            // console.log("clicked");
            setQuestions([...questions, ""])
          }}
        >
          <FiPlus />
        </button>
      </div>
    </div>
  );
}

export default AutoWriteQuestions;