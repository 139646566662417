import { StepProps } from "./interface";
import AddGrant from "./AddGrant";
import Complete from "./Complete";
import Feedback from "./Feedback";
import GrantBoard from "./GrantBoard";
import Notifications from "./Notifications";
import OrgProfile from "./OrgProfile";
import ReadyToExplore from "./ReadyToExplore";
import Welcome from "./Welcome";
import OrgSetup from "./OrgSetup";

const RenderNext = ({
  currentStep,
  setCurrentStep,
  handleFinish,
}: StepProps) => {
  switch (currentStep) {
    default:
    case 1:
      return <Welcome currentStep={1} setCurrentStep={setCurrentStep} />;
    case 2:
      return (
        <ReadyToExplore
          currentStep={2}
          handleFinish={handleFinish}
          setCurrentStep={setCurrentStep}
        />
      );
    case 3:
      return <AddGrant currentStep={3} setCurrentStep={setCurrentStep} />;
    case 4:
      return <GrantBoard currentStep={4} setCurrentStep={setCurrentStep} />;
    case 5:
      return <Notifications currentStep={5} setCurrentStep={setCurrentStep} />;
    case 6:
      return <Feedback currentStep={6} setCurrentStep={setCurrentStep} />;
    case 7:
      return <OrgProfile currentStep={7} setCurrentStep={setCurrentStep} />;
    case 8:
      return <OrgSetup currentStep={8} setCurrentStep={setCurrentStep} />;
    case 9:
      return (
        <Complete
          currentStep={9}
          setCurrentStep={setCurrentStep}
          handleFinish={handleFinish}
        />
      );
  }
};

export default RenderNext;
