import { IResponse, Event, MenuAction, User, Dict, Entity } from "@utils/index";
import { useContext, useEffect, useRef, useState } from "react";
import { BsPencilSquare, BsPeople, BsTrash2Fill } from "react-icons/bs";
import { useOnClickOutside } from "usehooks-ts";
import { BiCalendar, BiCheck, BiTrash, BiX } from "react-icons/bi";
import { useDisplayToast, usePost, usePut } from "@utils/hooks";
import { MdOutlineNotifications } from "react-icons/md";
import { LuPenLine } from "react-icons/lu";
import { FiSave } from "react-icons/fi";
import AppButton from "@components/AppButton";
import { ContextStore } from "@components/utils/Context";
import { Avatar } from "@chakra-ui/react";

const AddEditEvent = ({ handleDone, event, refetch: refetchEvents }) => {
  const { user, account, team } = useContext(ContextStore);

  const defaultEvent = {
    title: "",
    start: "",
    end: "",
    description: "",
    reminder: false,
    users: [],
  };

  const { successToast, errorToast, warningToast } = useDisplayToast();

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => handleDone());

  const teamRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(teamRef, () => setIsAddMembers(false));

  const [newEvent, setNewEvent] = useState(event || defaultEvent);
  const [isAddMembers, setIsAddMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<User[]>(
    event?.users || [user]
  );

  const [createOne, { isLoading: isCreating }] = usePost<Event>();
  const [updateOne, { isLoading: isUpdating }] = usePut<Event>();

  const handleCreateEvent = async () => {
    if (disableConfirm) return;
    createOne(
      Entity.events,
      {
        data: {
          ...newEvent,
          start: new Date(newEvent?.start),
          end: new Date(newEvent?.end ? newEvent?.end : newEvent?.start),
          account: { connect: { id: account?.id } },
          users: {
            connect: selectedMembers?.map((x) => ({ id: x?.id })),
          },
          organizer: { connect: { id: user?.id } },
        },
      },
      {
        onSuccess: (data) => {
          // console.log("onSuccess", data);
          refetchEvents();
          successToast(`Event Added`);
          // setNewEvent(defaultEvent);
          handleDone();
        },
        onError: (error) => {
          // console.log("onError", error);
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleUpdateEvent = async () => {
    if (disableConfirm) return;
    updateOne(
      Entity.events,
      {
        id: event?.id,
        data: {
          ...newEvent,
          start: new Date(newEvent?.start),
          end: new Date(newEvent?.end),
          users: {
            connect: selectedMembers?.map((x) => ({ id: x?.id })),
          },
        },
      },
      {
        onSuccess: (data) => {
          // console.log("onSuccess", data);
          refetchEvents();
          successToast(`Event Updated`);
          // setNewEvent(defaultEvent);
          handleDone();
        },
        onError: (error) => {
          // console.log("onError", error);
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const disableConfirm = !newEvent?.title || !newEvent?.start;

  return (
    <div className="pb-24 px-4 w-screen h-screen flex-center flex-col fixed z-[1000] top-0 left-0 ">
      <div
        ref={modalRef}
        className="w-full max-w-[396px] pb-5 rounded-lg border border-[#B794F4] bg-white"
      >
        <div className="h-16 pl-4 pr-2 sticky top-0 text-lg flex-y-center justify-between text-white bg-[#B794F4] rounded-t-lg">
          <div className="ml-auto flex gap-4 text-xl">
            <button
              className="mr-4 border border-3 border-white rounded-full"
              onClick={() => handleDone(false)}
            >
              <BiX />
            </button>
          </div>
        </div>

        <div className="text-slate-400 text-xs pt-2 pb-4 flex flex-col">
          <div className="py-3 px-6 flex-y-center gap-4 border-b border-gray-100">
            <input
              className={`${
                newEvent?.title?.length ? "text-black" : ""
              } w-full text-lg outline-none placeholder:text-inherit`}
              type="text"
              placeholder="Event Title"
              value={newEvent?.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
            />
          </div>

          <div className="py-3 px-6 flex-y-center gap-4 cursor-pointer border-b border-gray-100">
            <BiCalendar
              className={`text-neutral-300 text-xl flex-shrink-0 ${
                newEvent?.start?.length ? "text-purple-500" : ""
              }`}
            />
            <input
              type="date"
              placeholder="Start Date"
              className={`${newEvent?.start?.length ? "text-black" : ""}`}
              value={newEvent?.start?.split("T")[0] || ""}
              onChange={(e) => {
                if (e.target.value < new Date().toISOString().split("T")[0])
                  return warningToast(
                    "Start date cannot be in the past",
                    "Unable to set start date"
                  );
                setNewEvent({ ...newEvent, start: e.target.value });
              }}
            />
            <p>-</p>
            <input
              type="date"
              placeholder="End Date"
              className={`${newEvent?.end?.length ? "text-black" : ""}`}
              value={newEvent?.end?.split("T")[0] || ""}
              onChange={(e) => {
                if (!newEvent?.start)
                  return warningToast(
                    "Please select a start date",
                    "Unable to set end date"
                  );
                if (newEvent?.start && e.target.value < newEvent?.start)
                  return warningToast(
                    "End date cannot be before start date",
                    "Unable to set end date"
                  );
                setNewEvent({ ...newEvent, end: e.target.value });
              }}
            />
          </div>

          <div className="py-3 px-6 flex gap-4 cursor-pointer border-b border-gray-100 relative">
            <div
              className="flex w-full"
              onClick={() => setIsAddMembers(!isAddMembers)}
            >
              <BsPeople
                className={`text-neutral-300 text-xl flex-shrink-0 ${
                  selectedMembers?.length > 1 ? "text-purple-500" : ""
                }`}
              />
              <p className="pl-4">Add team members</p>
            </div>
            {isAddMembers ? (
              <div
                ref={teamRef}
                className="no-scrollbar max-h-[200px] w-content pb-7 grid bg-white border cursor-default absolute z-10 top-6 -right-14 overflow-y-auto custom-scrollbar rounded-lg"
              >
                {team
                  ?.filter((u) => u?.id !== user?.id)
                  ?.map((member) => {
                    const isSelected = selectedMembers.some(
                      (selectedMember) => selectedMember?.id === member.id
                    );
                    return (
                      <div
                        className={`${
                          isSelected ? "bg-neutral-50" : "hover:bg-neutral-50"
                        } py-2 px-3 flex-y-center gap-1 text-xs cursor-pointer`}
                        onClick={() =>
                          setSelectedMembers((prev) =>
                            prev?.find((x) => x.id === member?.id)
                              ? prev?.filter((x) => x.id !== member?.id)
                              : [...prev, member]
                          )
                        }
                      >
                        <Avatar size="sm" src={member?.imageUrl} />
                        <span className="mr-1 text-black font-medium">
                          {member?.firstName || ""} {member?.lastName || ""}
                        </span>
                        {/* <span>@{member?.email}</span> */}
                        {isSelected && (
                          <div className="ml-auto text-primary text-xl">
                            <BiCheck />
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              selectedMembers
                .filter((u) => u?.id !== user?.id)
                ?.map((member) => <Avatar size="sm" src={member?.imageUrl} />)
            )}
          </div>

          <div
            className="py-3 px-6 flex-y-center gap-4 cursor-pointer border-b border-gray-100"
            onClick={() => {
              setNewEvent({ ...newEvent, reminder: !newEvent?.reminder });
            }}
          >
            <MdOutlineNotifications
              className={`text-neutral-300 text-xl flex-shrink-0 ${
                newEvent?.reminder ? "text-purple-500" : ""
              }`}
            />
            <p>Notify the week before</p>
          </div>

          <div className="py-3 px-6 flex gap-4">
            <LuPenLine
              className={`text-neutral-300 text-xl flex-shrink-0 ${
                newEvent?.description?.length ? "text-purple-500" : ""
              }`}
            />
            <textarea
              className={`${
                newEvent?.description?.length ? "text-black" : ""
              } w-full placeholder:text-inherit resize-none outline-none custom-scrollbar`}
              placeholder="Add Description"
              value={newEvent?.description}
              onChange={(e) =>
                setNewEvent({ ...newEvent, description: e.target.value })
              }
            />
          </div>
        </div>

        <AppButton
          className="ml-auto mr-5 flex"
          icon={FiSave}
          text={event ? "Update" : "Create"}
          width="100px"
          margin="0"
          variant="secondary"
          iconPosition="right"
          onClick={event ? handleUpdateEvent : handleCreateEvent}
          isDisabled={disableConfirm}
          isLoading={isCreating || isUpdating}
          marginTop="4"
        />
      </div>
    </div>
  );
};

export default AddEditEvent;
