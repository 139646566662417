import { Account, AccountType } from "@utils/interface";
import NewAccountForm from "./agency/NewAccountForm";
import { useEffect, useState } from "react";
import { useDisplayToast, usePost } from "@utils/hooks";
import { useNavigate } from "react-router-dom";
// import Overlay from "@components/Overlay";
import OverlayModal from "@/components/modal/OverlayModal";
import Streamers from "@components/Streamers";
import Loader from "@components/utils/Loader";
import TextLoader from "@components/TextLoader";
import { Switch } from "@chakra-ui/react";
import { withChakra } from "@components/utils/ChakraWrapper";

const NewAccount = withChakra(() => {
  const { successToast, warningToast, errorToast } = useDisplayToast();
  const navigate = useNavigate();

  const [create, { isLoading, error }] = usePost<Account>();
  const [newAccount, setNewAccount] = useState<Account>({
    // planName: "free",
    // seats: 1,
  } as Account);

  //support creating new account
  const handleCreateAccount = (newAccount: Account) => {
    if (Object.keys(newAccount).length === 0) {
      warningToast("Please fill in the account details", "Invalid input");
      return;
    }
    create(
      `register`,
      {
        data: newAccount as Account,
      },
      {
        onSuccess: (data) => {
          // console.log("data", data);
          successToast(
            "Account created successfully. You'll be redirected to login page",
            "Account created"
          );
          setTimeout(() => {
            navigate(`/login`);
          }, 1000);
        },
        onError: (error) => {
          // console.log("error", error);
          errorToast(error?.body?.message || "Error creating account");
        },
      }
    );
  };

  const invalidAccount =
    !newAccount?.name ||
    !newAccount?.primaryContact?.firstName ||
    !newAccount?.primaryContact?.email ||
    // !newAccount?.phone ||
    // !newAccount?.history ||
    !newAccount?.mission;
  //|| !newAccount?.mandate;

  const invalidAgency =
    !newAccount?.name ||
    !newAccount?.primaryContact?.firstName ||
    !newAccount?.primaryContact?.email ||
    !newAccount?.phone;

  const isAgency = newAccount?.type === AccountType.AGENCY;

  const isDisabled = isLoading || (isAgency ? invalidAgency : invalidAccount);

  return (
    <div className="">
      <Streamers show={!isLoading && !error} />
      <OverlayModal
        title={"Create your FREE Account"}
        onClose={() => {
          window.history.back();
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAccount(newAccount);
          }}
        >
          <NewAccountForm
            newAccount={newAccount}
            setNewAccount={setNewAccount}
            customFields={[
              {
                label: "Phone",
                type: "tel",
                key: "phone",
                placeholder: "1234567890",
                required: true,
                pattern: "^[+]?[0-9]{9,12}$",
                visible: isAgency,
              },
              // {
              //   label: "History",
              //   type: "textarea",
              //   key: "history",
              //   placeholder: "Enter your organization's history",
              //   required: true,
              //   visible: !isAgency,
              // },
              {
                label: "Mission",
                type: "textarea",
                key: "mission",
                placeholder: "Enter your organization's mission",
                required: true,
                visible: !isAgency,
              },
              // {
              //   label: "Mandate",
              //   type: "textarea",
              //   key: "mandate",
              //   placeholder: "Enter your organization's aim/mandate",
              //   required: true,
              // },
            ]}
            customHeader={
              <div className="flex flex-col text-center  mb-10">
                <div className="flex-center pb-5 bg-purple-300">
                  <p className="text-xl text-white mt-4">
                    Need to manage multiple clients/orgs?
                  </p>
                  <Switch
                    id="isAgency"
                    isChecked={isAgency}
                    colorScheme="purple"
                    className="mt-5 ml-2 -sm:mr-3"
                    onChange={() =>
                      setNewAccount({
                        ...newAccount,
                        type: isAgency
                          ? AccountType.REGULAR
                          : AccountType.AGENCY,
                      })
                    }
                  />
                </div>
                {isAgency && (
                  <>
                    <p className="text-sm text-purple-500 mt-4">
                      You are about to sign up as an agency. That means you plan
                      to create & manage multiple clients/orgs. <br />
                      Your first 5 client accounts are free!
                    </p>
                  </>
                )}
              </div>
            }
          />
          <div className="flex flex-col flex-center text-center">
            <p className="text-sm text-gray-500 mt-4">
              By "Creating an Account" you agree to our{" "}
              <a href="/terms" className="text-purple-500">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy" className="text-purple-500">
                Privacy Policy
              </a>
            </p>
            <p className="text-sm text-black-500 mt-4">
              Already have an account?{" "}
              <a href="/login" className="text-purple-500">
                Login
              </a>
            </p>
            <button
              className={`w-1/2 bg-purple-500 text-white py-2 rounded-lg mt-4 transition-all duration-300 ease-in-out ${
                isDisabled ? "opacity-50 cursor-default" : "hover:bg-purple-600"
              }`}
              type="submit"
              disabled={isDisabled}
              // onClick={() => handleCreateAccount(newAccount)}
            >
              {isLoading ? (
                <Loader size={"lg"} />
              ) : (
                `Create ${isAgency ? "Agency" : ""} Account`
              )}
            </button>
          </div>
        </form>
      </OverlayModal>
    </div>
  );
});

export default NewAccount;
