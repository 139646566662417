import darkCheckIcon from "@assets/dark-checkmark-icon.svg";
import lightCheckIcon from "@assets/light-checkmark-icon.svg";

interface Props {
  activePlan: string;
}

const Pricing = (props: Props) => {
  const { activePlan } = props;

  // console.log("activePlan", activePlan);
  const isBasic = activePlan === "basic";
  const isPro = activePlan === "pro";
  const isPremium = !activePlan || activePlan === "premium";

  return (
    <div
      id="pricing"
      className="mb-14 px-10 flex-center-y flex-col text-gray-900"
    >
      <div className="mb-6 flex sm:flex-row -lg:flex-wrap justify-center gap-5 text-center ">
        <div
          className={`p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] ${
            isBasic ? "bg-primary-dark text-white" : "bg-white"
          } rounded-[14px] shadow-xl`}
        >
          <h3 className="text-2xl -sm:text-[22px] font-bold">Basic</h3>
          <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
            Starter
            {/* Best for organizations that manages under 20 grants application per
            year */}
          </p>
          <div>
            <span className="text-6xl -sm:text-[52px] font-bold">$29</span>
            <span>/month</span>
          </div>
          {/* <button
            onClick={() =>
              window.open("https://buy.stripe.com/dR6cMQeqJ9FdaaYeUU", "_blank")
            }
            className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-white font-bold bg-primary-dark rounded-md"
          >
            Start Free Trial
          </button> */}
          <ul className="text-sm text-left font-medium">
            <PricingItem
              title="To Get You Started"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="50 Proposals"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="100 Documents"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="Unlimited Users"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="Unlimited AI Drafts"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="Export Proposals To PDF"
              variant={isBasic ? "light" : "dark"}
            />
            <PricingItem
              title="Import Grants Via Copy/Paste"
              variant={isBasic ? "light" : "dark"}
            />
          </ul>
        </div>
        <div
          className={`p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] ${
            isPro ? "bg-primary-dark text-white" : "bg-white"
          } rounded-[14px] shadow-xl`}
        >
          <h3 className="text-2xl -sm:text-[22px] font-bold">Pro</h3>
          <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
            Most Popular
            {/* Best for organizations that manages 20-40 grants application per
            year */}
          </p>
          <div>
            <span className="text-6xl -sm:text-[52px] font-bold">$59</span>
            <span>/month</span>
          </div>
          {/* <button
            onClick={() =>
              window.open("https://buy.stripe.com/fZe4gkfuN7x5aaY9AB", "_blank")
            }
            className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-primary-dark font-bold bg-white rounded-md"
          >
            Start Free Trial
          </button> */}
          <ul className="text-sm text-left font-medium">
            <PricingItem
              title="Everything in the Basic Plan"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Unlimited Proposals"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Unlimited Documents"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Access To Prompt Snippets"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Export Proposals To DOCX"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Access To AI Assistant Chat"
              variant={isPro ? "light" : "dark"}
            />
            <PricingItem
              title="Import Grants Via URL"
              variant={isPro ? "light" : "dark"}
            />
          </ul>
        </div>
        <div
          className={`p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] ${
            isPremium ? "bg-primary-dark text-white" : "bg-white"
          } rounded-[14px] shadow-xl`}
        >
          <h3 className="text-2xl -sm:text-[22px] font-bold">Premium</h3>
          <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
            Advanced
            {/* Best for organizations that manages 50+ grants application per year */}
          </p>
          <div>
            <span className="text-6xl -sm:text-[52px] font-bold">$149</span>
            <span>/month</span>
          </div>
          {/* <button
            onClick={() =>
              window.open("https://buy.stripe.com/aEU7swgyR7x55UI146", "_blank")
            }
            className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-white font-bold bg-primary-dark rounded-md"
          >
            Start Free Trial
          </button> */}
          <ul className="text-sm text-left font-medium">
            <PricingItem
              title="Everything in the Pro Plan"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Pro Text Editor"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Unlimited AI Interactions"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Share Grants Via Link"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Share Proposal Via Link"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Import Grants Via Search"
              variant={isPremium ? "light" : "dark"}
            />
            <PricingItem
              title="Priority email support for quick assistance"
              variant={isPremium ? "light" : "dark"}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

const PricingItem = ({
  title,
  variant,
}: {
  title: string;
  variant: "light" | "dark";
}) => {
  return (
    <li className="h-max my-5 -sm:my-3.5 flex gap-2.5 -sm:gap-2">
      <img
        className="w-5 -sm:w-4 h-5 -sm:h-4 mt-1 -sm:mt-1.5"
        src={variant == "light" ? lightCheckIcon : darkCheckIcon}
        alt={title}
      />
      <span>{title}</span>
    </li>
  );
};

export default Pricing;
