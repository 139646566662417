import { IoCloseOutline, IoShareSharp } from "react-icons/io5";
import {
  BsFileEarmark,
  BsFileEarmarkText,
  BsPencilSquare,
  BsPlusCircleDotted,
} from "react-icons/bs";
import { FiBookOpen } from "react-icons/fi";
import { useContext, useRef } from "react";
import { ContextStore } from "@components/utils/Context";
import {
  AccountGrant,
  ActionType,
  LinkType,
  Grant,
  MenuAction,
  MenuActionItem,
  BoardItem,
  Entity,
} from "@utils/interface";
import { IoIosShareAlt } from "react-icons/io";
import {
  useCopyToClipboard,
  useDisplayToast,
  usePlanFeatureCheck,
  usePut,
} from "@utils/hooks";
import {
  PLANS,
  copyToClipboard,
  ellipsis,
  getBaseUrl,
  includesString,
  openInNewTab,
  setURLQuery,
  tailwindHelpers,
  toUTMUrl,
} from "@utils/index";
import Loader from "@components/utils/Loader";
import { useGetOne } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Overlay from "@components/Overlay";
import CloseBtn from "@components/CloseButton";
import { Switch } from "@chakra-ui/react";
import BadgeSection, { BadgeItem } from "@components/BadgeSection";

interface Props {
  children?: React.ReactNode;
}

const ViewGrant = (props: Props) => {
  const {
    account,
    appModal,
    setAppModal,
    notes,
    files,
    grants,
    boardItems,
    setBoardItems,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const navigate = useNavigate();
  const { needAccess } = usePlanFeatureCheck();
  const { copyToClipboardWithToast } = useCopyToClipboard();
  const [updateBoardItem] = usePut<BoardItem>();

  const isShared = includesString(["share"], window.location.pathname);

  const boardItem = boardItems?.find((b) => b?.grantId === appModal?.grantId);

  let grant = appModal?.item
    ? appModal?.item
    : (grants?.find((x) => x?.id === appModal?.grantId) as AccountGrant);

  grant = boardItem?.additionalData?.grant || grant;

  const relatedFile = files?.find((x) => x.id === grant?.fileId);
  // console.log("ViewGrant", grant?.id, grant?.grantId, notes[grant?.id]);

  const actions: MenuActionItem[] = [
    {
      label: "Edit Grant",
      iconBefore: <BsPencilSquare />,
      onSelect: () =>
        setAppModal((prev) => ({
          ...prev,
          views: { ...prev.views, [ActionType.edit]: true },
          type: LinkType.grant,
          grantId: grant?.id,
        })),
      // visible: grant?.contributorId === account?.id,
      visible: true,
    },
    {
      label: "View Document",
      iconBefore: <BsFileEarmarkText />,
      onSelect: () => alert("Document view"),
      // visible: !!grant?.documentId,
      visible: false,
    },
    {
      label: "View Project",
      iconBefore: <BsFileEarmark />,
      onSelect: () => alert("Project view"),
      // visible: !!grant?.projectId,
      visible: false,
    },
    // {
    //   label: "View Notes",
    //   iconBefore: <BsPlusCircleDotted />,
    //   onSelect: () => alert("View notes"),
    //   visible: !!notes[grant?.id]?.length,
    // },
    {
      label: "Generate Report",
      iconBefore: <FiBookOpen />,
      onSelect: () => alert("Report generated"),
      visible: !!grant?.reportId,
    },
    {
      label: "Share",
      iconBefore: <IoIosShareAlt />,
      onSelect: () => {
        // if (needAccess([PLANS.basic, PLANS.pro])) return;
        // successToast("Copy the URL link to share!");
        copyToClipboardWithToast(
          toUTMUrl(`${getBaseUrl()}/share/grant/${grant?.id}/`, {
            utm_source: "share",
            utm_medium: "link",
            utm_campaign: grant?.id,
            utm_content: "grant",
          })
        );
        // openInNewTab(`/share/grant/${grant?.id}`);
      },
      visible: grant?.visible,
    },
  ];

  const onClose = () => {
    if (!isShared) {
      setAppModal((prev) => ({
        ...prev,
        views: { ...prev.views, [ActionType.view]: false },
        item: undefined,
      }));
      setURLQuery({ grantId: null });
    }
  };

  const handleUpdate = () => {
    if (!boardItem) return;
    const deadlineReminders = !boardItem?.additionalData?.deadlineReminders;

    setBoardItems((prev) =>
      prev.map((item) =>
        item.id !== boardItem?.id
          ? item
          : {
              ...item,
              additionalData: { ...item.additionalData, deadlineReminders },
            }
      )
    );
    updateBoardItem(
      Entity.boardItems,
      {
        id: boardItem?.id,
        data: {
          additionalData: {
            ...boardItem?.additionalData,
            deadlineReminders,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          //boardItem already updated in state
          successToast("Grant Updated");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  /// get is from url path
  const { id } = useParams<{ id: string }>();

  if (isShared) {
    // console.log("ShareGrant-id", id);

    if (!id) {
      errorToast("No grant found");
      setTimeout(() => navigate("/"), 1000);
      return null;
    }

    // useGetOne with grant
    const { data, isLoading, error } = useGetOne<Grant>(
      `${Entity.grants}/share`,
      { id },
      {
        onSuccess(data) {
          if (account) successToast("Copy the URL link to share!");
        },
        onError(error) {
          console.log("ShareGrant-error", error);
          errorToast("No grant found");
        },
      }
    );

    // console.log("ShareGrant:", grant);

    if (isLoading) {
      return (
        <Overlay>
          <Loader size={"xl"} />
        </Overlay>
      );
    }
    if (error || !data) {
      setTimeout(() => navigate("/"), 1000);
      return null;
    }

    if (data) {
      grant = data as AccountGrant;
    }
  }

  return (
    <Overlay onDone={onClose} zIndex={"z-[2001]"}>
      <div
        className={`${tailwindHelpers?.responsiveModal} py-10 flex flex-col flex-1 rounded-lg mx-8`}
      >
        <div className="px-5 h-14 bg-[#B794F4] text-white flex flex-shrink-0 justify-between items-center rounded-t-lg">
          <p className={`text-2xl font-semibold -sm:text-sm -sm:text-wrap`}>
            {ellipsis(grant?.name || "View Grant", 60)}
          </p>
          {!isShared && <CloseBtn variant="outline" closeModal={onClose} />}
        </div>

        {!grant ? (
          <Loader size={"xl"} />
        ) : (
          <div className="px-5 pb-16 overflow-y-scroll custom-scrollbar text-sm font-bold bg-white relative overflow-hidden ">
            {!isShared &&
              actions?.filter((x) => x?.visible !== false).length > 0 && (
                <div className="-sm:flex-col flex justify-between">
                  <div className="pt-5 pb-3 flex gap-4 flex-wrap sticky top-0 bg-white">
                    {actions?.map((action, idx) => {
                      return action?.visible ? (
                        <button
                          key={idx}
                          onClick={() => action?.onSelect && action?.onSelect()}
                          className="py-2 px-4 flex-y-center hover:bg-[#B794F4] text-[#B794F4] hover:text-white border border-[#D6BCFA] rounded"
                        >
                          <span className="mr-3">{action.label}</span>
                          {action.iconBefore}
                        </button>
                      ) : null;
                    })}
                  </div>
                  <div className="">
                    <div className="flex -sm:justify-end">
                      <p className="text-sm font-semibold mt-5 text-[#6a54b1]">
                        Deadline Reminders
                      </p>
                      <Switch
                        id="deadlineReminders"
                        isChecked={
                          boardItem?.additionalData?.deadlineReminders !== false
                        }
                        colorScheme="purple"
                        className="mt-5 ml-2"
                        onChange={() => handleUpdate()}
                      />
                    </div>
                    <div className="pb-1 overflow-visible ">
                      <BadgeSection
                        // items={[{id: "1", name: "Tags"}]}
                        items={
                          [relatedFile].filter((x) => x?.id) as BadgeItem[]
                        }
                        handleClick={(item) => {
                          setAppModal((prev) => ({
                            ...prev,
                            type: LinkType.file,
                            views: { ...prev.views, [ActionType.view]: true },
                            File: files.find((x) => x.id === item.id),
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* <h1 className="text-2xl my-5">{grant?.name}</h1> */}

            <div className="my-3">
              Type:{" "}
              <span className="font-normal">
                {grant?.type || "Not for Profit"}
              </span>
            </div>
            <div className="my-3">
              Status: <span className="font-normal">{grant?.status}</span>
            </div>
            <div className="my-3">
              Amounts:{" "}
              <span className="font-normal">
                ${grant?.amount?.min || "0"} - ${grant?.amount?.max || "??"}
              </span>
            </div>
            <div className="my-3 flex-y-center">
              Deadlines:
              <span className="flex gap-2 flex-wrap text-xs font-normal">
                {grant?.deadlines?.map((date, idx) => {
                  return (
                    <span
                      key={idx}
                      className="px-2 py-1 rounded-full bg-[#FCE7F6]"
                    >
                      {date}
                    </span>
                  );
                })}
              </span>
            </div>
            <div className="my-3">
              Next Deadline:{" "}
              <span className="font-normal">
                {grant?.deadlines?.find(
                  (deadline) => new Date(deadline) > new Date()
                ) || "Unknown"}
              </span>
            </div>
            <div className="my-3 flex-y-center">
              Application Type:
              <span className="flex gap-2 flex-wrap text-xs font-normal">
                {grant?.applicationType?.map((type, idx) => {
                  return (
                    <span
                      key={idx}
                      className="px-2 py-1 rounded-full bg-[#D1FADF]"
                    >
                      {type}
                    </span>
                  );
                })}
              </span>
            </div>
            <div className="my-3">
              Website:{" "}
              <span className="font-normal">
                <a
                  href={grant?.websiteUrl}
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  {grant?.websiteUrl}
                </a>
              </span>
            </div>
            <div className="my-3 flex-y-center">
              <span className="pr-2">Field(s) of Work:</span>{" "}
              <span className="flex gap-2 flex-wrap text-xs font-normal">
                {grant?.fieldsOfWork?.map((field, idx) => {
                  return (
                    <span
                      key={idx}
                      className="px-2 py-1 rounded-full bg-[#FCE7F6]"
                    >
                      {field}
                    </span>
                  );
                })}
              </span>
            </div>
            <div className="my-3">
              Location: <span className="font-normal">{grant?.location}</span>
            </div>
            {/* <div className="my-3">
            Location of Residency:{" "}
            <span className="font-normal">{grant?.location}</span>
          </div> */}
            <div className="my-3 flex-y-center">
              Funding Uses:
              <span className="flex gap-2 flex-wrap text-xs font-normal">
                {grant?.fundingUses?.map((uses, idx) => {
                  return (
                    <span
                      key={idx}
                      className="px-2 py-1 line-clamp-1 rounded-full bg-[#FEF0C7]"
                    >
                      {uses}
                    </span>
                  );
                })}
              </span>
            </div>
            <div className="my-3">
              <span>Overview:</span>
              <p className="mt-2 px-4 font-normal">{grant?.overview}</p>
            </div>
            <div className="my-3">
              <span>Eligibility:</span>
              {/* <ul className="mt-2 px-4 font-normal">
              {grant?.eligibility.map((fact, idx) =>
                Array.isArray(fact) ? (
                  <ul key={idx} className="ml-4">
                    {fact.map((subFact) => (
                      <li>{subFact}</li>
                    ))}
                  </ul>
                ) : (
                  <li key={idx}>{fact}</li>
                )
              )}
            </ul> */}
              <p className="mt-2 px-4 font-normal">{grant?.eligibility}</p>
            </div>
            <div className="my-3">
              <span>Ineligibility:</span>
              {/* <ul className="mt-2 px-4 font-normal">
              {grant?.ineligibility.map((fact, idx) =>
                Array.isArray(fact) ? (
                  <ul key={idx} className="ml-4">
                    {fact.map((subFact) => (
                      <li >{subFact}</li>
                    ))}
                  </ul>
                ) : (
                  <li key={idx}>{fact}</li>
                )
              )}
            </ul> */}
              <p className="mt-2 px-4 font-normal">{grant?.ineligibility}</p>
            </div>
          </div>
        )}
      </div>
    </Overlay>
  );
};

export default ViewGrant;
