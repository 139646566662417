import {
  Avatar,
  Icon,
  Text,
  Button,
  Spacer,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  VStack,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import {
  useCommonQueries,
  useConfetti,
  useCopyToClipboard,
  useDisplayToast,
  useGet,
  usePost,
  usePut,
} from "@utils/hooks";
import {
  APP_URL,
  PLANS,
  PRODUCT_UPDATE_VIDEOS,
  // base64EncodedObject,
  calendlyLink,
  ellipsis,
  // expressLoginLink,
  isDev,
  isFreeAccount,
  isWhiteListAgency,
  notifyFeedback,
  openInNewTab,
  queryEmbeds,
} from "@utils/index";
import { useGetList, useLogout, useUpdate, useUpdateMany } from "ra-core";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { ContextStore } from "./utils/Context";
import {
  FiBell,
  FiExternalLink,
  FiInfo,
  FiLogOut,
  FiMenu,
  FiVideo,
} from "react-icons/fi";
import { MdFeedback } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { StatusRipple } from "./utils/Status";
import { SearchIcon } from "@chakra-ui/icons";
import { NotificationsModal } from "./modal/Notifications";
import {
  Activity,
  User,
  IResponse,
  Dict,
  ID,
  AffiliateLink,
  Affiliate,
  Entity,
  IDict,
} from "@utils/interface";
// import { BiX } from "react-icons/bi";
// import { useOnClickOutside } from "usehooks-ts";
// import { BsArrowReturnRight, BsFileEarmarkText } from "react-icons/bs";
import SearchBar from "./search";
import logo from "@assets/logo.svg";
import { Hamburger } from "./Sidebar";
import { isMobile } from "react-device-detect";
import ActionModal from "./modal/ActionModal";
// import { FaShareAlt } from "react-icons/fa";
import { TbHeartShare, TbMoneybag } from "react-icons/tb";
import Loader from "./utils/Loader";
import { pick, startCase } from "lodash";
import { FaReceipt, FaUserCircle, FaSlidersH } from "react-icons/fa";
import { FaSliders } from "react-icons/fa6";
import { PiCrownSimpleBold } from "react-icons/pi";
import Video from "./Video";
import Actions from "./Actions";
import Pricing from "./Pricing";
import partyBanner from "@assets/confetti-crown.jpg";
import {
  FaGlobe,
  FaPalette,
  FaDesktop,
  FaFile,
  FaFileWord,
} from "react-icons/fa";
import { BsFillKanbanFill } from "react-icons/bs";
import {
  MdEventAvailable,
  MdOutlineTaskAlt,
  MdOutlineStickyNote2,
} from "react-icons/md";
import { AiTwotoneSnippets } from "react-icons/ai";
import { FiActivity } from "react-icons/fi";

interface NavTopSectionProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const NavTopSection = (props: NavTopSectionProps) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const { onOpen, onClose, isOpen } = props;

  const [searchText, setSearchText] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isNotifications, setIsNotifications] = useState(false);
  const [feedback, setFeedback] = useState("");
  //pull notifications from db
  const {
    user,
    setUser,
    account,
    activity,
    setActivity,
    actionModal,
    setActionModal,
    setGlobalState,
    globalState,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const { copyToClipboardWithToast } = useCopyToClipboard();
  const { showConfetti } = useConfetti();

  const { fetchAccountActivity, fetchUserActivity } = useCommonQueries();

  const hasChecked = useRef<boolean>(false);
  const activities = Object.values(activity).flat();
  const isAffiliate = !!user?.affiliate?.stripeAccountId;
  const isAccountComplete = user?.affiliate?.additionalData?.isAccountComplete;

  // update activity
  // TODO show loader when notification being updated
  const [updateActivity, { isLoading: isUpdating }] = usePut<Activity>();
  const [updateManyActivities, { isLoading: isUpdatingAll }] = useUpdateMany<
    any,
    IResponse
  >();
  const [createOne, { isLoading: isRegistering }] = usePost<Dict>();

  const handleSubmitFeedback = () => {
    if (feedback) {
      notifyFeedback({
        info: `Feedback from ${user?.firstName || user?.email} ${user?.lastName || ""
          } (${user?.email})`,
        feedback,
      });
      successToast("Feedback submitted");
      setFeedback("");
      setActionModal({ ...actionModal, submitFeedback: false });
    }
  };

  const handleCloseNotifications = () => {
    setIsNotifications(!isNotifications);
    fetchUserActivity.refetch();
  };

  const handleMarkAsRead = (id: string) => {
    updateActivity(
      Entity.activity,
      { id, data: { read: true } },
      {
        onSuccess: (data) => {
          // successToast("Notification marked as read");
          fetchUserActivity.refetch();
        },
        onError: (error) => {
          errorToast(error?.body?.message || "Error marking activity as read");
        },
      }
    );
  };

  const handleMarkAllAsRead = () => {
    updateManyActivities(
      Entity.activity,
      {
        ids: activities?.map((x) => x?.id),
        data: { read: true },
      },
      {
        onSuccess(data, variables, context) {
          // successToast("All notifications marked as read");
          successToast("All notifications marked as read");
          fetchUserActivity.refetch();
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message || "Error marking all as read");
        },
      }
    );
  };

  const getAccountStats = useGet<IDict>(
    `${Entity.accounts}/stats`,
    {
      id: user?.accountId,
    },
    (data) => {
      // console.log("onSuccess", data);
      setGlobalState({ ...globalState, accountStats: data?.counts });
    }
  );

  const getSignupLink = useGet<IDict>(
    `${Entity.affiliate}/signup/link`,
    {
      id: user?.affiliate?.id,
    },
    (data) => {
      // console.log("onSuccess", data);
      setGlobalState({ ...globalState, affiliateSignupLink: data?.url });
    }
  );

  const getLoginLink = useGet<IDict>(
    `${Entity.affiliate}/login`,
    {
      id: user?.affiliate?.id,
    },
    (data) => {
      // console.log("onSuccess", data);
      setGlobalState({ ...globalState, affiliateLoginLink: data?.url });
    }
  );

  const checkAccountComplete = useGet<Affiliate>(
    `${Entity.affiliate}/check`,
    {
      id: user?.affiliate?.id,
    },
    (data) => {
      // console.log("onSuccess", data);
      setUser({ ...user, affiliate: data });
    }
  );

  const handleCreateAffiliate = () => {
    createOne(
      `${Entity.affiliate}/signup`,
      {
        data: {
          email: user?.email,
          first_name: user?.firstName,
          last_name: user?.lastName,
          userId: user?.id,
        },
      },
      {
        onSuccess: (data) => {
          // console.log("onSuccess", data);
          openInNewTab(data?.url);
        },
        onError: (error) => {
          // console.log("onError", error);
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleBecomeAffiliate = () => {
    showConfetti();
    setActionModal({ ...actionModal, becomeAffiliate: true });
  };

  const handleGetAffiliateLink = () => {
    setActionModal({ ...actionModal, showAffiliateLink: true });
    if (isAffiliate && !isAccountComplete) {
      checkAccountComplete.refetch();
    }
    if (!isAccountComplete) {
      return getSignupLink.refetch();
    } else {
      return getLoginLink.refetch();
    }
  };

  // const handleCopyAffiliateLink = (link: AffiliateLink) => {
  //   // const referralData = {
  //   //   referrer: {
  //   //     ...pick(user, ["id"]),
  //   //   },
  //   //   createdAt: new Date().toISOString(),
  //   // };
  //   // let base64Encoded = base64EncodedObject(referralData);
  //   // // https://docs.stripe.com/payment-links/url-parameters#streamline-reconciliation-with-a-url-parameter
  //   // if (base64Encoded?.length > 200) {
  //   //   console.log("base64Encoded length exceeded 200: ", base64Encoded?.length);
  //   //   base64Encoded = base64EncodedObject(
  //   //     pick(referralData, ["referrer", "createdAt"])
  //   //   );
  //   // }
  //   copyToClipboardWithToast(
  //     // `${link?.paymentLinkUrl}?client_reference_id=${base64Encoded}`
  //     link?.paymentLinkUrl
  //   );
  // };

  // const handleSidebar = () => {
  //   isOpen ? onClose() : onOpen();
  // };

  const infoActions = {
    top: HelpResources?.map((resource) => ({
      label: resource?.label,
      iconBefore: <resource.icon />,
      onSelect: () => openInNewTab(resource?.link),
      visible: true,
    })),
    bottom: [],
  };

  useEffect(() => {
    if (!activity?.user.length) {
      fetchUserActivity.refetch();
    }
    if (!activity?.account.length) {
      fetchAccountActivity?.refetch();
    }
  }, []);

  const isLoading =
    getSignupLink.isFetching ||
    checkAccountComplete.isFetching ||
    getLoginLink.isFetching;

  return (
    <>
      {actionModal?.submitFeedback && (
        <ActionModal
          overlay
          icon={<VscFeedback />}
          handleConfirm={() => {
            handleSubmitFeedback();
            setActionModal({ ...actionModal, submitFeedback: false });
          }}
          handleClose={() => {
            setFeedback("");
            setActionModal({ ...actionModal, submitFeedback: false });
          }}
        >
          <div className="mb-5 flex flex-col justify-center w-full">
            <h3 className="text-center">
              Hey, <span className="text-[#6a54b1]">{user?.firstName}</span>!
            </h3>
            <h3 className="text-center">
              {" "}
              Need to jump on a call w/ a product expert?
            </h3>
            <p className="text-sm text-center text-gray-500">
              We're happy to help you with any product questions you have.
            </p>
            <button
              // className="mt-4 w-full bg-[#e9d9fc] rounded-lg py-2 text-[#6a54b1] font-semibold"
              className="text-[#6a54b1]"
              onClick={() => openInNewTab(calendlyLink)}
            >
              Schedule a call instead
            </button>
          </div>

          {/*  tailwind divider */}
          <div className="mb-5 w-full rounded-lg bg-gray-200 border-2 dark:bg-gray-700" />

          <textarea
            className="w-full h-20 p-2 border border-neutral-300 outline-none resize-none"
            placeholder="What problem are you trying to solve? Enter your feedback here..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.shiftKey) {
                e.preventDefault();
                handleSubmitFeedback();
              } else if (e.key === "Enter") {
                e.preventDefault();
                setFeedback((prev) => prev + "\n");
              }
            }}
          />
        </ActionModal>
      )}
      {actionModal?.becomeAffiliate && (
        <ActionModal
          card
          overlay
          handleConfirm={() => {
            handleCreateAffiliate();
          }}
          onDone={() => {
            setActionModal({ ...actionModal, becomeAffiliate: false });
          }}
          disableConfirm={!!globalState?.affiliateSignupLink}
          isLoading={isRegistering}
          confirmText="Create Affiliate Account"
        >
          <Video
            title="Affiliate Account Setup"
            src={
              "https://www.youtube.com/embed/e26tszC-Qt0/?listType=playlist&rel=0&loop=1"
            }
            text={
              "Watch the video to see how easy it is to setup your affiliate account!"
            }
          />
        </ActionModal>
      )}
      {actionModal?.showAffiliateLink && (
        <ActionModal
          overlay
          disableConfirm
          icon={<TbHeartShare />}
          handleClose={() => {
            setActionModal({ ...actionModal, showAffiliateLink: false });
          }}
        >
          <div className="flex flex-col justify-center w-full">
            {isLoading ? (
              <Loader size={"xl"} />
            ) : !isAccountComplete ? (
              <>
                <h3 className="text-xl font-semibold">
                  Complete Your Affiliate Account
                </h3>
                <p className="text-sm text-gray-500">
                  To become an affiliate, you must complete your account
                </p>
                <button
                  className="mt-4 w-full bg-[#e9d9fc] rounded-lg py-2 text-[#6a54b1] font-semibold"
                  onClick={() => openInNewTab(globalState?.affiliateSignupLink)}
                >
                  Complete Account
                </button>
              </>
            ) : (
              <>
                <h3 className="text-xl font-semibold">Affiliate Link</h3>
                <p className="text-sm text-gray-500">
                  Share this link with others to earn each month they are a
                  member.
                </p>
                <button
                  onClick={() => {
                    const link =
                      APP_URL + `share/referral/${user?.affiliate?.id}`;
                    copyToClipboardWithToast(link);
                  }}
                  className="mt-4 w-full bg-[#e9d9fc] rounded-lg py-2 text-[#6a54b1] font-semibold"
                >
                  Copy Referral Link
                </button>
                <button
                  onClick={() => {
                    openInNewTab(globalState?.affiliateLoginLink);
                  }}
                  className="mt-4 w-full bg-[#e9d9fc] rounded-lg py-2 text-[#6a54b1] font-semibold"
                >
                  View Earnings 💰
                </button>
              </>
            )}
          </div>
        </ActionModal>
      )}
      {isSearchMode && <SearchBar setIsSearchMode={setIsSearchMode} />}
      {isNotifications && (
        <NotificationsModal
          isLoading={fetchUserActivity.isFetching || isUpdatingAll}
          onClose={handleCloseNotifications}
          handleMarkAllAsRead={handleMarkAllAsRead}
          handleMarkAsRead={handleMarkAsRead}
          activities={activities}
        />
      )}
      {actionModal?.openBilling && (
        <ActionModal
          card
          overlay
          showClose
          onDone={() => {
            setActionModal({ ...actionModal, openBilling: false });
          }}
        >
          <div className={`mx-auto mt-[1rem] overflow-auto`}>
            {account?.planName ? (
              <Pricing activePlan={account.planName} />
            ) : null}
            <div className="flex flex-col justify-center">
              <button
                className="mt-4 w-1/2 -sm:w-[90%] mx-auto bg-primary-dark rounded-lg py-2 text-white font-semibold"
                onClick={() => {
                  openInNewTab(process.env?.STRIPE_CUSTOMER_PORTAL);
                  setActionModal({ ...actionModal, openBilling: false });
                }}
              >
                Change Plan / Card
              </button>
              <button
                className="mt-4 w-1/2 -sm:w-[90%] mx-auto bg-red-300 hover:bg-red-500 rounded-lg py-2 text-white font-semibold"
                onClick={() => {
                  getAccountStats.refetch();
                  setActionModal({
                    ...actionModal,
                    openBilling: false,
                    deleteAccount: true,
                  });
                }}
              >
                Delete Account
              </button>
            </div>
          </div>
        </ActionModal>
      )}
      {actionModal?.deleteAccount && (
        <ActionModal
          card
          overlay
          showClose
          onDone={() => {
            setActionModal({ ...actionModal, deleteAccount: false });
          }}
        >
          <div
            className={`mx-auto justify-between flex flex-row -sm:flex-wrap`}
          >
            <div className="flex flex-col justify-center w-full">
              {/* <div className="flex flex-col justify-center w-1/2 overflow-auto"> */}
              <h3 className="text-xl font-semibold text-center">
                Are you sure you want to cancel your account?
              </h3>
              {/* <p className="text-sm text-gray-500 text-center">
                This action is irreversible and will delete all your data.
              </p> */}

              <div className="bg-white rounded-lg p-4 -sm:w-full mx-auto">
                <p className="text-sm text-gray-500 text-center mb-5">
                  <b>{user?.firstName}</b>, you{" "}
                  {account?.users?.length > 1 ? "and your team" : ""} will loose
                  these features you put to good use!
                </p>
                <ul>
                  {isDev || globalState?.accountStats?.documentCount ? (
                    <li className="flex items-center justify-between p-2 rounded-lg bg-blue-100">
                      <div className="flex items-center">
                        <FaFileWord className="text-blue-500 mr-2" />
                        <span className="font-semibold">Created Documents</span>
                      </div>
                      <span className="bg-blue-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.documentCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.boardItemCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-teal-100">
                      <div className="flex items-center">
                        <BsFillKanbanFill className="text-teal-500 mr-2" />
                        <span className="font-semibold">Grant Board Items</span>
                      </div>
                      <span className="bg-teal-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.boardItemCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.fileCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-red-100">
                      <div className="flex items-center">
                        <FaFile className="text-red-500 mr-2" />
                        <span className="font-semibold">Uploaded Files</span>
                      </div>
                      <span className="bg-red-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.fileCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.chatCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-yellow-100">
                      <div className="flex items-center">
                        <FaGlobe className="text-yellow-500 mr-2" />
                        <span className="font-semibold">Grant Searches</span>
                      </div>
                      <span className="bg-yellow-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.chatCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.eventCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-orange-100">
                      <div className="flex items-center">
                        <MdEventAvailable className="text-orange-500 mr-2" />
                        <span className="font-semibold">Events/Reminders</span>
                      </div>
                      <span className="bg-orange-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.eventCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.promptCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-purple-100">
                      <div className="flex items-center">
                        <AiTwotoneSnippets className="text-purple-500 mr-2" />
                        <span className="font-semibold">Prompt Snippets</span>
                      </div>
                      <span className="bg-purple-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.promptCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.promptCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-lime-100">
                      <div className="flex items-center">
                        <MdOutlineStickyNote2 className="text-lime-500 mr-2" />
                        <span className="font-semibold">Notes</span>
                      </div>
                      <span className="bg-lime-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.noteCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.taskCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-slate-100">
                      <div className="flex items-center">
                        <MdOutlineTaskAlt className="text-slate-500 mr-2" />
                        <span className="font-semibold">Tasks</span>
                      </div>
                      <span className="bg-slate-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.taskCount}
                      </span>
                    </li>
                  ) : null}
                  {isDev || globalState?.accountStats?.activityCount ? (
                    <li className="flex items-center justify-between p-2 mt-2 rounded-lg bg-blue-100">
                      <div className="flex items-center">
                        <FiActivity className="text-blue-500 mr-2" />
                        <span className="font-semibold">Activities</span>
                      </div>
                      <span className="bg-blue-500 text-white py-1 px-3 rounded-full text-sm">
                        {globalState?.accountStats?.activityCount}
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
              <button
                className="mt-4 w-1/2 -sm:w-full mx-auto bg-primary-dark rounded-lg py-2 text-white font-semibold"
                onClick={() =>
                  setActionModal({ ...actionModal, deleteAccount: false })
                }
              >
                🌟 Keep Account
              </button>
              <button
                className="mt-4 w-1/2 -sm:w-full mx-auto bg-red-300 hover:bg-red-500 rounded-lg py-2 text-white font-semibold"
                onClick={() =>
                  openInNewTab(process.env?.STRIPE_CUSTOMER_PORTAL)
                }
              >
                Continue cancellation
              </button>
            </div>
            {/* <div className="sm:w-1/2 -sm:w-full">
              <img src={partyBanner} alt="party banner" />
            </div> */}
          </div>
        </ActionModal>
      )}

      <header
        className={` sticky w-full h-14 ${isMobile ? "" : "pl-[70px]"
          } pr-4 z-[100] top-0 flex bg-inherit item-center justify-between md:justify-end border-b`}
      >
        {/* <div className="w-[69px] mr-2 z-[1000000] flex-shrink-0">
          <button
            className="mt-3 mx-auto w-8 h-8 flex justify-center items-center rounded bg-slate-200"
            onClick={() => handleSidebar()}
          >
            <FiMenu />
          </button>
        </div>*/}

        {isMobile && !isOpen && (
          <Hamburger
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            logo={logo}
            planName={
              globalState?.isAgency && !isWhiteListAgency(user?.accountId)
                ? globalState?.agency?.planName
                : account?.planName
            }
          />
        )}

        <div className="flex items-center w-full">
          <div className=" w-full lg:pr-[8rem] pl-[12rem] md:block hidden">
            <div
              className=" relative"
              onClick={() => setIsSearchMode(true)}
            >
              <div className="absolute top-2 left-2 text-neutral-400">
                <SearchIcon />
              </div>
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={`Type to search${account?.name
                  ? ' "' + ellipsis(account?.name, 40) + '" org'
                  : "..."
                  }`}
                type="text"
                className="w-full bg-gray-100 rounded-lg border border-neutral-300 outline-none pl-8 p-2"
              />
            </div>
          </div>

          <Spacer />

          <button
            className="md:hidden mb-[6px] mr-5 text-gray-500"
            onClick={() => setIsSearchMode(true)}
          >
            <SearchIcon />
          </button>

          <Tooltip label={"Product updates"}>
            <div className="mr-5">
              <Actions actions={infoActions} icon={
                <>
                  <FiInfo />
                  {/* <p className="ml-2 text-sm">Updates</p> */}
                </>
              } />
            </div>
          </Tooltip>
          <Tooltip
            label={"Have product feedback for us? We'd love to hear from you!"}
          >
            <div
              className="cursor-pointer mr-5"
              onClick={() => {
                setActionModal({ ...actionModal, submitFeedback: true });
              }}
            >
              <Icon color="gray.500" as={MdFeedback} />
            </div>
          </Tooltip>

          <Tooltip label={"Notifications"}>
            <div
              className="cursor-pointer mr-5"
              onClick={handleCloseNotifications}
            >
              <Icon color="gray.500" as={FiBell} />
              <StatusRipple color="green" isOff={activities?.length === 0} />
            </div>
          </Tooltip>

          <Menu isLazy>
            <MenuButton as={Button} size="sm" px={0} py={0} rounded="full">
              {isRegistering ? (
                <Loader size={"ms"} />
              ) : (
                <Avatar size="sm" src={user?.imageUrl} />
              )}
            </MenuButton>
            <MenuList zIndex={5}>
              <MenuItem onClick={() => navigate(`/user?id=${user?.id}`)}>
                <VStack justify="start" alignItems="left">
                  <Text fontWeight="500">
                    {user?.firstName} {ellipsis(user?.lastName)}
                  </Text>
                  <Text size="sm" color="gray.500" mt="0 !important">
                    {ellipsis(user?.email)}
                  </Text>
                </VStack>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => navigate(`/user?id=${user?.id}`)}>
                <FaUserCircle />
                <Text className="ml-2" fontWeight="500">
                  Profile
                </Text>
              </MenuItem>
              <MenuDivider />
              {(globalState?.isAgency ||
                account?.primaryContactId === user?.id) && (
                  <>
                    <MenuItem
                      onClick={() => navigate(`/preferences?id=${account?.id}`)}
                    >
                      <FaSliders />
                      <Text className="ml-2" fontWeight="500">
                        Preferences
                      </Text>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
              <MenuItem onClick={() => openInNewTab(calendlyLink)}>
                <FiInfo />
                <Text className="ml-2" fontWeight="500">
                  Help Center
                </Text>
              </MenuItem>
              {user?.customerId && account?.planName !== PLANS.FREE
                && (
                  <>
                    <MenuDivider />
                    <MenuItem
                      onClick={() =>
                        setActionModal({ ...actionModal, openBilling: true })
                      }
                    >
                      <PiCrownSimpleBold />
                      <Text className="ml-2" fontWeight="500">
                        Plans & Billing
                      </Text>
                    </MenuItem>
                  </>
                )}
              {
                // isDev &&
                <>
                  <MenuDivider />
                  <MenuItem
                    onClick={() =>
                      !isAffiliate
                        ? handleBecomeAffiliate()
                        : handleGetAffiliateLink()
                    }
                  >
                    <TbMoneybag />
                    <Text className="ml-2" fontWeight="500">
                      {isAffiliate ? "Affiliate Link" : "Become An Affiliate"}
                    </Text>
                  </MenuItem>
                </>
              }
              <MenuDivider />
              <MenuItem>
                <FiLogOut />
                <Text className="ml-2" fontWeight="500" onClick={logout}>
                  Sign Out
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default NavTopSection;

const HelpResources = [
  ...Object.entries(PRODUCT_UPDATE_VIDEOS).map(([key, value]) => ({
    label: value?.title,
    link: value?.link,
    icon: FiVideo,
  })),
  {
    label: "Affiliate Program",
    link: "https://blog.grantwriteai.com/affiliate",
    icon: FiExternalLink,
    // link: "https://www.youtube.com/embed/e26tszC-Qt0/?listType=playlist&rel=0&loop=1",
    // icon: FiVideo,
  },
];
