import { Text } from "@chakra-ui/react";
import { ContextStore } from "@components/utils/Context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const { flags } = useContext(ContextStore);

  if (!flags?.v2?.isEnabled || !flags?.v3?.isEnabled) {
    navigate("/");
  }

  return (
    <div>
      <Text fontSize={"2xl"}>Home</Text>
    </div>
  );
};

export default Home;
