import { SearchIcon } from "@chakra-ui/icons";
import { Dict, IDict } from "@utils/interface";
import { useContext, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
// import { ContextStore } from "./utils/Context";

interface Props {
  items: Partial<IDict>[];
  position?: string;
  onDone: () => void;
  onClick?: (item: Partial<IDict>) => void;
  icon?: React.ReactNode;
  renderItem?: (item: Partial<IDict>) => React.ReactNode;
}

const ModalSearch = (props: Props) => {
  const { items, onDone, position, onClick, icon, renderItem } = props;
  const [results, setResults] = useState(items || []);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  });

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => onDone && onDone());

  return (
    <div
      ref={ref}
      className={`fixed ${position || ""
        } bg-white border w-[280px] h-[280px] z-[10001] rounded-lg overflow-y-auto custom-scrollbar`}
    >
      <div className="h-11 sticky top-0">
        <div className="w-full h-full text-slate-600 relative">
          <input
            type="text"
            autoFocus
            ref={inputRef}
            placeholder="Search"
            className="pl-3 pr-9 w-full h-full text-sm bg-[#FCFAFF] outline-none"
            onChange={(e) =>
              setResults(
                items?.filter((item) =>
                  (item?.name || item?.title || item?.label)
                    ?.toLowerCase()
                    ?.includes(e.target.value.toLowerCase())
                  || (item?.content)
                    ?.toLowerCase()
                    ?.includes(e.target.value.toLowerCase())
                )
              )
            }
          />
          {icon ? (
            <div className="absolute top-2 right-2 hover:cursor-pointer">
              {icon}
            </div>
          ) : (
            <SearchIcon
              className="absolute top-4 right-2 hover:cursor-pointer"
              onClick={() => inputRef?.current?.focus()}
            />
          )}
        </div>
      </div>
      <div className="text-[#1D2939] font-bold text-xs">
        {results?.map((item, idx) => {
          return (
            <div
              key={idx}
              className="px-4 py-3 hover:bg-[#e9d9fc] hover:cursor-pointer"
              onClick={(e) => {
                // console.log("modal", e);
                e.preventDefault();
                onClick && onClick(item);
                onDone();
              }}
            >
              {renderItem ? (
                renderItem(item)
              ) : (
                <p className="line-clamp-1">{item?.name || item?.title || item?.label}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModalSearch;
