import bubbleText2 from "@assets/onboarding/speech-bubble-text.png";
import { StepProps, totalCards } from "./interface";
import triangleIcon from "./triangle";

const Notifications = ({ setCurrentStep, currentStep }: StepProps) => {
  return (
    <div className="bg-black bg-opacity-40 w-screen h-screen fixed top-0 left-0 z-[1100]">
      <div className=" absolute right-8 top-10">
        <div className="ml-[304px] -xs:ml-[244px] w-[32px] h-[23px] text-[#FECDCA]">
          {triangleIcon}
        </div>

        <div className=" w-[360px] -xs:w-[300px] rounded-lg overflow-hidden">
          <div className="h-40 pt-7 flex-x-center bg-[#FECDCA]">
            <img className="w-64 h-28" src={bubbleText2} alt="bubble-text" />
          </div>
          <div className=" px-5 pt-5 pb-8 bg-white text-[#6B46C1]">
            <p className="text-lg font-bold">
              All your notifications will appear here
            </p>
            <p className="text-sm">
              Your notifications will be conveniently displayed right here,
              ensuring you stay informed.
            </p>
            <div className=" mt-8 flex-y-center justify-between">
              <span className="">
                {currentStep - 2}/{totalCards}
              </span>
              <button
                className=" h-9 w-24 py-1 text-[#553C9A] hover:text-white bg-[#E9D8FD] hover:bg-[#553C9A] transition-colors duration-100 rounded-lg"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
