import { useTheme } from "react-admin";
import { Theme } from "@material-ui/core";

export const useThemeColor = () => {
  const [theme] = useTheme("light");
  // console.log("theme", theme);
  return (theme as any)?.palette?.mode === "dark" ? "black" : "white";
  // return theme === "dark" ? "black" : "white";
};

export const useOppositeThemeColor = () => {
  return useThemeColor() === "black" ? "white" : "black";
};
