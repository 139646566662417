// https://github.com/ZanyuanYang/Text-Editor-Tiptap/blob/master/src/contexts/tiptap_context.tsx
// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/extensions/index.tsx
import Highlight from "@tiptap/extension-highlight";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import TextAlign from "@tiptap/extension-text-align";
import Text from "@tiptap/extension-text";
import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
// import StarterKit from "@tiptap/starter-kit";
import { useEditor } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import { PluginKey } from "@tiptap/pm/state";
import CustomCommand from "./extensions/custom";
import PromptCommand from "./extensions/promptbox";
import InputCommand from "./extensions/inputbox";
// import CustomInputBox from "./extensions/inputbox";
// import History from "./extensions/history";
// import QuestionInputExtension from "./extensions/QuestionInputExtension";
import UniqueID from "@tiptap/extension-unique-id"; // Import the UniqueID extension
// https://tiptap.dev/docs/editor/api/extensions/character-count
import CharacterCount from "@tiptap/extension-character-count";
// https://tiptap.dev/docs/editor/api/extensions/list-keymap
import ListKeymap from "@tiptap/extension-list-keymap";

export const baseExtensions = [
  UniqueID.configure({
    types: ["heading", "paragraph"],
  }),
  CharacterCount.configure({
    mode: "nodeSize", // This configures the extension to count nodes, effectively counting words
  }),
];


export default [
  ...baseExtensions,
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === "heading") {
        return `Heading ${node.attrs.level}`;
      }
      return "Press '/' for help, '??' for commands, or Start Writing then Press '++' for AI autocomplete!";
    },
    includeChildren: true,
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableCell,
  TableHeader,
  // CustomCommand,
  // InputCommand,
  PromptCommand,
  // QuestionInputExtension,
  ListKeymap.configure({
    // https://github.com/ueberdosis/tiptap/blob/main/docs/api/extensions/list-keymap.md
    // https://github.com/ueberdosis/tiptap/issues/3128#issuecomment-1945081050
    listTypes: [
      {
        itemName: "listItem",
        wrapperNames: ["bulletList", "orderedList"],
      },
      {
        itemName: "taskItem",
        wrapperNames: ["taskList"],
      },
    ],
  }),
];

// Gapcursor,
// Highlight,
// Document,
// Paragraph,
// Text,
