import { createRef, useContext, useEffect, useRef, useState } from "react";
// import SectionPreview from "@components/document/SectionPreview";
// import TableOfContents from "@components/document/TableOfContents";
import { Badge, Divider, Tooltip } from "@chakra-ui/react";
// import PromptPanel from "@components/document/PromptPanel";
// import AppButton from "@components/AppButton";
import { FiAlertTriangle, FiArrowLeft, FiPlus } from "react-icons/fi";
import {
  // Preview,
  // PromptAttachment,
  // Grant,
  // BoardItem,
  Document,
  MenuAction,
  CreatePromptPayload,
  // Project,
  Dict,
  LinkTypes,
  LinkType,
  // TDict,
  ExportTypes,
  ExportType,
  ActionType,
  ID,
  DocumentStatus,
  Entity,
  // Report,
} from "@utils/interface";
import DraftActions from "@components/document/DraftActions";
import {
  IResponse,
  PLANS,
  getBaseUrl,
  handleRenderResponse,
  handleRenderPrompt,
  isNullOrEmpty,
  openInNewTab,
  setURLQuery,
  isDev,
  isPlanIncluded,
  toUTMUrl,
  ellipsis,
} from "@utils/index";
import {
  useStoreEntity,
  useConfetti,
  useCopyToClipboard,
  useDel,
  useDisplayToast,
  usePlanFeatureCheck,
  usePost,
  usePut,
  useURLParams,
  useCommonQueries,
} from "@utils/hooks";
import { ContextStore } from "@components/utils/Context";
import { orderBy, pick, startCase } from "lodash";
// import {
//   slateToPlainString,
//   stringToSlate,
//   // stringToSlate,
//   // createSlateNode,
// } from "@playground/editors/slate";
import {
  exportToDocx,
  exportToPdf,
  getExportPayload,
} from "@playground/editors/slate/exports";
import Actions from "@components/Actions";
import { PiExportFill } from "react-icons/pi";
import { RiFilePaperFill } from "react-icons/ri";
import BadgeSection, { BadgeItem } from "@components/BadgeSection";
import Loader from "@components/utils/Loader";
// import { Node, Text } from "slate";
import { IoCheckmark, IoDocumentTextOutline } from "react-icons/io5";
import { IoIosShareAlt } from "react-icons/io";
// import { isMobile } from "react-device-detect";
// import ActionModal from "@components/ActionModal";
// import { debug } from "@utils/logging";
import ModalSearch from "@/components/modal/ModalSearch";
import { BiUndo } from "react-icons/bi";
import EditDoc from "./EditDoc";
import { useParams, useNavigate } from "react-router-dom";
import VideoUpdate from "@components/VideoUpdate";
import { TipTapEditor } from "@/playground/editors/interface";
import { useAlwaysScrollToBottom } from "@/utils/helpers";

const DraftDocument = () => {
  // const { queryParams } = useURLParams();
  // const { documentId } = queryParams;
  const navigate = useNavigate();
  const { id: documentId } = useParams();

  const {
    // user,
    // account,
    grants,
    // projects,
    documents,
    // boardItems,
    hasChanges,
    // team,
    actionModal,
    flags,
    setActionModal,
    setAppModal,
    setHasChanges,
    setGlobalState,
  } = useContext(ContextStore);
  // const [showPanel, setShowPanel] = useState(sections?.length == 0);
  // const { isOpen, onToggle } = useDisclosure();
  const { successToast, errorToast } = useDisplayToast();
  const { needAccess } = usePlanFeatureCheck();
  const { copyToClipboardWithToast } = useCopyToClipboard();
  const { showConfetti } = useConfetti();
  const { fetchDocuments } = useCommonQueries();

  const [document, setCurrentDocument] = useState<Document>(
    documents.find((x) => x.id === documentId)!
  );

  // debug("DraftView", proposal);
  // TODO: handle link projects, reports, etc
  const relatedGrant = grants?.find((x) => x.id === document?.grantId);
  const [linkedItems, setLinkedItems] = useState<
    (Dict & ID & { type: LinkType })[]
  >(relatedGrant ? [{ ...relatedGrant, type: LinkType.grant }] : []);

  const isNovelEdit = true;
  // isDev || isPlanIncluded(account, [PLANS.premium]);

  const editorRef = useRef<TipTapEditor>();
  const getEditorContent = () => {
    return editorRef.current?.getHTML() || "";
  };

  useEffect(() => {
    return () => {
      // clean up ref
      editorRef.current = undefined;
    };
  }, []);

  const handleEditTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    handleChanges(() => setCurrentDocument({ ...document, [id]: value }));
  };

  const handleChanges = (cb: () => void) => {
    cb();
    if (!hasChanges?.draftUpdates) {
      setHasChanges((prev) => ({ ...prev, draftUpdates: true }));
    }
  };

  // update document
  const [updateDocument, { isLoading: isUpdating }] = usePut<Document>();

  const handleLinkItem = (item: Dict & ID, type: LinkTypes) => {
    const linkKey = `${type}Id`;
    const isLinked = !!(document[type] || document[linkKey]);

    updateDocument(
      Entity.documents,
      {
        id: document?.id,
        data: {
          ...(isLinked
            ? { [type]: { disconnect: true } } //unlink
            : { [type]: { connect: { id: item?.id } } }), //link
        },
      },
      {
        onSuccess(data, variables, context) {
          setCurrentDocument((prev) => ({ ...prev, ...data }));
          setGlobalState((prev) => ({ ...prev, currentDocument: data })); //update novel editor's current document
          if (!isLinked) {
            successToast(`${startCase(type)} Linked`);
            setLinkedItems((prev) => [
              ...prev,
              {
                ...item,
                type: type as LinkType,
              },
              // getStoreEntity(type).find
            ]);
          } else {
            successToast(`${startCase(type)} Unlinked`);
            // disconnect
            setLinkedItems((prev) => prev?.filter((x) => x?.id !== item?.id));
          }
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

 
  // const handleExport = async (type: ExportTypes = ExportType.PDF) => {
  //   setIsExporting(true);
  //   switch (type) {
  //     case "PDF":
  //       await new Promise((resolve, reject) =>
  //         exportToPdf(getEditorContent(), document?.title, (result, error) => {
  //           if (result) {
  //             successToast("Exported to PDF...");
  //             resolve(null);
  //           } else {
  //             reject(error);
  //           }
  //         })
  //       ).catch((err) => {
  //         errorToast((err as Error)?.message, "Export Error", 10000);
  //       });
  //       break;
  //     case "DOCX":
  //       if (
  //         needAccess(
  //           [PLANS.free, PLANS.basic],
  //           true,
  //           "Please upgrade your account to export to DOCX",
  //           null,
  //           () => setIsExporting(false)
  //         )
  //       )
  //         return;
  //       await new Promise((resolve, reject) =>
  //         exportToDocx(getEditorContent(), document?.title, (result) => {
  //           if (result) {
  //             successToast(
  //               "Exported to DOCX...Open file with Google Docs to view/edit."
  //             );
  //             resolve(null);
  //           }
  //         })
  //       ).catch((err) => {
  //         errorToast((err as Error)?.message, "Export Error", 10000);
  //       });
  //       break;
  //   }
  //   setIsExporting(false);
  // };

  const actions: MenuAction = {
    top: [
      {
        label: "Link Grant",
        iconBefore: <RiFilePaperFill />,
        onSelect: () =>
          setActionModal({
            ...actionModal,
            modalSearch: true,
            modalSearchType: LinkType.grant,
            modalSearchAction: (item) => handleLinkItem(item, LinkType.grant),
          }),
        visible: !document?.grantId,
      },
      {
        label: "Link Project",
        iconBefore: <RiFilePaperFill />,
        onSelect: () =>
          setActionModal({
            ...actionModal,
            modalSearch: true,
            modalSearchType: LinkType.project,
            modalSearchAction: (item) => handleLinkItem(item, LinkType.project),
          }),
        visible: !!flags?.v2?.isEnabled && !document?.projectId,
        // visible: false,
      },
      {
        label: "Share",
        iconBefore: <IoIosShareAlt />,
        onSelect: () => {
          if (isNovelEdit) return;
          // if (needAccess([PLANS.basic, PLANS.pro])) return;
          copyToClipboardWithToast(
            toUTMUrl(`${getBaseUrl()}/share/document/${document?.id}`, {
              utm_source: "share",
              utm_medium: "link",
              utm_campaign: document?.id,
              utm_content: "document",
            })
          );
        },
        // visible: item?.status !== "DRAFT",
        visible: true,
      },
    ],

    bottom: [
      // {
      //   label: "Export to PDF",
      //   iconBefore: <PiExportFill />,
      //   onSelect: () => handleExport(ExportType.PDF),
      //   // visible: !isMobile,
      // },
      // {
      //   label: "Export to DOCX",
      //   iconBefore: <IoDocumentTextOutline />,
      //   onSelect: () => handleExport(ExportType.DOCX),
      //   // visible: !isMobile,
      // },
      {
        label: "Mark As Complete",
        iconBefore: <IoCheckmark />,
        onSelect: () =>
          handleChanges(() => {
            showConfetti(0.25);
            setCurrentDocument((prev) => ({
              ...prev,
              status: DocumentStatus.SUBMITTED,
            }));
          }),
        visible: document?.status === DocumentStatus.DRAFT,
      },
      {
        label: "Mark As Draft",
        iconBefore: <BiUndo />,
        onSelect: () =>
          handleChanges(() => {
            setCurrentDocument((prev) => ({
              ...prev,
              status: DocumentStatus.DRAFT,
            }));
          }),
        visible: document?.status !== DocumentStatus.DRAFT,
      },
    ],
  };

  // const renderEditTitle = () => {
  //   const updateTitle = () => {
  //     if (!isNullOrEmpty(sectionTracker?.section?.title)) {
  //       handleUpdatePrompt(
  //         sectionTracker?.section?.id,
  //         { title: sectionTracker?.section?.title },
  //         () => setSectionTracker((prev) => ({ ...prev, editingId: undefined }))
  //       );
  //     } else {
  //       setSectionTracker((prev) => ({ ...prev, editingId: undefined }));
  //     }
  //   };

  //   // console.log("sectionTracker?.section", sectionTracker?.section);

  //   return (
  //     <input
  //       autoFocus={true}
  //       className={
  //         "text-2xl font-bold outline-none rounded border p-2 focus:border-blue-500 focus:outline-none w-full"
  //       }
  //       placeholder="Prompt Title"
  //       value={
  //         sectionTracker?.section?.title ||
  //         sectionTracker?.section?.document?.content ||
  //         ""
  //       }
  //       id="title"
  //       onChange={(e) => {
  //         setSectionTracker((prev) => ({
  //           ...prev,
  //           section: { ...prev.section, title: e.target.value },
  //         }));
  //       }}
  //       onBlur={() => {
  //         updateTitle();
  //       }}
  //       onKeyDown={(e) => {
  //         if (e.key === "Enter") {
  //           updateTitle();
  //         }
  //       }}
  //     />
  //   );
  // };

  useEffect(() => {
    if (!document) {
      navigate(`/${Entity.documents}`);
      return;
    }
    // handleUpdateDocument(["status"]);

    return () => {
      fetchDocuments.refetch();
    }
  }, [document]);

  // console.log("sections", sections)
  // console.log("previews", previews)

  // if (promptId && currentView === DocumentView.DRAFT) {
  if (!documents?.length) {
    return (
      <div className="flex justify-center w-full h-full ">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <>
      <VideoUpdate videoKeys={["Templates"]} />
      <div>

        {/* <div className="z-10 bg-white sticky top-14 px-5">
          <div className="flex">
            <div className="flex-grow"></div>
            {actionModal?.modalSearch && (
              <ModalSearch
                position={"top-[8rem] right-10"}
                items={useStoreEntity(actionModal?.modalSearchType)}
                onDone={() =>
                  setActionModal((prev) => ({ ...prev, modalSearch: false }))
                }
                onClick={(item) => actionModal?.modalSearchAction(item)}
              />
            )}
          </div>
          <div className="flex flex-col mb-3">
            <div className="flex justify-between items-center">
              {isEdit ? (
                <input
                  autoFocus={true}
                  className={
                    "text-2xl font-bold outline-none rounded border p-2 focus:border-blue-500 focus:outline-none -sm:w-full w-1/2"
                  }
                  placeholder="Enter Document Title"
                  value={document?.title || ""}
                  id="title"
                  onChange={handleEditTitle}
                  onBlur={() => {
                    if (!isNullOrEmpty(document?.title)) {
                      handleUpdateDocument(["title"]);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUpdateDocument(["title"]);
                    }
                  }}
                />
              ) : (
                <div className="mb-2">
                  <h1
                    className="text-2xl font-bold mt-5 hover:outline-dashed -sm:hidden w-full"
                    onClick={() => setIsEdit(true)}
                  >
                    {document?.title}
                  </h1>
                  <h1
                    className="text-2xl font-bold mt-5 hover:outline-dashed sm:hidden -sm:w-full w-1/2"
                    onClick={() => setIsEdit(true)}
                  >
                    {ellipsis(document?.title, 50)}
                  </h1>
                </div>
              )}
              <Actions actions={actions} isLoading={isExporting} />
            </div>
            <div className="flex justify-between -sm:flex-wrap-reverse">
              <FileMenu />
              <div className="pb-1 overflow-visible">
                <BadgeSection
                  items={linkedItems
                    ?.filter((x) => x?.id)
                    .map((x) => ({
                      ...(pick(x, [
                        "title",
                        "id",
                        "name",
                        "type",
                      ]) as BadgeItem),
                    }))}
                  handleRemove={(item) => {
                    setLinkedItems((prev) =>
                      prev?.filter((x) => x?.id !== item?.id)
                    );
                    handleLinkItem(item, item?.type);
                  }}
                  handleClick={(item) => {
                    setAppModal((prev) => ({
                      ...prev,
                      type: item?.type,
                      views: { ...prev.views, [ActionType.view]: true },
                      grantId: item?.id,
                    }));
                  }}
                // className={isMobile ? "" : "flex-1 flex flex-col"}
                />
              </div>
            </div>
          </div>
          <Divider mb={4} size={"xl"} />
        </div> */}

        <EditDoc
          document={document}
          setCurrentDocument={setCurrentDocument}
          editorRef={editorRef}
        />
      </div>
    </>
  );
};

export default DraftDocument;
