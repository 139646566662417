import { FiPlus } from "react-icons/fi";
// import Loader from "@components/utils/Loader";
import { useGetList } from "react-admin";
import {
  APP_URL,
  DATE_TIME_FORMAT,
  DEFAULT_DATE,
  DEFAULT_US_DATE_FORMAT,
  Dict,
  IResponse,
  isNullOrEmpty,
  openInNewTab,
  pluralize,
  queryEmbeds,
  setURLQuery,
  uniqueObjects,
} from "@utils/index";
import {
  useDisplayToast,
  useCopyToClipboard,
  useCommonQueries,
  useURLParams,
} from "@utils/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { ContextStore } from "@components/utils/Context";
import CalendarIcon from "@assets/calendar-icon.svg";
import BellIcon from "@assets/calendar/bell.svg";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { Event, MenuAction } from "@utils/interface";
import { orderBy } from "lodash";
// import ActionModal from "@components/ActionModal";
import {
  Nav,
  months,
  days,
  currentMonthStr,
  AddEdit as AddEditEvent,
  View as ViewEvent,
  getYearMonthStr,
} from "./index";
import { Calendar as ICalendar, CalendarDay } from "./interface";
import Actions from "@components/Actions";
import { BsCalendar2Day } from "react-icons/bs";
import MonthToggle from "./MonthToggle";
import React from "react";
import VideoUpdate from "@components/VideoUpdate";

interface Props {
  children?: React.ReactNode;
}

const Calendar = (props: Props) => {
  const { account, events, setEvents, actionModal, setActionModal } =
    useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const { copyToClipboardWithToast } = useCopyToClipboard();
  const [showNav, setShowNav] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [modalEvent, setModalEvent] = useState<Event | null>(null);

  const { queryParams } = useURLParams();
  const { eventId } = queryParams;

  const now = moment();

  const [currentPeriod, setCurrentPeriod] = useState({
    year: now.year(),
    month: now.month() + 1,
  });

  const currentMonth = currentPeriod.month;
  const currentYear = currentPeriod.year;
  const yearMonth = getYearMonthStr(currentMonth, currentYear);
  // console.log("yearMonth", yearMonth);
  const currentMoment = moment(yearMonth);

  //get days in month using moment
  const daysInMonth = moment(yearMonth, "YYYY-MM").daysInMonth();

  const startOfMonthDOW = currentMoment.startOf("M").day();
  const endOfMonthDOW = currentMoment.endOf("M").day();

  // console.log("startOfMonthDOW", startOfMonthDOW);
  // console.log("endOfMonthDOW", endOfMonthDOW);

  // AND: [
  //   { startDate: { gte: startDate } },
  //   { endDate: { lte: endDate } }
  // ]

  //  colors: { bg: "#FCCEEE", color: "#F670C7" }, //pink
  //  colors: { bg: "#BCC2FA", color: "#7A8CEA" }, //purple
  //  colors: { bg: "#FECDCA", color: "#F97066" }, //red`
  //  colors: { bg: "#D6BCFA", color: "#9F7AEA" }, //violet

  const generateDays = (daysInMonth: number): ICalendar => {
    const days: any = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const day = {
        day: i,
        date: moment(yearMonth).date(i).format(DEFAULT_DATE),
        events: events
          ?.filter((e) => e?.start?.includes(yearMonth))
          ?.filter((event) => moment(event?.start.split("T")[0]).date() === i)
          .map((x) => ({ ...x, colors: { bg: "#A6F4C5", main: "#32D583" } })),
      };
      days.push(day);
    }
    return days;
  };

  const activeCalendarDays = generateDays(daysInMonth);

  const extraRow =
    (startOfMonthDOW > 4 && activeCalendarDays.length === 31) ||
    (startOfMonthDOW > 5 && activeCalendarDays.length === 30);

  const placeholders = {
    before: Array(startOfMonthDOW).fill(null),
    after: Array(endOfMonthDOW || extraRow ? 6 - endOfMonthDOW : 0).fill(null),
  };

  const { fetchCalendarEvents } = useCommonQueries();
  const fetchEvents = fetchCalendarEvents(yearMonth, placeholders);

  useEffect(() => {
    fetchEvents?.refetch();
  }, [account, currentPeriod]);

  const handlePopUp = (event: Event) => {
    setModalEvent(event);
    setViewModal(true);
  };

  const handleDone = () => {
    setActionModal({ ...actionModal, addEditEvent: false });
    setModalEvent(null);
  };

  const handleEdit = (event: Event) => {
    setViewModal(false);
    setActionModal({ ...actionModal, addEditEvent: true });
    setModalEvent(event);
  };

  const actions = {
    top: [
      {
        label: "Add Event",
        iconBefore: <FiPlus />,
        onSelect: () => setActionModal({ ...actionModal, addEditEvent: true }),
        visible: true,
      },
      {
        label: "Get Export Link",
        iconBefore: <BsCalendar2Day />,
        onSelect: () => {
          const iCal = `${process.env.VITE_API_URL}/export/${account?.id}/calendar.ics`;
          copyToClipboardWithToast(
            iCal,
            "Import your ics link to Google Calendar"
            // () =>  openInNewTab(iCal, 1000)
          );
        },
        visible: true,
      },
    ],
    bottom: [],
  };

  useEffect(() => {
    if (eventId && events?.length) {
      const event = events.find((item) => item?.id === eventId);
      if (event) {
        setURLQuery(eventId ? queryParams : { eventId: event?.id });
        handlePopUp(event);
      }
    }
  }, [eventId, events?.length]);

  // console.log("activeCalendarDays", activeCalendarDays);

  return (
    <>
      <VideoUpdate videoKeys={["Deadlines"]} />
      <div className=" w-full h-full flex overflow-hidden relative">
        {actionModal?.addEditEvent && (
          <AddEditEvent
            event={modalEvent}
            refetch={() => fetchEvents?.refetch()}
            handleDone={handleDone}
          />
        )}

        {viewModal && modalEvent && (
          <ViewEvent
            event={modalEvent}
            handleDone={() => {
              setViewModal(false);
              setModalEvent(null);
            }}
            handleEdit={handleEdit}
          />
        )}

        <Nav
          showNav={showNav}
          setShowNav={setShowNav}
          months={months}
          currentPeriod={currentPeriod}
          setCurrentPeriod={setCurrentPeriod}
          handlePopUp={handlePopUp}
        />

        <div className="w-full overflow-hidden h-full flex flex-col md:border-l">
          <div className="py-1 px-2 -sm:pl-[3.5rem] flex justify-between flex-shrink-0 text-sm border-b">
            <div className="flex">
              <div className="mr-2 flex">
                <MonthToggle
                  currentPeriod={currentPeriod}
                  setCurrentPeriod={setCurrentPeriod}
                />
              </div>
              <p className=" my-auto">
                <span className=" mr-1.5 text-[#553C9A]">
                  {months[currentMonth - 1]}
                </span>
                <span>{currentYear}</span>
              </p>
            </div>
            <button
              className="-sm:pl-[3.5rem] ml-[-100px] my-auto text-[#553C9A]"
              onClick={() => {
                setCurrentPeriod({
                  ...currentPeriod,
                  year: moment().year(),
                  month: moment().month() + 1,
                });
              }}
            >
              Today
            </button>
            <Actions actions={actions} />
          </div>

          <div className="h-full w-full flex flex-col overflow-x-auto">
            <div className="py-1 min-w-[1000px] grid grid-cols-7 text-xs flex-shrink-0">
              {days?.map((day, idx) => {
                return (
                  <div
                    key={`d-${idx}`}
                    className="flex-center overflow-x-hidden"
                  >
                    <img
                      className="w-3 mr-1"
                      src={CalendarIcon}
                      alt="Calendar Icon"
                    />
                    <span>{day}</span>
                  </div>
                );
              })}
            </div>

            <div
              className={`
            ${
              activeCalendarDays.length === 28
                ? "grid-rows-4"
                : extraRow
                ? "grid-rows-6"
                : "grid-rows-5"
            }
            text-xs min-w-[1000px] flex-1 h-full overflow-hidden grid grid-cols-7 `}
            >
              {placeholders?.before?.map((_, index) => {
                const dayNum =
                  moment(yearMonth).subtract(1, "M").daysInMonth() -
                  (placeholders?.before?.length - (index + 1));
                const prevMonthDate = moment(yearMonth)
                  .subtract(1, "M")
                  .date(dayNum)
                  .format(DEFAULT_DATE);
                return (
                  <React.Fragment key={prevMonthDate}>
                    <PlaceHolderItem dayNum={dayNum}>
                      {events
                        ?.filter(
                          (event) =>
                            event?.start.split("T")[0] === prevMonthDate
                        )
                        .map((event, idx) => {
                          return (
                            <div key={`placeholder-before-event-${idx}`}>
                              <EventItem
                                event={event}
                                handlePopUp={handlePopUp}
                              />
                            </div>
                          );
                        })}
                    </PlaceHolderItem>
                  </React.Fragment>
                );
              })}

              {activeCalendarDays?.map((item, idx) => {
                const isToday = item?.date === now.format(DEFAULT_DATE);
                return (
                  <div
                    key={`active-month-${idx}`}
                    className={`w-full flex-shrink-0 overflow-y-scroll no-scrollbar relative border-r border-t ${
                      isToday ? "bg-[#f4effa]" : ""
                    }`}
                  >
                    <p
                      className={`mb-4 bg-white w-max px-2 py-1 rounded-br-box sticky top-0`}
                    >
                      {item?.day}
                    </p>
                    {item?.events?.map((event, idx) => {
                      return (
                        <div key={`active-month-event-${idx}`}>
                          <EventItem event={event} handlePopUp={handlePopUp} />
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {placeholders?.after?.map((_, index) => {
                const nextMonthDate = moment(yearMonth)
                  .add(1, "M")
                  .date(index + 1)
                  .format(DEFAULT_DATE);
                return (
                  <React.Fragment key={nextMonthDate}>
                    <PlaceHolderItem dayNum={index + 1}>
                      {events
                        ?.filter(
                          (event) =>
                            event?.start.split("T")[0] === nextMonthDate
                        )
                        .map((event, idx) => {
                          return (
                            <div key={`placeholder-after-event-${idx}`}>
                              <EventItem
                                event={event}
                                handlePopUp={handlePopUp}
                              />
                            </div>
                          );
                        })}
                    </PlaceHolderItem>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;

interface EventItemProps {
  event: Event;
  handlePopUp: (e: Event) => void;
}

const EventItem = (props: EventItemProps) => {
  const { event, handlePopUp } = props;
  return (
    <div
      onClick={() => handlePopUp(event)}
      style={{
        backgroundColor: event?.colors?.bg,
        // color: event?.colors?.main,
        borderColor: event?.colors?.main,
      }}
      className="py-1 px-0.5 mb-1 mx-1 flex items-start rounded border cursor-pointer"
    >
      <img
        className=" mt-1 mr-[6px] w-3 flex-shrink-0"
        src={event?.reminder ? BellIcon : CalendarIcon}
        alt=""
      />
      <p className="mr-1">{event?.title}</p>
      {/* <p>{event.time}</p> */}
    </div>
  );
};

interface PlaceHolderItemProps {
  dayNum: number;
  children?: React.ReactNode;
}

const PlaceHolderItem = (props: PlaceHolderItemProps) => {
  const { dayNum, children } = props;
  return (
    <div className=" bg-gray-100 text-gray-400 px-1 w-full border-r border-t">
      <p>{dayNum}</p>
      {children}
    </div>
  );
};
