import { useContext, useRef, useState } from "react";
import { BiX, BiPlus, BiArrowBack } from "react-icons/bi";
import ActionCard from "@components/ActionCard";
import { Dict, IResponse, Note as INote, Entity } from "@utils/interface";
import { useGetList } from "react-admin";
import { useDel, useDisplayToast, usePost, usePut } from "@utils/hooks";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import { isNullOrEmpty } from "@utils/index";

interface Props {
  itemId: string;
  setShowNote: React.Dispatch<React.SetStateAction<boolean>>;
}

const Note = (props: Props) => {
  const { setShowNote, itemId } = props;
  const { successToast, errorToast } = useDisplayToast();
  const { notes: _notes, setNotes } = useContext(ContextStore);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [noteData, setNoteData] = useState<Dict>({});
  const [createOne, { isLoading: isCreating }] = usePost<INote>();
  const [updateOne, { isLoading: isUpdating }] = usePut<INote>();
  const [deleteOne, { isLoading: isDeleting }] = useDel<INote>();

  const handleAdd = () => {
    if (isNullOrEmpty(noteData)) {
      errorToast("Note cannot be empty");
      return;
    }
    createOne(
      Entity.notes,
      {
        data: {
          ...noteData,
          boardItem: {
            connect: {
              id: itemId,
            },
          },
        },
      },
      {
        onSuccess: (data) => {
          // console.log("onSuccess", data);
          successToast(`Note Added`);
          setNotes((prev) => ({
            ...prev,
            [itemId]: [...prev[itemId], data as INote],
          }));
          setIsEdit(false);
          setNoteData({});
        },
        onError: (error) => {
          console.log("onError", error);
          // console.log("createError", JSON.stringify(createError));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleDelete = (itemId: string) => {
    deleteOne(
      Entity.notes,
      { id: itemId },
      {
        onSuccess(data, variables, context) {
          successToast("Note Deleted");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleUpdate = (itemId: string, data: any) => {
    updateOne(
      Entity.notes,
      { id: itemId, data },
      {
        onSuccess(data, variables, context) {
          // console.log("Board Updated");
          successToast("Task Updated");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  // console.log("Task", itemId);
  const notes = _notes[itemId];

  //get tasks
  const {
    // data,
    isLoading,
    // error,
    refetch,
  } = useGetList<INote>(
    Entity.notes,
    {
      sort: { field: "createdAt", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
      filter: { boardItemId: itemId },
    },
    {
      // node_modules/react-query/types/core/types.d.ts:66
      enabled: true,
      onSuccess(data) {
        // console.log("taskData", data?.data);
        setNotes((prev) => ({ ...prev, [itemId]: data?.data }));
      },
      onError(error) {
        errorToast(error?.message);
      },
    }
  );

  return (
    <ActionCard
      setShowCard={setShowNote}
      header={
        isEdit ? (
          <div className="flex">
            <button
              onClick={() => setIsEdit(false)}
              className="flex-y-center gap-1 hover:text-black pr-1"
            >
              <BiArrowBack />
            </button>
            <p>New Note</p>
            {/* <input className="outline-none pb-1"
                placeholder="New Note"
                value={note?.title}
                onChange={(e) => setNote({ ...note, title: e.target.value })} /> */}
          </div>
        ) : (
          <p>Notes</p>
        )
      }
      footer={
        <>
          <div className="flex-grow" />
          <button
            onClick={() => {
              if (isEdit) {
                handleAdd();
              } else {
                setIsEdit(true);
              }
            }}
            className="flex-y-center gap-1 hover:text-black"
          >
            <BiPlus />
            <span>{isEdit ? "Add Note" : "New Note"}</span>
          </button>
        </>
      }
    >
      {isLoading || isCreating || isUpdating || isDeleting ? (
        <Loader size={"md"} />
      ) : isEdit ? (
        <div className="h-20">
          <textarea
            className="w-full h-full resize-none outline-none"
            placeholder="Add a note..."
            value={noteData?.text}
            onChange={(e) => setNoteData({ ...noteData, text: e.target.value })}
          />
        </div>
      ) : (
        <>
          {notes?.length > 0 ? (
            <div className="h-20 pl-3">
              <ul className="children:pt-1">
                {notes?.map((note, idx) => {
                  return (
                    // <li key={idx}>{note?.title}: {note?.text}</li>
                    <div className="flex justify-between w-full">
                      {note?.id === noteData?.id ? (
                        <input
                          className="outline-none w-full h-10 pb-1"
                          placeholder="Edit Note..."
                          value={noteData?.text}
                          onChange={(e) =>
                            setNoteData({ ...noteData, text: e.target.value })
                          }
                          onBlur={(e) => {
                            handleUpdate(note?.id, { text: noteData?.text });
                            setNoteData({});
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleUpdate(note?.id, { text: noteData?.text });
                              setNoteData({});
                            }
                          }}
                        />
                      ) : (
                        <>
                          <li
                            key={idx}
                            onClick={() => setNoteData({ ...note })}
                          >
                            {note?.text}
                          </li>
                          <button
                            onClick={() => handleDelete(note?.id)}
                            className="mr-3 h-max text-[13px] text-gray-400 hover:text-black flex-center border border-gray-400 hover:border-black rounded-full"
                          >
                            <BiX />
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div className="flex-center h-14">
              <p>What's your first note?</p>
            </div>
          )}
        </>
      )}
    </ActionCard>
  );
};

export default Note;
