// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/index.tsx
// https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/extensions/slash-command.tsx

import React, {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { Editor, Range, Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";
import { ReactRenderer } from "@tiptap/react";
import { useCompletion } from "ai/react";
import tippy from "tippy.js";
import { toast } from "sonner";
// import va from "@vercel/analytics";
// import Loader from "@components/utils/Loader";
import {
  getCompletionHeaders,
  getPrevText,
  handleUpdateDoc,
  processEscapedChars,
} from "../../helper";
// import { FaAlignLeft, FaAlignRight, FaMagic } from "react-icons/fa";
import { PluginKey } from "@tiptap/pm/state";
import { CreatePromptPayload, Entity } from "@utils/interface";
import NovelPanel from "@components/document/NovelPanel";
import { useDisplayToast, useFetchAccount } from "@utils/hooks";
import Loader from "@components/utils/Loader";
import {
  APP_CONST,
  getApiUrl,
  handleMaintainFocus,
  notifyError,
} from "@utils/index";
import { ContextStore } from "@components/utils/Context";
// import ReactDOM from "react-dom";
import { isMobile } from "react-device-detect";

interface CommandItemProps {
  title: string;
  description: string;
  icon: ReactNode;
  searchTerms?: string[];
  command?: ({ editor, range }: CommandProps) => void;
}

interface CommandProps {
  editor: Editor;
  range: Range;
}

const name = "prompt-command";
const pluginKey = new PluginKey(name);
const Command = Extension.create({
  name,
  addOptions() {
    return {
      suggestion: {
        // https://github.com/ueberdosis/tiptap/discussions/1768
        pluginKey: pluginKey,
        char: "??",
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  // addKeyboardShortcuts() {
  //   return {
  //     Space: ({ editor }) => {
  //       return false;
  //     },
  //     Enter: ({ editor }) => {
  //       const pluginState = pluginKey.getState(editor.state);
  //       if (pluginState && pluginState.active) {
  //         // Perform submission or closure action here
  //         // Example: pluginState.submit();
  //         return true; // Indicate that the event was handled
  //       }
  //       return false; // Allow other handlers to process the event
  //     },
  //   };
  // },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

interface PromptBoxProps {
  items: CommandItemProps[];
  command: any;
  editor: any;
  range: any;
  updatePopup: any;
}

const PromptBox = (props: PromptBoxProps) => {
  const [promptPayload, setPromptPayload] = useState<CreatePromptPayload>();
  const [popup, setPopup] = useState<any>(null);
  const { globalState, setGlobalState } = useContext(ContextStore);
  const { refreshAccount } = useFetchAccount();

  const isBusy = useRef(false);
  const prev = useRef("");

  const handleClose = () => {
    if (!popup || popup?.state?.isDestroyed) return;
    // console.log("Close popup", popup);
    popup?.destroy();
  };
  // const { errorToast } = useDisplayToast();

  const handleUpdatePopup = (newPopup) => {
    setPopup(newPopup);
  };

  const handleReset = () => {
    console.log("Resetting....");
    setGlobalState((prev) => ({ ...prev, promptPayload: null }));
    stop();
    setCompletion("");
    prev.current = "";
  };

  useEffect(() => {
    handleReset();

    //call back function to update popup
    const interval = setTimeout(() => {
      props?.updatePopup(handleUpdatePopup);
    }, 500);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  const { complete, isLoading, completion, stop, setCompletion, data } =
    useCompletion({
      id: "stream-prompt",
      api: getApiUrl(`/${Entity.prompts}/stream`),
      headers: {
        ...getCompletionHeaders(),
      },
      body: {
        ...promptPayload,
        document: { connect: { id: globalState?.currentDocument?.id } },
      },
      onResponse: async (response) => {
        // https://github.com/steven-tey/novel/blob/0.1.22/packages/core/src/ui/editor/extensions/slash-command.tsx#L267
        if (response.status === 429) {
          const resp = await response?.json();
          toast.error(resp?.message, { duration: 5000 });
          return;
        }
        // clone response to read
        // const resp = await response?.clone().text();
        // console.log("response", resp);

        props?.editor.chain().focus().deleteRange(props?.range).run();
        handleUpdateDoc(props?.editor, promptPayload?.prompt || "");
      },
      onFinish: (_prompt, completion) => {
        try {
          refreshAccount();
          // highlight the generated text
          props?.editor.commands.setTextSelection({
            from: props?.range.from,
            to: props?.range.from,
            // + completion.length,
          });
          // handleUpdateDoc(
          //   props?.editor,
          //   _prompt,
          //   processEscapedChars(completion)
          // );
          // handleClose();
        } catch (e) {
          console.log("error", completion, (e as Error).stack);
        }
      },
      onError: (e) => {
        console.log("error", e.stack);
        let msg = APP_CONST.DEFAULT_ERROR;

        try {
          msg = JSON.parse(e?.message)?.message;
        } catch (_e) {
          notifyError("promptBox: " + e?.stack);
          msg = e?.message || APP_CONST.DEFAULT_ERROR;
        }

        toast.error(msg, { duration: 5000 });
      },
    });

  useEffect(() => {
    if (promptPayload?.prompt) {
      if (promptPayload?.skipAi) {
        props?.editor.chain().focus().deleteRange(props?.range).run();
        handleUpdateDoc(
          props?.editor,
          promptPayload?.prompt,
          promptPayload?.currentResponse
        );
        return;
      }
      complete(promptPayload?.prompt);
    }
  }, [promptPayload]);

  useEffect(() => {
    if (!completion) return;
    const diff = completion.slice(prev.current.length);
    prev.current = completion;
    try {
      props?.editor?.commands.insertContent(processEscapedChars(diff));
    } catch (e) {
      console.log("error", (e as Error)?.stack);
    }
    if (!diff.length) handleReset();
  }, [completion]);

  // useEffect(() => {
  //   setGlobalState((prev) => ({ ...prev, isStreaming: isLoading }));
  // }, [isLoading]);

  useEffect(() => {
    // if user presses escape or cmd + z and it's loading,
    // stop the request, delete the completion, and insert back the "++"
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || (e.metaKey && e.key === "z")) {
        stop();
        if (e.key === "Escape") {
          props?.editor?.commands.deleteRange({
            from: props?.editor.state.selection.from - completion.length,
            to: props?.editor.state.selection.from,
          });
          // handleClose();
        }
      }
    };
    const mousedownHandler = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isBusy.current) return;
      isBusy.current = true;
      if (window.confirm("Cancel Request?")) {
        console.log("cancel request");
        stop();
        // handleClose();
        setCompletion("");
        // complete(props?.editor?.getText() || "");
      }
      isBusy.current = false;
    };
    if (isLoading) {
      document.addEventListener("keydown", onKeyDown);
      window.addEventListener("mousedown", mousedownHandler);
    } else {
      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("mousedown", mousedownHandler);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("mousedown", mousedownHandler);
    };
  }, [stop, isLoading, props?.editor, complete, completion.length]);

  return (
    <div
      className="novel-rounded-md novel-border novel-border-stone-200 novel-bg-white novel-px-1 novel-py-2 novel-shadow-md novel-transition-all novel-text-lg novel-text-stone-900"
      style={{
        maxWidth: "500px",
        width: isMobile ? "100%" : "500px",
      }}
    >
      <NovelPanel
        editor={props?.editor}
        canCancel={true}
        handleGenerate={(data, cb) => {
          setPromptPayload(data);
        }}
        isLoading={isLoading}
        onClose={() => {
          isLoading ? stop() : handleClose();
        }}
      />
    </div>
  );

  // return (
  //   <div className="novel-rounded-md novel-border novel-border-stone-200 novel-bg-white novel-px-1 novel-py-2 novel-shadow-md novel-transition-all">
  //     <input
  //       type="text"
  //       style={{ width: "100%" }}
  //       autoFocus={true}
  //       className="text-xl w-[400px] rounded-md border-2 border-gray-300 p-10 focus:outline-none focus:border-blue-500"
  //       value={""}
  //       placeholder="How can I help? Type a command..."
  //       onChange={() => { }}
  //     />
  //     <div className="novel-text-sm novel-text-stone-900">
  //       <p>How can I help? Type a command ...</p>
  //     </div>
  //     <div className="novel-flex novel-w-full novel-text-lg novel-text-stone-900 ">
  //       <NovelPanel
  //         canCancel={true}
  //         handleGenerate={() => {
  //           console.log("handleGenerate");
  //         }}
  //         isLoading={false}
  //       />
  //     </div>
  //   </div>
  // );
};

const renderItems = () => {
  let component: ReactRenderer | null = null;
  let popup: any | null = null;

  const setPopupCallback = (callback) => {
    callback(popup);
  };

  return {
    onStart: (props: { editor: Editor; clientRect: DOMRect }) => {
      component = new ReactRenderer(PromptBox, {
        props: {
          ...props,
          updatePopup: setPopupCallback,
        },
        editor: props.editor,
      });

      const instances = tippy("body" as any, {
        getReferenceClientRect: props.clientRect as any,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        hideOnClick: false, // Keeps the popup open when clicking inside it
        trigger: "focusin",
        touch: true,
        // sticky: 'popper',
        placement: "auto",
        maxWidth: "none",
        theme: "voyager",
        // popperOptions: {
        //   strategy: 'fixed',
        // },
        // allowHTML: true,
        // onCreate(instance) {
        //   if (instance)
        //     instance.popper.querySelector('.tippy-box')?.classList.add('bg-blue-500', 'text-white', 'p-2', 'rounded');
        // },
        onShow(instance) {
          // Focus the input field when the tooltip is shown
          const input = instance.popper.querySelector("textarea");
          if (input) {
            input?.focus();
          }
        },
        // onHide(instance) {
        //   const input = instance.popper.querySelector("textarea");
        //   if (input) {
        //     input?.blur();
        //   }
        // },
      });

      handleMaintainFocus("textarea");

      popup = instances[0];
    },
    onUpdate: (props: { editor: Editor; clientRect: DOMRect }) => {
      component?.updateProps(props);

      if (!popup || popup?.state?.isDestroyed) return;
      popup &&
        popup.setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === "Escape") {
        if (!popup || popup?.state?.isDestroyed) return;
        popup?.hide();
        return true;
      }

      return (component?.ref as any)?.onKeyDown(props);
    },
    onExit: () => {
      return;
      if (!popup || popup?.state?.isDestroyed) return;
      console.log("exit", popup);
      popup?.destroy();
      component?.destroy();
    },
  };
};

const PromptCommand = Command.configure({
  suggestion: {
    items: ({ query }: { query: string }) => [],
    render: renderItems,
  },
});

export default PromptCommand;
