import { FaUndo } from "react-icons/fa";
import Overlay from "./Overlay";
import { BoardItem } from "@utils/interface";
import { getDaysAgoString, tailwindHelpers } from "@utils/index";
import CloseBtn from "./CloseButton";

interface Props {
  items: BoardItem[];
  closeModal: () => void;
  unArchive: (card: BoardItem) => void;
}

const ArchiveList = (props: Props) => {
  const { items, closeModal, unArchive } = props;

  return (
    <Overlay onDone={closeModal}>
      <div
        className={`${tailwindHelpers?.responsiveModal} pb-10 my-14 -sm:mx-8 rounded-lg bg-white`}
      >
        <div className="h-full relative bg-white custom-scrollbar overflow-y-auto rounded-t-lg">
          <div className="py-3.5 px-6 sticky top-0 font-bold bg-neutral-200 border-b flex justify-between -sm:text-lg">
            <p>{items?.length} archived items</p>
            <div className="justify-end">
              <CloseBtn variant="outline" closeModal={closeModal} />
            </div>
          </div>

          <div className="text-neutral-700">
            {items?.map((archive, idx) => {
              return (
                <div
                  key={idx}
                  className="flex-y-center justify-between py-1.5 px-6 -xs:px-2.5 border-b"
                >
                  <div className="w-full">
                    <p className="text-[15px] -xs:text-sm line-clamp-1">
                      {archive?.title}
                    </p>
                    <p className="text-[13px] -xs:text-xs text-neutral-400">
                      archived {getDaysAgoString(archive?.archivedAt)}
                    </p>
                  </div>

                  <button
                    onClick={() => unArchive(archive)}
                    className="ml-2 flex-shrink-0 hover:text-[#6a54b1]"
                  >
                    <FaUndo />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default ArchiveList;
