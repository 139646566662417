interface Props {
  type: "pulse" | "bounce" | "wave";
}

const ChatLoader = (props: Props) => {
  const { type } = props;

  switch (type) {
    case "bounce": {
      return (
        <div className="flex justify-center items-center space-x-2">
          <div className="w-4 h-4 bg-purple-500 rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-purple-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-4 h-4 bg-purple-500 rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-purple-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-4 h-4 bg-purple-500 rounded-full animate-bounce"></div>
        </div>
      );
    }
    case "wave": {
      return (
        <div className="flex justify-center items-center">
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-wave h-4 w-4 bg-purple-500 rounded-full"></div>
        </div>
      );
    }
    case "pulse": {
      return (
        <div className="flex justify-center items-center">
          <div className="animate-pulse h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-pulse h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-pulse h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-pulse h-4 w-4 bg-purple-500 rounded-full mr-2"></div>
          <div className="animate-pulse h-4 w-4 bg-purple-500 rounded-full"></div>
        </div>
      );
    }
  }
};

export default ChatLoader;
