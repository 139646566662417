import {
  Dict,
  User,
  Event,
  // Activity,
  Account,
  BoardItem,
  AppModal,
  Document,
  Board,
  Task,
  Note,
  TDict,
  Notification,
  Grant,
  Flags,
  Project,
  Report,
  IFile,
  Chat,
  Folder,
  Recommendation,
  Snippet,
} from "@utils/interface";
import { createContext, useState } from "react";

export const ContextStore = createContext({} as ContextStoreTypes);

export const ContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState<Dict>({});
  const [actionModal, setActionModal] = useState<Record<string, any>>({});
  const [appModal, setAppModal] = useState<AppModal>({ views: {} });
  // entities
  const [user, setUser] = useState<User>(null as any);
  const [account, setAccount] = useState<Account>(null as any);
  const [team, setTeam] = useState<User[]>([]);
  const [board, setBoard] = useState<Board>(null as any);
  const [boardItems, setBoardItems] = useState<BoardItem[]>([]);
  const [grants, setGrants] = useState<Grant[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [activity, setActivity] = useState<Notification>({ user: [], account: [], });
  const [documents, setDocuments] = useState<Document[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [flags, setFlags] = useState<Flags>({});
  const [hasChanges, setHasChanges] = useState<Record<string, boolean>>({});
  const [tasks, setTasks] = useState<TDict<Task[]>>({}); /** {[boardItemId]: Tasks[]} */
  const [notes, setNotes] = useState<TDict<Note[]>>({}); /** {[boardItemId]: Notes[]} */
  const [recommendations, setRecommendations] = useState<Recommendation[]>(undefined as unknown as any);
  const [snippets, setSnippets] = useState<Snippet[]>([]);

  const value = {
    user,
    setUser,
    account,
    setAccount,
    boardItems,
    setBoardItems,
    files,
    setFiles,
    columns,
    setColumns,
    documents,
    setDocuments,
    team,
    setTeam,
    events,
    setEvents,
    activity,
    setActivity,
    actionModal,
    setActionModal,
    appModal,
    setAppModal,
    flags,
    setFlags,
    projects,
    setProjects,
    reports,
    setReports,
    hasChanges,
    setHasChanges,
    board,
    setBoard,
    tasks,
    setTasks,
    notes,
    setNotes,
    grants,
    setGrants,
    globalState,
    setGlobalState,
    chats,
    setChats,
    folders,
    setFolders,
    recommendations,
    setRecommendations,
    snippets, setSnippets
  };

  return (
    <ContextStore.Provider value={value}>{children}</ContextStore.Provider>
  );
};

export type ContextStoreTypes = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  account: Account;
  setAccount: React.Dispatch<React.SetStateAction<Account>>;
  boardItems: BoardItem[];
  setBoardItems: React.Dispatch<React.SetStateAction<BoardItem[]>>;
  grants: Grant[];
  setGrants: React.Dispatch<React.SetStateAction<Grant[]>>;
  files: IFile[];
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  documents: Document[];
  setDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
  reports: Report[];
  setReports: React.Dispatch<React.SetStateAction<Report[]>>;
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  team: User[];
  setTeam: React.Dispatch<React.SetStateAction<User[]>>;
  events: Event[];
  setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
  activity: Notification;
  setActivity: React.Dispatch<React.SetStateAction<Notification>>;
  actionModal: Record<string, any>;
  setActionModal: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  appModal: AppModal;
  setAppModal: React.Dispatch<React.SetStateAction<AppModal>>;
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  hasChanges: Record<string, boolean>;
  setHasChanges: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  board: Board;
  setBoard: React.Dispatch<React.SetStateAction<Board>>;
  tasks: TDict<Task[]>;
  setTasks: React.Dispatch<React.SetStateAction<TDict<Task[]>>>;
  notes: TDict<Note[]>;
  setNotes: React.Dispatch<React.SetStateAction<TDict<Note[]>>>;
  globalState: Dict;
  setGlobalState: React.Dispatch<React.SetStateAction<Dict>>;
  chats: Chat[];
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>;
  folders: Folder[];
  setFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
  recommendations: Recommendation[];
  setRecommendations: React.Dispatch<React.SetStateAction<Recommendation[]>>;
  snippets: Snippet[];
  setSnippets: React.Dispatch<React.SetStateAction<Snippet[]>>;
};
