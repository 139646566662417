import { useContext, useEffect, useRef, useState } from "react";
import {
  ArrowForwardIcon,
  ArrowBackIcon,
  CalendarIcon,
} from "@chakra-ui/icons";
import { BiX } from "react-icons/bi";
import { withChakra } from "@components/utils/ChakraWrapper";
import Stepper from "@components/Stepper";
import { useNavigate } from "react-router-dom";
import { httpClient } from "@providers/client";
import {
  useCommonQueries,
  useDisplayToast,
  useGet,
  usePost,
  usePut,
  useURLParams,
} from "@utils/hooks";
import { omit } from "lodash";
import { useGetOne } from "react-admin";
import Loader from "@components/utils/Loader";
// import { dataProvider } from "../providers/data";
import { ContextStore } from "@components/utils/Context";
// import { Dict, MenuAction, User, IResponse } from "@utils/interface";
import {
  Account,
  AccountType,
  Entity,
  HTTP_METHODS,
  LOCAL_STORE_KEYS,
  User,
  appName,
  isNullOrEmpty,
  mapNullToUndefined,
  openInNewTab,
} from "@utils/index";
import { FormOverview, FormBasicInfo, FormTeam } from ".";
// import AppButton from "@components/AppButton";
import { CgDanger } from "react-icons/cg";
import ActionModal from "@/components/modal/ActionModal";
import { PiPlugsBold, PiPlugsConnectedBold } from "react-icons/pi";

export default withChakra(() => {
  const {
    queryParams: { page },
  } = useURLParams();

  // console.log('page:queryParams', page)

  const steps = [
    {
      stepNo: 1,
      description: "Basic Information",
      path: "/organization?page=basic",
    },
    {
      stepNo: 2,
      description: "Organization Overview",
      path: "/organization?page=overview",
    },
    {
      stepNo: 3,
      description: "Team",
      path: "/organization?page=team",
    },
    // { stepNo: 4, description: "Additional Information" },
  ];
  const stepsPercent = (1 / steps?.length) * 100;

  const getStep = () => {
    switch (page) {
      case "overview":
        return 2;
      case "team":
        return 3;
      case "basic":
      default:
        return 1;
    }
  };

  const currStep = getStep();
  // console.log('currStep', currStep);

  const [step, setStep] = useState(currStep);
  const [progress, setProgress] = useState(stepsPercent);
  const navigate = useNavigate();
  const { successToast, errorToast } = useDisplayToast();

  const {
    user,
    account,
    setAccount,
    hasChanges,
    setHasChanges,
    actionModal,
    setActionModal,
    globalState,
  } = useContext(ContextStore);

  const [updateAccount] = usePut<Account>();
  const [handleAgencyLink, { isLoading: isLinking }] = usePut<Account>();

  const { fetchAgency, fetchAccount } = useCommonQueries();
  const agencyLink = useRef("");

  useEffect(() => {
    fetchAccount?.refetch();
  }, []);

  // console.log('data', data)

  const handleSubmit = (showAlert = true) => {
    // console.log("handleSubmit....");
    updateAccount(
      Entity.accounts,
      {
        id: account?.id,
        data: mapNullToUndefined(account),
        // data: acct,
      },
      {
        onSuccess: (data) => {
          // console.log("response", response);
          setAccount(data);
          // successToast(`Account ${account?.id ? "Updated" : "Created"}`);
          if (showAlert) successToast(`Account Updated`);
          if (globalState?.isAgency && hasChanges?.fetchAgency) {
            fetchAgency.refetch();
            setHasChanges({ ...hasChanges, fetchAgency: false });
          }
        },
        onError: (error) => {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const renderView = (step: number) => {
    switch (step) {
      case 1:
        return (
          <FormBasicInfo
            handleSubmit={handleSubmit}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, ...e })}
          />
        );
      case 2:
        return (
          <FormOverview
            handleSubmit={handleSubmit}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, ...e })}
          />
        );
      case 3:
        return <FormTeam />;
    }
  };

  const handleChangePage = () => {
    if (hasChanges?.orgProfile) {
      handleSubmit();
      setHasChanges({ ...hasChanges, orgProfile: false });
    }
  };

  const handleContinue = () => {
    if (actionModal?.accountImport) return;
    handleChangePage();
    // setStep(step + 1);
    navigate(steps[step].path);
    // navigate('/organization?page=overview', { state: { } });
    // navigate('/organization?page=overview');
    step === steps?.length
      ? setProgress(100)
      : setProgress(progress + stepsPercent);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    // console.log("step", step, currStep, steps[step - 1]);
    if (actionModal?.accountImport) return;
    if (step === 1) return navigate("/");
    handleChangePage();
    // setStep(step - 1);
    navigate(steps[step - 2].path);
    setProgress(progress - stepsPercent);
    window.scrollTo(0, 0);
  };

  const handleLinkAgency = () => {
    if (account?.linkedToId) {
      updateAccount(
        Entity.accounts,
        {
          id: account?.id,
          data: {
            linkedTo: { disconnect: true },
          },
        },
        {
          onSuccess: (data) => {
            setAccount(data);
            successToast(`Account UnLinked from Agency`);
            setActionModal({ ...actionModal, linkAccount: false });
            agencyLink.current = "";
          },
          onError: (error) => {
            errorToast(error?.body?.message);
          },
        }
      );
    } else {
      if (isNullOrEmpty(agencyLink?.current)) {
        errorToast("Please enter a valid agency email");
        return;
      }
      handleAgencyLink(
        `${Entity.accounts}/link`,
        {
          id: account?.id,
          data: {
            agencyEmail: agencyLink.current,
          },
        },
        {
          onSuccess: (data) => {
            setAccount(data);
            successToast(`Account Linked to Agency`);
            setActionModal({ ...actionModal, linkAccount: false });
            agencyLink.current = "";
          },
          onError: (error) => {
            errorToast(error?.body?.message);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (currStep) {
      setStep(currStep);
    }
  }, [page]);

  useEffect(() => {
    return () => {
      setHasChanges({ orgProfile: false });
    };
  }, []);

  return (
    <>
      {actionModal?.deleteAccount && (
        <ActionModal
          icon={<CgDanger />}
          handleClose={() => {
            setActionModal({ ...actionModal, deleteAccount: false });
          }}
          handleConfirm={() => {
            openInNewTab(process.env?.STRIPE_CUSTOMER_PORTAL);
            setActionModal({ ...actionModal, deleteAccount: false });
          }}
          title={"Delete Account"}
        >
          <span className="text-center">
            <p className="text-2xl">Caution</p>
            <p className="text-xl pt-2">
              You are going to delete all your users, proposals, grants,
              documents, etc.
            </p>
            <p className="font-bold pt-5">Are you sure you want to do that?</p>
          </span>
        </ActionModal>
      )}

      {actionModal?.linkAccount && (
        <ActionModal
          icon={
            account?.linkedToId ? <PiPlugsBold /> : <PiPlugsConnectedBold />
          }
          handleClose={() => {
            setActionModal({ ...actionModal, linkAccount: false });
          }}
          handleConfirm={() => {
            handleLinkAgency();
          }}
          title={account?.linkedToId ? `Unlink Agency` : `Link To Agency`}
        >
          {isLinking ? (
            <Loader size={"lg"} />
          ) : (
            <span className="text-center">
              {account?.linkedToId ? (
                <>
                  <p className="text-xl mt-10">
                    Looking to unlink your account from{" "}
                    {(account?.linkedTo as Account)?.name || "your"} agency?
                  </p>
                </>
              ) : (
                <>
                  <p className="text-xl">
                    Looking to link your account to an agency on {appName}?
                  </p>
                  <input
                    type="email"
                    className="mt-5 border border-gray-300 p-2 rounded w-full min-w-[300px]"
                    placeholder="Enter Agency Email"
                    onChange={(e) => {
                      agencyLink.current = e?.target.value;
                    }}
                  />
                </>
              )}
            </span>
          )}
        </ActionModal>
      )}

      {/* <form
        onSubmit={
          (e) => {
            e.preventDefault();
            handleContinue();
          }
        }> */}
      <div className="py-8 flex flex-col h-full">
        <div className=" mb-5 px-5 border-b justify-between flex pb-2">
          <h1 className="text-2xl font-bold">Add Organization Profile</h1>
          {account?.primaryContactId === user?.id && (
            <div className="flex children:mr-2">
              {/* <button
                onClick={() => setActionModal({ ...actionModal, deleteAccount: true })}
                className="px-5 py-2 rounded bg-red-300 hover:bg-red-500 text-white hover:text-white transition-colors duration-200 flex items-center"
              >
                <span className="mr-2 text-lg">
                  <CgDanger />
                </span>
                <span className="mr-2 -sm:hidden">Delete</span>
              </button> */}
              {/* {account?.type !== AccountType.AGENCY && (
                <button
                  onClick={() =>
                    setActionModal({ ...actionModal, linkAccount: true })
                  }
                  className="px-5 py-2 rounded bg-purple-200 hover:bg-purple-500 text-[#553C9A] hover:text-white transition-colors duration-200 flex items-center"
                >
                  <span className="mr-2 text-lg">
                    {account?.linkedToId ? (
                      <PiPlugsBold />
                    ) : (
                      <PiPlugsConnectedBold />
                    )}
                  </span>
                  <span className="mr-2 -sm:hidden">
                    {account?.linkedToId ? "UnLink" : "Link"} Agency
                  </span>
                </button>
              )} */}
            </div>
          )}
        </div>

        <div className="px-5">
          <Stepper
            steps={steps}
            step={step}
            setStep={setStep}
            onNext={handleChangePage}
          />
          {!account || fetchAccount?.isLoading ? (
            <div className="justify-center items-center">
              <Loader />
            </div>
          ) : (
            renderView(step)
          )}

          <div className="mt-24 mb-16 w-full text-sm flex items-center justify-center">
            <button
              className="w-32 mr-9 h-9 rounded flex justify-center items-center border font-semibold"
              onClick={handleBack}
              // type="submit"
            >
              {step === 1 ? (
                <>
                  <span className="border rounded-full mr-2">
                    <BiX />
                  </span>
                  <span>Cancel</span>
                </>
              ) : (
                <>
                  <ArrowBackIcon />
                  <span className="ml-2">Back</span>
                </>
              )}
            </button>

            {step <= steps.length - 1 && (
              <button
                className={`w-32 h-9 rounded font-semibold bg-[#e9d9fc] enabled:hover:bg-[#553C9A] enabled:hover:text-white cursor-pointer`}
                // disabled={!hasChanges?.orgProfile}
                onClick={handleContinue}
                // type="submit"
              >
                <span className="mr-2">Continue</span>
                {/* <Link to={{ pathname: '/organization', query: { page: 'overview' } }}>My Link</Link> */}
                <ArrowForwardIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      {/* </form> */}
    </>
  );
});
