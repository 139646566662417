export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "638af329-b4c6-4b16-a318-af7d14bef142",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Organization Information",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "0461990a-907e-428d-a2d3-274c25d3a32d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Legal Name of Organization:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "4c9156d7-4d0c-402a-b4a3-22848fada74c",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "774ddc39-9a2e-4c97-9b85-50a03c63fc8d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mailing Address:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "f44fb072-558e-440f-babe-feda7bbe7442",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "a8003b6a-e4b8-46cc-90c8-3a3d75abf916",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Phone:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "a6a8839d-e842-4437-bfe2-d5613fbe0089",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "6ce4c74c-1b42-4342-bb83-1ffa8711c3e6",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Website:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "7a170a12-5ff7-48e3-a0ca-72272ad4dbdc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "79417dc8-b0d2-412a-a214-1ed4c2e8db5b",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Year Established:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "df73c4f6-b188-4d50-ad92-b1767b06cbdc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "2bc8c862-b782-4acd-a100-7c63e9bddbf1",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Registered Charity Number:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "f1b50924-9258-478d-a1f4-af8ef83962fd",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "12db1462-dfda-4441-8ba4-a6018c584b75",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "# FT Employees:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5839355f-4c14-44b8-b76d-bed24afbb2dc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "dfb4ebab-e7a4-4988-a1bf-69d82c1c578d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "# PT Employees:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "fcb97574-96a1-4d12-a6ff-56c84c6046de",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "ef19f4b3-76a0-4688-9bf2-7c132df1193d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "#Volunteers:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "9b386862-a516-46d4-a0e5-272f1dfcd9d5",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "dccf169a-4840-47e6-9d9e-7fc4dc0bf08f",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Volunteer hours:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "13ad07a3-d97a-4cf9-a394-30cd4973cb17",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "c2366f47-d6cc-46a7-bb59-ff922e004170",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Primary Contact",
        },
      ],
    },
    {
      type: "bulletList",
      attrs: {
        tight: true,
      },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "69cc3991-6684-48a0-abc3-5064f16fabad",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Name",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "a82666bf-a3ae-4fe5-8a60-863c9a4d519c",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Email",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "cf086ee1-6bd6-41c4-9336-ee9da74bc6fd",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Phone",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "24e29672-1564-4dfa-9028-942f79c766a2",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "0941dcb8-a40b-4e09-b507-3baafca00f03",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Other Contacts",
        },
      ],
    },
    {
      type: "bulletList",
      attrs: {
        tight: true,
      },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "4ac1de77-59ee-40d7-aa18-733f4d8f2cb3",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Name",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "b6fee50f-a75f-49b4-911a-e8e2e6ca7134",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Email",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "579a17b1-f823-4ff5-8626-59df89be8aea",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Phone",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6ad9c18f-68a2-4dff-b298-b04b5a0d7dfc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "a655b342-fffa-488d-997c-03ba7064a25b",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mission:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "52edf74c-36b5-4732-a519-8306626bc816",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "97911fb1-b3f2-4be6-b5e0-f4068bf4ba90",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mandate:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b75154af-7f1e-43a2-bb08-5ac56fac84ad",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "5167d7e1-e37a-471c-8efc-29fdea7f6beb",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "History:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b7301e6b-7724-4e0f-9d54-19b05968796e",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5bc860a2-5060-4b92-8599-eb35b957168d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Provide an outline of your programs and services and how they are relevant to your community.",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "a7944e3d-18a7-483d-9548-a150bfdf954c",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Describe your organization and membership",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "5448b317-ef49-4103-8ed0-15e1fd78103f",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Questions",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d7888468-559e-473c-b8f0-17b133a3656d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Name",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "3e3dcacb-1da1-4913-a400-dbc7cea7cdea",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Description",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "cdc8670c-83ac-4902-93a4-6591f9ce5889",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Short Version",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "806065ac-f3ad-4082-aa37-d68bf8dacad4",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Long Version",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "20c551d3-4595-4dfa-bab0-b61dfd58375d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Need",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "55205b07-b83b-47ab-937b-9bd378b1b465",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Describe the overall need within your organization or initiative, how this grant will support your work, and how do you plan to use it? Please share the importance of this grant for the community you serve. How were the needs determined?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d7dfc766-f32b-436c-babb-537baa76a273",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "7e04f63e-5ad9-4980-acaa-6ed53c741290",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Goals, Objectives, Outputs, Outcomes",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5f3338c0-205d-4576-9a0f-6389ec1a41c7",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Goals ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "60dde46b-8b40-4449-9b7f-2877acf51c81",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(What you want to do)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "27ba31bb-83dc-4197-a603-b162bc060267",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "70eead6b-a9d3-47e1-b275-9ebc8e9240c0",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Objectives",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6551fde1-a228-43f3-8a15-67cf4c3f3472",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(How you will achieve goals) ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b7e7c59a-fccf-4432-8c19-4634a7dc5e73",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "916ae1a9-5630-4b02-a56c-82db3ff9c69d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Outputs",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "421e4c73-d84d-48c5-8cd8-7870db99ca50",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(What is produced)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "bbc42d39-5e88-46b9-acea-8d5e4daa6387",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b0166650-1064-4a18-8d7c-e9397d3d26b1",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Outcomes",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "de67ff27-2eb1-412b-bf8a-21a84f8c7867",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(The changes or benefits that will result from the program/project)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "32672f91-41da-41d2-8d16-e260c3258bf5",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "6b35d72b-f5ed-409b-9494-abb6319887c1",
        textAlign: "left",
        level: 2,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Reconciliation & DEI",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5371d87d-eba1-4470-aec9-13ae29250adc",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Does your project take action on reconciliation?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b948d53b-c205-446d-b6f3-a6b8cd7134e1",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1b65b295-b55f-4097-b0fb-b4975b53ce90",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: 'Reconciliation defined: "The commission believes reconciliation is about establishing and maintaining mutually respectful relationships between Indigenous and non-Indigenous people in this country. For this to happen, there must be awareness of the past, acknowledgment of the harm that has been done, atonement for the causes and action to change behavior." (Indigenous Peoples Atlas of Canada Reference, The Executive Summary of the TRC Report)',
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6df5910d-c1e3-4829-929c-80158aac107d",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "8ff4ad27-11fa-4354-b42f-c434d4ffd52c",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "How does your organization include the people that it serves in its decision-making processes?",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "aba8f69c-9087-4d65-b71a-b2ea5e8e8aa2",
        textAlign: "left",
        level: 2,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Activities/Timeline",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1eed78f9-4771-496b-8f44-560862a14bc7",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Project Start Date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "dc8ad9e7-3be6-419d-977c-57a95733c05c",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Project End Date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "e2a7ffdd-1b47-4ea2-932c-dd3324f6fa2e",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "8311722f-1690-4837-bc02-3bbc1c3c4a9c",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Timeline",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "9e805961-ae7d-4559-b517-2ebb1261682a",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1a0a5e71-ccde-46dc-bfd6-c82be84bde9e",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Description",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "20286386-0ebd-4f11-a2ac-4154c7d68449",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Start date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "57af6a7d-3551-4f07-a841-5a7ef9a28939",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity End date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "0e9e0959-06ef-407a-9f30-4b2b34dc21d7",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "3dcb175d-1ea8-4247-b0c7-1c6a90f921d2",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Evaluation & Impact",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "fff1e32f-9041-48a9-82b8-6e9f220f9bff",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Who will benefit from this project, directly and indirectly?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "ea76f860-9a88-4771-a590-41afb8c6760d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How will they benefit?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "e022f227-98f6-40f5-99e5-16da236e56e3",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How will you evaluate your project after you complete it?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "dd5ebb7a-6c38-46f4-839e-04b546324402",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "What are your criteria for success for the project, and how will you know you have met them?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5c02e1df-088b-4ce6-82f8-1cdad1808cf3",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How many people will your project impact?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "84050b4b-3b9e-44ae-b456-b5e67fc4710a",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "61a1d772-d21c-4c5b-b679-f2a1a36327fa",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Sustainability",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "a3708edb-4392-4729-aa04-fd0b64ed8391",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Is this grant for an ongoing program? If so, how do you plan to sustain it?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "da84e8f1-ecc5-456c-9f0a-89345d683715",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "e7dc30b8-d2f9-40bc-9087-fd584efe36aa",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "ed99c839-af34-4bcd-912f-425340d10b44",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Partnerships/Collaboration",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "53d06d2f-7a3a-482b-b108-479705e7b4cb",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Does your project involve other individuals or community organizations in the planning, execution and/or evaluation of the project?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "660fce7b-f97b-47a2-9f9f-b58069e1dede",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Are you working with other partners to carry out this project?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1cb08bb6-d904-49f4-954a-92758363015a",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Please describe your organization's major partnerships, collaborators, and/or stakeholders in your overall operation.",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d36fdc77-c851-4a4c-8ec3-fdd7e4ac9a91",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "6fdffedf-ac76-40dc-9cbd-3562207fc684",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "4024f989-7711-4d18-9970-0e0c5919f4a9",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Recognition",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "4c5dd29e-ab04-48a9-89a0-3882efc8461f",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "How do you plan to recognize your funders?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "7a05422f-f745-44c0-9c0e-8f2877e60582",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "d01106ae-3f58-4f09-af74-f04ab7e2c171",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "a5d2b0e1-5d75-404b-88c1-671fa1b5479b",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "27235ec1-137a-4a81-af87-d59fe9c8355e",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Budget",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "8c92e5a2-6841-43b8-9635-6b487b393152",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "table",
      content: [
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "f047cff7-1fd1-4bd7-9bb9-747e4f8a011f",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Expenses",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "6353b62d-36a7-4364-bab8-6a578511239f",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "($)",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "dc081510-59b1-4788-9398-4a14b6f239d6",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Notes",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7d2ac117-e82a-4c58-bb07-f4a370da6e01",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Salaries & Benefits",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "17ae4d67-97a9-4f9a-b267-0a52e934472e",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "2fe03c86-b4a9-41c1-8fbd-4f2e4e345a1c",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "09d5673d-286a-4b29-8a87-c29d9d72a3fa",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Contractors",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "a97c597d-8065-4b2d-937f-65a478977fc3",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "b1f63411-561e-4f13-8647-f852ab95c9bb",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "34254954-833f-4118-8d1d-a5981ce65014",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Materials & Equipment",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "fa8f7bfe-0c10-4224-9090-3303b0929182",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "15e0fdc0-d3a0-4e3f-8c5a-0603e95a7b3e",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "f6d3de7e-fd99-48cc-a3a7-d73c6c8b4457",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Administration",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "cebafa39-fd48-4830-99a9-fcdb6a49753a",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "cea7ecf3-3612-4369-9d7d-ddda608253a6",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "ff6ba13c-28ea-4e90-a546-c8092f037f63",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Marketing & Promotions",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "b386763c-1491-4fdb-b686-c7ae2e72db99",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "aa2044cc-bd50-45dc-89d0-a3dc40894b5b",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "8d223bac-57ba-4a19-af5d-27619d8cb234",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "5bf5add4-d2ab-4ee3-9d48-405b93c13daa",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7ef56f34-90c0-41e9-846e-5b085749db00",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "29ebb969-546a-4f2a-8c8a-22aa14c01ced",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "871eae7f-e815-48cb-ba46-c83b07b5b804",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "4fad49ff-cc88-4197-88c3-35a58fa8bfab",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "004a9d15-876f-462a-8e51-8816f204169b",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Total",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "32d5cbf8-10e6-4032-9d71-0da71c8119c2",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "c1cc3785-f8da-4855-9af5-7a7009a3b41d",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "89c71c10-cee9-4852-a132-ce62356cb59b",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
        {
          type: "hardBreak",
        },
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "table",
      content: [
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "01274d1e-5185-45af-871b-86c4cf55dabf",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Revenues",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7c8c2f95-44be-4948-aa7f-e3889f5a8823",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "($)",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "946cbdfd-8ce4-4597-b2b2-28d0d3591218",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Pending/Confirmed",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "df535e76-2510-4b7c-92fb-482236a4b0b4",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "75dc0818-fdb0-45b0-9a38-24999499a7df",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "51ffc210-9ae4-47c5-8cd7-9276136aa260",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e30d26c5-8485-461f-bbcc-96c4d5900131",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "68ef1cc0-4c9a-4830-b65a-b0aaf1264342",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e9ed39da-c106-4010-896d-ac4dadef8142",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "d7fb074a-e023-4caa-9bcb-d12f09f0fae9",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7ef6c3ff-ed04-41b0-95b2-1bf2ef6baa92",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e0e3feba-cddf-4ac6-b91e-358f420b3612",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "3d8154bc-2454-4dea-9477-67eff342a312",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "8b1bb9e4-dd7f-491b-b6bd-8c52046354dc",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "6bbb7271-17b4-4741-bbff-f15782f5b948",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "2587a900-2846-49a0-be23-998aec6535af",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "da72ecc4-9aa3-4dad-b09c-6e1c81884d65",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "fa7a99d5-5c9f-4bbc-91bd-bbb077dd3841",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "807daec6-c260-4432-bff2-b9987a3f7a72",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Is this grant request to support a bigger initiative or project? If so, what is the total cost of the initiative, and who else has contributed?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "12d77472-1eff-46b5-ac36-8b14a4965540",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Grant Dollar Amount Requested ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6e3303e5-7ef7-4177-abde-2ba3374c3340",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Identify the need for the funding and the impact the funding will have on your organization.",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "0cfe547f-2c9a-4e35-bee3-9dcb69a75240",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "cb60843e-fc0b-497a-b11a-d022189afc3f",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Other sources of funding",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "e163f762-fec2-4116-8aa9-e65c177143bf",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Please describe what changes you will make to your project if you are awarded a smaller grant. How will the proposal be adapted? What impact would partial funding have on your proposal's Goals?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "07971f73-4a89-4daf-a380-c0ba719a594c",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "4986f9e6-f080-48e2-9b69-decb70341d33",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
  ],
};
