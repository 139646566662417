import { useContext, useEffect, useState, useRef } from "react";
import { useCommonQueries, useDisplayToast, useTopScroll } from "@utils/hooks";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import UploadView from "./Upload";
import DirectoryView from "./View";
import VideoUpdate from "@components/VideoUpdate";

const Documents = () => {
  const { scrollToTop } = useTopScroll();
  const { files } = useContext(ContextStore);

  // const { successToast, errorToast } = useDisplayToast();
  const [isViewOpen, setViewOpen] = useState(files?.length > 0);

  const { fetchFiles } = useCommonQueries((data) =>
    setViewOpen(!!data?.length)
  );
  const { isLoading: isLoadingFiles } = fetchFiles;

  return (
    <>
      <VideoUpdate videoKeys={["Files"]} />
      {isLoadingFiles ? (
        <div className="flex justify-center w-full h-full">
          <Loader size={"xl"} />
        </div>
      ) : isViewOpen ? (
        <DirectoryView
          setViewOpen={setViewOpen}
          refetchFiles={fetchFiles.refetch}
          isLoadingFiles={isLoadingFiles}
        />
      ) : (
        <UploadView setViewOpen={setViewOpen} refetchFiles={fetchFiles.refetch} />
      )}
    </>
  );
};

export default Documents;
