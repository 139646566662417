import OverlayModal from "@/components/modal/OverlayModal";
import Video from "@components/Video";
import { useConfetti } from "@utils/hooks";
import { useEffect } from "react";

interface Props {
  onClose: () => void;
  src: string;
  disableConfetti?: boolean;
  title: string;
  text?: string;
}

const VideoModal = ({ onClose, src, disableConfetti, title, text }: Props) => {
  const { showConfetti } = useConfetti();

  useEffect(() => {
    if (!disableConfetti) {
      showConfetti();
    }
  }, []);

  return (
    <div className="relative">
      <OverlayModal title={title} onClose={onClose}>
        <Video src={src} text={text} />
      </OverlayModal>
    </div>
  );
};

export default VideoModal;
