import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import BadgeInput from "@components/BadgeInput";
import CustomDropdown from "@components/CustomDropdown";
import { SyntheticEvent, useEffect, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { GrantFormProps } from "../interfaces";

const FormTab = (props: GrantFormProps) => {
  const { grant, setGrant, setHasChanges } = props;
  const [deadlines, setDeadlines] = useState<string[]>(
    grant?.deadlines || [""]
  );

  const handleHasChanges = (cb?: () => void) => {
    cb && cb();
    setHasChanges(true);
  };

  const handleGrantChanges = (e: React.ChangeEvent<any>) => {
    const { id, value } = e.target;
    setGrant({ ...grant, [id]: value });
    handleHasChanges();
  };

  useEffect(() => {
    handleHasChanges(() =>
      setGrant({
        ...grant,
        deadlines: [...deadlines].sort(),
      })
    );
  }, [deadlines]);

  return (
    <div className="px-5">
      <div className="text-sm flex flex-col md:grid md:grid-cols-2 md:gap-x-10 gap-y-7">
        <div className="grid h-max">
          <label htmlFor="name">Grant Name</label>
          <input
            className="h-10 px-3 outline-none mt-1 rounded border"
            type="text"
            id="name"
            value={grant?.name || ""}
            onChange={handleGrantChanges}
          />
        </div>

        <div className="grid h-max">
          <span>Application Type</span>
          {/* <CustomDropdown
            options={["Option 1", "Option 2", "Option 3"]}
            value={grant?.applicationType || ""}
            id="applicationType"
            onChange={handleGrantChanges}
          /> */}
          <BadgeInput
            id="applicationType"
            values={grant?.applicationType}
            onChange={handleGrantChanges}
          />
        </div>

        <div>
          <div className="grid h-max">
            <label htmlFor="amount">Grant Amount</label>
            <div className="flex">
              <div className="w-[70px] flex-shrink-0">
                <CustomDropdown
                  options={["USD", "GBP", "CHF", "AUD", "CAD"]}
                  id="currency"
                  value={"USD"}
                  disabled={true}
                />
              </div>
              <div className="flex gap-x-1">
                {grant?.amount?.min ? (
                  <input
                    className="h-10 -sm:w-full w-half px-3 outline-none rounded border pr-1"
                    type="number"
                    id="amount"
                    placeholder="min"
                    value={grant?.amount?.min || 1}
                    min={1}
                    onChange={(e) =>
                      handleHasChanges(() =>
                        setGrant({
                          ...grant,
                          amount: { ...grant?.amount, min: e.target.value },
                        })
                      )
                    }
                  />
                ) : null}
                <input
                  className={`h-10 ${
                    grant?.amount?.min ? "w-half" : ""
                  } -sm:w-full w-full px-3 outline-none rounded border`}
                  type="number"
                  id="amount"
                  placeholder="max"
                  value={grant?.amount?.max || 1}
                  min={1}
                  onChange={(e) =>
                    handleHasChanges(() =>
                      setGrant({
                        ...grant,
                        amount: { ...grant?.amount, max: e.target.value },
                      })
                    )
                  }
                />
              </div>
            </div>

            <div className="mt-2">
              <span className="mr-3">Range?</span>
              <Switch
                id="amount-range"
                isChecked={!!grant?.amount?.min}
                colorScheme="purple"
                onChange={() => {
                  // console.log('toggled')
                  handleHasChanges(() =>
                    setGrant({
                      ...grant,
                      amount: {
                        ...grant?.amount,
                        min: !grant?.amount?.min ? "1" : undefined,
                      },
                    })
                  );
                }}
              />
            </div>
          </div>

          <div className="grid pt-4 h-max">
            <label htmlFor="">Website</label>
            <input
              className="h-10 px-3 outline-none mt-1 rounded border"
              type="text"
              id="websiteUrl"
              value={grant?.websiteUrl || ""}
              onChange={handleGrantChanges}
            />
          </div>
        </div>

        <div className="grid h-max">
          <label htmlFor="deadline">Deadlines</label>
          {deadlines?.map((deadline, idx) => (
            <div key={idx} className={`flex`}>
              <button
                type="button"
                className="text-lg mt-1 w-max mr-2 text-[#B784F4]"
                onClick={() => {
                  handleHasChanges(() =>
                    setDeadlines(deadlines?.filter((_, i) => i !== idx))
                  );
                }}
              >
                <FiMinus />
              </button>
              <input
                key={idx}
                className="w-full h-10 px-3 placeholder:text-neutral-200 outline-none mt-1 rounded border"
                type="date"
                id={`deadline-${idx}`}
                value={deadline || ""}
                onChange={(e) => {
                  // setDeadlines([...deadlines.slice(0, idx), e.target.value, ...deadlines.slice(idx + 1)])
                  const copy = [...deadlines];
                  copy[idx] = e.target.value;
                  handleHasChanges(() => setDeadlines([...copy]));
                }}
              />
            </div>
          ))}

          <div className="flex">
            <button
              type="button"
              className="text-lg mt-1 w-max text-purple-700"
              onClick={(e) => {
                // console.log("clicked");
                handleHasChanges(() => setDeadlines([...deadlines, ""]));
              }}
            >
              <FiPlus />
            </button>
          </div>
        </div>

        <div className="grid h-max">
          <label htmlFor="fieldsOfWork">Field(s) of Work</label>
          <BadgeInput
            id="fieldsOfWork"
            values={grant?.fieldsOfWork}
            onChange={handleGrantChanges}
          />
          {/* <input
            className="h-10 px-3 outline-none mt-1 rounded border"
            type="text"
            id="fieldsOfWork"
            value={grant?.fieldsOfWork || ''}
            onChange={(e) =>
              setGrant({ ...grant, fieldsOfWork: e.target.value })
            }
          /> */}
        </div>

        <div className="grid h-max">
          <label htmlFor="fundingUses">Allowed Funding Use(s)</label>
          <BadgeInput
            id="fundingUses"
            values={grant?.fundingUses}
            onChange={handleGrantChanges}
          />
          {/* <input
            className="h-10 px-3 outline-none mt-1 rounded border"
            type="text"
            id="fundingUses"
            value={grant?.fundingUses}
            onChange={(e) =>
              setGrant({ ...grant, fundingUses: e.target.value })
            }
          /> */}
        </div>

        <div className="grid h-max">
          <span>Status</span>
          <CustomDropdown
            options={["Open", "Closed"]}
            border={true}
            id="status"
            value={grant?.status || ""}
            onChange={(e) =>
              handleGrantChanges({
                ...e,
                target: { ...e.target, value: e.target.value.toUpperCase() },
              })
            }
          />
        </div>

        <div className="grid h-max">
          <span>Location</span>
          <input
            className="h-10 px-3 outline-none mt-1 rounded border"
            type="text"
            value={grant?.location || ""}
            id="location"
            onChange={handleGrantChanges}
          />
        </div>

        <div className="grid col-span-2 h-max">
          <label htmlFor="overview">Grant Overview</label>
          <textarea
            className="h-40 px-3 py-2 outline-none mt-1 rounded border resize-none"
            id="overview"
            placeholder="Enter grant overview"
            // rows={3}
            value={grant?.overview || ""}
            onKeyDown={(e) => {
              //support new line in textarea
              if (e.key === "Enter") {
                e.preventDefault();
                setGrant({
                  ...grant,
                  overview: (grant?.overview || "") + "\n",
                });
              }
            }}
            onChange={handleGrantChanges}
          />
        </div>

        <div className="grid h-max">
          <label htmlFor="eligibility">Eligibility</label>
          <textarea
            className="h-28 mt-1 px-3 py-2 resize-none rounded border outline-none"
            id="eligibility"
            placeholder="Enter eligibility information"
            value={grant?.eligibility}
            onKeyDown={(e) => {
              //support new line in textarea
              if (e.key === "Enter") {
                e.preventDefault();
                setGrant({
                  ...grant,
                  eligibility: (grant?.eligibility || "") + "\n",
                });
              }
            }}
            onChange={handleGrantChanges}
          />
        </div>

        <div className="grid h-max">
          <label htmlFor="ineligibility">Ineligibility</label>
          <textarea
            className="h-28 mt-1 px-3 py-2 resize-none rounded border outline-none"
            id="ineligibility"
            placeholder="Enter ineligibility information"
            value={grant?.ineligibility}
            onKeyDown={(e) => {
              //support new line in textarea
              if (e.key === "Enter") {
                e.preventDefault();
                setGrant({
                  ...grant,
                  ineligibility: (grant?.ineligibility || "") + "\n",
                });
              }
            }}
            onChange={handleGrantChanges}
          />
        </div>

        <Accordion defaultIndex={[]} allowMultiple className="col-span-2 ">
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <h1 className="col-span-2 font-bold flex-1 text-left ">
                      FUNDER DETAILS (optional)
                    </h1>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                {/* <AccordionPanel
                  pb={4}
                  className="text-sm flex flex-col md:grid md:grid-cols-2 gap-x-10 gap-y-7"
                >
                  <FunderForm {...props} />
                </AccordionPanel> */}
              </>
            )}
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

const FunderForm = (props: GrantFormProps) => {
  const { grant: grant, setGrant: setGrant, setHasChanges } = props;

  /*
  name        String
  description String?
  ein         String?
  websiteUrl  String?
  phone       String?
  email       String?
  address     String?
  city        String?
  state       String?
  zip         String?
  location    String?
  imageUrl    String?
  type        String?
 
  contact 
  */

  return (
    <>
      <div className="grid h-max">
        <label htmlFor="funder.name">Name</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="text"
          id="funder.name"
          value={grant?.funder?.name}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  name: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max">
        <label htmlFor="funder.phone">Phone Number</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="tel"
          id="funder.phone"
          value={grant.funder?.phone}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  phone: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max">
        <label htmlFor="funder.email">Email</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="email"
          id="funder.email"
          value={grant.funder?.email}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  email: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max">
        <label htmlFor="funder.websiteUrl">Website</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="url"
          id="funder.websiteUrl"
          value={grant.funder?.websiteUrl}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  websiteUrl: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max">
        <label htmlFor="funder.ein">EIN</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="number"
          id="funder.ein"
          value={grant?.funder?.ein}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  ein: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max">
        <label htmlFor="funder.location">Location</label>
        <input
          className="h-10 px-3 outline-none mt-1 rounded border"
          type="text"
          id="funder.location"
          value={grant.funder?.location}
          onChange={(e) =>
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  location: e.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="grid h-max col-span-2">
        <label htmlFor="funder.description">Description</label>
        <textarea
          className="h-28 mt-1 px-3 py-2 resize-none rounded border outline-none"
          id="funder.description"
          placeholder="Enter description information"
          value={grant.funder?.description}
          onKeyDown={(e) => {
            //support new line in textarea
            if (e.key === "Enter") {
              e.preventDefault();
              setGrant({
                ...grant,
                funder: {
                  create: {
                    ...grant?.funder?.create,
                    description:
                      (grant?.funder?.create.description || "") + "\n",
                  },
                },
              });
            }
          }}
          onChange={(e) => {
            setGrant({
              ...grant,
              funder: {
                create: {
                  ...grant?.funder?.create,
                  description: e.target.value,
                },
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default FormTab;
