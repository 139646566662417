import { useContext, useEffect, useRef, useState } from "react";
import CalendarIcon from "@assets/calendar-icon.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import moment from "moment";
import { Event, MenuAction } from "@utils/interface";
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";
import { useOnClickOutside } from "usehooks-ts";
import { isMobile } from "react-device-detect";
import { ContextStore } from "@components/utils/Context";
import { currentMonthStr, getYearMonthStr } from "./index";
import { MdClose } from "react-icons/md";
import MonthToggle from "./MonthToggle";

interface NavProps {
  showNav: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
  months: string[];
  currentPeriod: { year: number; month: number };
  setCurrentPeriod: React.Dispatch<
    React.SetStateAction<{ year: number; month: number }>
  >;
  handlePopUp: (event: Event) => void;
}

const Nav = ({
  showNav,
  setShowNav,
  months,
  currentPeriod,
  setCurrentPeriod,
  handlePopUp,
}: NavProps) => {
  const { month: currentMonth, year: currentYear } = currentPeriod;
  const { events } = useContext(ContextStore);

  // console.log("events", events);
  const upcomingEvents =
    events?.filter((x) => moment(x?.start).isSameOrAfter(moment())) || [];
  // .map((x) => ({ ...x, colors: { bg: "#A6F4C5", main: "#32D583" } }));
  // const nextMonthEvents = Object.values(events).flat()?.filter((x) =>
  //   moment(x?.start).isBetween(
  //     moment().add(1, "m").startOf("month"),
  //     moment().add(1, "m").endOf("month")
  //   )
  // );
  const [showAll, setShowAll] = useState(upcomingEvents?.length > 5);

  const renderMonthEvent = (event: Event, idx: number) => {
    // console.log("renderMonthEvent", event);
    return (
      <li
        key={idx}
        className=" my-3 flex text-sm cursor-pointer"
        onClick={() => handlePopUp(event)}
      >
        <div
          className={`mt-1 w-2 h-2 rounded-full flex-shrink-0`}
          style={{ backgroundColor: event?.colors?.main }}
        />
        <p className="ml-1 mr-2 w-full" style={{ color: event?.colors?.main }}>
          {event?.title}
        </p>
        <p>{moment(event?.start?.split("T")[0]).format("MM/DD")}</p>
      </li>
    );
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => isMobile && setShowNav(false));

  return (
    <div className="pt-1" ref={ref}>
      <button
        // className={` px-4 -sm:mx-2 mx-3 h-10 -md:absolute left-0 font-bold rounded ${showNav
        //   ? "bg-[#6a54b1] text-white hover:bg-[#eee8f8] hover:text-[#6a54b1]"
        //   : "bg-[#eee8f8] text-[#6a54b1] hover:bg-[#6a54b1] hover:text-white"
        //   }`}
        className={`w-11 flex-center -sm:mx-2 mx-3 h-10 -md:absolute left-0 font-bold rounded bg-[#eee8f8] text-[#6a54b1] hover:bg-[#6a54b1] hover:text-white`}
        onClick={() => setShowNav(!showNav)}
      >
        {!showNav ? <RxHamburgerMenu /> : <MdClose className="text-[22px]" />}
      </button>

      {showNav && (
        <div
          className={`w-[220px] h-full pt-5 pb-6 mt-1 ${
            showNav ? "-md:absolute z-10 left-0 -md:top-11" : "-md:hidden"
          } bg-white border-t flex flex-col flex-shrink-0`}
        >
          <div className="px-3">
            <MonthToggle
              currentPeriod={currentPeriod}
              setCurrentPeriod={setCurrentPeriod}
            />

            <div className=" mt-2 grid grid-cols-3 ">
              {months?.map((month, index) => {
                return (
                  <div
                    key={index}
                    onClick={() =>
                      setCurrentPeriod({ ...currentPeriod, month: index + 1 })
                    }
                    className={` ${
                      currentMonth === index + 1 && "bg-[#6B46C1] text-white"
                    } hover:bg-[#6B46C1] hover:text-white rounded py-2 text-center cursor-pointer`}
                  >
                    {month?.substr(0, 3)}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-12 h-full overflow-hidden flex flex-col">
            <div className="flex px-2 pb-2">
              <img src={CalendarIcon} alt="Calendar Icon" />
              <span className="ml-2 text-sm">Upcoming Events</span>
            </div>
            {!upcomingEvents?.length && (
              <div className="px-2">
                <p className="text-xs">No Events</p>
              </div>
            )}
            {upcomingEvents?.length > 5 && (
              <div className="px-2">
                <button
                  className="text-xs text-[#6B46C1]"
                  onClick={() => setShowAll(!showAll)}
                >
                  {!upcomingEvents?.length
                    ? "No Events"
                    : showAll
                    ? "Hide"
                    : "Show All"}
                </button>
              </div>
            )}

            <ul className=" pb-10 px-2 h-full overflow-y-auto custom-scrollbar">
              {upcomingEvents?.slice(0, 5).map(renderMonthEvent)}
              {showAll &&
                upcomingEvents
                  ?.slice(5, upcomingEvents.length)
                  .map(renderMonthEvent)}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
