import {
  Admin,
  CustomRoutes,
  EditGuesser,
  ListGuesser,
  Resource,
  ShowGuesser,
} from "react-admin";
// import { StripePricing } from "./components/PricingTable";
import { Route } from "react-router-dom";
// import { Layout } from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
// import jsonServerProvider2 from "./providers/data/json";
// import jsonServerProvider from "ra-data-json-server";
import Login from "@pages/Login";
import { httpClient } from "./providers/client";
import {
  addRefreshAuthToDataProvider,
  addRefreshAuthToAuthProvider,
  useAuthState,
} from "react-admin";
import { refreshAuth } from "./providers/auth/refreshAuth";
// import { jwtAuthProvider } from "./providers/auth/jwtAuthProvider";
import { AppLayout, EmptyAppLayout, emptyTheme } from "./AdminLayout";
// import Signup from "@pages/Signup";
// import { CreateListing, CreateListing2 } from "@pages/crud/create-listing";
// import { CreateUser } from "@pages/crud/create-user";
// import { Listings } from "@pages/crud/list-listings";
// import Playground from "./playground";
// import Chat from "@pages/gpt/Chat";
// import { withChakra } from "./components/utils/ChakraWrapper";
import { dataProvider } from "./providers/data";
import { authProvider } from "./providers/auth/authProvider";
import AppShell from "./AppShell";
import OrgProfile from "@pages/organization/OrgProfile";
// import GrantTracker from "@pages/GrantTracker";
import Home from "@pages/Home";
import Dashboard from "@pages/Dashboard";
import UserProfile from "@pages/UserProfile";
import Files from "@pages/file/Files";
import GrantBoard from "@pages/board/GrantBoard";
import Calendar from "@pages/calendar/Calendar";
// import Activity from "@pages/Activity";
import { Share, Grant, Document } from "@pages/share";
import { includesString } from "@utils/index";
import Conversations from "@/pages/Conversations";
import Folders from "@pages/folder/Folders";
import Referral from "@pages/share/Referral";
import Preferences from "@pages/Preferences";
import NewAccount from "@pages/NewAccount";
import ChakraWrapper from "@components/utils/ChakraWrapper";
import Recommendations from "@pages/recs/Recommendations";
import { DraftDocument, ListDocuments } from "@pages/document";
// import SearchPage from "@pages/browser/SearchPage";
// import { DraftView, EditView } from "@pages/document";
// import ReactDraft from "@playground/editors/wysiwyg";
// import { RichTextBlock as Editor } from "@playground/editors/slate";

const MyLayout = (props: any) => {
  return (
    <>
      <AppLayout {...props} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const isPublicRoute = includesString(
  ["/share/", "/signup"],
  window.location.pathname
);

const App = () => {
  return (
    <Admin
      dataProvider={addRefreshAuthToDataProvider(dataProvider, refreshAuth)}
      authProvider={addRefreshAuthToAuthProvider(authProvider, refreshAuth)}
      // dataProvider={dataProvider}
      // authProvider={jwtAuthProvider}
      layout={EmptyAppLayout}
      // layout={MyLayout}
      loginPage={Login}
      requireAuth={isPublicRoute == true ? false : true}
      theme={emptyTheme}
    >
      {/* <Resource name="books" list={ListGuesser} /> */}
      <CustomRoutes noLayout={true}>
        <Route element={<AppShell />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/user" element={<UserProfile />} />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/organization" element={<OrgProfile />} />
          <Route path="/board" element={<GrantBoard />} />
          <Route path="/files" element={<Files />} />
          <Route path="/documents" element={<ListDocuments />} />
          <Route path="/documents/:id" element={<DraftDocument />} />
          <Route path="/proposals" element={<Folders />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/chats" element={<Conversations />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/projects" element={<Home />} />
          <Route path="/funders" element={<Home />} />
          <Route path="/reports" element={<Home />} />
          {/* 
          <Route path="/activity" element={<Activity />} /> 
          <Route path="/chat" element={<Chat />} /> 
          <Route path="/signup" element={<Signup />} />
          <Route path="/pricing" element={<StripePricingTable />} />
          <Route path="/playground" element={<Playground />} /> 
          */}
        </Route>
        <Route path="/share" element={<Share />}>
          <Route path="grant/:id" element={<Grant />} />
          <Route path="document/:id" element={<Document />} />
          <Route path="referral/:id" element={<Referral />} />
        </Route>
        <Route path="/signups" element={<NewAccount />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
