import { ChakraProvider } from "@chakra-ui/react";
import { ObjectProps } from "@utils/interface";
import { FC, PropsWithChildren } from "react";

// https://stackoverflow.com/a/75050378

export default ({ children }: ObjectProps) => {
  return <ChakraProvider>{children}</ChakraProvider>;
};

export interface ComponentProps extends ObjectProps {
  children?: React.ReactNode;
}

// FIX: Element type is invalid: expected a string (for built-in components)
// or a class/function (for composite components) but got: object.
export const withChakra =
  (Component: any, children?: React.ReactNode | React.ReactNode[]) => () => {
    return (
      <ChakraProvider>
        {/* <Component /> */}
        <Component>{children && <>{children}</>}</Component>
        {/* {Component(props)} */}
      </ChakraProvider>
    );
  };

// export const withChakra2 = <T,>(Component:any) => (props:T): React.ReactElement<T> => {
//   return () => {
//     return (
//       <ChakraProvider>
//         <Component  {...props}/>
//       </ChakraProvider>
//     );
//   };
// }

// // Props
// interface Props<T> {
//   a: T;
// }

// // Component
// export const MyComponent: <T>(p: PropsWithChildren<Props<T>>) => React.ReactElement = props => {
//   return <div {...props}>Hello Typescript</div>;
// };
