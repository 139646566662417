import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "./Admin";
import Chat from "./components/gpt/Chat";
import { StripePricingTable } from "@components/PricingTable";
import { ChakraProvider } from "@chakra-ui/react";
import Uploader from "./components/Uploader";
import Sidebar2 from "./AppShell";
import Playground from "./playground";
import { DevOnlyRoute, ProtectedRoute } from "@components/utils/ProtectedRoute";
import { useLocalStorage } from "usehooks-ts";
import { useEffect } from "react";
import Verify from "@pages/Verify";
import Novel from "@playground/editors/novel-1";
// import PlateEditor from "@playground/editors/plate";
import "./App.css";
import SearchPage from "@pages/browser/SearchPage";
// import Signup from "@pages/Signup";
// import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
  const query = window.location.search;
  const [_, setQueryParams] = useLocalStorage("query", "");

  // const queryClient = new QueryClient();

  useEffect(() => {
    if (query) {
      setQueryParams(query);
    }
  }, [query]);

  return (
    <BrowserRouter>
      <ChakraProvider>
        <Routes>
          <Route path="*" element={<Admin />} />
          <Route path="/confirm" element={<Verify />} />
          <Route path="/x/*" element={<DevOnlyRoute />}>
            <Route path="pricing" element={<StripePricingTable />} />
            <Route path="ai" element={<Chat />} />
            <Route path="upload" element={<Uploader />} />
            <Route path="layout" element={<Sidebar2 />} />
            <Route path="playground" element={<Playground />} />
            <Route path="web" element={<SearchPage />} />
            <Route
              path="novel"
              element={
                <Novel
                // document={{
                //   userName: "tester",
                //   accountId: "acct1",
                //   documentId: "doc1",
                // }}
                />
              }
            />
          </Route>
          {/* <Route element={<ProtectedRoute />}>
            </Route> */}
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;
