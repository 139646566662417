import { Dict, Entity, Snippet, ellipsis } from "@/utils";
import { useCommonQueries, useDel, usePost, useStoreEntity, useToggleBubbleMenu } from "@/utils/hooks";
import { Editor } from "@tiptap/core";
import { Check, Trash } from "lucide-react";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { BsBookmarkStarFill, BsCopy } from "react-icons/bs";
import { MdContentPaste } from "react-icons/md";
import { useOnClickOutside } from "usehooks-ts";
import { toast } from 'sonner';
import { ContextStore } from "@/components/utils/Context";
import ModalSearch from "@/components/modal/ModalSearch";
import Loader from "@/components/utils/Loader";
import { startCase } from 'lodash';
import { BiTrash } from "react-icons/bi";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { getFormattedSelection, getHTMLContentBetween, getStringFromHTML } from "../../helper";

interface SnippetSelectorProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const SnippetSelector: FC<SnippetSelectorProps> = ({ editor, isOpen, setIsOpen, }) => {
  const { snippets, setActionModal } = useContext(ContextStore);
  const { fetchSnippets } = useCommonQueries();
  const inputRef = useRef<HTMLInputElement>(null);
  const [createOne, { isLoading: isCreating }] = usePost<Dict>();
  const [deleteOne, { isLoading: isDeleting }] = useDel<Dict>();
  // const [selectedItem, setSelectedItem] = useState<Snippet>();

  // Autofocus on input by default
  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  });

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  // ToggleBubbleMenu on snippet insert
  const { handleToggleBubble } = useToggleBubbleMenu(editor);

  const handleCreateSnippet = (content: string, title?: string) => {
    createOne(
      `${Entity.snippets}`,
      {
        data: { content, title, },
      },
      {
        onSuccess(data, variables, context) {
          toast.success('Snippet saved');
          // update snippet list
          fetchSnippets.refetch();
          setIsOpen(false);
        },
        onError(error, variables, context) {
          toast.error(error?.body?.message);
        },
      }
    );
  }

  const handleDeleteSnippet = (snippet: Snippet) => {
    deleteOne(
      `${Entity.snippets}`,
      { id: snippet.id },
      {
        onSuccess(data, variables, context) {
          toast.success('Snippet deleted');
          setIsOpen(false);
        },
        onError(error, variables, context) {
          toast.error(error?.body?.message);
        },
      })
  }

  const handleCopySnippet = (snippet: Snippet) => {
    navigator.clipboard.writeText(getStringFromHTML(snippet.content))
    setIsOpen(false);
  }

  const handleInsertSnippet = (snippet: Snippet) => {
    handleToggleBubble(false);
    editor.chain().focus().insertContentAt(editor.state.selection.from, snippet.content).run();
    setIsOpen(false);
    setTimeout(() => {
      handleToggleBubble(true);
    }, 1000 * 1);
  }

  // const selection = editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to, " ");
  const selection = getFormattedSelection(editor);
  const isLoading = isCreating || isDeleting;
  // console.log('selection', selection);

  return (
    <div className="flex" ref={ref}>
      <button
        type="button"
        className={`flex h-full items-center space-x-2 px-3 py-1.5 text-sm font-medium hover:bg-stone-100 active:bg-stone-200 ${isOpen ? 'text-purple-500' : 'text-stone-600'}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <BsBookmarkStarFill />
        <p className={'text-sm'}>
          Snippets
        </p>
      </button>
      {isOpen && (
        !!selection ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const input = e.currentTarget[0] as HTMLInputElement;
              const snippetName = input.value;
              // submit snippet
              handleCreateSnippet(selection, snippetName);
              // navigator.clipboard.writeText(formattedSelection);
              // editor.chain().focus().insertContentAt(editor.state.selection.to + 1, formattedSelection).run();
            }}
            className="fixed top-full z-[99999] mt-1 flex w-60 overflow-hidden rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1">
            <input
              ref={inputRef}
              type="text"
              placeholder={`Snippet title...`}
              className="flex-1 bg-white p-1 text-sm outline-none text-slate-500"
              defaultValue={""}
            />
            {isLoading ?
              <Loader size={'sm'} /> :
              <button
                // type="submit"
                className="flex items-center rounded-sm p-1 text-stone-600 transition-all hover:bg-stone-100">
                <Check className="h-4 w-4" />
              </button>
            }
          </form>
        ) : (
          <ModalSearch
            position={"top-[3rem]"}
            items={snippets}
            onDone={() =>
              setIsOpen(false)
            }
            renderItem={(item) => (
              <div
                key={item.id}
                className="flex items-center border-stone-200">
                <div className="flex-col">
                  <p className="text-xs font-bold text-slate-600">
                    {startCase(item.title)}
                  </p>
                  <p className="text-xs text-slate-400">
                    {ellipsis(getStringFromHTML(item.content), 60)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <PiMagnifyingGlassBold className="min-w-5 hover:text-purple-500 mb-2" size={15}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActionModal(prev => ({ ...prev, openSnippet: true, snippetContent: getStringFromHTML(item?.content) }))
                      handleToggleBubble(false);
                    }} />
                  <MdContentPaste className="min-w-5 hover:text-purple-500 mb-2" size={15}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopySnippet(item as Snippet)
                    }} />
                  <BiTrash className="min-w-5  hover:text-red-500" size={15}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSnippet(item as Snippet);
                    }} />
                </div>
                {/* <button
                  type="button"
                  className="flex items-center justify-center rounded-full p-1 bg-stone-100 text-stone-600 transition-all hover:bg-stone-200"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    editor.chain().focus().insertContentAt(editor.state.selection.from, item.content).run();
                    setIsOpen(false);
                  }}>
                  <BsCopy />
                </button> */}
              </div>
            )}
            onClick={(item) => { handleInsertSnippet(item as Snippet); }}
          />
        ))}
    </div>
  );
};
