import { useEffect } from "react";

const useOutsideClickContains = (
  mainRef: React.RefObject<HTMLElement>,
  handler: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const domNode = mainRef?.current;
      // ?.parentNode; // or ref?.current?.node;
      // console.log("domNode", domNode);
      // if (event.target instanceof Node) {
      const contains = domNode?.contains(event.target as Node);
      // console.log("contains", contains);
      if (mainRef?.current && !contains) {
        // console.log("You clicked outside of me!");
        // console.log("event.target", event.target);
        // console.log("mainRef.current", mainRef.current);
        return handler();
      }
      // }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);
};

export default useOutsideClickContains;
