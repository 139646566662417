import { IoCloseOutline } from "react-icons/io5";
import {
  BsFileEarmark,
  BsFileEarmarkText,
  BsPencilSquare,
  BsPlusCircleDotted,
} from "react-icons/bs";
import { FiArrowLeft, FiBookOpen } from "react-icons/fi";
import { useContext, useState } from "react";
import { ContextStore } from "@components/utils/Context";
import {
  ActionType,
  LinkType,
  Dict,
  Grant,
  IResponse,
  BoardItem,
  Entity,
} from "@utils/interface";
import { FormTab } from "./Tabs";
import AppButton from "@components/AppButton";
import { useDisplayToast, usePut } from "@utils/hooks";
import { omit } from "lodash";
import Overlay from "@components/Overlay";
import { mapNullToUndefined, tailwindHelpers } from "@utils/index";

const EditGrant = () => {
  const { successToast, errorToast } = useDisplayToast();
  const {
    account,
    grants,
    setGrants,
    appModal,
    setAppModal,
    hasChanges,
    setHasChanges,
    boardItems,
    setBoardItems,
  } = useContext(ContextStore);

  const _grant =
    boardItems?.find((b) => b?.grantId === appModal?.grantId)?.additionalData
      ?.grant || grants?.find((g) => g.id === appModal?.grantId);

  const [grant, setGrant] = useState<Grant>(_grant);
  const [updateGrant, { isLoading: isUpdating }] = usePut<Grant>();
  const [updateBoardItem, { isLoading: isSaving }] = usePut<BoardItem>();
  const boardItem = boardItems.find((b) => b?.grantId === _grant?.id);

  // console.log("EditGrant", grant);

  const handleSave = () => {
    if (!hasChanges?.editGrant) return;
    if (grant?.contributorId === account?.id) {
      updateGrant(
        `grants`,
        {
          id: grant?.id,
          data: {
            ...mapNullToUndefined(omit(grant, "id")),
            boardItemId: boardItem?.id,
          },
        },
        {
          onSuccess(data, variables, context) {
            // console.log("data", data);
            successToast("Grant Updated");
            setGrants(grants?.map((g) => (g?.id === grant?.id ? data : g)));
            handleClose();
          },
          onError(error, variables, context) {
            // console.log("error", JSON.stringify(error));
            errorToast(error?.body?.message);
          },
        }
      );
    } else {
      // store changes locally on boardItem
      updateBoardItem(
        Entity.boardItems,
        {
          id: boardItem?.id,
          data: {
            additionalData: {
              ...boardItem?.additionalData,
              grant,
            },
          },
        },
        {
          onSuccess(data, variables, context) {
            successToast("Grant Updated");
            setBoardItems(
              boardItems?.map((b) => (b?.id === boardItem?.id ? data : b))
            );
            handleClose();
          },
          onError(error, variables, context) {
            errorToast(error?.body?.message);
          },
        }
      );
    }
  };

  // console.log("hasChanges", hasChanges);

  const handleClose = () => {
    setHasChanges({ ...hasChanges, editGrant: false });
    setAppModal((prev) => ({
      ...prev,
      views: { ...prev.views, [ActionType.edit]: false },
    }));
  };

  return (
    <Overlay onDone={handleClose} zIndex={"z-[3001]"}>
      <div
        className={`${tailwindHelpers?.responsiveModal} py-10 flex flex-col flex-1 rounded-lg mx-8`}
      >
        <div className="px-5 h-14 bg-[#B794F4] text-white flex flex-shrink-0 justify-between items-center rounded-tr-lg rounded-tl-lg">
          <p className="text-2xl font-semibold -sm:text-lg">Edit Grant</p>
          <button
            onClick={handleClose}
            className="p-1 text-2xl rounded-full hover:bg-white hover:text-[#B784F4] transition-colors duration-150 border-2"
          >
            <IoCloseOutline />
          </button>
        </div>
        <div className="flex justify-between bg-white pb-10">
          <AppButton
            icon={FiArrowLeft}
            text="Back"
            width="100px"
            variant="secondary"
            iconPosition="left"
            onClick={() => {
              handleClose();
              setAppModal((prev) => ({
                ...prev,
                views: { ...prev.views, [ActionType.view]: true },
                type: LinkType.grant,
                grantId: grant?.id,
              }));
            }}
            mt={{ base: 2 }}
            mb={{ base: 2 }}
          />
          <AppButton
            icon={BsPencilSquare}
            isLoading={isUpdating || isSaving}
            isDisabled={!hasChanges?.editGrant}
            text="Save"
            width={"100px"}
            variant="secondary"
            iconPosition="left"
            onClick={handleSave}
            mt={{ base: 2 }}
            mb={{ base: 2 }}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto bg-white pb-20">
          <FormTab
            grant={grant}
            setGrant={setGrant}
            setHasChanges={(e) =>
              setHasChanges({ ...hasChanges, editGrant: e })
            }
          />
        </div>
      </div>
    </Overlay>
  );
};

export default EditGrant;
