import logo from "@assets/logo.svg";
import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import Loader from "./Loader";
import { useContext } from "react";
import { ContextStore } from "./Context";
import { appName } from "@utils/index";

interface Props {
  name?: string;
}

const AppLoader = (props: Props) => {
  const { name } = props;
  const { user } = useContext(ContextStore);
  const userName = name || user?.firstName || "";

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <Flex px="4" py="5" align="center">
        <Image src={logo} h={8} w={8} />
        <Text
          fontSize="2xl"
          ml="2"
          color={useColorModeValue("brand.500", "white")}
          fontWeight="semibold"
        >
          {appName}
        </Text>
      </Flex>
      <Loader messages={["Welcome back"]} userName={userName} />
    </div>
  );
};

export default AppLoader;
