import { BiX } from "react-icons/bi";
import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { SearchIcon } from "@chakra-ui/icons";
import { BsArrowReturnRight } from "react-icons/bs";
import { LuCopy } from "react-icons/lu";
import { useGetOne } from "react-admin";
import { handleMaintainFocus, isNullOrEmpty } from "@utils/index";
import { useDisplayToast, useCopyToClipboard } from "@utils/hooks";
import { ContextStore } from "@components/utils/Context";
import { CreatePromptPayload, Entity, PromptSection } from "@utils/interface";
// import moment from "moment";
import Loader from "@components/utils/Loader";
import { slateToPlainString } from "@playground/editors/slate";
import SearchResult from "./SearchResult";
import moment from "moment";

interface Props {
  q: string;
  setShowModal: (val: boolean) => void;
  handleGenerate: (data: CreatePromptPayload, cb: () => void) => void;
}

const SnippetSearchNovel = ({ q, setShowModal, handleGenerate }: Props) => {
  const [search, setSearch] = useState(q);
  const { account, hasChanges, setHasChanges } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const { copyToClipboardWithToast } = useCopyToClipboard();
  const [searchResults, setSearchResults] = useState<PromptSection[]>([]);

  const modalRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(modalRef, () => setShowModal(false));

  // https://stackoverflow.com/a/68173806/3562407
  const { isFetching: isSearching, refetch: executeSearch } = useGetOne(
    `${Entity.accounts}/snippets`,
    {
      id: account?.id,
      meta: { query: search },
    },
    {
      enabled: false,
      onError: (error: any) => {
        errorToast(error?.message || error?.body?.message);
      },
      onSuccess: (searchData: any) => {
        if (searchData) {
          setSearchResults(searchData?.results);
          setHasChanges({ ...hasChanges, snippetSearchBar: false });
        }
      },
    }
  );

  const handleSearch = (canExecute = hasChanges?.snippetSearchBar) => {
    if (!isNullOrEmpty(search) && canExecute) {
      executeSearch();
    }
  };

  const handleCloseSearch = () => {
    setShowModal(false);
    setSearch("");
  };

  useEffect(() => {
    if (!isNullOrEmpty(search)) {
      handleSearch(true);
    }
    handleMaintainFocus();
  }, [q]);

  // TODO: include document in search results

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 1000, // Assuming z-[1000] is meant to set the z-index to 1000
        display: "flex", // Assuming flex-x-center is meant to center items horizontally
        alignItems: "baseline", // Assuming items-baseline is meant to align items along their baseline
        width: "100%", // w-screen
        height: "100%", // h-screen
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "1px solid #E2E8F0",
        borderRadius: "0.375rem",
      }}
      className="fixed left-0 top-0 z-[1000] flex-x-center items-baseline w-screen h-screen bg-black bg-opacity-50"
    >
      <div
        ref={modalRef}
        style={{
          paddingLeft: "1.25rem", // px-5
          paddingBottom: "2rem", // pb-8
          marginTop: "auto", // mt-auto
          marginBottom: "1.25rem", // mb-5
          height: "65vh", // h-[65vh]
          width: "100%", // w-full
          maxWidth: "700px", // max-w-[700px]
          overflowY: "auto", // overflow-y-auto
          backgroundColor: "white", // bg-white
          borderRadius: "0.375rem", // rounded-lg
          // Assuming custom-scrollbar is a custom class you've defined elsewhere
        }}
        className=" px-5 pb-8 -sm:mx-10 -md:mx-16 -xl:mx-[100px] mt-auto mb-5 h-[65vh] w-full max-w-[700px] overflow-y-auto custom-scrollbar bg-white rounded-lg"
      >
        <div
          style={{
            paddingTop: "1.25rem", // py-5
            paddingBottom: "1.25rem", // py-5
            display: "flex", // flex
            alignItems: "center", // flex-y-center
            position: "sticky", // sticky
            top: 0, // top-0
            backgroundColor: "white", // bg-white
          }}
          className=" py-5 flex-y-center sticky top-0 bg-white"
        >
          <div
            style={{
              marginRight: "4rem", // mr-4
              flex: 1, // flex-1
              position: "relative", // relative
            }}
            className=" sm:mr-16 mr-4 flex-1 relative"
          >
            <div
              style={{
                position: "absolute", // absolute
                top: "0.5rem", // top-2
                left: "0.5rem", // left-2
                color: "#6b7280", // text-neutral-400
                marginRight: "1rem",
              }}
              className="absolute top-2 left-2 text-neutral-400"
            >
              <SearchIcon />
            </div>
            <input
              placeholder="Type to search...then press enter"
              type="text"
              autoFocus
              style={{
                border: "2px solid #B794F4", // border-[#B794F4]
                width: "100%", // w-full
                outlineWidth: "4px", // outline-4
                outlineOffset: "0", // outline-offset-0
                outlineColor: "#E9D8FD", // outline-[#E9D8FD]
                borderRadius: "0.375rem", // rounded-lg
                padding: "0.5rem 3rem 0.5rem 2rem", // p-2
              }}
              className="w-full border-[#B794F4] outline-4 outline-offset-0 outline-[#E9D8FD] rounded-lg border outline-none pl-8 pr-[3.5rem] p-2"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setHasChanges({ ...hasChanges, snippetSearchBar: true });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              onBlur={() => handleSearch()}
            />
            <button
              onClick={() => {
                setHasChanges({ ...hasChanges, snippetSearchBar: false });
                setSearch("");
              }}
              style={{
                position: "absolute", // absolute
                top: "0.5rem", // top-2
                right: "0.75rem", // right-3
                color: "#6b7280", // text-neutral-400
                // hoverColor: 'black', // hover:text-black (Note: hover styles cannot be directly applied in inline styles. You would need to handle this with JavaScript or CSS)
              }}
              className="absolute top-2 right-3 text-neutral-400 hover:text-black"
            >
              Clear
              {/* <BiX size={'2rem'} /> */}
            </button>
          </div>

          <button
            style={{
              width: "1.5rem", // w-6
              height: "1.5rem", // h-6
              display: "flex", // flex
              borderRadius: "9999px", // rounded-full
              fontSize: "1.875rem", // text-3xl
            }}
            className=" w-6 h-6 flex rounded-full text-3xl"
            onClick={() => setShowModal(false)}
          >
            <BiX className="h-full" />
          </button>
        </div>

        <div
          style={{
            paddingTop: "0.75rem", // pt-3
            color: "#6C6685", // text-[#6C6685]
          }}
          className="pt-3 text-[#6C6685]"
        >
          <div>
            {isSearching ? (
              <Loader size={"lg"} />
            ) : (
              searchResults?.map((result, idx) => {
                const prompt =
                  result?.embeddings?.[0]?.content || "Empty prompt";
                const response =
                  result?.response?.embeddings?.[0]?.content ||
                  result?.currentResponse ||
                  "Empty Response";
                return (
                  <div
                    key={idx}
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className=" mb-3 flex-y-center justify-between"
                  >
                    <div
                      style={{ marginRight: "1.25rem" }}
                      className="mr-5 md:mr-12 lg:mr-20"
                    >
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          color: "black",
                        }}
                        className="line-clamp-1 text-black"
                      >
                        {prompt}
                      </p>
                      <p style={{ fontSize: "0.75rem" }} className="text-xs">
                        {response}
                      </p>
                    </div>

                    <div
                      style={{ fontSize: "0.75rem", flexShrink: 0 }}
                      className="text-xs flex-shrink-0"
                    >
                      {result?.createdAt && (
                        <p>{moment(result?.createdAt).format("MMM D, YYYY")}</p>
                      )}
                      <div
                        style={{
                          marginTop: "0.25rem",
                          display: "flex",
                          fontSize: "0.875rem",
                        }}
                        className=" mt-1 flex text-sm"
                      >
                        <button
                          style={{ marginRight: "0.75rem" }}
                          className="mr-3"
                          onClick={() => copyToClipboardWithToast(response)}
                        >
                          <LuCopy />
                        </button>
                        <button
                          onClick={() =>
                            handleGenerate(
                              {
                                prompt: search,
                                skipAi: true,
                                currentResponse: response,
                              },
                              handleCloseSearch
                            )
                          }
                        >
                          <BsArrowReturnRight />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnippetSearchNovel;
