"use client";

import "../styles/globals.css";
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { TipTapEditor } from "@/playground/editors/interface";
import { Editor as InkeEditor, AIMenuItem, BubbleMenuItem } from "inkejs";
import { JSONContent } from "@tiptap/react";
// import useLocalStorage from "../lib/hooks/use-local-storage";
import { useDebouncedCallback } from "use-debounce";
import { DEBOUNCE_DURATION, VERSION_DURATION, VERSION_LIMIT } from "../lib/consts";
import { ContentItem } from "../lib/types/note";
import {
  exportAsJson,
  exportAsMarkdownFile,
  fetcher,
  formatTmpDate,
  timeAgo,
} from "../lib/utils";
import Menu, { MenuAction } from "../ui/menu";
import { toPng } from "html-to-image";
import { usePDF } from "react-to-pdf";
import { isEqual } from 'lodash';
// import { IResponse } from "../lib/types/response";
// import { ShareNote } from "@prisma/client";
// import { LoadingCircle, LoadingDots } from "../ui/shared/icons";
import {
  BadgeInfo, ExternalLink, Shapes, Clipboard,
  Check,
  FileJson,
  FileText,
  Menu as MenuIcon,
  Monitor,
  Moon,
  SunDim,
  SaveAll,
  ToggleLeft,
  ToggleRight,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
// import Link from "next/link";
// import Tooltip from "../ui/shared/tooltip";
// import { useSearchParams } from "next/navigation";
// import { useParams } from "react-router-dom";
// import UINotFound from "../ui/layout/not-found";
// import { addItem, deleteItem, getItem, updateItem } from "../store/db.model";
// import { v4 as uuidv4 } from "uuid";
import { Entity, Document, User, getApiUrl, isDev, Dict, TDict } from "@/utils";
import Loader from "@/components/utils/Loader";
import { baseExtensions } from "../../novel-1/extensions";
import randomColor from "randomcolor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { getCompletionHeaders } from "../../helper";
// import { FaQuestion } from "react-icons/fa6";
import { SnippetSelector } from "../commands/snippets";
import { AutoDraft } from "../commands/draft";
import { AutoWrite } from "../commands/write";
import Sidebar from "./sider";
import ImageDown from "../ui/shared/icons/image-down";
import { Outlines } from "../commands/outlines";
// import FocusPlugin from "../../novel-1/extensions/focus";
import { ContextStore } from "@/components/utils/Context";
import { AIPrompt } from "../commands/prompt";
import { useToggleBubbleMenu } from "@/utils/hooks";
import ActionModal from "@/components/modal/ActionModal";
import AutoWriteQuestions from "../components/AutoWriteQuestions";

interface Props {
  doc: Document,
  user?: User,
  defaultContent: JSONContent;
  versions: ContentItem[];
  allowVersioning?: boolean;
  allowSaving?: boolean;
  itemId?: string;
  setItemId: Dispatch<SetStateAction<string | undefined>>;
  onEditorUpdate?: (editor: TipTapEditor) => void;
  customProvider?: HocuspocusProvider;
  lastVersion?: ContentItem;
  handleVersionDoc: (updatedContent: JSONContent, itemIndex: number, forceSave?: boolean) => void;
}

export default function Editor(props: Props) {
  const { chats, actionModal, setActionModal, globalState, setGlobalState } = useContext(ContextStore);
  const {
    doc, user, versions, itemId, setItemId, defaultContent,
    allowVersioning, allowSaving, customProvider, handleVersionDoc,
    lastVersion
  } = props;
  // const params = useParams();  // useSearchParams();
  // const [collaboration, setCollaboration] = useState(false);

  const debounceDuration = DEBOUNCE_DURATION;
  const hydrated = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { toPDF, targetRef } = usePDF({ filename: `${doc?.title || 'New Document'}.pdf` });

  const editorRef = useRef<TipTapEditor | undefined>(undefined);
  const [bubbleSelectorOpen, setBubbleSelectorOpen] = useState<TDict<boolean>>({});

  const showBubbleMenu = globalState.showBubbleMenu !== undefined ? globalState.showBubbleMenu : true;
  const setShowBubbleMenu = (val: boolean) => setGlobalState({ ...globalState, showBubbleMenu: val });
  const showGenAiLoader = globalState.showGenAiLoader !== undefined ? globalState.showGenAiLoader : false;
  // const setShowGenAiLoader = (val: boolean) => setGlobalState({ ...globalState, showGenAiLoader: val });

  const [counts, setCounts] = useState<Dict>({});
  const itemIndex = versions?.findIndex((item) => item.id === itemId);
  const activeVersion = versions?.find((item) => item.id === itemId);

  const [saveStatus, setSaveStatus] = useState("Saved");
  const [isLoading, setLoading] = useState(false);

  const [currentContent, setCurrentContent] = useState<JSONContent>();
  const [currentPureContent, setPureContent] = useState("");

  const { handleToggleBubble } = useToggleBubbleMenu(editorRef.current);

  //1. load default content
  //2. switch versions manually
  //3. save latest content before switch if itemId undefined
  useEffect(() => {
    if (hydrated.current) return;
    setLoading(true);
    setCurrentContent(defaultContent);
    hydrated.current = true;
    setLoading(false);
  }, [defaultContent]);

  useEffect(() => {
    // editorRef?.current?.chain()?.blur()?.run();
    editorRef?.current?.setEditable(itemId == undefined || itemId === lastVersion?.id);
    if (activeVersion) {
      setCurrentContent(activeVersion.content);
    }
  }, [itemId, versions]);

  useEffect(() => {
    if (!editorRef?.current) return;
    const editor = editorRef.current;
    const totalCounts = async () => {
      const { from, to } = editor.state.selection;
      // const currentText = editorRef.state.doc.nodesBetween(from !== to ? from : 0, to).map(n => n.text).join("\n");
      const currentText = new Set<string>();

      await new Promise((resolve) => {
        editor.state.doc.nodesBetween(
          from !== to ? from : 1,
          to,
          (node) => {
            if (node?.isText) {
              currentText.add(
                from === to
                  ? node.text!
                  : editor.state.doc.textBetween(from, to)
              );
            }
          }
        );
        resolve(true);
      });

      const totalText = Array.from(currentText);

      // console.log("totalCounts: from", from, "to", to, ",currentText", totalText, ",length", editorRef.state.doc.textContent.length);

      const fullText = totalText.join("");
      const totalChars = fullText.length;
      // const totalWords = currentText.split(/\s+/).filter(x => !!x)?.length;
      const totalWords = totalText
        .join(" ")
        .split(/\s+/)
        .filter((x) => !!x).length;

      setCounts({
        chars: totalChars,
        words: totalWords,
      });
    };

    editor.on("update", totalCounts);
    editor.on("selectionUpdate", totalCounts);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      editor.off("update", totalCounts);
      editor.off("selectionUpdate", totalCounts);
    };
  }, [editorRef.current]);

  useEffect(() => {
    if (!editorRef.current) return;
    if (editorRef.current.isFocused && itemId && lastVersion && lastVersion.id !== itemId) {
      const restoreVersionMsg = "Do you want to restore to this version? Else click CANCEL.";
      const confirm = window.confirm(restoreVersionMsg);
      if (!confirm) {
        editorRef.current.setEditable(false);
        editorRef?.current?.chain()?.blur()?.run();
        return;
      } else {
        const restoreVersionMsg = "LAST CHANCE: Do you want to restore to this version? Else click CANCEL.";
        const confirm = window.confirm(restoreVersionMsg);
        if (!confirm) {
          editorRef.current.setEditable(false);
          editorRef?.current?.chain()?.blur()?.run();
          return;
        }
        setItemId(undefined);
      }
    }
  }, [editorRef.current?.isFocused]);

  const debouncedUpdates = useDebouncedCallback(
    async (json, text, markdown, editor) => {
      // versioning and lastVersion created more than 24 hours ago
      if (allowVersioning) handleVersionDoc(json, itemIndex);
      setPureContent(markdown);
      if (allowSaving && props?.onEditorUpdate) props.onEditorUpdate(editor);
    },
    debounceDuration,
  );

  const handleExportImage = useCallback(() => {
    if (ref.current === null || activeVersion === undefined || saveStatus !== "Saved") {
      return;
    }

    toPng(ref.current, {
      cacheBust: true,
      width: ref.current.scrollWidth,
      height: ref.current.scrollHeight,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = activeVersion.title + ".png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, activeVersion, versions]);

  const handleExportJson = () => {
    if (!versions || activeVersion === undefined || saveStatus !== "Saved") return;
    exportAsJson(activeVersion, activeVersion.title);
  };

  const handleExportMarkdown = () => {
    if (
      currentPureContent.length === 0 ||
      activeVersion === undefined ||
      saveStatus !== "Saved"
    )
      return;

    exportAsMarkdownFile(currentPureContent, activeVersion.title);
  };

  const handleExportPDF = () => {
    toPDF();
  };

  // const { handleToggleBubble } = useToggleBubbleMenu(editorRef.current);

  // useEffect(() => {
  //   handleToggleBubble(true);
  // },[])

  // console.log("currentContent", editorRef.current?.getHTML());

  const customMenuItems = [
    {
      component: AIPrompt,
      isOpen: bubbleSelectorOpen["edit"],
      setIsOpen: (_isOpen: boolean) => setBubbleSelectorOpen(prev => ({ ...prev, edit: _isOpen })),
    },
    {
      component: AutoWrite,
      isOpen: bubbleSelectorOpen["write"],
      setIsOpen: (_isOpen: boolean) => setBubbleSelectorOpen(prev => ({ ...prev, write: _isOpen })),
    },
    {
      component: AutoDraft,
      isOpen: bubbleSelectorOpen["draft"],
      setIsOpen: (_isOpen: boolean) => setBubbleSelectorOpen(prev => ({ ...prev, draft: _isOpen })),
    },
    {
      component: SnippetSelector,
      isOpen: bubbleSelectorOpen["snippet"],
      setIsOpen: (_isOpen: boolean) => setBubbleSelectorOpen(prev => ({ ...prev, snippet: _isOpen })),
    },
    {
      component: Outlines,
      isOpen: bubbleSelectorOpen["template"],
      setIsOpen: (_isOpen: boolean) => setBubbleSelectorOpen(prev => ({ ...prev, template: _isOpen })),
    },
  ].filter(x => x.component);

  const userDetails = {
    name: user?.firstName ? `@${user?.firstName} ${user?.lastName?.[0] || ""}` : 'unknown',
    clientId: user?.id,
    color: randomColor(),
  };

  const menuItems = [
    {
      label: "Export as Image",
      fn: handleExportImage,
      icon: ImageDown,
    },
    {
      label: "Export as PDF",
      fn: handleExportPDF,
      icon: FileText,
    },
    {
      label: "Export as JSON",
      fn: handleExportJson,
      icon: FileJson,
    },
    {
      label: "Export as Markdown",
      fn: handleExportMarkdown,
      icon: FileText,
    },
    {
      label: "Save",
      fn: () => {
        if (editorRef.current) {
          console.log("saving...");
          handleVersionDoc(editorRef.current.getJSON(), itemIndex, true);
        }
      },
      icon: SaveAll,
      visible: isDev,
    },
    {
      label: "Toggle Bubble",
      fn: () => setShowBubbleMenu(!showBubbleMenu),
      icon: showBubbleMenu ? ToggleLeft : ToggleRight,
      visible: isDev,
    },
  ] as MenuAction[];

  // exclude the 1st message
  const chatHistory = (chats.find(chat => chat.id === doc?.id)?.history || []).slice(1);

  // console.log("currentContent", currentContent);
  // // // console.log("editorContent", editorRef.current?.getJSON());
  // console.log("activeVersion", activeVersion?.id);
  // console.log("itemIdIndex", itemIndex);
  // console.log("itemId", itemId);
  // console.log("hydrated", hydrated.current)


  if (isLoading) {
    return (
      <div className="flex justify-center w-full h-full">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <>
      <Toaster />
      {allowVersioning && doc?.id ?
        <Sidebar
          doc={doc}
          versions={versions}
          itemId={itemId}
          setItemId={setItemId}
          editor={editorRef.current}
          handleVersionDoc={handleVersionDoc}
        /> : null
      }
      {actionModal?.openAutoWriteQs ? (
        <ActionModal
          card
          overlay
          showClose
          onDone={() => {
            setActionModal({ ...actionModal, openAutoWriteQs: false });
            handleToggleBubble(true);
          }}
        >
          <div className={`mx-auto mt-[1rem] overflow-auto min-w-[500px] w-full`}>
            <AutoWriteQuestions />
          </div>
        </ActionModal>
      ) : null}
      {actionModal?.openSnippet ? (
        <ActionModal
          card
          overlay
          onDone={() => {
            setActionModal({ ...actionModal, openSnippet: false, snippet: undefined });
            handleToggleBubble(true);
          }}
        >
          <div className={`mx-auto mt-[1rem] overflow-auto min-w-[500px] w-full`}>
            <p>{actionModal?.snippetContent}</p>
          </div>
        </ActionModal>
      ) : null}
      <div className="relative flex h-screen w-full justify-center overflow-auto">
        <div className="bg-white/50 fixed z-[5] mb-5 flex rounded-lg items-center justify-end gap-2 px-3 py-2 backdrop-blur-xl">
          <span className="hidden text-xs text-slate-400 md:block">
            Created <b>{doc?.title || ""}</b> on{" "}
            {formatTmpDate(doc?.createdAt && new Date(doc.createdAt).getTime() || versions?.length && versions[0]?.created_at || 0)}
          </span>

          <div className="mr-auto flex items-center justify-center gap-2 rounded-lg bg-stone-100 px-2 py-1 text-sm ">
            <i
              style={{
                width: "9px",
                height: "9px",
                borderRadius: "50%",
                backgroundColor:
                  saveStatus === "Saved"
                    ? "#cf80f2"
                    : saveStatus === "Saving..."
                      ? "#ff6b2c"
                      : "#919191",
                display: "block",
                transition: "all 0.5s",
              }}
            />
            <span className="text-xs text-slate-400 transition-all">
              {saveStatus}{" "}
              {saveStatus === "Saved" &&
                timeAgo(doc?.updatedAt ? new Date(doc?.updatedAt).getTime() : activeVersion && activeVersion?.created_at || 0)}
            </span>

            <div className="ml-3 text-[#868e96]">
              {counts?.chars || 0} character{counts?.chars !== 1 ? "s" : ""}
              {' / '}
              {counts?.words || 0} word{counts?.words !== 1 ? "s" : ""}
            </div>
          </div>

          {/* <Tooltip
            content={
              <div className="w-64 px-3 py-2 text-sm text-slate-400">
                <h1 className="mb-2 font-semibold text-slate-500">
                  Publish and Share
                </h1>
                <p>
                  Click the <code>`Publish`</code> button to save your note
                  remotely and generate a sharing link, allowing you to share
                  your notes with others. Your notes will be uploaded after
                  serialization. e.g{" "}
                  <a
                    className="text-cyan-500 after:content-['_↗'] hover:text-cyan-300"
                    href="https://inke.app/publish/0e1be533-ae66-4ffa-9725-bd6b84899e78"
                    target="_blank"
                  >
                    link
                  </a>
                  .
                </p>
                <p>
                  You need to <strong>sign in</strong> first to try this
                  feature.
                </p>
              </div>
            }
            fullWidth={false}
          >
            <button className="hidden sm:block">
              <BadgeInfo className="h-4 w-4 text-slate-400 hover:text-slate-500" />
            </button>
          </Tooltip> */}
          <Menu items={menuItems} />
        </div>

        <div ref={ref} className="w-full max-w-screen-lg overflow-auto">
          <div ref={targetRef}>
            <InkeEditor
              className="relative min-h-screen overflow-y-auto overflow-x-hidden border-stone-200 bg-white pt-10 flex"
              // storageKey={Content_Storage_Key}
              // debounceDuration={ 1000 * 60 * (isDev ? 10 : 0.5)}
              debounceDuration={debounceDuration}
              defaultValue={currentContent}
              completionApi={getApiUrl(`/${Entity.ai}`)}
              extensions={[
                ...baseExtensions,
                //  FocusPlugin(),
              ]}
              additionalData={{
                bot: true,
                collaboration: !!customProvider,
                customProvider,
                userDetails,
                autoCompleteShortKey: '++',
                body: { documentId: doc?.id, },
                headers: { ...getCompletionHeaders(), },
                getEditor: (editor) => {
                  if (!editorRef.current) {
                    editorRef.current = editor;
                  }
                },
                chatHistory,
                // aiMenuItems,
                customMenuItems,
                bubbleMenuOpen: showBubbleMenu,
                focusOnEnter: false,
                showAiSelector: false,
                showLinkSelector: false,
                aiSelectorTitle: 'Prompt',
                loadingGenAi: showGenAiLoader,
              }}
              onUpdate={(editor) => {
                setSaveStatus("Unsaved")
              }}
              disableLocalStorage={true}
              onDebouncedUpdate={(json: JSONContent, text: string, markdown: string, editor: TipTapEditor,) => {
                setSaveStatus("Saving...");
                debouncedUpdates(json, text, markdown, editor);
                setTimeout(() => {
                  setSaveStatus("Saved");
                }, 500);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
