import handWave from "@assets/onboarding/handwave.svg";
import { BsArrowRight } from "react-icons/bs";
import { BiSad, BiXCircle } from "react-icons/bi";
import { StepProps } from "./interface";

const ReadyToExplore = ({
  setCurrentStep,
  handleFinish,
  currentStep,
}: StepProps) => {
  return (
    <div className="px-8 pt-20 mx-auto w-full max-w-[930px]">
      <div className="py-16 px-10 flex-col flex-y-center border-2 rounded-md relative">
        <div className="flex-y-center mb-12">
          <h1 className="mr-2 text-[30px] text-[#805AD5] font-bold">
            Welcome!
          </h1>
          <img className="w-8 h-8" src={handWave} alt="hand-wave icon" />
        </div>

        <div className="mb-6 px-5 py-3 text-[24px] -sm:text-[19px] text-center border rounded-lg border-[#805AD5]">
          <p>
            Before you dive in, let's embark on a quick tour of the incredible
            features that await you so you can effortlessly grasp how it all
            works.
          </p>
          <p>Ready to explore?</p>
        </div>

        <div className="flex-center -sm:flex-col w-full overflow-hidden">
          {/* <AppButton
            icon={BsArrowRight}
            text="Get Started"
            width="176px"
            variant="secondary"
            iconPosition="right"
            onClick={() => setCurrentStep(3)}
          /> */}

          <button
            onClick={() => setCurrentStep(currentStep + 1)}
            className="h-10 mt-[30px] sm:mr-5 w-44 px-4 border bg-[#E9D8FD] hover:bg-[#553C9A] text-[#553C9A] hover:text-white transition-colors duration-100 font-bold rounded flex-center"
          >
            <p>Get Started</p>
            <span className="text-lg ml-2">
              <BsArrowRight />
            </span>
          </button>

          <button
            onClick={() => handleFinish && handleFinish(false)}
            className="h-10 mt-[30px] sm:ml-5 w-44 px-4 border hover:bg-[#E9D8FD] hover:text-[#553C9A] text-[#344054] transition-colors duration-100 font-bold rounded flex-center"
          >
            <span className="text-lg mr-2">
              <BiSad />
            </span>
            <p>Show me later</p>
          </button>
        </div>

        <button
          className="absolute -top-5 -right-5 rounded-full bg-white text-4xl text-[#667085]"
          onClick={() => handleFinish && handleFinish(false)}
        >
          <BiXCircle />
        </button>
      </div>
    </div>
  );
};

export default ReadyToExplore;
