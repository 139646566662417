import { Editor } from "@tiptap/core";
import {
  ChevronDown, MailOpen, Sigma, BookUp2, SearchX,
  LayoutList, ScrollText, ClipboardSignature, ClipboardList,
  Coins, PiggyBank, Quote, PauseCircle, Pencil,
} from "lucide-react";
import React, { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Command } from "cmdk";
import { useCompletion } from "ai/react";
import { useOnClickOutside } from "usehooks-ts";
import { FaHandshake } from "react-icons/fa";
import { GiLoveLetter } from "react-icons/gi";
import { LuPenLine } from "react-icons/lu";
import { Dict, Entity, Grant, LinkType, getApiUrl } from "@/utils";
// import { getCompletionHeaders } from "../../helper";
import { NovelContextType } from "inkejs";
import { ContextStore } from "@/components/utils/Context";
import { Select } from "@chakra-ui/react";
import { startCase } from "lodash";
import { useStoreEntity } from "@/utils/hooks";
import { toast } from 'sonner';
import { tones } from "../../helper";

interface AutoDraftProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  context: React.Context<NovelContextType>;
}

export const AutoDraft: FC<AutoDraftProps> = (props: AutoDraftProps) => {
  const { editor, isOpen, setIsOpen, context: NovelContext } = props;
  const { completionApi, additionalData: { body, headers } } = useContext(NovelContext);
  const { documents, globalState, setGlobalState } = useContext(ContextStore);

  const options = globalState.autoWriteOptions;
  const setOptions = (options: Dict) => setGlobalState({ ...globalState, autoWriteOptions: options });

  const grants = (useStoreEntity(LinkType.grant) as Grant[]);

  const items = [
    {
      name: "Cover Letter",
      icon: MailOpen,
      command: 'Write a cover letter',
      visible: true,
    },
    {
      name: "Summary",
      icon: Sigma,
      command: 'Write a summary',
      visible: true,
    },
    {
      name: "Introduction",
      icon: FaHandshake,
      command: 'Write an introduction',
      visible: true,
    },
    {
      name: "Problem Statement",
      icon: SearchX,
      command: 'Write a problem statement',
      visible: true,
    },
    {
      name: "Objectives",
      icon: LayoutList,
      command: 'Write objectives',
      visible: true,
    },
    {
      name: "Project Description",
      icon: ClipboardList,
      command: 'Write a project description',
      visible: true,
    },
    {
      name: "Evaluation",
      icon: ClipboardSignature,
      command: 'Write an evaluation',
      visible: false,
    },
    {
      name: "Future Funding",
      icon: Coins,
      command: 'Write about future funding',
      visible: false,
    },
    {
      name: "Budget",
      icon: PiggyBank,
      command: 'Craft a budget',
      visible: true,
    },
    {
      name: "References",
      icon: Quote,
      command: 'Write references',
      visible: false,
    },
    {
      name: "Letter of Support",
      icon: GiLoveLetter,
      command: 'Write a letter of support',
      visible: true,
    },
  ].filter(i => i.visible !== false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  const hasSelection = editor?.state?.selection?.empty === false;

  const { complete, isLoading, stop, } = useCompletion({
    id: "ai-draft",
    api: `${completionApi}/draft`,
    body: { ...(body || {}), },
    headers: { ...(headers || {}), },
  });

  // console.log("options: ", options);
  const mt = `${options?.grantId ? 'mt-[10rem]' : 'mt-[13rem]'}`;

  return (
    !hasSelection ?
      <div className="flex" ref={ref}>
        <div className={`flex h-full items-center gap-1 text-sm font-medium hover:bg-stone-100 active:bg-stone-200 ${isOpen ? 'text-purple-500' : 'text-stone-600'}`}>
          <button
            className="p-2 flex h-full items-center gap-2"
            onClick={() => {
              if (isLoading) {
                stop();
              }
              setIsOpen(!isOpen);
              editor.chain().blur().run();
            }}>
            <Pencil className="h-5 w-5" /> Draft
            {isLoading ? (
              <PauseCircle
                onClick={stop}
                className="h-4 hover:text-stone-500 cursor-pointer w-4 text-stone-300"
              />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </button>
        </div>

        {isOpen && (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="fixed top-full z-[99999] mt-1 w-[60%] overflow-hidden rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1 flex flex-col">
              <Select
                id="grant"
                autoComplete="grant"
                placeholder="Select Grant (optional)"
                value={options?.grantId == undefined ? '' : options?.grantId || ''}
                onChange={(e) => setOptions({ ...options, grantId: e.target.value, title: options?.title || '' })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {grants.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {startCase(option.name)}
                  </option>
                ))}
              </Select>
              <Select
                id="tone"
                autoComplete="tone"
                placeholder="Select Tone"
                value={options?.tone == undefined ? '' : options?.tone || tones[0]}
                onChange={(e) => setOptions({ ...options, tone: e.target.value })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {tones.map((option) => (
                  <option key={option} value={option}>
                    {startCase(option)}
                  </option>
                ))}
              </Select>             
              {
                !!options?.grantId ? null : 
                <textarea
                  placeholder="Enter a topic/title...*"
                  className="flex-1 bg-white p-1 text-sm border rounded text-slate-500"
                  value={options?.title || ''}
                  // disabled={options?.grantId}
                  onChange={(e) => {
                    let value = e.currentTarget.value;
                    setOptions({ ...options, title: value });
                  }}                  
                />}
              <textarea
                placeholder="Enter additional info..."
                className="flex-1 bg-white p-1 text-sm border rounded text-slate-500"
                value={options?.info || ''}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setOptions({ ...options, info: value });
                }}               
              />
            </form>
            <Command className={`fixed top-full z-[99999] ${mt} w-60 overflow-hidden rounded border border-stone-200 bg-white p-2 shadow-xl animate-in fade-in slide-in-from-top-1`}>
              <Command.List>
                {items?.map((item, index) => (
                  <Command.Item
                    key={index}
                    onSelect={() => {
                      if (!options?.title && !options?.grantId) {
                        toast.error("Please enter a title/topic or select a grant.");
                        return;
                      }
                      if (!isLoading) {
                        const input = `${item.command}:\n`;
                        complete(input, { body: { ...options, command: item.command, } });
                        setOptions({ grantId: options?.grantId, tone: options?.tone, });
                        setIsOpen(false);
                      }
                    }}
                    className="flex group cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-sm text-gray-600 active:bg-stone-200 aria-selected:bg-stone-100">
                    <div className="flex items-center space-x-2">
                      <item.icon className="h-4 w-4 text-purple-500" />
                      <span>{item.name}</span>
                    </div>
                    {/* <CornerDownLeft className="hidden h-4 w-4 group-hover:block" /> */}
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </>
        )}
      </div> : null
  );
};
