const triangleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
  >
    <path
      d="M9.8512 2.68858C10.9948 -0.896195 13.0052 -0.896195 14.1488 2.68858L23.5 32L0.499998 32L9.8512 2.68858Z"
      fill="currentColor"
    />
  </svg>
);

export default triangleIcon;
