import { Note_Storage_Key } from "../lib/consts";
import { ContentItem } from "../lib/types/note";
import Dexie, { Table } from "dexie";

const db = new Dexie("GWAIDatabase");

db.version(1).stores({
  /**
   * https://stackoverflow.com/a/51258581
   * Table gwai__note_storage_data does not exist
   */
  // https://github.com/dexie/Dexie.js/issues/661#issuecomment-364793298
  // https://stackoverflow.com/a/39015986
  // https://dexie.org/docs/Compound-Index
  gwai__storage_data: "id, docId, created_at, [docId+created_at]",
});

export const idbTable = db.table(Note_Storage_Key);

export const addItem = async (item: ContentItem) => {
  console.log("adding item...");
  await idbTable.add(item);
};

export const updateItem = async (item: ContentItem) => {
  console.log("updating item...");
  await idbTable.put(item);
};

export const deleteItem = async (id: string) => {
  console.log("deleting item...");
  await idbTable.delete(id);
};

export const bulkAddItems = async (data: ContentItem[]) => {
  console.log("bulkAdd...");
  await idbTable.bulkAdd(data);
};

export const getItem = async (id: string) => {
  const item = await idbTable.get(id);
  console.log("getting item...", item);
  return item;
}


// export const createStoreIfNotExists = async (storeName: string) => {
//   // https://github.com/dexie/Dexie.js/issues/661#issuecomment-364793298
//   // https://stackoverflow.com/a/39015986
//   console.log("creating store...");
//   if (!db.table(storeName)) {
//     const newDb = new Dexie(db.name);
//     newDb.item(db.verno + 1).stores({
//       // [newStoreName]: `${primaryKey},${indexes.join(',')}`
//       [storeName]: "++id, title, content, tag, created_at, updated_at, collapsed",
//     });
//     db = await newDb.open();
//     // db = newDb;
//   }
// }

export default db;


// const indexDBPollyFill = ('indexedDB' || 'webkitIndexedDB' || 'mozIndexedDB' || 'oIndexedDB' || 'msIndexedDB');
// export class IDBKV {
//   // https://www.freecodecamp.org/news/a-quick-but-complete-guide-to-indexeddb-25f030425501/
//   public window;
//   public customStore;
//   public supported = true;

//   constructor(window, storeName: string, dbName?: string, item?: number) {
//     if (!(indexDBPollyFill in window)) {
//       this.supported = false;
//       console.log('This browser doesn\'t support IndexedDB', 'IndexedDB');
//       return;
//     }
//     this.window = window;
//     dbName = dbName || 'database';
//     if (item) {
//       this.customStore = createStore(dbName, storeName);
//     } else {
//       this.customStore = createStore(dbName, storeName);
//     }
//   }

//   public async getAll(kv?: boolean) {
//     if (!this.supported) {
//       console.log('IndexedBD Not Supported', 'IDBKV/getAll');
//       return undefined;
//     }
//     return (await keys(this.customStore)).map((key) => {
//       if (kv) return this.get(key as string, true);
//       return this.get(key as string);
//     });
//   }

//   public get(key: string, kv?: boolean) {
//     if (!this.supported) {
//       console.log('IndexedBD Not Supported', 'IDBKV/get');
//       return undefined;
//     }
//     console.log(key, "get/key");
//     return get(key, this.customStore)
//       .then(val => {
//         console.log(val, `DBKV/get`);
//         if (kv) return { key, val }
//         return val;
//       })
//       .catch(err => {
//         console.error('Get failed!', err);
//         // stateStore.dispatch(alertError('Failed to retrieve data...'));
//       });
//   }

//   public add(key: string, value: any) {
//     if (!this.supported) {
//       console.log('IndexedBD Not Supported', 'IDBKV/add');
//       return undefined;
//     }
//     return set(key, value, this.customStore)
//       .then(() => {
//         console.log('Save worked!', 'IDBKV/add');
//       })
//       .catch(err => {
//         console.error(err, 'IDBKV/get');
//         // stateStore.dispatch(alertError('Failed to save data...'));
//       });
//   }

//   public delete(key: string) {
//     if (!this.supported) {
//       console.log('IndexedBD Not Supported', 'IDBKV/delete');
//       return undefined;
//     }
//     return del(key, this.customStore);
//   }

//   public clearAll() {
//     if (!this.supported) {
//       console.log('IndexedBD Not Supported', 'IDBKV/clearAll');
//       return undefined;
//     }
//     return clear(this.customStore);
//   }
// }
