import handWave from "@assets/onboarding/handwave.svg";
import planner from "@assets/onboarding/calendar.svg";
import { BsArrowRight } from "react-icons/bs";
import AppButton from "@components/AppButton";
import { StepProps } from "./interface";
import { appName } from "@utils/index";

const Welcome = ({ setCurrentStep, currentStep }: StepProps) => {
  return (
    <div className="pt-12 px-4">
      <div className="flex mb-8">
        <h1 className="mr-2 text-5xl text-[#805AD5]">Welcome Aboard!</h1>
        <img src={handWave} alt="hand-wave icon" />
      </div>

      <p className=" text-lg text-gray-600">
        {appName} is your gateway to a simpler and faster way of tailoring your
        grants, collaborating effectively with your team, and managing your
        grants workflow with ease. Are you ready?
      </p>

      <div className="flex justify-start -xs:flex-col-reverse">
        <img
          className="w-96 h-96 -sm:w-[300px] -sm:h-[300px]"
          src={planner}
          alt="calendar icon"
        />
        <AppButton
          icon={BsArrowRight}
          text="Get Started"
          variant="secondary"
          iconPosition="right"
          onClick={() => setCurrentStep(currentStep + 1)}
        />
      </div>
    </div>
  );
};

export default Welcome;
