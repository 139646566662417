export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "5448b317-ef49-4103-8ed0-15e1fd78103f",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Questions",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d7888468-559e-473c-b8f0-17b133a3656d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Name",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "3e3dcacb-1da1-4913-a400-dbc7cea7cdea",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Description",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "cdc8670c-83ac-4902-93a4-6591f9ce5889",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Short Version",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "806065ac-f3ad-4082-aa37-d68bf8dacad4",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Long Version",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "20c551d3-4595-4dfa-bab0-b61dfd58375d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Need",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "55205b07-b83b-47ab-937b-9bd378b1b465",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Describe the overall need within your organization or initiative, how this grant will support your work, and how do you plan to use it? Please share the importance of this grant for the community you serve. How were the needs determined?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d7dfc766-f32b-436c-babb-537baa76a273",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "7e04f63e-5ad9-4980-acaa-6ed53c741290",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Goals, Objectives, Outputs, Outcomes",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5f3338c0-205d-4576-9a0f-6389ec1a41c7",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Goals ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "60dde46b-8b40-4449-9b7f-2877acf51c81",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(What you want to do)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "27ba31bb-83dc-4197-a603-b162bc060267",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "70eead6b-a9d3-47e1-b275-9ebc8e9240c0",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Objectives",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6551fde1-a228-43f3-8a15-67cf4c3f3472",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(How you will achieve goals) ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b7e7c59a-fccf-4432-8c19-4634a7dc5e73",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "916ae1a9-5630-4b02-a56c-82db3ff9c69d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Outputs",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "421e4c73-d84d-48c5-8cd8-7870db99ca50",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(What is produced)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "bbc42d39-5e88-46b9-acea-8d5e4daa6387",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b0166650-1064-4a18-8d7c-e9397d3d26b1",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Outcomes",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "de67ff27-2eb1-412b-bf8a-21a84f8c7867",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "(The changes or benefits that will result from the program/project)",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "32672f91-41da-41d2-8d16-e260c3258bf5",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "6b35d72b-f5ed-409b-9494-abb6319887c1",
        textAlign: "left",
        level: 2,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Reconciliation & DEI",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5371d87d-eba1-4470-aec9-13ae29250adc",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Does your project take action on reconciliation?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b948d53b-c205-446d-b6f3-a6b8cd7134e1",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1b65b295-b55f-4097-b0fb-b4975b53ce90",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "italic",
            },
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: 'Reconciliation defined: "The commission believes reconciliation is about establishing and maintaining mutually respectful relationships between Indigenous and non-Indigenous people in this country. For this to happen, there must be awareness of the past, acknowledgment of the harm that has been done, atonement for the causes and action to change behavior." (Indigenous Peoples Atlas of Canada Reference, The Executive Summary of the TRC Report)',
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6df5910d-c1e3-4829-929c-80158aac107d",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "8ff4ad27-11fa-4354-b42f-c434d4ffd52c",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "How does your organization include the people that it serves in its decision-making processes?",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "aba8f69c-9087-4d65-b71a-b2ea5e8e8aa2",
        textAlign: "left",
        level: 2,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Project Activities/Timeline",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1eed78f9-4771-496b-8f44-560862a14bc7",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Project Start Date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "dc8ad9e7-3be6-419d-977c-57a95733c05c",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Project End Date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "e2a7ffdd-1b47-4ea2-932c-dd3324f6fa2e",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "8311722f-1690-4837-bc02-3bbc1c3c4a9c",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Timeline",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "9e805961-ae7d-4559-b517-2ebb1261682a",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1a0a5e71-ccde-46dc-bfd6-c82be84bde9e",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Description",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "20286386-0ebd-4f11-a2ac-4154c7d68449",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Start date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "57af6a7d-3551-4f07-a841-5a7ef9a28939",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity End date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "0e9e0959-06ef-407a-9f30-4b2b34dc21d7",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "3dcb175d-1ea8-4247-b0c7-1c6a90f921d2",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Evaluation & Impact",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "fff1e32f-9041-48a9-82b8-6e9f220f9bff",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Who will benefit from this project, directly and indirectly?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "ea76f860-9a88-4771-a590-41afb8c6760d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How will they benefit?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "e022f227-98f6-40f5-99e5-16da236e56e3",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How will you evaluate your project after you complete it?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "dd5ebb7a-6c38-46f4-839e-04b546324402",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "What are your criteria for success for the project, and how will you know you have met them?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5c02e1df-088b-4ce6-82f8-1cdad1808cf3",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "How many people will your project impact?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "84050b4b-3b9e-44ae-b456-b5e67fc4710a",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "heading",
      attrs: {
        id: "61a1d772-d21c-4c5b-b679-f2a1a36327fa",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Sustainability",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "a3708edb-4392-4729-aa04-fd0b64ed8391",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Is this grant for an ongoing program? If so, how do you plan to sustain it?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "da84e8f1-ecc5-456c-9f0a-89345d683715",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "e7dc30b8-d2f9-40bc-9087-fd584efe36aa",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "ed99c839-af34-4bcd-912f-425340d10b44",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Partnerships/Collaboration",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "53d06d2f-7a3a-482b-b108-479705e7b4cb",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Does your project involve other individuals or community organizations in the planning, execution and/or evaluation of the project?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "660fce7b-f97b-47a2-9f9f-b58069e1dede",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Are you working with other partners to carry out this project?",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1cb08bb6-d904-49f4-954a-92758363015a",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Please describe your organization's major partnerships, collaborators, and/or stakeholders in your overall operation.",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "d36fdc77-c851-4a4c-8ec3-fdd7e4ac9a91",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "6fdffedf-ac76-40dc-9cbd-3562207fc684",
        textAlign: "left",
      },
    },
    {
      type: "heading",
      attrs: {
        id: "4024f989-7711-4d18-9970-0e0c5919f4a9",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Recognition",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "4c5dd29e-ab04-48a9-89a0-3882efc8461f",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "How do you plan to recognize your funders?",
        },
      ],
    },
  ],
};
