// import React, { Suspense } from "react";
import App from "./App";
import { ContextProvider } from "./components/utils/Context";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import { Toaster } from "sonner"; // https://sonner.emilkowal.ski/toast

function AppRoot() {
  return (
    <ErrorBoundary>
      <ContextProvider>
        <Toaster />
        <App />
      </ContextProvider>
    </ErrorBoundary>
  );
}

export default AppRoot;
