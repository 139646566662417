import { Editor } from "@tiptap/core";
import {
  Beef,
  Book,
  Check,
  CheckCheck,
  ChevronDown,
  Heading1,
  LayoutPanelTop,
  ListMinus,
  ListPlus,
  PartyPopper,
  PauseCircle,
  Scissors,
  Send,
  Bot,
  Trash,
  Wand,
  LucideIcon,
} from "lucide-react";
import React, { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Command } from "cmdk";
import Magic from "../ui/shared/icons/magic";
import { useCompletion } from "ai/react";
import { toast } from "sonner";
import Magic1 from "../ui/shared/icons/magic-1";
import { AIMenuItem, NovelContextType } from "inkejs";
import { useOnClickOutside } from "usehooks-ts";
import { Select } from "@chakra-ui/react";
import { startCase } from "lodash";
import { tones } from "../../helper";
import { ContextStore } from "@/components/utils/Context";
import { Dict, Grant, LinkType } from "@/utils";
import { useStoreEntity } from "@/utils/hooks";

interface AIEditProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  context: React.Context<NovelContextType>;
  // subMenuItems?: AIMenuItem[]
}

export const AIPrompt: FC<AIEditProps> = (props: AIEditProps) => {
  const { editor, isOpen, setIsOpen, context: NovelContext } = props;
  const { globalState, setGlobalState } = useContext(ContextStore);
  const grants = (useStoreEntity(LinkType.grant) as Grant[]);

  const options = globalState.autoWriteOptions;
  const setOptions = (options: Dict) => setGlobalState({ ...globalState, autoWriteOptions: options });

  const { completionApi, additionalData: { body, headers, aiSelectorTitle } } = useContext(NovelContext);

  const { complete, isLoading, stop, } = useCompletion({
    id: "ai-edit",
    api: `${completionApi}/edit`,
    body: { ...(body || {}) },
    headers: { ...(headers || {}), },
  });

  const defaultItems = [
    {
      name: "Improve selection",
      command: "Improve writing",
      icon: Wand,
    },
    {
      name: "Fix spelling & grammar",
      command:
        "Please correct spelling and grammar errors in the following text",
      icon: CheckCheck,
    },
    {
      name: "Make shorter",
      command: "Make shorter",
      icon: ListMinus,
    },
    {
      name: "Make longer",
      command: "Make longer",
      icon: ListPlus,
    },
    {
      name: "Writing suggestions",
      command: "Provide suggestions and improvements for the writing",
      icon: Beef,
    },
    {
      name: "Enhance vocabulary",
      command: "Suggest synonyms and expand vocabulary usage",
      icon: Book,
    },
    {
      name: "Generate titles",
      command: "Automatically generate compelling titles for the content",
      icon: Heading1,
    },
    {
      name: "Templates & structure",
      command:
        "Offer templates and structure suggestions to improve the writing organization",
      icon: LayoutPanelTop,
    },
    {
      name: "Fix repetitive",
      command: "Identify and fix repetitive words or phrases in the content",
      icon: Scissors,
    },
    // {
    //   name: "Fill out the content",
    //   command: "Fill out the following outline",
    //   icon: LuPenLine,
    // },
    // {
    //   name: "Answer",
    //   command: "Answer",
    //   icon: FaQuestion,
    // },
  ];

  const items = [
    ...defaultItems,
    // ...(subMenuItems || []),
  ] as AIMenuItem[];

  const handleSubmit = () => {
    if (!options?.command) return;
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to, " ");
    const input = `${options?.command}:\n ${text}`;
    complete(input, { body: { ...options, text, action: 'prompt' } });
    setOptions({ grantId: options?.grantId, tone: options?.tone, });
    setIsOpen(false);
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || (e.metaKey && e.key === "z")) {
        stop();
        if (e.key === "Escape") {
          setIsOpen(false);
        }
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", onKeyDown);
    } else {
      document.removeEventListener("keydown", onKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isOpen]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  // useEffect(() => {
  //   if (!hasSelection) inputRef.current && inputRef.current?.focus();
  // });

  // https://github.com/pacocoursey/cmdk?tab=readme-ov-file#nested-items

  const hasSelection = editor?.state?.selection?.empty === false;

  return (
    <div className="flex" ref={ref}>
      <div className={`flex h-full items-center gap-1 text-sm font-medium hover:bg-stone-100 active:bg-stone-200 ${isOpen ? 'text-purple-500' : 'text-stone-600'}`}>
        <button
          className="p-2 flex h-full items-center gap-2"
          onClick={() => {
            if (isLoading) {
              stop();
            }
            setIsOpen(!isOpen);
            editor.chain().blur().run();
          }}>
          <Magic1 className="h-5 w-5" /> {aiSelectorTitle || 'AI'}
          {isLoading ? (
            <PauseCircle
              onClick={stop}
              className="h-4 hover:text-stone-500 cursor-pointer w-4 text-stone-300"
            />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </button>
      </div>

      {isOpen && (
        <>
          {!hasSelection ?
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="fixed top-full z-[99999] mt-1 w-[60%] overflow-hidden rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1 flex flex-col">
              <div className="flex w-full">
                <textarea
                  rows={1}
                  placeholder="Enter a prompt or question...*"
                  className="flex-1 bg-white p-1 border text-sm outline-none text-slate-500"
                  value={options?.command || ''}
                  onChange={(e) => {
                    let value = e.currentTarget.value;
                    setOptions({ ...options, command: value });
                  }}
                />
                <button type="submit" className={`flex items-center border border-purple-400 rounded-sm p-1 text-stone-600 transition-all ${options?.command ? 'hover:bg-purple-200' : 'cursor-default'}`}>
                  <Send className="h-4 w-4 text-purple-500" />
                </button>
              </div>
              <Select
                id="grant"
                autoComplete="grant"
                placeholder="Select Grant (optional)"
                value={options?.grantId == undefined ? '' : options?.grantId || ''}
                onChange={(e) => setOptions({ ...options, grantId: e.target.value })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {grants.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {startCase(option.name)}
                  </option>
                ))}
              </Select>
              <Select
                id="tone"
                autoComplete="tone"
                placeholder="Select Tone"
                value={options?.tone == undefined ? '' : options?.tone || tones[0]}
                onChange={(e) => setOptions({ ...options, tone: e.target.value })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {tones.map((option, idx) => (
                  <option key={idx} value={option}>
                    {startCase(option)}
                  </option>
                ))}
              </Select>
              <textarea
                rows={2}
                autoComplete="on"
                placeholder="Enter additional info..."
                className="flex-1 bg-white p-1 text-sm border rounded text-slate-500"
                value={options?.info || ''}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setOptions({ ...options, info: value });
                }}
              />
            </form>
            : <Command className={`fixed top-full z-[99999] mt-[0rem] w-60 overflow-hidden rounded border border-stone-200 bg-white p-2 shadow-xl animate-in fade-in slide-in-from-top-1`}>
              <Command.List>
                <Command.Group heading="Requires Text Selection" className="text-slate-400">
                  {items?.filter(i => i?.visible !== false)?.map((item, index) => (
                    <Command.Item
                      key={index}
                      disabled={!hasSelection}
                      onSelect={() => {
                        if (!hasSelection) return;
                        if (!isLoading) {
                          const { from, to } = editor.state.selection;
                          const text = editor.state.doc.textBetween(from, to, " ");
                          const input = `${item.command}:\n ${text}`;
                          complete(input, { body: { ...options, command: item.command, text, action: 'edit' } });
                          setIsOpen(false);
                        }
                      }}
                      className={`flex group items-center justify-between rounded-sm px-2 py-1 text-sm text-gray-600 active:bg-stone-200 aria-selected:bg-stone-100 ${!hasSelection ? 'cursor-default' : 'cursor-pointer'}`}>
                      <div className="flex items-center space-x-2">
                        <item.icon className="h-4 w-4 text-purple-500" />
                        <span>{item.name}</span>
                      </div>
                      {/* <CornerDownLeft className="hidden h-4 w-4 group-hover:block" /> */}
                    </Command.Item>
                  ))}
                </Command.Group>
              </Command.List>
            </Command>
          }
        </>
      )}
    </div>
  );
};
