import { Button, Text, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Dict, appColors } from "@/utils";
import { HTMLChakraProps } from "@chakra-ui/system";
import Loader from "./utils/Loader";

const AppButton = (
  props: HTMLChakraProps<"button"> & {
    text?: string;
    icon?: IconType;
    variant: "primary" | "secondary";
    iconPosition?: "left" | "right";
    isDisabled?: boolean;
    isLoading?: boolean;
    width?: string;
    margin?: string;
    marginTop?: string;
    iconOnly?: boolean;
  }
) => {
  const {
    text,
    icon,
    variant,
    iconPosition,
    isLoading,
    width,
    marginTop = "30",
    margin = 3,
    iconOnly,
    ...rest
  } = props;
  const isPrimary = variant === "primary";

  return (
    <Button
      mt={{ base: marginTop }}
      color={isPrimary ? appColors.primaryText : appColors.secondaryText}
      bg={
        iconOnly
          ? "none"
          : isPrimary
            ? appColors.primaryBg
            : appColors.secondaryBg
      }
      ml={{ base: margin }}
      mr={{ base: margin }}
      _hover={{
        bg: iconOnly
          ? "none"
          : isPrimary
            ? appColors.primaryBg
            : appColors.secondaryBg,
        color: iconOnly
          ? "none"
          : isPrimary
            ? appColors.primaryText
            : appColors.secondaryText,
      }}
      {...rest}
      w={width}
    >
      {iconPosition == "left" &&
        (isLoading ? (
          <Loader size={"md"} mr={2} />
        ) : (
          <Icon as={icon} mr={text && "2"} />
        ))}
      <Text>{text}</Text>
      {iconPosition == "right" &&
        (isLoading ? <Loader size={"md"} ml={2} /> : <Icon as={icon} ml="2" />)}
    </Button>
  );
};

export default AppButton;
