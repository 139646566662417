interface TableLoaderProps {
  children?: React.ReactNode;
}

const TableLoader = (props: TableLoaderProps) => {
  return (
    <tr>
      <td>{props?.children}</td>
    </tr>
  );
};

export default TableLoader;
