// import { Avatar } from "@chakra-ui/react";
import { FiPlus, FiFileText, FiEdit } from "react-icons/fi";
import { IoCheckmark } from "react-icons/io5";
import Table from "@components/Table";
import {
  Dict,
  Entity,
  MenuAction,
  PromptSection,
  Document,
  DocumentStatus,
  DocumentView,
} from "@utils/interface";
import Loader from "@components/utils/Loader";
import {
  BASIC_PROPOSAL_LIMIT,
  IResponse,
  PLANS,
  ellipsis,
  handleSort,
  setURLQuery,
} from "@utils/index";
import {
  useCommonQueries,
  useConfetti,
  useDel,
  useDisplayToast,
  usePlanFeatureCheck,
  usePost,
  usePut,
  useURLParams,
} from "@utils/hooks";
import { useContext, useState, useEffect, useRef } from "react";
import { ContextStore } from "@components/utils/Context";
import Actions from "@components/Actions";
import { BiTrash, BiUndo } from "react-icons/bi";
import ActionModal from "@/components/modal/ActionModal";
import propsIcon from "@assets/empty-proposal.png";
import AppButton from "@components/AppButton";
// import { Avatar } from "@chakra-ui/react";
import ProfileImg from "@components/ProfileImg";
import TitleBox from "@components/TitleBox";
import VideoUpdate from "@components/VideoUpdate";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

const ListDocuments = () => {
  const navigate = useNavigate();

  const {
    user,
    account,
    actionModal,
    setActionModal,
    setAppModal,
    documents,
    setDocuments,
    hasChanges,
    setHasChanges,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const { needAccess } = usePlanFeatureCheck();

  const [activeItem, setActiveItem] = useState<Dict | undefined>(undefined);
  const { showConfetti } = useConfetti();
  const { fetchDocuments } = useCommonQueries();
  const { isFetching: isLoading } = fetchDocuments;

  useEffect(() => {
    fetchDocuments.refetch();
  }, []);
  // const { queryParams } = useURLParams();
  // const { documentId } = queryParams;

  //create proposal
  const [create, { isLoading: isCreating }] = usePost<Document>();
  const [update, { isLoading: isUpdating }] = usePut<Document>();
  const handleUpdate = (id: string, payload: Dict) => {
    // setActiveItem(item);
    update(
      Entity.documents,
      {
        id,
        data: payload,
      },
      {
        onSuccess(data, variables, context) {
          if (payload?.status === DocumentStatus.SUBMITTED) {
            showConfetti(0.25);
          }
          successToast("Document Updated");
          fetchDocuments.refetch();
          setActiveItem(undefined);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const goToDocument = (id: string) => {
    return navigate(`/documents/${id}`)
  };

  const handleNewDocument = () => {
    // if (
    //   needAccess(
    //     [PLANS.basic],
    //     true,
    //     "Please upgrade your account to create more proposals",
    //     documents?.length >= BASIC_PROPOSAL_LIMIT
    //     // TODO use feature flags for Limits
    //   )
    // )
    //   return;

    create(
      Entity.documents,
      {
        data: {
          title: actionModal?.newTitle || "Untitled Document",
          user: {
            connect: {
              id: user?.id,
            },
          },
          account: {
            connect: {
              id: account?.id,
            },
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          // console.log("result", result);
          successToast("New Document!");
          setActionModal({
            ...actionModal,
            addTitle: false,
            newTitle: undefined,
          });
          fetchDocuments.refetch()
            .then(() => {
              goToDocument(data.id);
            });
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const [deleteDocument, { isLoading: isDeleting }] = useDel<Document>();
  const handleDelete = () => {
    if (!activeItem?.id) return;
    // setActiveItem(id);
    deleteDocument(
      Entity.documents,
      { id: activeItem?.id },
      {
        onSuccess(data, variables, context) {
          successToast("Document Deleted");
          fetchDocuments.refetch();
          setActiveItem(undefined);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const newDoc = () => {
    setActionModal({ ...actionModal, addTitle: true });
  };

  const EmptyState = () => {
    return (
      <div className="py-8">
        <div className="mb-5 mx-4 py-7 px-5 lg:flex-between cursor-pointer rounded-xl bg-[#FAF5FF]">
          <div className="flex -sm:items-center -sm:flex-col">
            <FiFileText size={80} className="text-[#8B5CF6] mr-5" />
            <div>
              <h2 className="mb-1 text-lg font-bold">
                Welcome to your Documents Workspace!
              </h2>
              <p className="text-xs">
                Unlock the full potential of your documents by harnessing the
                remarkable capabilities of AI. With the ability to seamlessly
                incorporate tone of voice, essential documents, and guidelines,
                our platform makes grant writing easier and more effective than
                ever before.
              </p>
            </div>
          </div>

          <div className="flex-shrink-0 lg:ml-14 -lg:mt-6 -lg:flex-x-center">
            <AppButton
              icon={FiPlus}
              text="New Document"
              width="250px"
              variant="secondary"
              iconPosition="right"
              marginTop="0"
              onClick={newDoc}
            />
          </div>
        </div>
      </div>
    );
  };

  const updateTitle = () => {
    if (!hasChanges.renameFolder) return;
    setActiveItem(undefined);
    setActionModal((prev) => ({ ...prev, renameFolder: false }));
    if (
      !activeItem?.title ||
      activeItem?.title === "Untitled Document" ||
      documents.find((f) => f.id === activeItem.id)?.title === activeItem.title
    )
      return;
    handleUpdate(activeItem.id, { title: activeItem?.title, });
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(inputRef, () => {
    setActionModal((prev) => ({ ...prev, renameFolder: false }));
  });

  const handleEditTitle = () => {
    return (
      <input
        ref={inputRef}
        // autoFocus={true}  // TODO: figure why not working
        className={
          "text-md font-bold outline-none rounded border p-2 focus:border-blue-500 focus:outline-none w-full"
        }
        placeholder="Document Name"
        value={activeItem?.title || ""}
        id="title"
        onFocus={(e) => {
          setHasChanges((prev) => ({ ...prev, renameFolder: true }));
        }}
        onChange={(e) => {
          setActiveItem((prev) => ({
            ...prev,
            title: e.target.value,
          }));
        }}
        onBlur={() => {
          updateTitle();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateTitle();
          }
        }}
      />
    );
  };

  // useEffect(() => {
  //   if (documentId && documents?.length) {
  //     const document = documents.find((item) => item?.id === documentId);
  //     if (document) {
  //       setURLQuery(documentId ? queryParams : { documentId: document?.id });
  //       goToEditDoc(document);
  //     }
  //   }
  // }, [documentId, documents?.length]);

  if (
    isLoading
    // || (documentId && currentView === DocumentView.HOME)
  ) {
    return (
      <div className="flex justify-center w-full h-full ">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <>
      <VideoUpdate videoKeys={["Folders"]} />

      {actionModal?.addTitle && (
        <TitleBox
          type={Entity.documents}
          actionModal={actionModal}
          isLoading={isCreating}
          setActionModal={setActionModal}
          onConfirm={handleNewDocument}
        />
      )}

      {actionModal?.deleteDocument && (
        <ActionModal
          icon={<BiTrash />}
          handleConfirm={() => {
            handleDelete();
            setActionModal({ ...actionModal, deleteDocument: false });
          }}
          handleClose={() =>
            setActionModal({ ...actionModal, deleteDocument: false })
          }
          title={"Confirm"}
        >
          <p>Are you sure?</p>
        </ActionModal>
      )}

      {!documents.length ? (
        <EmptyState />
      ) : (
        <div className="pb-8 relative">
          <div className="pb-2 mb-5 pt-[30px] px-5 sticky z-30 top-14 flex justify-between items-end border-b bg-white">
            <h1 className="text-2xl font-bold">Documents</h1>

            <button
              onClick={newDoc}
              className=" px-5 py-2 rounded bg-[#e9d9fc] hover:bg-[#553C9A] text-[#553C9A] hover:text-white transition-colors duration-200 flex items-center"
            >
              <span className="mr-2 -sm:hidden">New Document</span>
              <span className="text-lg">
                <FiPlus />
              </span>
            </button>
          </div>

          <div className="px-5">
            <Table
              tHeaders={[
                {
                  title: "Name",
                  isSortable: true,
                  fn: (dir) =>
                    handleSort(dir, "title", documents, setDocuments),
                },
                {
                  title: "Created By",
                  isSortable: true,
                  fn: (dir) =>
                    handleSort(dir, "user.firstName", documents, setDocuments),
                },
                {
                  title: "Status",
                  isSortable: true,
                  fn: (dir) =>
                    handleSort(dir, "status", documents, setDocuments),
                },
              ]}
              isLoading={isLoading}
              tBody={
                documents?.length > 0 &&
                documents?.map((item, index) => {
                  const isRenameFolder = actionModal?.renameFolder && item.id === activeItem?.id;
                  const actions: MenuAction = {
                    top: [
                      {
                        label: "Rename",
                        iconBefore: <FiEdit />,
                        onSelect: () => {
                          setActionModal({ ...actionModal, renameFolder: true, });
                          setActiveItem(item);
                        },
                        visible: !isRenameFolder,
                      },
                    ],
                    bottom: [
                      {
                        label: "Mark As Complete",
                        iconBefore: <IoCheckmark />,
                        onSelect: () =>
                          handleUpdate(item.id, { status: DocumentStatus.SUBMITTED }),
                        visible: item?.status === DocumentStatus.DRAFT,
                      },
                      {
                        label: "Mark As Draft",
                        iconBefore: <BiUndo />,
                        onSelect: () =>
                          handleUpdate(item.id, { status: DocumentStatus.DRAFT }),
                        visible: item?.status !== DocumentStatus.DRAFT,
                      },
                      {
                        label: "Remove",
                        iconBefore: <BiTrash />,
                        onSelect: () => {
                          setActionModal({ ...actionModal, deleteDocument: true, });
                          setActiveItem(item);
                        },
                        visible: item?.status === DocumentStatus.DRAFT,
                      },
                    ],
                  };

                  const userFullName =
                    !item?.user?.firstName && !item?.user?.lastName
                      ? `User Not Found`
                      : `${item?.user?.firstName || ""} ${item?.user?.lastName || ""
                      }`;

                  return (
                    <tr
                      className="h-16 border-b border-neutral-300"
                      key={index}
                    >
                      <td className="-md:w-[45%] w-[40%] min-w-[150px] pr-1 bg-white sticky left-0">
                        <div className="flex h-full children:text-left">
                          <button
                            className="flex"
                            onClick={() => {
                              if (isRenameFolder) return;
                              goToDocument(item?.id);
                            }}>
                            <span className="mr-2 text-xl">
                              <FiFileText />
                            </span>
                            <span>
                              {isRenameFolder
                                ? handleEditTitle()
                                : ellipsis(item?.title || "No Title", 40)}
                            </span>
                          </button>
                        </div>
                      </td>
                      <td className="w-[40%] min-w-[120px] pr-1">
                        <div className="flex items-center">
                          <ProfileImg
                            src={item?.user?.imageUrl}
                            className="mr-2 h-8 w-8 rounded-full flex-shrink-0"
                          />
                          <span> {userFullName} </span>
                        </div>
                      </td>
                      <td className="w-[20%]">{item?.status}</td>
                      {/* <td className="w-[19%]"></td> */}
                      <td>
                        {(isUpdating || isDeleting) &&
                          item?.id == activeItem?.id ? (
                          <Loader size={"md"} />
                        ) : (
                          <Actions actions={actions} isTableActions={true} />
                        )}
                      </td>
                    </tr>
                  );
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ListDocuments;
