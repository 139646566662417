export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "b35cb0ef-821c-4eb6-8085-578f8e24ddfc",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Grants Documents Checklist",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "4158f754-f350-4f25-87f7-8182b4644a71",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "[Keep these documents updated and together in a single file for easy access]",
        },
      ],
    },
    {
      type: "taskList",
      content: [
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "b112b43d-12af-4f03-8e03-307bfc759ad6",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Current Board of Directors list",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "e7451595-d716-4670-bfc9-1cb8db9acb2f",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Latest Annual Report",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "9466143e-dfd0-4c82-b1ba-97d3820ffdae",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Current Financial Statements",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "ef5dcaff-a224-4d7c-8d3b-bad6979f0c2a",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Current Org Budget",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "7debeb60-c6a1-48a0-96b9-c56b77d26146",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Bylaws",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "6d432055-d38d-42bb-8abb-48c909c828c5",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Letters of Support",
                },
              ],
            },
          ],
        },
        {
          type: "taskItem",
          attrs: {
            checked: false,
          },
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "cadc3b57-d31c-4c9c-a065-cacf425f8f25",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(0, 0, 0)",
                      },
                    },
                  ],
                  text: "Quotes and Testimonials",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
