import { includesString, APP_CONST, getOriginUrl, ellipsis, openInNewTab } from "@/utils";
import { Dict, Recommendation } from "@/utils/interface";
import { BiLike, BiSolidLike, BiDislike, BiSolidDislike } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";

interface ResultProps {
  rec: Recommendation;
  handleUpdate: (id: string, data: Dict, cb?: () => void) => void;
}

const Result = (props: ResultProps) => {
  const { rec, handleUpdate } = props;

  const ShowDislike = ({ rec }: { rec: Recommendation }) => {
    return rec?.liked == undefined ?
      <BiDislike
        size={20}
        onClick={() => {
          handleUpdate(rec?.id, { liked: false });
        }}
        className={` hover:text-primary-dark cursor-pointer`}
      /> :
      <BiSolidDislike
        size={20}
        onClick={() => {
          handleUpdate(rec?.id, { liked: null });
        }}
        className={`text-primary-dark hover:text-inherit cursor-pointer ${rec?.liked == true ? 'hidden' : ''}`}
      />;
  }

  const ShowLike = ({ rec }: { rec: Recommendation }) => {
    return rec?.liked == undefined ?
      <BiLike
        size={20}
        onClick={() => {
          handleUpdate(rec?.id, { liked: true });
        }}
        className={` hover:text-primary-dark  cursor-pointer`}
      /> :
      <BiSolidLike
        size={20}
        onClick={() => {
          handleUpdate(rec?.id, { liked: null });
        }}
        className={`text-primary-dark hover:text-inherit cursor-pointer ${rec?.liked == false ? 'hidden' : ''}`}
      />;
  }


  return (
    <div className="h-full px-3.5 py-4 rounded-[11.2px] bg-white [box-shadow:0px_7.987px_47.919px_0px_rgba(38,45,118,0.08)]">
      <div className="flex justify-end mb-1 gap-4">
        <ShowLike rec={rec} />
        <ShowDislike rec={rec} />
      </div>
      <div className="mb-3 flex flex-wrap gap-3 text-[9.5px] font-medium text-[#344054]">
        {rec?.tags
          .split(",")
          // .slice(0, 3)
          ?.map((tag, index) => {
            return (
              <span
                className="w-max px-3 py-1.5 bg-[#D1FADF] rounded-[13px] text-xs"
                key={index}
              >
                {tag?.toUpperCase()}
              </span>
            );
          })}
      </div>
      <p className="mb-2.5 text-sm text-[#777795]">
        Next Deadline:{" "}
        {!rec?.deadline ||
          includesString(
            [APP_CONST.UNKNOWN, "varies"],
            rec?.deadline?.toLowerCase()
          )
          ? "Rolling Deadline"
          : rec?.deadline}
      </p>
      <p className="mb-2.5 text-lg font-bold text-[#344054]">
        <a href={getOriginUrl(rec?.link)} target="blank">
          {rec?.name}{" "}
        </a>
      </p>
      <p className="mb-2.5 text-md font-bold text-[#DC6803]">
        {!rec?.amount ||
          includesString(
            [APP_CONST.UNKNOWN, "varies"],
            rec?.amount?.toLowerCase()
          )
          ? "$$$"
          : rec?.amount}
      </p>
      <div>
        <p className="text-sm font-bold text-[#344054]">About</p>
        <p className="text-md text-[#4D4D4D]">
          {ellipsis(rec?.description, 0)}
        </p>
      </div>
      <a
        onClick={(e) => {
          e.preventDefault();
          if (rec?.viewed !== true) {
            handleUpdate(rec?.id, { viewed: true }, () => openInNewTab(getOriginUrl(rec?.link)));
          } else {
            openInNewTab(getOriginUrl(rec?.link))
          }
        }}
        target="blank"
        className="flex mt-1.5 items-center gap-2 text-sm font-semibold text-[#553C9A] cursor-pointer hover:text-secondary-dark"
      >
        <span>View Grant</span>
        <FaArrowRight />
      </a>
    </div>
  );
};

export default Result;
