import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
// import { ContextStore } from "@components/utils/Context";
// import { useDisplayToast, usePlanFeatureCheck } from "@utils/hooks";
import { tailwindHelpers } from "@utils/index";
import Overlay from "@components/Overlay";
import TextLoader from "@components/TextLoader";

interface TabModalProps {
  title: string;
  handleClose: () => void;
  tabs: { title: string; icon: React.ReactNode; content: React.ReactNode }[];
  isLoading?: boolean;
}

const TabModal = (props: TabModalProps) => {
  const { title, handleClose, tabs, isLoading } = props;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Overlay onDone={handleClose}>
      <div
        className={`${tailwindHelpers?.responsiveModal} pb-10 my-14 mx-8 rounded-lg bg-white`}
      >
        <div className="px-5 mb-5 h-14 bg-[#B794F4] text-white flex justify-between items-center rounded-tr-lg rounded-tl-lg">
          <h1 className="text-2xl font-semibold -sm:text-lg">{title}</h1>
          <button
            onClick={handleClose}
            className={`${
              isLoading
                ? "cursor-default"
                : "hover:bg-white hover:text-[#B784F4]"
            } p-1 text-2xl rounded-full transition-colors duration-150 border-2`}
          >
            <IoCloseOutline />
          </button>
        </div>

        <div className="mb-8 flex rounded-t overflow-hidden">
          {tabs.map((tab, index) => (
            <button
              key={index}
              type="button"
              onClick={() => !isLoading && setActiveTab(index)}
              className={`block h-max w-full py-2 ${
                index === activeTab
                  ? "border-t-4 border-t-[#B794F4]"
                  : "border-t-4 border-t-white border-b-2"
              } outline-none`}
            >
              <span className="-sm:hidden">{tab?.title}</span>
              <span className="w-max mx-auto hidden -sm:block text-2xl">
                {tab?.icon}
              </span>
            </button>
          ))}
        </div>

        <div className="pb-10">{tabs[activeTab]?.content}</div>

        {isLoading && <TextLoader color={"#6b4bbe"} />}
      </div>
    </Overlay>
  );
};

export default TabModal;
