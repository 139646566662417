// https://stackoverflow.com/a/77369049
export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "27235ec1-137a-4a81-af87-d59fe9c8355e",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Budget",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "8c92e5a2-6841-43b8-9635-6b487b393152",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "table",
      content: [
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "f047cff7-1fd1-4bd7-9bb9-747e4f8a011f",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Expenses",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "6353b62d-36a7-4364-bab8-6a578511239f",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "($)",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "dc081510-59b1-4788-9398-4a14b6f239d6",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Notes",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7d2ac117-e82a-4c58-bb07-f4a370da6e01",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Salaries & Benefits",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "17ae4d67-97a9-4f9a-b267-0a52e934472e",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "2fe03c86-b4a9-41c1-8fbd-4f2e4e345a1c",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "09d5673d-286a-4b29-8a87-c29d9d72a3fa",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Contractors",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "a97c597d-8065-4b2d-937f-65a478977fc3",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "b1f63411-561e-4f13-8647-f852ab95c9bb",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "34254954-833f-4118-8d1d-a5981ce65014",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Materials & Equipment",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "fa8f7bfe-0c10-4224-9090-3303b0929182",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "15e0fdc0-d3a0-4e3f-8c5a-0603e95a7b3e",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "f6d3de7e-fd99-48cc-a3a7-d73c6c8b4457",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Administration",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "cebafa39-fd48-4830-99a9-fcdb6a49753a",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "cea7ecf3-3612-4369-9d7d-ddda608253a6",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "ff6ba13c-28ea-4e90-a546-c8092f037f63",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Marketing & Promotions",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "b386763c-1491-4fdb-b686-c7ae2e72db99",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "aa2044cc-bd50-45dc-89d0-a3dc40894b5b",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "8d223bac-57ba-4a19-af5d-27619d8cb234",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "5bf5add4-d2ab-4ee3-9d48-405b93c13daa",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7ef56f34-90c0-41e9-846e-5b085749db00",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "29ebb969-546a-4f2a-8c8a-22aa14c01ced",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "871eae7f-e815-48cb-ba46-c83b07b5b804",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "4fad49ff-cc88-4197-88c3-35a58fa8bfab",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "004a9d15-876f-462a-8e51-8816f204169b",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Total",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "32d5cbf8-10e6-4032-9d71-0da71c8119c2",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "c1cc3785-f8da-4855-9af5-7a7009a3b41d",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "89c71c10-cee9-4852-a132-ce62356cb59b",
        textAlign: "left",
      },
      content: [
        {
          type: "hardBreak",
        },
        {
          type: "hardBreak",
        },
        {
          type: "hardBreak",
        },
      ],
    },
    {
      type: "table",
      content: [
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "01274d1e-5185-45af-871b-86c4cf55dabf",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Revenues",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7c8c2f95-44be-4948-aa7f-e3889f5a8823",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "($)",
                    },
                  ],
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "946cbdfd-8ce4-4597-b2b2-28d0d3591218",
                    textAlign: "left",
                  },
                  content: [
                    {
                      type: "text",
                      marks: [
                        {
                          type: "textStyle",
                          attrs: {
                            color: "rgb(0, 0, 0)",
                          },
                        },
                      ],
                      text: "Pending/Confirmed",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "df535e76-2510-4b7c-92fb-482236a4b0b4",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "75dc0818-fdb0-45b0-9a38-24999499a7df",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "51ffc210-9ae4-47c5-8cd7-9276136aa260",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e30d26c5-8485-461f-bbcc-96c4d5900131",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "68ef1cc0-4c9a-4830-b65a-b0aaf1264342",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e9ed39da-c106-4010-896d-ac4dadef8142",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "d7fb074a-e023-4caa-9bcb-d12f09f0fae9",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "7ef6c3ff-ed04-41b0-95b2-1bf2ef6baa92",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "e0e3feba-cddf-4ac6-b91e-358f420b3612",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "3d8154bc-2454-4dea-9477-67eff342a312",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "8b1bb9e4-dd7f-491b-b6bd-8c52046354dc",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "6bbb7271-17b4-4741-bbff-f15782f5b948",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "2587a900-2846-49a0-be23-998aec6535af",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "da72ecc4-9aa3-4dad-b09c-6e1c81884d65",
                    textAlign: "left",
                  },
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: "paragraph",
                  attrs: {
                    id: "fa7a99d5-5c9f-4bbc-91bd-bbb077dd3841",
                    textAlign: "left",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
