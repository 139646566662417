import { Center, Spinner } from "@chakra-ui/react";
import { omit } from "lodash";

interface Props {
  className?: string;
  messages?: string[];
  userName?: string;
  [x: string]: any; // for the rest property
}

const Loader = (props: Props) => {
  const { className, userName } = props;
  const messages = props?.messages || [
    "Working for you",
    "Just a little while longer",
    "Almost done",
    "Hang in there",
  ];

  return (
    <Center className={`${className || ""} flex flex-col`}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#B794F4"
        size={'lg'}
        {...omit(props, ["className", "messages", "userName"])}
      />
      {userName && (
        <p className="mt-5">
          {messages[Math.floor(Math.random() * messages?.length)]}
          {userName ? ", " + userName : ""}! 🎉
        </p>
      )}
    </Center>
  );
};

export default Loader;
