import ActionModal from "@/components/modal/ActionModal";
import { Dict, Entity } from "@utils/interface";

interface Props {
  type: Entity.documents | Entity.folders;
  actionModal: Dict;
  setActionModal: React.Dispatch<React.SetStateAction<Dict>>;
  isLoading: boolean;
  onConfirm: () => void;
}

const TitleBox = (props: Props) => {
  const { type, isLoading, actionModal, setActionModal, onConfirm } = props;
  const item = type == Entity.folders ? "Folder" : "Document";
  return (
    <ActionModal
      card
      overlay
      handleConfirm={onConfirm}
      onDone={() => {
        setActionModal({
          ...actionModal,
          addTitleType: undefined,
          addTitle: false,
        });
      }}
      isLoading={isLoading}
      confirmText={`Add ${item}`}
    >
      <div className="pt-10 pl-10 pr-10">
        {/* <p className="text-lg text-center font-bold pb-10">New {item}</p> */}
        <input
          type="text"
          placeholder={`Enter ${item} Name`}
          value={actionModal?.newTitle || ""}
          onChange={(e) => {
            setActionModal((prev) => ({ ...prev, newTitle: e.target.value }));
          }}
          className="w-full border-b-2 border-purple-300 focus:outline-none focus:border-purple-500 transition-all duration-300 ease-in-out"
        />
      </div>
    </ActionModal>
  );
};

export default TitleBox;
