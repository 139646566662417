import { Avatar, Center } from "@chakra-ui/react";
import { useContext, useEffect, useState, useRef } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { FiFileText, FiFile, FiUploadCloud } from "react-icons/fi";
import { PiTrashLight } from "react-icons/pi";
import AppButton from "@components/AppButton";
import {
  // DEFAULT_US_DATE_FORMAT,
  HTTP_METHODS,
  // appColors,
  isNullOrEmpty,
  getFileSize,
  getDateString,
  PLANS,
  BASIC_FILE_LIMIT,
  Dict,
  includesString,
  ellipsis,
  Entity,
  MAX_FILE_SIZE,
} from "@utils/index";
import { httpClient } from "@providers/client";
import {
  useCopyToClipboard,
  useDisplayToast,
  useFetchAccount,
  usePlanFeatureCheck,
} from "@utils/hooks";
import {
  IoEllipsisHorizontal,
  IoCloseCircleOutline,
  IoWarning,
} from "react-icons/io5";
import { BiSad, BiTrash } from "react-icons/bi";
// import { MdCancel } from "react-icons/md";
import uploadIcon from "@assets/upload-icon.svg";
import ActionModal from "@/components/modal/ActionModal";
import Table from "@components/Table";
import { FileData, fileOverLimit, handleFileSelect } from "./helpers";
import { ContextStore } from "@components/utils/Context";
import { useOnClickOutside } from "usehooks-ts";
import NewBadge from "@components/NewBadge";
import { useAlwaysScrollToBottom } from "@/utils/helpers";

interface Props {
  setViewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchFiles?: () => void;
  dragNDropOnly?: boolean;
  onDone?: () => void;
  fileData?: Dict;
}

const UploadView = (props: Props) => {
  const { setViewOpen, refetchFiles, dragNDropOnly, fileData: filePayload, } = props;
  const { account, files, actionModal, setActionModal, setGlobalState } =
    useContext(ContextStore);
  const { needAccess } = usePlanFeatureCheck();
  const { refreshAccount } = useFetchAccount();
  const { successToast, errorToast, warningToast } = useDisplayToast();
  const [showCategory, setShowCategory] = useState(false);
  const multipleCategories = ["Source", "Voice", "Proposal", "Grant"];
  const categories: string[] = [
    "Current Board of Directors list",
    "Latest Annual Report",
    "Current Financial Statements",
    "Current Org Budget",
    "Bylaws",
    "Letters of Support",
    "Quotes and Testimonials",
    ...multipleCategories,
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<FileData[]>([]);
  const [itemIndex, setItemIndex] = useState(-1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { AlwaysScrollToBottom } = useAlwaysScrollToBottom();

  const categoryRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(categoryRef, () => setShowCategory(false));

  const allowExistingCategory = (category: string) => {
    return filesToUpload.find(
      (file) =>
        !multipleCategories.includes(category) &&
        file.data?.category === category
    );
  };

  const resetFilesToUpload = () => {
    setFilesToUpload([]);
    setShowCategory(false);
  };

  const onDone = () => {
    setViewOpen && setViewOpen(true);
    resetFilesToUpload();
    refetchFiles && refetchFiles();
  };

  const handleOnDone = props?.onDone || onDone;

  const handleUploadCancel = () => {
    setActionModal({ ...actionModal, uploadError: false });
  };

  const warnProposalImport = () => {
    warningToast(
      "For the best results Proposal imports should be a .doc or .docx file",
      "Proposal Import"
    );
  };

  const handleSelectFiles = (files: File[]) => {
    setIsImporting(true);
    const fileDetails = files.map((file) => ({
      file,
      data: { category: "Source" },
    }));
    setFilesToUpload([...fileDetails, ...filesToUpload]);
    setIsImporting(false);
  };

  const handleDelete = (index: number) => {
    // delete item from array
    setFilesToUpload(filesToUpload?.filter((_, i) => i !== index));
  };

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    handleSelectFiles(Array.from(e.dataTransfer.files));
  };

  const handleFileUpload = () => {
    if (
      needAccess(
        [PLANS.FREE, PLANS.BASIC],
        true,
        "Please upgrade your account to upload more files.",
        account?.additionalData?.monthlyFileUploads >= 10
        // || (account?.planName == PLANS.basic && files?.length >= BASIC_FILE_LIMIT),
        // TODO use feature flags for Limits
      )
    )
      return;

    if (!filesToUpload || !filesToUpload?.length) return;
    const formData = new FormData();

    Array.from(filesToUpload)?.map((fd: FileData) => {
      formData.append("files", fd.file);
      formData.append(
        "data",
        JSON.stringify({ ...fd.data, ...(filePayload || {}) })
      );
    });

    setIsLoading(true);

    // setTimeout(() => setIsDirectoryOpen(true), 1000);
    //clearTimeout(directoryTimeout);

    return httpClient(`/upload/files`, {
      method: HTTP_METHODS.POST,
      body: formData,
    })
      .then((res) => {
        // console.log(JSON.stringify(res));
        successToast(res.json.message);
        handleOnDone();
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        errorToast(err?.body?.message, "Error uploading files");
      })
      .finally(() => {
        setIsLoading(false);
        refreshAccount();
      });
  };

  const SelectCategory = (props: { fileData: FileData, index: number }) => {
    const { fileData, index } = props;
    const selectedCategory = fileData.data.category;
    const openDropdown = showCategory && itemIndex == index;

    return (
      <>
        <div className="w-72 h-10 mt-1 flex items-center relative border">
          <div
            onClick={() => setShowCategory((val) => !val)}
            className={`w-full h-full px-3 bg-white text-neutral-400 cursor-pointer flex justify-between items-center`}
          >
            <span className={`${selectedCategory && "text-black"} block`}>
              {!selectedCategory ? "Select Category" : selectedCategory}
            </span>
            <span className="block">
              {openDropdown ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </div>

          {openDropdown && (
            <div
              ref={categoryRef}
              className={`absolute top-10 w-full bg-white border-x border-b h-[9.5rem] overflow-y-scroll z-[9999]`}
            >
              {categories?.map((category) => {
                return (
                  <div
                    onClick={() => {
                      if (category === "Proposal") warnProposalImport();
                      setFilesToUpload(filesToUpload.map((file, i) => (i === index) ? { ...file, data: { category } } : file));
                      setShowCategory(false);
                    }}
                    key={category}
                    className={`w-full h-10 ${allowExistingCategory(category)
                      ? "text-neutral-300 cursor-default"
                      : "hover:bg-[#FCFAFF] cursor-pointer"
                      } px-3 flex justify-between items-center`}
                  >
                    <span className="block z-1">{category}</span>
                    {includesString(["Grant", "Proposal"], category) && (
                      <NewBadge text={"new"} />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    )
  }

  const hasFilesOverLimit = filesToUpload?.some((f) => fileOverLimit(f.file));

  return (
    <div className={` ${dragNDropOnly ? "bg-white mt-10 sm:min-w-[500px] h-full" : ""}`}>
      {actionModal?.uploadError && (
        <ActionModal
          title={"Error"}
          icon={<IoWarning />}
          handleClose={handleUploadCancel}
        >
          <p>File size larger than {MAX_FILE_SIZE}MB.</p>
          <p>Please select a file smaller than {MAX_FILE_SIZE}MB.</p>
        </ActionModal>
      )}

      <div className={`mb-6 pt-[24px] px-5 z-30 top-[56px] flex ${dragNDropOnly ? "justify-end" : "sticky justify-between"} items-end border-b bg-white`}>
        {!dragNDropOnly && <h1 className="text-2xl font-bold">Upload Files</h1>}
        {files?.length ? (
          <button
            onClick={() => {
              handleOnDone();
            }}
            className="text-[#6b4bbe] hover:bg-white hover:text-[#B784F4]"
          >
            <IoCloseCircleOutline size={40} />
          </button>
        ) : null}
      </div>

      <div className="px-5 flex flex-col bg-white">
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`w-full py-6 mb-14 flex flex-col justify-center items-center rounded-lg border border-neutral-400 border-dashed hover:cursor-pointer`}
          onClick={(e) => {
            fileInputRef.current?.click();
          }}
        >
          <img src={uploadIcon} alt="upload-icon" />
          <p className="mt-4 mb-2">Select a file or drag and drop here</p>
          <p className=" mb-5 text-xs text-neutral-500">
            JPG, PNG, DOCX or PDF, file size no more than {MAX_FILE_SIZE}MB
          </p>
          <label
            className={`cursor-pointer mb-2 py-1 px-4 border text-[#6B46C1] border-[#6B46C1] rounded hover:bg-secondary-dark hover:text-white`}
          >
            Select Files
          </label>
          <input
            onChange={(e) => {
              // console.log(e.target.files?.length)
              handleSelectFiles(Array.from(e.target.files || []));
              // https://stackoverflow.com/a/12102992
              // input file selection event not firing upon selecting the same file
              e.target.value = '';
            }}
            ref={fileInputRef}
            type="file"
            multiple
            id="upload-file"
            className={`hidden`}
          />
        </div>

        <Table
          isLoading={isImporting}
          className={`${filesToUpload?.length > 0 ? "pb-20" : "pb-5"}`}
          tHeaders={[
            { title: "Name", isSortable: false },
            { title: "Category", isSortable: false },
            { title: "File Size", isSortable: false },
          ]}
          tBody={
            filesToUpload?.length > 0 &&
            filesToUpload?.map((fileData, idx) => {
              const overLimit = fileOverLimit(fileData?.file);
              return (
                <tr className={`h-14 text-neutral-500 ${overLimit ? 'children:bg-red-200' : ''}`} key={idx} onClick={() => setItemIndex(idx)}>
                  <td className={`w-[42%] pr-1 bg-white min-w-[150px] sticky left-0`}>
                    <div className={`flex-y-center h-full`}>
                      <span className={`mr-3`}>
                        <FiFileText />
                      </span>
                      <span className="line-clamp-1">
                        {ellipsis(fileData?.file?.name, 40)}
                      </span>
                      {overLimit && (
                        <span className="text-red-600 text-xs ml-2">File size over {MAX_FILE_SIZE}MB limit</span>
                      )}
                    </div>
                  </td>
                  <td className="w-[38%] min-w-[150px]">
                    <SelectCategory fileData={fileData} index={idx} />
                  </td>
                  <td className={`${overLimit ? 'text-red-600' : ''} w-[20%] min-w-[90px]`}>
                    {getFileSize(fileData?.file?.size)}
                  </td>
                  <td className={`text-2xl -xs:text-lg ${overLimit ? ' text-red-900 hover:text-red-400' : ' text-red-400 hover:text-red-900'}`}>
                    <div className="flex-center">
                      <button onClick={() => handleDelete(idx)} autoFocus={overLimit}>
                        <PiTrashLight />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        />

        <div className="flex-center pb-10">
          <AppButton
            text="Upload Files"
            icon={FiUploadCloud}
            variant="primary"
            iconPosition="right"
            onClick={handleFileUpload}
            zIndex={0}
            isLoading={isLoading}
            isDisabled={!filesToUpload?.length || hasFilesOverLimit}
          />
        </div>
        <AlwaysScrollToBottom />
      </div>
    </div>
  );
};

export default UploadView;
