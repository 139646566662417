import moment from "moment";

interface ResultProps {
  title: string;
  description: string;
  timestamp: string;
  action1Icon: React.ReactNode;
  action2Icon: React.ReactNode;
  action1Callback: (() => void) | null;
  action2Callback: (() => void) | null;
}

const SearchResult = (props: ResultProps) => {
  const {
    title,
    timestamp,
    description,
    action1Icon,
    action2Icon,
    action1Callback,
    action2Callback,
  } = props;
  return (
    <div className=" mb-3 flex-y-center justify-between">
      <div className="mr-5 md:mr-12 lg:mr-20">
        {title && <p className="line-clamp-1 text-black">{title}</p>}
        {description && <p className="text-xs">{description}</p>}
      </div>

      <div className="text-xs flex-shrink-0">
        {timestamp && <p>{moment(timestamp).format("MMM D, YYYY")}</p>}
        <div className=" mt-1 flex text-sm">
          {action1Callback && (
            <button className="mr-3" onClick={action1Callback}>
              {action1Icon}
            </button>
          )}
          {action2Callback && (
            <button onClick={action2Callback}>{action2Icon}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
