// https://blog.logrocket.com/how-to-create-react-admin-panel/#react-admin-authentication
// src/layouts/AppLayout.js
import {
  defaultTheme,
  Layout,
  AppBar,
  ToggleThemeButton,
  TitlePortal,
  useAuthState,
} from "react-admin";
import { createTheme } from "@mui/material";
import * as React from "react";
import { Drawer } from "@mui/material";
import { SidebarClasses, useLocale, useSidebarState } from "react-admin";
import { ObjectProps } from "@/utils";
import Sidebar from "./components/Sidebar-test";

export const CustomSidebar = ({ children }: ObjectProps) => {
  const { isLoading, authenticated } = useAuthState();

  const [open, setOpen] = useSidebarState();
  // useLocale(); // force redraw on locale change

  const toggleSidebar = () => setOpen(!open);

  return authenticated ? (
    <Drawer
      variant="temporary"
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
    >
      {children}
    </Drawer>
  ) : null;
};

const darkTheme = createTheme({
  palette: { mode: "dark" },
});

const CustomAppBar = () => {
  const { isLoading, authenticated } = useAuthState();

  return authenticated ? (
    <AppBar>
      <TitlePortal />
      <ToggleThemeButton
        lightTheme={defaultTheme}
        // lightTheme={darkTheme}
        darkTheme={darkTheme}
      />
    </AppBar>
  ) : null;
};

export const AppLayout = (props: any) => {
  // const { isLoading, authenticated } = useAuthState();
  // return (<Layout {...props} appBar={authenticated ? CustomAppBar : null} />);
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      // sidebar={CustomSidebar}
      sidebar={Sidebar}
    />
  );
};

export const EmptyAppLayout = (props: any) => {
  return (
    <Layout
      appBarAlwaysOn={false}
      appBar={EmptyBar}
      sidebar={EmptyBar}
      {...props}
      // https://marmelab.com/react-admin/Theming.html
      sx={{
        "& .RaLayout-appFrame": {
          marginTop: "0px",
        },
        "& .RaLayout-content": {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      }}
    />
  );
};

const EmptyBar = () => null;

export const emptyTheme = {
  // https://marmelab.com/react-admin/Theming.html#app-wide-theming
  // https://stackoverflow.com/a/70547765
  // https://stackoverflow.com/a/65986586
  // https://www.youtube.com/watch?v=q8cabjyUTVY&t=700s
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
  },
};
