import AppButton from "@components/AppButton";
import CenterLoader from "@components/utils/CenterLoader";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import { useCommonQueries, useGet, usePut } from "@utils/hooks";
import {
  APP_CONST,
  ellipsis,
  getOriginUrl,
  includesString,
  openInNewTab,
} from "@utils/index";
import { Dict, Entity, Recommendation } from "@utils/interface";
import { useContext, useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { toast } from "sonner";
import IllustrationOne from "@assets/illustration-1.svg";
import IllustrationTwo from "@assets/illustration-2.svg";
import Eclipse from "@assets/eclipse.svg";
import FilterIcon from "@assets/filter-icon.svg";
import RecIllustration from "@assets/recommendations-illustration.svg";
import Filters from "./Filters";
import Result from "./Results";

enum TabName {
  Unseen = "New",
  Interested = "Interested",
  NotInterested = "Not Interested",
}

const Recommendations = () => {
  const {
    recommendations: recs,
    account,
    setRecommendations,
  } = useContext(ContextStore);
  const { fetchRecommendations } = useCommonQueries();
  const [updateRec, { isLoading: isUpdating }] = usePut<Recommendation>();

  const findGrants = useGet<{ recs: Recommendation[] }>(
    `${Entity.recommendations}/find`,
    {
      id: account?.id,
    },
    (data) => setRecommendations((prev) => [...prev, ...data.recs])
    // (error) => toast.error(error?.body?.message, { duration: 5000 })
  );

  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabName>(TabName.Unseen);
  const [tabGroup, setTabGroup] = useState<Tab[]>([]);
  const [filteredRecs, setFilteredRecs] = useState<Recommendation[]>([]);

  const likedRecs = recs?.filter((x) => x?.liked);
  const notInterestedRecs = recs?.filter((x) => x?.liked == false);
  const unseenRecs = recs?.filter((x) => x?.liked == undefined)

  const handleTab = (tab: TabName) => {
    setCurrentTab(tab);
    switch (tab) {
      case TabName.Interested:
        setFilteredRecs(likedRecs);
        break;
      case TabName.NotInterested:
        setFilteredRecs(notInterestedRecs);
        break;
      default:
      case TabName.Unseen:
        setFilteredRecs(unseenRecs);
        break;
    }
  };

  const handleUpdate = async (id: string, data: Dict, cb?: () => void) => {
    // console.log("handleUpdate", data);
    updateRec(
      Entity.recommendations,
      {
        id,
        data
      },
      {
        onSuccess(data, variables, context) {
          // console.log("success", JSON.stringify(data));
          fetchRecommendations.refetch();
          cb && cb();
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          toast.error(error?.body?.message || APP_CONST.DEFAULT_ERROR);
        },
      }
    );
  }

  // const NoResults = () => {
  //   return (
  //     <p className="text-xl p-[5rem] w-1/2 mx-auto">
  //       Sorry, no recommendations found for your organization. Don't want to
  //       leave you empty-handed so as a thank you for trying this out, checkout{" "}
  //       <a
  //         href="https://www.causeiq.com/directory/grants/?utm_source=www.grantwriteai.com"
  //         target="blank"
  //         className="text-primary-dark font-semibold"
  //       >
  //         this huge directory of grants
  //       </a>
  //       .
  //     </p>
  //   );
  // };


  const EmptyState = () => {
    return (
      <div className="h-full w-full max-w-[1100px] pb-10 flex -md:flex-col justify-between items-center md:gap-10 lg:gap-2 ">
        <div className="md:w-1/2 md:max-w-[500px] -md:flex -md:flex-col md:flex-shrink -md:text-center -md:items-center">
          <GrCertificate size={80} className="text-[#8B5CF6]" />
          <p className="mb-5 text-5xl font-bold leading-[60px]">
            Get personalized recommendations for your organization.
          </p>
          <p className="text-gray-500">
            Don't worry if you're new to this – our AI assistance is here to
            help to get recommendations for you that aligns with your company
            goals.
          </p>
          <AppButton
            text={"Find Matched Grants"}
            variant="secondary"
            onClick={() => findGrants.refetch()}
            margin={"auto"}
            marginTop={"40px"}
            width={"226px"}
          />
        </div>

        <div className="w-full max-w-max -md:my-14">
          <img className="h-[80%]" src={RecIllustration} alt="" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchRecommendations.refetch();

    const interval = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(interval);
    }
  }, []);

  useEffect(() => {
    setIsLoading(fetchRecommendations?.isFetching || findGrants?.isFetching || false);
  }, [fetchRecommendations?.isFetching, findGrants?.isFetching]);

  useEffect(() => {
    setTabGroup([
      {
        name: TabName.Unseen,
        results: unseenRecs?.length,
      },
      {
        name: TabName.Interested,
        results: likedRecs?.length,
      },
      {
        name: TabName.NotInterested,
        results: notInterestedRecs?.length,
      },
    ]);
    handleTab(currentTab);
  }, [recs]);

  if (isLoading) {
    return <RecsLoader />;
  }

  // console.log(recs);
  return (
    <>
      {showFilters && <Filters setIsFilter={setShowFilters} />}
      <div className="pb-8 relative h-full">
        <div className="h-full pb-2 mb-5 pt-[30px] px-5 flex flex-col sticky top-14">
          <div className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-2xl font-bold">Recommendations</h1>

            {recs?.length && (
              <button
                onClick={() => setShowFilters(true)}
                className="h-7 p-4 flex items-center gap-4 text-primary-dark hover:bg-secondary-dark hover:text-primary-light text-xs font-medium bg-primary-light border border-[#d5d7da] rounded-md shadow"
              >
                <span className="-sm:hidden">Edit Your Grant Filter</span>
                <img src={FilterIcon} alt="filter icon" />
              </button>
            )}
          </div>

          <p className="mt-3 mb-8 text-sm text-[#777795]">
            Recommended for you is a personalized cycling grant recommendation
            tailored to your nonprofit's mission and programs. These grants are
            specially curated based on your organization's mission, ensuring
            that they align perfectly with your goals and objectives. With these
            specially curated recommendations, you can access the grants needed
            to propel your organization forward and make a lasting difference.
          </p>

          {/* <p className="pt-5 pb-5 text-sm text-[#777795]">
          Automatically / periodically we will continue to search for grants
          that match your organization's profile.
          <b> Check back often for new recommendations.</b>
        </p> */}

          {!recs?.length ? (
            <EmptyState />
          ) : (
            <>
              <TabGroup
                tabGroup={tabGroup}
                currentTab={currentTab}
                handleTab={handleTab}
              />
              <div className="grid grid-cols-3 -lg:grid-cols-2 -md:grid-cols-1 gap-y-5 gap-x-6">
                {filteredRecs?.map((grant, _idx) => {
                  return (
                    <div key={grant?.id}>
                      <Result rec={grant} handleUpdate={handleUpdate} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {/* {fetchRecommendations?.isFetching || findGrants?.isFetching ? (
          <CenterLoader />
        ) : null} */}
        </div>
      </div>
    </>
  );
};



interface Tab {
  name: TabName;
  results: number;
}

interface TabProps {
  tabGroup: Tab[];
  currentTab: TabName;
  handleTab: (tab: TabName) => void;
}

const TabGroup = (props: TabProps) => {
  const { tabGroup, currentTab, handleTab } = props;
  return (
    <div className="mb-8 pb-4 flex items-center border-b-[1.5px] border-gray-300">
      {tabGroup.map((tab) => {
        return (
          <div className="px-3 relative">
            <div
              onClick={() => handleTab(tab.name)}
              className="flex items-center gap-1.5 cursor-pointer"
            >
              <span
                className={`${currentTab === tab.name
                  ? "text-[#6b46c1] font-bold"
                  : "text-[#24292F]"
                  } text-sm`}
              >
                {tab.name}
              </span>
              <div
                className={`${currentTab === tab.name
                  ? "text-white bg-[#553c9a]"
                  : "text-[#6b46c1] bg-primary-light"
                  } h-5 w-7 flex justify-center items-center text-xs rounded-3xl`}
              >
                {tab.results}
              </div>
            </div>
            <div
              className={`${currentTab !== tab.name ? "hidden" : "absolute"
                } w-full h-0.5 left-0 -bottom-[17px] bg-secondary-dark`}
            />
          </div>
        );
      })}
    </div>
  );
};

const RecsLoader = () => {
  return (
    <div className="h-full flex justify-center w-full">
      <div className="overflow-hidden w-full max-w-[1095px] h-full px-20 -md:pt-14 -sm:pt-0 flex items-end -md:items-center relative">
        <img
          className="-lg:h-64 -sm:h-56 absolute left-10 top-5 z-10"
          src={IllustrationOne}
          alt=""
        />
        <img
          className="-lg:h-56 absolute right-10 bottom-0 z-10"
          src={IllustrationTwo}
          alt=""
        />
        <img className="h-[530px] -lg:h-[550px] mx-auto" src={Eclipse} alt="" />
      </div>
    </div>
  );
};

export default Recommendations;
