import { useSteps } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Step {
  stepNo: number;
  description: string;
  path: string;
}

interface Props {
  steps: Step[];
  step: number;
  setStep: (params: number) => void;
  onNext?: () => void;
}

const Index = ({ steps, step, setStep, onNext }: Props) => {
  const navigate = useNavigate();

  const { activeStep, setActiveStep } = useSteps({
    index: step,
    count: steps?.length,
  });

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  const handleGoTo = (step: Step) => {
    if (onNext) onNext();
    return step?.path ? navigate(step?.path) : setStep(step?.stepNo);
  };

  return (
    <div className={` text-xs sm:text-sm text-[#444] grid grid-cols-3 gap-x-3`}>
      {steps?.map((step, idx) => (
        <div className="text-center" key={idx}>
          <div className="w-full h-10 mb-2 flex items-center">
            <div className="w-full h-[2px] bg-[#B794F4]" />

            {activeStep !== step?.stepNo ? (
              <button
                onClick={() => handleGoTo(step)}
                className="w-7 h-7 bg-[#B794F4] flex-shrink-0 rounded-full flex justify-center items-center"
              >
                {step?.stepNo}
              </button>
            ) : (
              <button className="p-1 rounded-full border border-[#B794F4]">
                <div className="w-7 h-7 text-white bg-[#B794F4] flex-shrink-0 rounded-full flex justify-center items-center">
                  {step?.stepNo}
                </div>
              </button>
            )}

            <div className="w-full h-[2px] bg-[#B794F4]" />
          </div>

          <span className=" cursor-pointer" onClick={() => handleGoTo(step)}>
            {step?.description}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Index;
