import { BoxProps, Image, Switch } from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  FiBriefcase,
  FiFilePlus,
  FiPenTool,
  FiTrello,
  FiCalendar,
  FiLayers,
  FiAward,
  FiPlus,
  FiMenu,
  FiUpload,
  FiFolder,
  FiFileText,
} from "react-icons/fi";
import { LuLayoutDashboard } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import AppButton from "./AppButton";
import { TbReportSearch } from "react-icons/tb";
import { useContext, useEffect, useRef, useState } from "react";
// import { ContextStore } from "./utils/Context";
import {
  Account,
  AccountType,
  AddTabType,
  Dict,
  Entity,
  FeatureFlag,
  Flags,
  SideBarMenuTitle,
  User,
} from "@utils/interface";
import { isMobile } from "react-device-detect";
import { useOnClickOutside } from "usehooks-ts";
import { MdLink, MdSwitchAccount } from "react-icons/md";
import { ContextStore } from "./utils/Context";
import { useDisplayToast, usePost, usePut } from "@utils/hooks";
import TabModal from "./modal/TabModal";
import NewAccountForm from "@pages/agency/NewAccountForm";
import {
  PLANS,
  PRICING_URL,
  appName,
  ellipsis,
  shouldUpgrade,
  openInNewTab,
  APP_CONST,
  includesString,
  isWhiteListAgency,
  isDev,
  isFreeAccount,
  isAccountType,
  isNotAgencyCheck,
  getNumberOfActiveClients,
} from "@utils/index";
import AccountSwitcher from "@pages/agency/AccountSwitcher";
import { FaSearchDollar } from "react-icons/fa";
import { BsChatSquareDotsFill, BsChatSquareDots, BsChatLeftQuote } from "react-icons/bs";
import { TbMessageDollar } from "react-icons/tb";
import NewBadge from "./NewBadge";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { Progress } from "@chakra-ui/react";
import { BiLike } from "react-icons/bi";
// import { TbWorldSearch } from "react-icons/tb";

interface SidebarMenuProps {
  logo: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleAddGrant: React.MouseEventHandler<HTMLButtonElement>;
}

export const SidebarMenu = (props: BoxProps & SidebarMenuProps) => {
  const { handleAddGrant, isOpen, onClose, onOpen, logo, ...rest } = props;
  // const isOpen = true;
  const [accountModal, showAccountModal] = useState(false);
  const [newAccount, setNewAccount] = useState<Account>({} as Account);
  // TODO: caused warning React has detected a change in the order of Hooks called by SidebarItem. This will lead to bugs and errors if not fixed
  const {
    flags,
    account,
    setAccount,
    user,
    setUser,
    globalState,
    team,
    // setTeam,
    actionModal,
    setActionModal,
    setGlobalState,
  } = useContext(ContextStore);
  const { successToast, warningToast, errorToast } = useDisplayToast();
  const navigate = useNavigate();

  // console.log("flags", flags);

  const [update, { isLoading: isUpdating }] = usePut<User>();
  const [create, { isLoading: isCreating }] = usePost<Account>();

  const showSidebar = isOpen || !isMobile;

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  // support switching accounts
  const handleSwitchAccount = (switchAccount: Account) => {
    const isSwitchingBackToAgency =
      switchAccount?.id == globalState?.agency?.id;
    // update user onBehalfOf
    update(
      `${Entity.users}`,
      {
        id: user.id,
        data: {
          onBehalfOf: isSwitchingBackToAgency
            ? { disconnect: true }
            : { connect: { id: switchAccount.id } },
        },
      },
      {
        onSuccess: (data) => {
          // console.log("data", data);
          setUser(data);
          successToast("Account switched");
          //set account to the new account
          setAccount(switchAccount);
          // if (isSwitchingBackToAgency) {
          //   setTeam(switchAccount?.users);
          // } else {
          //   setTeam((prev) => [
          //     ...prev,
          //     ...(switchAccount?.users ? switchAccount.users : []), //pollutes user from other client accounts
          //   ]);
          // }
          showAccountModal(false);
          if (window?.location?.pathname.includes("/board")) {
            setGlobalState((prev) => ({ ...prev, rerenderBoard: new Date() }));
          }
          if (window?.location?.pathname.includes("/documents")) {
            // refresh docs if changes account
            navigate(`/${Entity.documents}`);
          }
          setGlobalState((prev) => ({ ...prev, folderView: undefined }));
        },
        onError: (error) => {
          errorToast(error?.body?.message || "Error switching account");
        },
      }
    );
  };

  //support creating new account
  const handleCreateAccount = (newAccount: Account) => {
    if (Object.keys(newAccount).length === 0) {
      warningToast("Please fill in the account details", "Invalid input");
      return;
    }
    if (team?.find((u) => u?.email === newAccount?.primaryContact?.email)) {
      warningToast(
        "Client's primary contact must be the client's and not someone on your team",
        "Invalid input"
      );
      return;
    }
    create(
      `${Entity.accounts}/link/${globalState?.agency?.id}`,
      {
        data: newAccount as Account,
      },
      {
        onSuccess: (data) => {
          // console.log("data", data);
          successToast("Account added successfully");
          handleSwitchAccount(data as Account);
          setGlobalState((prev) => ({
            ...prev,
            agency: {
              ...prev.agency,
              linkedAccounts: [...(prev.agency.linkedAccounts || []), data],
            },
          }));
          showAccountModal(false);
          setNewAccount({} as Account);
          setActionModal({ ...actionModal, agencyAddClient: false });
        },
        onError: (error) => {
          errorToast(error?.body?.message || "Error creating account");
        },
      }
    );
  };

  // console.log("newAccount", newAccount);
  // console.log("globalState", globalState?.agency, account);

  return (
    <div
      ref={ref}
      onMouseEnter={() => onOpen()}
      onMouseLeave={() => onClose()}
      // className={` ${isOpen ? "w-[290px]" : "w-[70px]"
      //   } group overflow-hidden h-full flex flex-col fixed top-0 left-0 z-[1000] bg-white overflow-x-hidden overflow-y-hidden`}
      className={`group overflow-hidden h-full flex flex-col fixed top-0 left-0 z-[1000] overflow-x-hidden overflow-y-hidden`}
    >
      <div
        className={`w-full h-full flex-shrink-0 flex flex-col items-center text-gray-600`}
      >
        <Hamburger
          isOpen={isOpen}
          logo={logo}
          onOpen={onOpen}
          onClose={onClose}
          planName={
            globalState?.isAgency && !isWhiteListAgency(user?.accountId)
              ? globalState?.agency?.planName
              : account?.planName
          }
        />

        <div
          className={`pt-5 pb-8 h-full w-full flex flex-col items-center justify-between bg-[#fcfaff] border-r`}
        >
          {globalState?.isAgency && accountModal && (
            <AccountSwitcher
              showAccountModal={showAccountModal}
              handleSwitchAccount={handleSwitchAccount}
            />
          )}

          {actionModal?.agencyAddClient && (
            <TabModal
              title={"New Client"}
              handleClose={() => {
                setNewAccount({} as Account);
                setActionModal({ ...actionModal, agencyAddClient: false });
              }}
              isLoading={isCreating}
              tabs={[
                {
                  title: "Add New Account",
                  icon: <MdLink />,
                  content: (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleCreateAccount(newAccount);
                      }}
                    >
                      <NewAccountForm
                        newAccount={newAccount}
                        setNewAccount={setNewAccount}
                        customFields={[
                          {
                            label: "Country",
                            type: "text",
                            key: "country",
                            placeholder: "United States",
                          },
                          // {
                          //   label: "Phone",
                          //   type: "tel",
                          //   key: "phone",
                          //   placeholder: "1234567890",
                          //   pattern: "^[+]?[0-9]{9,12}$",
                          // },
                          // {
                          //   label: "History",
                          //   type: "textarea",
                          //   key: "history",
                          //   placeholder: "Enter the organization's history",
                          //   required: false,
                          //   visible: true,
                          // },
                          {
                            label: "Mission",
                            type: "textarea",
                            key: "mission",
                            placeholder: "Enter the organization's mission",
                            required: false,
                            visible: true,
                          },
                        ]}
                        customHeader={
                          isWhiteListAgency(user?.accountId) ? (
                            <div className="flex flex-col text-center mb-10">
                              <div className="flex-center pb-5 mt-4 text-xl">
                                <p className="">Agency?</p>
                                <Switch
                                  id="isAgency"
                                  isChecked={
                                    newAccount?.type === AccountType.AGENCY
                                  }
                                  colorScheme="purple"
                                  className="ml-2 -sm:mr-3"
                                  onChange={() =>
                                    setNewAccount({
                                      ...newAccount,
                                      type:
                                        newAccount?.type === AccountType.AGENCY
                                          ? AccountType.REGULAR
                                          : AccountType.AGENCY,
                                    })
                                  }
                                />
                                <p className="ml-2">Free?</p>
                                <Switch
                                  id="isFree"
                                  isChecked={newAccount?.planName == PLANS.FREE}
                                  colorScheme="purple"
                                  className="ml-2 -sm:mr-3"
                                  onChange={() =>
                                    setNewAccount({
                                      ...newAccount,
                                      planName:
                                        newAccount?.planName === PLANS.FREE
                                          ? undefined
                                          : PLANS.FREE,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ) : null
                        }
                      />
                      <div className="items-center flex flex-col">
                        <button
                          className={`w-1/2 bg-purple-500 text-white py-2 rounded-lg mt-4 transition-all duration-300 ease-in-out ${!newAccount?.name ||
                            !newAccount?.primaryContact?.email
                            ? "opacity-50 cursor-default"
                            : "hover:bg-purple-600"
                            }`}
                          disabled={
                            !newAccount?.name ||
                            !newAccount?.primaryContact?.email
                          }
                          type="submit"
                        >
                          Create Account
                        </button>
                      </div>
                    </form>
                  ),
                },
                // {
                //   title: "Link Client",
                //   icon: MdLink,
                //   content: (
                //     <div className="flex flex-col space-y-4">
                //       <input
                //         placeholder={"Enter Client Account ID"}
                //       // value={newAccount?.name}
                //       // onChange={(e) =>
                //       //   setNewAccount({ ...newAccount, name: e.target.value })
                //       // }
                //       />
                //     </div>
                //   ),
                // },
              ]}
            />
          )}

          <div className="w-full">
            {isOpen &&
              isNotAgencyCheck(globalState, user?.accountId) &&
              account?.additionalData?.shouldUpgrade && (
                <button
                  className={`flex-center mx-auto w-1/2 bg-purple-500 text-white py-2 mb-2 rounded-lg transition-all duration-300 ease-in-out hover:bg-purple-600`}
                  // onClick={() => openInNewTab(PRICING_URL)}
                  onClick={() =>
                    window.open(
                      PRICING_URL(
                        globalState?.isAgency ? PLANS.AGENCY : PLANS.PRO
                      ),
                      "_self"
                    )
                  }
                >
                  <MdOutlineRocketLaunch className="mr-2" />
                  Upgrade Account
                </button>
              )}
            <SidebarItem
              onClick={() => {
                if (globalState?.isAgency) {
                  showAccountModal(!accountModal);
                }
              }}
              icon={MdSwitchAccount}
              isOpen={isOpen}
              onClose={onClose}
              enable={globalState?.isAgency === true}
            >
              {ellipsis(account?.name, 20) || "Workspace"}
            </SidebarItem>
            <SidebarItem
              path={"/"}
              icon={LuLayoutDashboard}
              isOpen={isOpen}
              onClose={onClose}
            >
              {SideBarMenuTitle.Dashboard}
            </SidebarItem>
            <SidebarItem
              path={"/organization"}
              icon={FiBriefcase}
              isOpen={isOpen}
              onClose={onClose}
            >
              {SideBarMenuTitle.Organization}
            </SidebarItem>
            <SidebarItem
              path={`/${Entity.files}`}
              icon={FiUpload}
              isOpen={isOpen}
              onClose={onClose}
            >
              {SideBarMenuTitle.Files}
            </SidebarItem>
            {
              <SidebarItem
                path={`/${Entity.chats}`}
                icon={TbMessageDollar}
                isOpen={isOpen}
                onClose={onClose}
              >
                <div className="flex">
                  {SideBarMenuTitle.Chats}
                  <NewBadge className="ml-2" color={"green"} />
                </div>
              </SidebarItem>
            }
            {(isDev || isWhiteListAgency(user?.accountId)) && (
              <SidebarItem
                path={`/${Entity.recommendations}`}
                icon={BiLike}
                isOpen={isOpen}
                onClose={onClose}
              >
                <div className="flex">
                  {SideBarMenuTitle.Recommendations}
                  <NewBadge className="ml-2" color={"green"} />
                </div>
              </SidebarItem>
            )}
            <SidebarItem
              path={"/documents"}
              icon={FiFileText}
              isOpen={isOpen}
              onClose={onClose}
            >
              <div className="flex">{SideBarMenuTitle.Documents}</div>
            </SidebarItem>
            <SidebarItem
              path={`/${Entity.proposals}`}
              icon={FiFolder}
              isOpen={isOpen}
              onClose={onClose}
            >
              <div className="flex">
                {SideBarMenuTitle.Folders}
                {/* <NewBadge className="ml-2" text={"New"} color={"green"} /> */}
              </div>
            </SidebarItem>
            <SidebarItem
              path={"/board"}
              icon={FiTrello}
              isOpen={isOpen}
              onClose={onClose}
            >
              {SideBarMenuTitle.Board}
            </SidebarItem>
            <SidebarItem
              path={"/calendar"}
              icon={FiCalendar}
              isOpen={isOpen}
              onClose={onClose}
            >
              <div className="flex">
                {SideBarMenuTitle.Calendar}
                {/* <NewBadge className="ml-2" text={"New"} color={"green"} /> */}
              </div>
            </SidebarItem>
            {!!flags?.v2?.isEnabled && (
              <SidebarItem
                path={`/${Entity.projects}`}
                icon={FiLayers}
                isOpen={isOpen}
                onClose={onClose}
              >
                {SideBarMenuTitle.Projects}
              </SidebarItem>
            )}
            {!!flags?.v2?.isEnabled && (
              <SidebarItem
                path={"/funders"}
                icon={FiAward}
                isOpen={isOpen}
                onClose={onClose}
              >
                {SideBarMenuTitle.Funders}
              </SidebarItem>
            )}
            {flags?.v3?.isEnabled && (
              <SidebarItem
                path={`/${Entity.reports}`}
                icon={TbReportSearch}
                isOpen={isOpen}
                onClose={onClose}
              >
                {SideBarMenuTitle.Reports}
              </SidebarItem>
            )}
            {/* 
              <SidebarItem path={"/chat"} icon={FiCpu}>
                AI Chat
              </SidebarItem> 
            */}
          </div>

          <div className={`w-full ${isOpen ? "px-6" : "px-2"} `}>
            <AppButton
              text={isOpen ? "Import New Grant" : ""}
              icon={FiPlus}
              variant="secondary"
              iconPosition="left"
              onClick={handleAddGrant}
              margin={"0"}
              width={"100%"}
            />
          </div>

          {isOpen && (
            <div className={`w-[90%] mt-1 justify-start`}>
              {isNotAgencyCheck(globalState, user?.accountId) &&
                shouldUpgrade(account, AccountType.REGULAR) && (
                  <>
                    <p className={`text-xs`}>
                      AI Invocation Usage This Month:{" "}
                      {account?.additionalData?.monthlyAIQuota || 0} / 10{" "}
                    </p>
                    <Progress
                      hasStripe
                      max={APP_CONST.MONTH_AI_QUOTA}
                      value={account?.additionalData?.monthlyAIQuota || 0}
                      size={"lg"}
                      colorScheme={"purple"}
                    />
                  </>
                )}
              {globalState?.isAgency &&
                isAccountType(
                  isWhiteListAgency(user?.accountId)
                    ? account
                    : globalState?.agency,
                  AccountType.AGENCY
                ) &&
                isFreeAccount(
                  isWhiteListAgency(user?.accountId)
                    ? account
                    : globalState?.agency
                ) && (
                  <>
                    <p className={`text-xs`}>
                      {" "}
                      {getNumberOfActiveClients(
                        (isWhiteListAgency(user?.accountId)
                          ? account
                          : globalState?.agency
                        )?.linkedAccounts
                      ) || 0}{" "}
                      / 5 Free Clients
                    </p>
                    <Progress
                      hasStripe
                      max={5}
                      value={
                        getNumberOfActiveClients(
                          (isWhiteListAgency(user?.accountId)
                            ? account
                            : globalState?.agency
                          )?.linkedAccounts
                        ) || 0
                      }
                      size={"lg"}
                      colorScheme={"purple"}
                    />
                  </>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface SidebarItemProps {
  icon: IconType;
  isOpen: boolean;
  path?: string;
  children?: React.ReactNode;
  onClose: () => void;
  onClick?: () => void;
  enable?: boolean;
}

export const SidebarItem = (
  props:
    | (SidebarItemProps & { path: string })
    | (SidebarItemProps & { onClick: () => void })
) => {
  const navigate = useNavigate();
  const {
    icon: IconComponent,
    isOpen,
    path,
    children,
    onClose,
    onClick,
    enable = true,
  } = props;
  const isActive = window.location.pathname === path;

  const handleNavigate = () => {
    if (path) {
      navigate(path);
      onClose();
    }
  };

  return (
    <div
      onClick={onClick ? onClick : handleNavigate}
      className={`w-full h-max py-4 px-6 rounded text-xl flex justify-start transition duration-150 ease-linear ${enable && "hover:bg-[#eee8f8] hover:text-[#6a54b1] cursor-pointer"
        }
        ${isActive && "bg-[#eee8f8] text-[#6a54b1]"}
        ${isOpen && "pr-24"}
      `}
    >
      <IconComponent />
      {isOpen && (
        <span className=" ml-4 text-base leading-tight">{children}</span>
      )}
    </div>
  );
};

export const Hamburger = (props: {
  isOpen: boolean;
  logo: string;
  onOpen: () => void;
  onClose: () => void;
  planName?: string;
}) => {
  const navigate = useNavigate();
  const { isOpen, logo, onClose, onOpen, planName } = props;
  const handleSidebar = () => {
    isOpen ? onClose() : onOpen();
  };

  return (
    <div
      onClick={() => handleSidebar()}
      className={`h-14 ${isOpen ? "w-full" : ""
        } flex-x-center flex-shrink-0 border-b ${isOpen && "border-r bg-white"}`}
    >
      {/* {isMobile && (
        <div className="flex-x-center flex-shrink-0">
          <button
            className={`mt-3 w-8 h-8 flex flex-shrink-0 justify-center items-center rounded hover:bg-slate-200 
                ${isOpen && "bg-slate-200"}`}
          >
            <FiMenu />
          </button>
        </div>
      )} */}

      <div className="w-full flex-y-center">
        <div
          className={`${
            // isMobile ? "ml-0" :
            "ml-5"
            } mr-3 my-auto h-max flex-shrink-0`}
        >
          <Image src={logo} h={8} w={8} />
        </div>
        {isOpen && (
          <>
            <p
              onClick={() => navigate("/")}
              className="text-2xl font-bold text-black"
            >
              {appName}
            </p>
            {planName && (
              <NewBadge
                color={planName === PLANS.FREE ? "green" : "purple"}
                text={
                  planName === PLANS.FREE ? "freemium" : planName?.toLowerCase()
                }
                className="ml-2"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SidebarMenu;
