import { Extension } from "@tiptap/core";
import { TipTapEditor } from "../../interface";

const FocusPlugin = (blurCb?: (e: TipTapEditor) => void, focusCb?: (e: TipTapEditor) => void) => {
  return Extension.create({
    onBlur({ editor, event }: any) {
      // The editor isn’t focused anymore.
      console.log("Editor is blurred");
      blurCb && blurCb(editor);
    },
    onFocus({ editor, event }: any) {
      // The editor is focused.
      console.log("Editor is focused");
      focusCb && focusCb(editor);
    },
  });
};

export default FocusPlugin;
