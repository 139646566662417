import VideoModal from "@/components/modal/VideoModal";
import { ContextStore } from "@components/utils/Context";
import { usePut } from "@utils/hooks";
import { PRODUCT_UPDATE_VIDEOS, includesString } from "@utils/index";
import { Account, Dict, Entity, User } from "@utils/interface";
import { startCase } from "lodash";
import { useContext } from "react";

interface Props {
  videoKeys?: string[];
}

const VideoUpdate = (props: Props) => {
  let { videoKeys } = props;
  videoKeys = videoKeys?.filter((k) => k);

  const {
    user,
    account,
    // globalState,
    appModal,
    setAppModal,
    setUser,
    setAccount,
  } = useContext(ContextStore);
  const [updateUser] = usePut<User>();
  const [updateAccount] = usePut<Account>();

  const canViewModal = (
    key: string,
    type: Entity.users | Entity.accounts = Entity.users
  ) => {
    if (type === Entity.users) {
      return user?.additionalData && !user.additionalData[key];
    }
    return account?.additionalData && !account.additionalData[key];
  };

  const handleOnClose = (
    K: string,
    type: Entity.users | Entity.accounts = Entity.users
  ) => {
    setAppModal((prev) => ({ ...prev, [K]: true }));
    if (type === Entity.users) {
      updateUser(
        Entity.users,
        {
          id: user?.id,
          data: {
            additionalData: {
              ...user?.additionalData,
              [K]: true,
            },
          },
        },
        {
          onSuccess: (data) => {
            setUser(data);
          },
          onError: (error) => {
            console.error("Error updating user data", error);
          },
        }
      );
    } else {
      updateAccount(
        Entity.accounts,
        {
          id: account?.id,
          data: {
            additionalData: {
              ...account?.additionalData,
              [K]: true,
            },
          },
        },
        {
          onSuccess: (data) => {
            setAccount(data);
          },
          onError: (error) => {
            console.error("Error updating account data", error);
          },
        }
      );
    }
  };

  // show update videos
  for (const [key, value] of Object.entries(PRODUCT_UPDATE_VIDEOS) as [
    string,
    Dict
  ][]) {
    if (videoKeys?.length && !includesString(videoKeys, key)) continue;
    const K = `viewed${startCase(key)?.replace(/\s/g, "")}Vid`;
    if (
      // true
      canViewModal(K) &&
      !appModal[K]
      // &&
      // (value?.visible ? value?.visible(globalState) : true)
    ) {
      return (
        <VideoModal
          onClose={() => handleOnClose(K)}
          title={value.title}
          text={value.text}
          src={value.link}
        />
      );
    }
  }

  return null;
};

export default VideoUpdate;
