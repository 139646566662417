import { Checkbox } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";
import { useOnClickOutside } from "usehooks-ts";

const Filters = ({ setIsFilter }) => {
  const filtersRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(filtersRef, () => setIsFilter(false));

  return (
    <div className="bg-black bg-opacity-40 w-screen h-screen fixed top-0 left-0 z-[1100]">
      <div
        className="h-[100vh] px-5 pl-5 pt-8 pb-20 fixed top-0 right-0 bg-white text-gray-700"
        ref={filtersRef}
      >
        <div className="flex items-center justify-between pb-1.5 text-xl font-medium text-gray-900 border-b">
          <p>Your Grant Filters</p>
          <button
            onClick={() => setIsFilter(false)}
            className="p-0 outline-none"
          >
            <RiCloseCircleLine />
          </button>
        </div>

        <div className="max-w-[621px] h-full custom-scrollbar overflow-y-auto">
          <div className="pt-5">
            <p className="mb-1 text-lg font-medium text-gray-800">
              Saved grant recommendations
            </p>

            <div className="mb-6 py-3 px-2 flex -sm:flex-col items-center gap-x-6 gap-y-3 bg-primary-light">
              <p className="text-xs text-gray-700 -sm:text-center">
                Save your grant preferences with our filtering system and when
                new grants align with your organization, we'll notify you via
                email.
              </p>
              <button className="h-9 w-[107px] flex items-center flex-shrink-0 justify-center text-sm font-semibold text-primary-dark bg-secondary-light rounded-lg">
                Save Filters
              </button>
            </div>

            <CollapsibleMenu filterName={"Grant Needs"} type={"main"}>
              <CollapsibleMenu filterName={"Type of support"} type={"sub"}>
                <div className="flex flex-col gap-y-3 gap-x-2.5 text-sm text-gray-500">
                  <Checkbox>
                    <span className="pb-1 inline-block text-sm">
                      grants and funding
                    </span>
                  </Checkbox>
                  <Checkbox>
                    <span className="pb-1 inline-block text-sm">
                      researchers and facilities
                    </span>
                  </Checkbox>
                </div>
              </CollapsibleMenu>

              <CollapsibleMenu filterName={"Amount needed"} type={"sub"}>
                <Dropdown
                  placeholder="Up to $5,000"
                  options={[
                    { value: "Up to $5,000", label: "Up to $5,000" },
                    { value: "Up to $10,000", label: "Up to $10,000" },
                    { value: "Up to $20,000", label: "Up to $20,000" },
                    { value: "Up to $10,000", label: "Up to $50,000" },
                    { value: "Up to $100,000", label: "Up to $100,000" },
                  ]}
                />
              </CollapsibleMenu>

              <CollapsibleMenu filterName={"Main goal"} type={"sub"}>
                <div className="w-max grid sm:grid-cols-2 gap-3 gap-x-2.5 text-sm text-gray-500">
                  <div className="max-w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        increase productivity, quality, safety or efficiency
                      </span>
                    </Checkbox>
                  </div>
                  <div className="max-w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        develop the local economy and infrastructure
                      </span>
                    </Checkbox>
                  </div>
                  <div className="max-w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        reduce pollution or improve energy efficiency
                      </span>
                    </Checkbox>
                  </div>
                  <div className="max-w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        develop the local economy and infrastructure
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </CollapsibleMenu>

              <CollapsibleMenu filterName={"Project area"} type={"sub"}>
                <Dropdown
                  placeholder="develop the local economy and infrastructure"
                  options={[
                    {
                      value: "develop the local economy and infrastructure",
                      label: "develop the local economy and infrastructure",
                    },
                    {
                      value: "Saved grant recommendations",
                      label: "Saved grant recommendations",
                    },
                    {
                      value: "Your grant features",
                      label: "Your grant features",
                    },
                    {
                      value: "develop the local economy and infrastructure",
                      label: "develop the local economy and infrastructure",
                    },
                  ]}
                />
              </CollapsibleMenu>
            </CollapsibleMenu>

            <div></div>

            <CollapsibleMenu filterName={"Location/Industry"} type={"main"}>
              <CollapsibleMenu filterName={"Province/Territory"} type={"sub"}>
                <div className="w-max sm:grid grid-flow-col grid-rows-3 gap-y-3 gap-x-2.5 text-sm text-gray-500">
                  <div className="w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        British Columbia
                      </span>
                    </Checkbox>
                  </div>
                  <div className="w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        Manitoba
                      </span>
                    </Checkbox>
                  </div>
                  <div className="w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        Manitoba
                      </span>
                    </Checkbox>
                  </div>
                  <div className="w-56">
                    <Checkbox>
                      <span className="pb-1 inline-block text-sm">
                        Ontario (Nothern region)
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </CollapsibleMenu>
            </CollapsibleMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

const CollapsibleMenu = ({ filterName, type, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-6">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`w-max mb-3.5 flex items-center gap-1.5 cursor-pointer`}
      >
        <FaAngleDown className={`${!isOpen && "rotate-180"}`} />
        <p
          className={`${type === "sub" ? "text-xs" : "text-md"} font-semibold`}
        >
          {filterName}
        </p>
      </div>

      {isOpen && <>{children}</>}
    </div>
  );
};

interface Option {
  value: string;
  label: string;
}
interface Props {
  placeholder: string;
  options: Option[];
}

const Dropdown = (props: Props) => {
  const { placeholder, options } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className="max-w-[320px] text-xs text-gray-500 relative"
      ref={dropdownRef}
    >
      <div
        className="h-10 px-3 flex items-center justify-between border rounded-lg"
        onClick={toggleDropdown}
      >
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <FaAngleDown className={`${!isOpen && "rotate-180"}`} />
      </div>

      {isOpen && (
        <div className="w-full absolute top-10 z-10 bg-primary-light rounded-b-lg overflow-hidden">
          {options.map((option) => (
            <div
              key={option.value}
              className="h-10 px-3 flex items-center hover:bg-white"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filters;
