// import { isNullOrEmpty } from "@utils/index";
import { MenuAction, MenuActionItem } from "@utils/interface";
import { useRef, useState } from "react";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoEllipsisHorizontal, IoEllipsisVertical } from "react-icons/io5";
import { useOnClickOutside } from "usehooks-ts";
import { Dropdown } from "react-nested-dropdown";
import "react-nested-dropdown/dist/styles.css";
import "./actions.css";
import { filterVisibleItems } from "@utils/index";
import Loader from "./utils/Loader";

interface ActionsProps extends Record<string, any> {
  actions: MenuAction;
  isTableActions?: boolean;
  isVertical?: boolean;
  emptyClass?: boolean;
  isLoading?: boolean;
  icon?: React.ReactNode;
}

const Actions = ({
  actions,
  isTableActions = false,
  isVertical = false,
  emptyClass = false,
  isLoading = false,
  icon,
  ...rest
}: ActionsProps) => {
  const [isActions, setIsActions] = useState(false);

  const actionsRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(actionsRef, () => setIsActions(!isActions));

  const top = filterVisibleItems(actions?.top) || [];
  const bottom = filterVisibleItems(actions?.bottom) || [];
  const items = [...top, ...bottom];

  return (
    <div className={emptyClass ? "relative" : "ml-4 mb-1 relative"}>
      <Dropdown
        items={items}
        containerWidth="200px">
        {({ onClick }) =>
          !items.length ? (
            <></>
          ) : (
            <button
              className={
                emptyClass
                  ? ""
                  : `min-w-9 max-h-9 flex-center text-[20px] ${isTableActions ? "" : "border rounded-md"} flex-y-center cursor-pointer hover:bg-gray-200 hover:rounded-md p-2`
              }
              onClick={onClick}
              {...rest}
            >
              {isLoading ? (
                <Loader size={"sm"} />
              ) : icon ? (
                icon
              ) : isVertical ? (
                <IoEllipsisVertical />
              ) : (
                <IoEllipsisHorizontal />
              )}
            </button>
          )
        }
      </Dropdown>

      {/* 
      {isActions ? (
        <div className="absolute -left-[188px] z-[100]" ref={actionsRef}>
          <div
            className={` w-56 bg-white rounded-lg ${
              top?.length ? "border" : ""
            } border-neutral-300`}
          >
            {top && !!top?.length && (
              <div>
                {top?.map((action, idx) => {
                  return (
                    <ActionItem
                      key={idx}
                      action={action}
                      handleClose={() => setIsActions(false)}
                      isFirst={idx == 0}
                      isLast={
                        bottom?.length ? false : idx === top?.length - 1
                      }
                    />
                  );
                })}
              </div>
            )}
            {bottom && !!bottom?.length && (
              <div
                className={`${
                  top?.length ? "border-t" : ""
                } border-neutral-300`}
              >
                {bottom?.map((action, idx) => {
                  return (
                    <ActionItem
                      key={idx}
                      action={action}
                      handleClose={() => setIsActions(false)}
                      isFirst={top?.length ? false : idx == 0}
                      isLast={idx === bottom?.length - 1}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

interface ActionBtnProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

export const ActionsBtn = ({ children, onClick, className }: ActionBtnProps) => {
  return (
    <button
      className={`min-w-9 max-h-9 flex-center flex-y-center cursor-pointer border rounded-md ml-4 hover:bg-gray-200 hover:rounded-md p-2 ${className || ''}`}
      onClick={onClick}>
      {children}
    </button>
  );
}

// interface ActionItemProps {
//   action: MenuActionItem;
//   handleClose: () => void;
//   isFirst?: boolean;
//   isLast?: boolean;
// }

// const ActionItem = ({
//   action,
//   isFirst,
//   isLast,
//   handleClose,
// }: ActionItemProps) => {
//   const [isSubOpen, setIsSubOpen] = useState(false);

//   const subActionsRef = useRef<HTMLDivElement | null>(null);
//   useOnClickOutside(subActionsRef, () => setIsSubOpen(!isSubOpen));

//   const subActions =
//     action?.subActions?.filter((x) => x?.visible !== false) || [];

//   return (
//     <div className="relative z-[1000]">
//       <div
//         className={` px-4 py-[6px] w-full cursor-pointer  ${
//           isSubOpen && subActions && "bg-[#B794F4] text-white"
//         } hover:bg-[#B794F4] hover:text-white flex justify-between items-center ${
//           isFirst ? "rounded-t-lg" : ""
//         } ${isLast ? "rounded-b-lg" : ""}`}
//         onClick={() => {
//           if (!isNullOrEmpty(subActions)) {
//             setIsSubOpen(!isSubOpen);
//           } else if (action?.fn) {
//             action.fn();
//             handleClose();
//           }
//         }}
//       >
//         <div className="flex-y-center z-0">
//           <span className="mr-2">{action.icon}</span>
//           <p>{action.text}</p>
//         </div>

//         {!!subActions?.length &&
//           (isSubOpen ? <FaAngleLeft /> : <FaAngleRight />)}
//       </div>

//       {/* Sub-Action Menu */}
//       {isSubOpen && (
//         <div
//           className=" z-[100] bg-white border overflow-hidden rounded-lg absolute top-0 -left-28"
//           ref={subActionsRef}
//         >
//           {subActions
//             ?.filter((x) => x?.visible !== false)
//             .map((action, idx) => {
//               return (
//                 <div
//                   key={idx}
//                   className=" px-3 py-[6px] cursor-pointer hover:bg-[#B794F4] hover:text-white flex-y-center"
//                   onClick={() => {
//                     action?.fn && action.fn();
//                     handleClose();
//                   }}
//                 >
//                   {action?.icon && <span className="mr-2">{action?.icon}</span>}
//                   <p>{action.text}</p>
//                 </div>
//               );
//             })}
//         </div>
//       )}
//     </div>
//   );
// };

export default Actions;
