export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "8311722f-1690-4837-bc02-3bbc1c3c4a9c",
        textAlign: "left",
        level: 3,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Timeline",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "9e805961-ae7d-4559-b517-2ebb1261682a",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity ",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "1a0a5e71-ccde-46dc-bfd6-c82be84bde9e",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Description",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "20286386-0ebd-4f11-a2ac-4154c7d68449",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity Start date",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "57af6a7d-3551-4f07-a841-5a7ef9a28939",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(102, 102, 102)",
              },
            },
          ],
          text: "Activity End date",
        },
      ],
    },
  ],
};
