import { IResponse, Event, MenuAction, User, Entity } from "@utils/index";
import { useContext, useEffect, useRef, useState } from "react";
import { BsPencilSquare, BsPeople, BsTrash2Fill } from "react-icons/bs";
import { useOnClickOutside } from "usehooks-ts";
import { BiCalendar, BiCheck, BiTrash, BiX } from "react-icons/bi";
import moment from "moment";
import { useDel, useDisplayToast } from "@utils/hooks";
import Loader from "@components/utils/Loader";
import { MdOutlineNotifications } from "react-icons/md";
import { LuPenLine } from "react-icons/lu";
import { Avatar } from "@chakra-ui/react";

interface ModalProps {
  event: Event;
  handleDone: () => void;
  handleEdit: (event: Event) => void;
}

const ViewEvent = ({ event, handleDone, handleEdit }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, () => handleDone());
  const { successToast, errorToast } = useDisplayToast();
  const [deleteOne, { isLoading: isDeleting }] = useDel<{ id: any }>();

  const handleDelete = async () => {
    deleteOne(
      Entity.events,
      { id: event?.id },
      {
        onSuccess(data, variables, context) {
          handleDone();
          successToast("Event Deleted");
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  if (isDeleting) {
    return <Loader size={"md"} />;
  }

  const formatDate = (date: string) => {
    const inputDate = new Date(date);
    const parsedDate = moment(inputDate);
    const formattedDate = parsedDate.format("MMMM D, YYYY");
    return formattedDate;
  };

  return (
    <div className="pb-24 px-4 w-screen h-screen flex-center flex-col fixed z-[1000] top-0 left-0 ">
      <div
        ref={modalRef}
        className="w-full max-w-[396px] pb-5 rounded-lg border border-[#B794F4] bg-white"
      >
        <div className="h-16 pl-4 pr-2 sticky top-0 text-lg flex-y-center justify-between text-white bg-[#B794F4] rounded-t-lg">
          <button
            className={`pr-2 hover:text-purple-500`}
            onClick={handleDelete}
          >
            <BsTrash2Fill />
          </button>
          <p className="text-lg line-clamp-1">{event?.title}</p>

          <div className="ml-auto flex gap-4 text-xl">
            <button onClick={() => handleEdit(event)}>
              <BsPencilSquare />
            </button>
            <button
              className="border border-3 border-white rounded-full"
              onClick={handleDone}
            >
              <BiX />
            </button>
          </div>
        </div>

        <div className="text-xs text-gray-600 pt-2 pb-4 flex flex-col">
          <div className="py-3 px-6 flex-y-center gap-4 border-b border-gray-100">
            <BiCalendar
              className={`${
                event?.start?.length ? "text-purple-500" : ""
              } text-xl flex-shrink-0`}
            />
            <p>
              {event?.start && event?.end
                ? `${formatDate(event?.start)} - ${formatDate(event?.end)}`
                : event?.start || event?.end
                ? formatDate(event?.start || event?.end)
                : ""}
            </p>
          </div>

          {event?.users?.length ? (
            <div className="py-3 px-6 flex gap-4 border-b border-gray-100">
              <BsPeople
                className={`${
                  event?.users?.length ? "text-purple-500" : ""
                } text-xl flex-shrink-0`}
              />
              <div className="flex flex-wrap gap-2.5">
                {event?.users?.map((user) => {
                  return (
                    <div
                      key={user?.id}
                      className="flex-y-center gap-1 text-[10px]"
                    >
                      <Avatar size="sm" src={user?.imageUrl} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className="py-3 px-6 flex-y-center gap-4 border-b border-gray-100">
            <MdOutlineNotifications
              className={`${
                event?.reminder ? "text-purple-500" : ""
              } text-xl flex-shrink-0`}
            />
            <p>Notification the week before</p>
            <div className="w-7 h-4 pr-[1px] flex-y-center bg-[#6B46C1] rounded-full">
              <div
                className={`${
                  event?.reminder ? "ml-auto" : "mr-auto"
                }  w-3 h-3 bg-white rounded-full`}
              ></div>
            </div>
          </div>

          <div className="py-3 px-6 flex gap-4">
            <LuPenLine
              className={`${
                event?.description?.length ? "text-purple-500" : ""
              } text-xl flex-shrink-0`}
            />
            <p>{event?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
