import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App.tsx";
import "./index.css";
import AppRoot from "./AppRoot.tsx";
import { includesString, notifyError } from "@utils/index.ts";
// https://github.com/react-dnd/react-dnd/issues/1494#issuecomment-1703844404
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <DndProvider backend={HTML5Backend}>
    <AppRoot />
  </DndProvider>
  // </React.StrictMode>
);

// window.addEventListener('error', function (event) {
//   console.error('An error occurred:', event.error);
//   if (JSON.stringify(event?.error).includes('Slate')) {
//     event.preventDefault();
//   }
// });

window.addEventListener("error", function (event) {
  // console.error(`UNHANDLED ERROR: ${event.error.stack}`);
  // notifyError(`UNHANDLED ERROR: ${event.error.stack}`);
});

window.addEventListener("unhandledrejection", (event) => {
  // console.error(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
  const skipErrors = ["authorization denied"];
  // console.log("event?.reason", event);
  if (!includesString(skipErrors, event?.reason?.message || "")) {
    notifyError(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
  }
});
