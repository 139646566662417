import { useDisclosure } from "@chakra-ui/react";
import { withChakra, ComponentProps } from "@components/utils/ChakraWrapper";
import logo from "@assets/logo.svg";
import { useAuthState } from "react-admin";
import { Outlet, useNavigate } from "react-router-dom";
import AppLoader from "@components/utils/AppLoader";
import {
  APP_CONST,
  LOCAL_STORE_KEYS,
  PLANS,
  arrayToObject,
  includesString,
  isAccountType,
  isDev,
  isNullOrEmpty,
  queryEmbeds,
  shouldUpgrade,
} from "@utils/index";
import {
  useCommonQueries,
  useDisplayToast,
  useGet,
  useLocalStorage,
  usePut,
} from "@utils/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { ContextStore } from "@components/utils/Context";
import { omit } from "lodash";
import { SidebarMenu } from "@components/Sidebar";
import {
  Account,
  ActionType,
  Activity,
  AddTabType,
  BoardItem,
  LinkType,
  User,
  AccountType,
  AppModal,
  Entity,
} from "@utils/interface";
import NavTopSection from "@components/NavBar";
import { EditGrant, ViewGrant, AddGrant } from "@pages/grant";
import { isMobile } from "react-device-detect";
// import { dataProvider } from "@providers/data";
import ViewDocument from "@pages/document/View";
import moment from "moment";
import TrialExpiration from "@components/TrialExpiration";
import AppSection from "@/shell/AppSection";
import Confetti from "react-confetti";
import IFrameViewer from "@components/IFrameViewer";
import Banner from "@/components/Banner";

export default withChakra((props?: ComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getLocalStorageItem } = useLocalStorage();
  const {
    user,
    account,
    setUser,
    setAccount,
    appModal,
    setAppModal,
    flags,
    setFlags,
    boardItems,
    grants,
    hasChanges,
    globalState,
    setGlobalState,
  } = useContext(ContextStore);

  // https://marmelab.com/react-admin/useAuthState.html
  // useAuthenticated(); // redirects to login if not authenticated
  const { isLoading, authenticated } = useAuthState();
  const navigate = useNavigate();
  // const { successToast, errorToast } = useDisplayToast();
  const {
    fetchBoardItems,
    fetchTeam,
    fetchFiles,
    fetchDocuments,
    fetchAccountActivity,
    fetchUserActivity,
    fetchGrants,
    fetchFeatureFlags,
    fetchChats,
    fetchFolders,
    fetchRecommendations,
    fetchAgency,
    fetchAccount,
    fetchSnippets,
  } = useCommonQueries();

  const fetchFlags = fetchFeatureFlags(["v2", "v3"]);

  const token = getLocalStorageItem(LOCAL_STORE_KEYS.accessToken);

  const fetchUser = useGet<User & { account: Account }>(
    Entity.users,
    {
      id: getLocalStorageItem(LOCAL_STORE_KEYS.userId),
      meta: {
        _embed: `account._count,account.primaryContact,account.linkedAccounts._count,affiliate`,
      },
    },
    (response) => {
      const account = response?.account;
      const user = omit(response, "account");
      setUser(user as User);
      setAccount(account as Account);

      // maintain last client account
      const isAgency = isAccountType(account, AccountType.AGENCY);
      if (isAgency && globalState?.isAgency === undefined) {
        const agency = account;
        setGlobalState((prev) => ({ ...prev, isAgency, agency }));
        if (
          user?.onBehalfOfId &&
          agency?.linkedAccounts.map((a) => a.id).includes(user.onBehalfOfId)
        ) {
          setAccount(
            agency?.linkedAccounts?.find(
              (a) => a.id === user?.onBehalfOfId
            ) as Account
          );
        }
      }
    }
  );

  if (token && authenticated) {
    // get user data
    if (isNullOrEmpty(user)) {
      const userId = getLocalStorageItem(LOCAL_STORE_KEYS.userId);
      if (userId) {
        fetchUser.refetch();
      }
    }
  }

  // console.log("appModal", appModal);

  const onAddGrant = () => {
    setAppModal((prev) => ({
      ...prev,
      views: { ...prev.views, [ActionType.add]: true },
      type: LinkType.grant,
      addTab: Object.keys(globalState?.addGrant || {})?.length
        ? AddTabType.form
        : AddTabType.url,
    }));
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !appModal.views?.add &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [appModal]);

  if (isNullOrEmpty(flags)) {
    fetchFlags.refetch();
  }

  useEffect(() => {
    if (fetchFlags?.data?.length) {
      setFlags({ ...flags, ...arrayToObject(fetchFlags?.data, "flagName") });
    }
  }, [fetchFlags?.data]);

  useEffect(() => {
    // reload data when agency user switches account
    if (!globalState?.isAgency) return;
    if (isNullOrEmpty(user)) return;
    // console.log("re-fetching data");
    fetchBoardItems.refetch()
    fetchTeam.refetch();
    fetchFiles.refetch();
    fetchDocuments.refetch();
    fetchAccountActivity.refetch();
    fetchUserActivity.refetch();
    fetchChats.refetch();
    fetchFolders.refetch();
    fetchRecommendations.refetch();
    fetchAccount.refetch();
    fetchAgency.refetch();
    fetchSnippets.refetch();
  }, [user?.onBehalfOfId]);

  useEffect(() => {
    // console.log("re-fetching grants");
    fetchGrants.refetch();
  }, [boardItems?.length]);

  /*
  Note: hooks used conditionally within a component can lead to a change in the order of hooks being called between different renders, which is against the rules of Hooks and will cause the error you're seeing. Ensure that all hooks are called above aka at the top level of the component, and not inside loops, conditions, or nested functions. 
  https://stackoverflow.com/questions/68535624/react-has-detected-a-change-in-the-order-of-hooks
  */

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  if (
    // true
    isLoading ||
    !user
  ) {
    return <AppLoader />;
  }

  const agencyNeedsUpgrade =
    !includesString(
      (process.env.AGENCY_WHITELIST || "")?.split(","),
      user?.accountId
    ) &&
    globalState?.isAgency &&
    shouldUpgrade(globalState?.agency, AccountType.AGENCY);
  // [PLANS.free, undefined, null].includes(globalState?.agency?.planName) &&
  // globalState?.agency?.linkedAccounts?.length > 5;

  //   if (!isDev &&
  //     moment().isAfter(moment(account?.createdAt).add(APP_CONST.BETA_PERIOD, "d")) &&
  //     !account?.subscriptionId &&
  //     !globalState?.isAgency) {
  //     setGlobalState((prev) => ({ ...prev, showUpgradeToPaid: true }));
  //   }
  if (
    (!globalState?.isAgency && globalState?.showUpgradeToPaid) ||
    agencyNeedsUpgrade
  ) {
    return (
      <TrialExpiration
        cb={(data) =>
          globalState?.isAgency
            ? setGlobalState((prev) => ({ ...prev, agency: data }))
            : setAccount(data)
        }
        account={globalState?.agency || account}
        forceLogout={agencyNeedsUpgrade}
      />
    );
  }

  const renderModals = () => {
    // support views on top of views
    if (appModal?.type == LinkType.grant) {
      if (appModal.views?.[ActionType.edit]) return <EditGrant />;
      if (appModal.views?.[ActionType.view]) return <ViewGrant />;
      if (appModal.views?.[ActionType.add]) return <AddGrant />;
    } else if (appModal?.type == LinkType.document) {
      if (appModal.views?.[ActionType.view]) return <ViewDocument />;
    } else if (appModal?.type == LinkType.file) {
      if (appModal.views?.[ActionType.view])
        return (
          <IFrameViewer
            onDone={() => setAppModal({ ...appModal, File: undefined })}
            file={appModal?.File}
          />
        );
    }
  };

  const showSidebar = isOpen || !isMobile;

  // console.log("user", user);
  // console.log("appModal", appModal);
  // console.log("hasChanges", hasChanges);

  return (
    <>
      <section className="bg-white min-h-screen overflow-y-scroll w-full">
        <NavTopSection onOpen={onOpen} onClose={onClose} isOpen={isOpen} />

        {showSidebar && (
          <SidebarMenu
            handleAddGrant={onAddGrant}
            logo={logo}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}

        {renderModals()}

        {appModal?.showConfetti && (
          <Confetti
            // recycle={false}
            numberOfPieces={200}
            colors={["#f44336", "#e91e63", "#9c27b0"]}
          // confettiSource={{ x: 0, y: 0, w: 100, h: 100 }}
          // run={appModal?.showConfetti}
          />
        )}

        <AppSection isOpen={isOpen}>{props?.children}</AppSection>
      </section>
    </>
  );
}, <Outlet />);
