import { Dict, InputField } from "@utils/interface";
import { get } from "lodash";

interface InputFormProps {
  fields: InputField[];
  object: Dict;
  onChange: (e: React.ChangeEvent<any>) => void;
  cols?: number
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const InputForm = (props: InputFormProps) => {
  const { fields, object, onChange, cols, header, footer } = props;

  return (
    <div className="px-5">
      <div className={`children:w-full text-sm flex flex-col md:grid md:grid-cols-${cols !== undefined ? cols : 2} md:gap-x-10 gap-y-7`}>
        {header ? header : null}
        {fields?.map((item, index) => {
          return (
            <div key={index} className="grid h-max">
              <label htmlFor={item?.key}>
                {item?.label}
                {item?.required && (
                  <span className="text-red-500 font-bold ml-1">*</span>
                )}
              </label>
              {item?.type === "textarea" ? (
                <textarea
                  className={`h-${item?.h !== undefined ? item.h : 15} pt-2 px-3 mt-1 border-2 rounded-lg outline-none`}
                  id={item?.key}
                  required={item?.required}
                  placeholder={item?.placeholder || ""}
                  value={get(object, item?.key) || ""}
                  onChange={onChange}
                />
              ) : (
                <input
                  className={`h-${item?.h !== undefined ? item.h : 10} px-3 mt-1 border-2 rounded-lg outline-none`}
                  type={item?.type || "text"}
                  id={item?.key}
                  required={item?.required}
                  placeholder={item?.placeholder || ""}
                  pattern={item?.pattern}
                  value={get(object, item?.key) || ""}
                  onChange={onChange}
                />
              )}
            </div>
          );
        })}
        {footer ? footer : null}
      </div>
    </div>
  );
};

export default InputForm;
