import { withChakra } from "@components/utils/ChakraWrapper";
import { Outlet } from "react-router-dom";

const Share = withChakra(() => {
  return (
    <div>
      <Outlet />
    </div>
  );
});

export default Share;
