import jwtDecode, { JwtPayload } from "jwt-decode";
import { useLocalStorage } from "@utils/hooks";
import { APP_CONST, LOCAL_STORE_KEYS, isProd } from "@utils/index";
import { clearLocalStorage } from "./authProvider";

// https://marmelab.com/react-admin/Authentication.html#handling-refresh-tokens
// https://marmelab.com/react-admin/addRefreshAuthToAuthProvider.html

export const refreshAuth = () => {
  const accessToken = localStorage.getItem(LOCAL_STORE_KEYS.accessToken) || "";
  if (accessToken) {
    const decoded = jwtDecode<JwtPayload>(accessToken!).exp!;
    // console.log("auth expires at: ", new Date(decoded * 1000).toLocaleString());

    const timeoutPeriod = decoded * 1000 - 60 * 1000; // Validity period of the token in seconds, minus 60 seconds
    // console.log("refreshing at: ", new Date(timeoutPeriod).toLocaleString());

    const tOut = new Date(timeoutPeriod).getTime() < Date.now(); // https://stackoverflow.com/a/42956013

    if (tOut) {
      //  console.log("refreshing... ");
      const refreshToken =
        localStorage.getItem(LOCAL_STORE_KEYS.refreshToken) || "";
      // This function will fetch the new tokens from the authentication service and update them in localStorage
      return refreshAuthTokens(refreshToken);
    }
  }
  return Promise.resolve();
};

const refreshAuthTokens = (refreshToken: string) => {
  //  console.log("refreshAuthTokens...");

  return fetch(`${APP_CONST.API_URL}/refresh_token`, {
    method: "POST",
    // credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  })
    .then(async (res) => {
      const result = await res.json();
      if (res.status < 200 || res.status >= 300) {
        throw new Error(result?.message);
      }
      return result;
    })
    .then((res) => {
      // console.log("refreshAuthTokens", res);
      const { accessToken } = res;
      //  console.log("new accessToken", accessToken);
      localStorage.setItem(LOCAL_STORE_KEYS.accessToken, accessToken);
      // localStorage.setItem(LOCAL_STORE_KEYS.refreshToken, refreshToken);
      return Promise.resolve();
    })
    .catch((err) => {
      //  console.log("refreshAuthTokens error", err);
      if (isProd) clearLocalStorage();
      return Promise.reject();
    });
};
