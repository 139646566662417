import Confetti from "react-confetti";


interface Props {
  show?: boolean;
}

const Streamers = ({ show = true }: Props) => {
  return (
    show ?
      <Confetti
        numberOfPieces={200}
        colors={["#f44336", "#e91e63", "#9c27b0"]}
      // recycle={false}
      /> : null
  );
}

export default Streamers;
