import AgentChat from "@pages/AgentChat";
import PageView from "./PageView";
// import WebBrowser from "./Web";

const SearchPage = () => {
  return (
    <div className="flex flex-row">
      {/* <WebBrowser  /> */}
      <PageView src="https://www.google.com/search?igu=1" />
      {/* <AgentChat entity={{}} /> */}
    </div>
  );
};

export default SearchPage;
