import { useContext, useEffect, useState, useRef } from "react";
import { FiFileText, FiFile, FiUploadCloud, FiEye } from "react-icons/fi";
import {
  getFileSize,
  getDateString,
  handleSort,
  tailwindHelpers,
  openInNewTab,
  ellipsis,
  APP_CONST,
  setURLQuery,
} from "@utils/index";
import { useCommonQueries, useDel, useDisplayToast } from "@utils/hooks";
import { BiSad, BiTrash } from "react-icons/bi";
import { BsArrowBarUp, BsListNested } from "react-icons/bs";
// import { MdCancel } from "react-icons/md";
import { useGetList } from "react-admin";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import Table from "@components/Table";
import ActionModal from "@/components/modal/ActionModal";
import Actions from "@components/Actions";
import { IResponse, IFile, Dict, Entity } from "@utils/interface";
import Overlay from "@components/Overlay";
import IFrameViewer from "@components/IFrameViewer";
import FileViewer from "@components/FileViewer";
import { FaFileDownload } from "react-icons/fa";
import { Avatar } from "@chakra-ui/react";
import ProfileImg from "@components/ProfileImg";

interface Props {
  setViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingFiles: boolean;
  refetchFiles: () => void;
}

const DirectoryView = (props: Props) => {
  const { setViewOpen, isLoadingFiles, refetchFiles } = props;
  const { files, setFiles, actionModal, setActionModal } =
    useContext(ContextStore);

  const { successToast, errorToast } = useDisplayToast();
  const [activeItem, setActiveItem] = useState("");
  const [viewFile, setViewFile] = useState<IFile>();
  const { fetchFiles } = useCommonQueries();

  useEffect(() => {
    fetchFiles.refetch();
  }, []);

  useEffect(() => {
    setURLQuery({ fileId: viewFile?.id });
  }, [viewFile]);

  const [deleteFile, { isLoading: isDeleting }] = useDel<IFile>();
  const handleDeleteFile = (id: string) => {
    setActiveItem(id);
    deleteFile(
      Entity.files,
      { id },
      {
        onSuccess(data, variables, context) {
          successToast("File Deleted");
          setActiveItem("");
          refetchFiles();
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  return (
    <>
      {viewFile && (
        <IFrameViewer onDone={() => setViewFile(undefined)} file={viewFile} />
      )}

      {/* {viewFile && (
        <FileViewer docs={[{ uri: `${APP_CONST.API_URL}/proxy?url=${viewFile.publicUrl}`, fileName: viewFile.name }]} onDone={() => setViewFile(undefined)} />
        // <FileViewer docs={[{ uri: `${APP_CONST.API_URL}/proxy2?url=https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf`, fileName: viewFile.name }]} onDone={() => setViewFile(undefined)} />
        // <FileViewer docs={[{ uri: viewFile.publicUrl, fileName: viewFile.name }]} onDone={() => setViewFile(undefined)} />
      )} */}

      {actionModal?.removeFile && (
        <ActionModal
          icon={<BiTrash />}
          handleConfirm={() => {
            handleDeleteFile(activeItem);
            setActionModal({ ...actionModal, removeFile: false });
          }}
          handleClose={() =>
            setActionModal({ ...actionModal, removeFile: false })
          }
          title={"Confirm"}
        >
          <p>Are you sure?</p>
        </ActionModal>
      )}

      <div className="pb-8 relative">
        <div className="pb-2 mb-5 pt-[24px] px-5 sticky z-30 top-[56px] flex justify-between items-end border-b bg-white">
          <h1 className="text-2xl font-bold">Files</h1>

          <button
            onClick={() => setViewOpen(false)}
            className=" px-5 py-2 rounded bg-[#e9d9fc] hover:bg-[#553C9A] text-[#553C9A] hover:text-white transition-colors duration-200 flex-y-center"
          >
            <span className="mr-2 -md:hidden">Upload</span>
            <span className="text-lg">
              <BsArrowBarUp />
            </span>
          </button>
        </div>

        <div className="px-5">
          <Table
            tHeaders={[
              {
                title: "Name",
                isSortable: true,
                fn: (dir) => handleSort(dir, "name", files, setFiles),
              },
              {
                title: "Uploaded On",
                isSortable: true,
                fn: (dir) => handleSort(dir, "createdAt", files, setFiles),
              },
              {
                title: "File Size",
                isSortable: true,
                fn: (dir) => handleSort(dir, "size", files, setFiles),
              },
              {
                title: "Uploaded By",
                isSortable: true,
                fn: (dir) => handleSort(dir, "user.firstName", files, setFiles),
              },
              { title: "Actions" },
            ]}
            isLoading={isLoadingFiles}
            tBody={
              files &&
              files?.length > 0 &&
              files?.map((file, index) => {
                // console.log(files)
                const actions = {
                  top: [
                    {
                      label: "Remove",
                      iconBefore: <BiTrash />,
                      onSelect: () => {
                        setActionModal({ ...actionModal, removeFile: true });
                        setActiveItem(file?.id);
                      },
                    },
                  ],
                  bottom: [],
                };

                return (
                  <tr className="h-14" key={index}>
                    <td className="w-[34%] pr-2 bg-white min-w-[140px] sticky z-10 left-0">
                      <div className="flex h-full children:text-left">
                        <span
                          onClick={() => openInNewTab(file.publicUrl)}
                          className="mr-2 text-xl cursor-pointer"
                        >
                          <FaFileDownload className="text-purple-300 hover:text-purple-500" />
                        </span>
                        <div
                          className="cursor-pointer flex hover:text-purple-500"
                          onClick={() => setViewFile(file)}
                        >
                          <span className="mr-2 text-xl">
                            <FiEye className="text-purple-400 hover:text-purple-500" />
                          </span>
                          <span className="line-clamp-1">
                            {ellipsis(file?.name, 40)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="w-[19%] min-w-[105px]">
                      <div className="w-full h-10 flex items-center max-w-[250px]">
                        {getDateString(file?.createdAt)}
                      </div>
                    </td>
                    <td className="w-[17%] min-w-[85px]">
                      {getFileSize(file?.size)}
                    </td>
                    <td className="w-[28%] min-w-[150px]">
                      <div className="flex items-center">
                        <ProfileImg
                          src={file?.user?.imageUrl}
                          className="mr-2 h-8 w-8 rounded-full"
                        />
                        <span>
                          {`${file?.user?.firstName || file?.user?.email} ${
                            file?.user?.lastName || ""
                          }`}
                        </span>
                      </div>
                    </td>
                    <td>
                      {isDeleting && file?.id == activeItem ? (
                        <Loader size={"md"} />
                      ) : (
                        <Actions actions={actions} isTableActions={true} />
                      )}
                    </td>
                  </tr>
                );
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default DirectoryView;
