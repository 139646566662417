import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import UpRightSVG from "./icons/UpRightSVG";
import { IoDocumentAttachOutline } from "react-icons/io5/index";
import {
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { httpClient } from "@providers/client";
import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import { ellipsis, APP_CONST, MAX_FILE_SIZE } from "@utils/index";

interface InputControlProps {
  getResponse: () => Promise<void>;
  setPrompt: Dispatch<SetStateAction<string>>;
  prompt: string;
  loadingResponse: boolean;
}

export default function InputControl({
  getResponse,
  setPrompt,
  prompt,
  loadingResponse,
}: InputControlProps) {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getResponse();

    if (files) {
      upload(files);
    }
  };

  const [files, setFiles] = useState<File[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const upload = (files: File[]) => {
    if (!files) return;
    const formData = new FormData();

    Array.from(files)?.map((file: File) => {
      formData.append("files", file);
    });

    return httpClient(`${APP_CONST.API_URL}/upload/files`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        alert(res?.json?.message);
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        alert(err?.body?.message);
      });
  };

  const handleClick = () => {
    if (fileInputRef.current !== null) fileInputRef.current.click();
  };

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    // https://stackoverflow.com/a/59188398
    // console.log(e.target.files);
    const _files = Array.from(e.target.files as FileList).filter(validateFiles);
    setFiles(files?.concat(_files));
  };

  const validateFiles = (file: File) => {
    // https://gist.github.com/brenopolanski/5efe54b46cad0882b3ce41dc8db64608
    // for (const file of files) {
    const fsMb = file.size / (1024 * 1024);
    if (fsMb > MAX_FILE_SIZE) {
      alert(`Max file size ${MAX_FILE_SIZE}MB. Compress "${ellipsis(file.name)}" and try again. Checkout https://smallpdf.com/compress-pdf`);
      return false;
    }
    // }
    return true;
  };

  // useEffect(() => {
  //   if (files?.length > 0) {
  //     validateFiles(files)
  //   }
  // }, [files])

  return (
    <form onSubmit={handleSubmit} className="flex space-x-4">
      <div className="grow">
        {/* <input type="file" multiple ref={fileInputRef} /> */}
        <InputGroup size="md">
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            type="text"
            placeholder="Type here"
            className="input w-full"
          />
          <InputRightElement width="4.5rem">
            <Button
              h="70%"
              size="sm"
              rounded="md"
              bg={useColorModeValue("gray.300", "gray.700")}
              _hover={{
                bg: useColorModeValue("gray.400", "gray.800"),
              }}
              aria-label={""}
              onClick={handleClick}
            >
              <Input
                hidden
                type="file"
                multiple
                onChangeCapture={onFileChangeCapture}
                ref={fileInputRef}
              />
              {/* {'Attachment'} */}
              {/* https://stackoverflow.com/a/43769782 */}
              <IoDocumentAttachOutline size={25} />
            </Button>
          </InputRightElement>
        </InputGroup>
        {files?.length > 0 && (
          <div className="flex flex-wrap justify-center space-x-2 mt-2">
            {files?.map((file) => (
              <Button
                className="flex space-x-2 items-center"
                onClick={() => {
                  const newFiles = files.filter((f) => f.name !== file.name);
                  setFiles(newFiles);
                }}
              >
                <Icon as={CloseIcon} />
                <span>{ellipsis(file.name)}</span>
              </Button>
            ))}
          </div>
        )}
      </div>
      <Button
        type="submit"
        disabled={!prompt || loadingResponse}
        className={`btn btn-ghost ${loadingResponse && "loading"}`}
      >
        {!loadingResponse && <UpRightSVG />}
      </Button>
    </form>
  );
}
