import { APP_CONST, isNullOrEmpty, } from "@utils/index";
import { httpClient } from "@providers/client";
import { useConfetti, usePut } from "@utils/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { ContextStore } from "@components/utils/Context";
import Onboarding from "@pages/onboarding";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Loader from "@components/utils/Loader";
import VideoModal from "@/components/modal/VideoModal";
import ShowTrialNotice from "@pages/onboarding/ShowTrialNotice";
import MainContentArea from "./MainContentArea";
import { startCase } from "lodash";
import VideoUpdate from "@components/VideoUpdate";
import Banner from "@/components/Banner";

interface AppSectionProps {
  isOpen: boolean;
  children?: React.ReactNode;
}


const AppSection = (props: AppSectionProps) => {
  const { isOpen } = props;
  const { globalState, user, setAppModal } = useContext(ContextStore);
  const [viewedOnboarding, setViewedOnboarding] = useState<boolean | null>(
    null
  );
  const [showBanner, setShowBanner] = useState<boolean | null>(false);

  useEffect(() => {
    if (isNullOrEmpty(user)) return;
    setViewedOnboarding(
      user?.additionalData?.viewedOnboarding !== undefined
        ? user?.additionalData?.viewedOnboarding
        : false
    );
  }, [user?.additionalData?.viewedOnboarding]);

  useEffect(() => {
    if (isNullOrEmpty(user)) return;
    if (
      viewedOnboarding &&
      !!user?.additionalData?.viewedOnboarding === false
    ) {
      setAppModal((prev) => ({
        ...prev,
        viewedTrialNotice: false,
        viewedUpdate2Vid: !!user?.additionalData?.viewedUpdate2Vid,
      }));
    }
  }, [viewedOnboarding]);

  if (isNullOrEmpty(viewedOnboarding))
    return (
      <div className="w-full h-full bg-white flex justify-center">
        <Loader size={"xl"} />
      </div>
    );

  if (!viewedOnboarding) {
    return (
      <div
        className={`${!isMobile ? "ml-[70px]" : ""}
       h-[calc(100vh-56px)] transition duration-300 ease-in-out`}
      >
        <Onboarding setViewedOnboarding={setViewedOnboarding} />
      </div>
    );
  }

  // //show trial notice
  // if (
  //   // true
  //   !(account?.planName === PLANS.free) &&
  //   moment().diff(moment(account?.createdAt), "days") < 7 &&
  //   canViewModal("viewedTrialNotice", Entity.accounts) &&
  //   !appModal?.viewedTrialNotice
  // ) {
  //   return (
  //     <ShowTrialNotice
  //       onClose={() => handleOnClose("viewedTrialNotice", Entity.accounts)}
  //     />
  //   );
  // }

  // show update videos
  return (
    <>
      <VideoUpdate
        videoKeys={["Update2", globalState?.isAgency ? "Clients" : ""]}
      />
      <div
        className={`${!isMobile ? "ml-[70px]" : ""}
       h-[calc(100vh-56px)] transition duration-300 ease-in-out`}
      >
        {showBanner ? <Banner text={APP_CONST.BANNER_TEXT} onClose={() => setShowBanner(false)} /> : null}
        <MainContentArea viewedOnboarding={viewedOnboarding} {...props} />
      </div>
    </>
  );
};

export default AppSection;
