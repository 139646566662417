import CloseBtn from "@components/CloseButton";
import Overlay from "@components/Overlay";
import { ContextStore } from "@components/utils/Context";
import { Board, BoardItem, Entity, IResponse } from "@utils/interface";
import { useContext } from "react";
import { useUpdate } from "react-admin";
import { addCard } from "./helpers";
import { useDisplayToast } from "@utils/hooks";
import ArchiveList from "@components/ArchiveList";

interface UnArchiveProps {
  onUpdate: (board: Board, refetch?: boolean) => void;
}

const UnArchive = (props: UnArchiveProps) => {
  const { onUpdate } = props;

  const { successToast, errorToast } = useDisplayToast();
  const { board, boardItems, setActionModal, actionModal, setBoardItems } =
    useContext(ContextStore);

  const archivedItems = boardItems?.filter((item) => item?.archived);
  const closeModal = () =>
    setActionModal({ ...actionModal, showUnarchive: false });

  const [updateBoardItem, { isLoading: isUpdating }] = useUpdate<
    Record<string, any> & { id: string },
    IResponse
  >();

  const handleUnarchive = (card: BoardItem) => {
    updateBoardItem(
      Entity.boardItems,
      {
        id: card?.id,
        data: {
          archived: false,
          archivedAt: null,
        },
      },
      {
        onSuccess(data, variables, context) {
          setBoardItems((prev) => [...prev, data as BoardItem]);
          addCard(board, card, onUpdate);
          // console.log("data", data);
          successToast("Card UnArchived");
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  return (
    <ArchiveList
      closeModal={closeModal}
      items={archivedItems}
      unArchive={handleUnarchive}
    />
  );
};

export default UnArchive;
