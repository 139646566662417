import { Avatar } from "@chakra-ui/react";
import ActionModal from "@/components/modal/ActionModal";
import Actions from "@components/Actions";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import {
  useCommonQueries,
  useDel,
  useDisplayToast,
  usePlanFeatureCheck,
  usePost,
  usePut,
} from "@utils/hooks";
import { PLANS, handleSort, isNullOrEmpty } from "@utils/index";
import {
  IResponse,
  Dict,
  User,
  Account,
  MenuAction,
  Entity,
} from "@utils/interface";
import { omit } from "lodash";
import { useContext, useState, useEffect } from "react";
import { BiTrash, BiRefresh } from "react-icons/bi";
import { IoPaperPlaneOutline } from "react-icons/io5";
import Table from "@components/Table";
import { StarIcon } from "@chakra-ui/icons";

const FormTeam = () => {
  const {
    user,
    account,
    setAccount,
    team,
    setTeam,
    actionModal,
    setActionModal,
    setHasChanges,
    globalState,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const redirect = new URL("/login", window.location.origin).href;
  const [activeItem, setActiveItem] = useState("");
  const { needAccess } = usePlanFeatureCheck();

  // https://marmelab.com/react-admin/useGetList.html
  const { fetchTeam, fetchAgency } = useCommonQueries();
  const { isFetching: isLoading, refetch: fetchUsers } = fetchTeam;

  useEffect(() => {
    // Code to run on every render
    fetchUsers();
  }, []);

  const [deleteUser, { isLoading: isDeleting }] = useDel<User>();
  const [inviteUser, { isLoading: isInviting }] = usePost<User>();
  const [resendInvite, { isLoading: isReInviting }] = usePut<User>();
  const [updatePrimary, { isLoading: isUpdating }] = usePut<Account>();

  const handleDelete = (id: string) => {
    setActiveItem(id);
    deleteUser(
      Entity.users,
      { id },
      {
        onSuccess(data, variables, context) {
          successToast("User Deleted");
          setActiveItem("");
          fetchUsers();
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleResend = (id: string) => {
    setActiveItem(id);
    resendInvite(
      "invite",
      { id, data: { redirect } },
      {
        onSuccess(data, variables, context) {
          successToast("Invite resent");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const sendInvite = () => {
    if (
      needAccess(
        [PLANS.FREE, PLANS.BASIC],
        true,
        "Please upgrade to invite others to collaborate!"
      )
    )
      return;
    inviteUser(
      "invite",
      {
        data: {
          ...invite,
          // accountId: account?.id,
          redirect,
        },
      },
      {
        onSuccess(data, variables, context) {
          // console.log("inviteUser", data);
          successToast("Invite sent!");
          setInvite({});
          fetchUsers();
          if (globalState?.isAgency) {
            fetchAgency.refetch();
          }
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleUpdatePrimary = (id: string) => {
    updatePrimary(
      Entity.accounts,
      {
        id: account?.id,
        data: { primaryContact: { connect: { id } } },
      },
      {
        onSuccess(data, variables, context) {
          successToast("Account updated");
          setAccount(data);
          fetchUsers();
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  // if (error) return <div>Error: {error.message}</div>;
  // if (!data?.length) return <div>No Teammates found</div>;

  const [invite, setInvite] = useState<Dict>({});
  // const [teamData, setTeamData] = useState<any[] | undefined>(null);
  const [canInvite, setCanInvite] = useState(false);

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.id;
    const value = e?.target.value;
    if (value) {
      setInvite({ ...invite, [key]: value });
    } else {
      setInvite({ ...omit(invite, key) });
    }
  };

  useEffect(() => {
    setCanInvite(Object.keys(invite || {}).length === 3);
  }, [invite]);

  return (
    <>
      {actionModal?.removeInvite && (
        <ActionModal
          icon={<BiTrash />}
          handleConfirm={() => {
            handleDelete(activeItem);
            setActionModal({ ...actionModal, removeInvite: false });
          }}
          handleClose={() =>
            setActionModal({ ...actionModal, removeInvite: false })
          }
          title={"Confirm"}
        >
          <p>Are you sure?</p>
        </ActionModal>
      )}
      <h1 className="pl-3 py-1 my-8 w-full text-lg text-[#475467] bg-[#EAECF0]">
        Team
      </h1>

      <div className="mb-14">
        <div className="mb-7 w-full text-sm grid md:grid-cols-3 gap-y-4 gap-x-3">
          <div className="w-full">
            <label htmlFor="firstName">First Name</label>
            <input
              className="h-8 w-full block mt-1 placeholder:text-neutral-300 px-3 rounded border border-neutral-300 outline-none"
              type="text"
              id="firstName"
              placeholder="John"
              value={invite?.firstName || ""}
              onChange={(e) => handleChanges(e)}
            />
          </div>

          <div className="w-full">
            <label htmlFor="lastName">Last Name</label>
            <input
              className="h-8 w-full block mt-1 placeholder:text-neutral-300 px-3 rounded border border-neutral-300 outline-none"
              type="text"
              id="lastName"
              placeholder="Doe"
              value={invite?.lastName || ""}
              onChange={(e) => handleChanges(e)}
            />
          </div>

          <div className="w-full">
            <label htmlFor="email">Email Address</label>
            <input
              className="h-8 w-full block mt-1 placeholder:text-neutral-300 px-3 rounded border border-neutral-300 outline-none"
              type="email"
              id="email"
              placeholder="jdoe@youremail.com"
              value={invite?.email || ""}
              onChange={(e) => handleChanges(e)}
            />
          </div>
        </div>

        <button
          // className={`mx-auto flex items-center rounded px-5 py-2 ${canInvite ? 'enabled:hover:bg-[#553C9A] enabled:hover:text-white' : 'disabled:hover:bg-gray-200 disabled:hover:text-white cursor-default'} bg-[#E9D8FD] text-[#553C9A] transition-colors duration-200`}
          className={`mx-auto flex items-center rounded px-5 py-2 enabled:hover:bg-[#553C9A] ${
            canInvite ? " hover:text-white " : "cursor-default"
          } bg-[#E9D8FD] text-[#553C9A] transition-colors duration-200`}
          onClick={sendInvite}
          disabled={!canInvite}
        >
          <span className="mr-3">Send Invite</span>
          {isInviting ? <Loader size={"md"} /> : <IoPaperPlaneOutline />}
        </button>
      </div>

      {isLoading ? (
        <div className="justify-center items-center">
          <Loader />
        </div>
      ) : (
        <Table
          tHeaders={[
            {
              title: "Name",
              isSortable: true,
              fn: (dir) => handleSort(dir, "firstName", team, setTeam),
            },
            {
              title: "Email Address",
              isSortable: true,
              fn: (dir) => handleSort(dir, "email", team, setTeam),
            },
            {
              title: "Status",
              isSortable: true,
              fn: (dir) => handleSort(dir, "status", team, setTeam),
            },
            {
              title: "Actions",
            },
          ]}
          // error={error}
          // isLoading={isLoading}
          tBody={team?.map((member, index) => {
            const actions: MenuAction = {
              top: [
                {
                  label: "Resend Invite",
                  iconBefore: <BiRefresh />,
                  onSelect: () => handleResend(member?.id),
                  visible: member?.status?.toLowerCase() == "pending",
                },
              ],
              bottom: [
                {
                  label: "Make Primary",
                  iconBefore: <StarIcon />,
                  onSelect: () => handleUpdatePrimary(member?.id),
                  visible:
                    (globalState?.isAgency ||
                      user?.id == account?.primaryContactId) &&
                    member?.id !== account?.primaryContactId,
                },
                {
                  label: "Remove",
                  iconBefore: <BiTrash />,
                  onSelect: () => {
                    setActiveItem(member?.id);
                    setActionModal({ ...actionModal, removeInvite: true });
                  },
                  visible:
                    member?.id !== account?.primaryContactId &&
                    team?.length > 1 &&
                    isNullOrEmpty(member?.customerId),
                },
              ],
            };

            return (
              <tr className="w-full py-6 text-slate-500 relative" key={index}>
                <td className="w-[31%] -md:w-[40%] pr-3 bg-white sticky left-0 py-2 text-slate-700 font-semibold">
                  <div className=" flex items-center">
                    <Avatar size="sm" src={member?.imageUrl} className="mr-2" />
                    <span>
                      {member?.firstName} {member?.lastName}
                    </span>
                  </div>
                </td>
                <td className="w-[39%] -md:w-[40%] pr-3 py-1">
                  {member?.email}
                </td>
                <td className="w-[30%] -md:w-[20%] py-1">{member?.status}</td>
                <td>
                  {member?.email !== user?.email &&
                    ((isDeleting || isReInviting) &&
                    member?.id == activeItem ? (
                      <Loader size={"md"} />
                    ) : (
                      <Actions actions={actions} isTableActions={true} />
                    ))}
                </td>
              </tr>
            );
          })}
        />
      )}
    </>
  );
};

export default FormTeam;
