import { MAX_FILE_SIZE } from "@/utils";
import { Dict } from "@utils/interface";

export interface FileData {
  file: File;
  data: Dict & { category: string };
}

export const handleFileSelect = (
  e: React.ChangeEvent<HTMLInputElement>,
  _files?: FileList
) => {
  const files = _files ? _files : (e?.target.files as FileList);
  const file: File = files[0];
  // console.log("fileSizeInMB", fileSizeInMB);
  const overLimit = fileOverLimit(file);
  // const withinLimit = file.size < 1024;
  return { overLimit, file };
};

export const fileOverLimit = (file: File) => {  
  const fileSizeInBytes = file.size;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  const overLimit = fileSizeInMB > MAX_FILE_SIZE; //50MB
  return overLimit;
}