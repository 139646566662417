import Actions, { ActionsBtn } from "@components/Actions";
import ChatContainer from "@components/gpt/ChatContainer";
import { ContextStore } from "@components/utils/Context";
// import InputControl from "@components/gpt/InputControl";
// import Loader from "@components/utils/Loader";
import {
  useCommonQueries,
  useDel,
  useDisplayToast,
  usePost,
  useURLParams,
} from "@utils/hooks";
// import {
//   ChatCompletionRequestMessage,
//   ChatCompletionResponseMessage,
// } from "openai";
import {
  useState,
  createRef,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { BsFillChatQuoteFill, BsRobot, BsChatSquareDots } from "react-icons/bs";
import { orderBy } from "lodash";
import {
  Chat,
  Dict,
  Entity,
  IDict,
  SideBarMenuTitle,
  canParse,
  openInNewTab,
  setURLQuery,
} from "@utils/index";
import Loader from "@components/utils/Loader";
import ModalSearch from "@/components/modal/ModalSearch";
import { DeleteIcon } from "@chakra-ui/icons";

interface AIChat {
  id: string;
  reply: string;
  chatHistory?: Dict[];
}

const defaultQuery = "find grants for this organization";

const Conversations = () => {
  const { chats, actionModal, setActionModal } = useContext(ContextStore);
  const [mockLoading, setMockLoading] = useState(false);

  const [responseAdded, setResponseAdded] = useState(false);
  const [promptAdded, setPromptAdded] = useState(false);

  const [currentChatId, setCurrentChatId] = useState<string | undefined>(
    undefined
  );
  const [chatHistory, setChatHistory] = useState<Dict[]>([]);
  const [prompt, setPrompt] = useState(chatHistory?.length ? "" : defaultQuery);
  const [response, setResponse] = useState("");
  const [title, setTitle] = useState('');

  const inputRef = createRef<HTMLInputElement>();
  const canType = useRef(false);

  const { fetchChats } = useCommonQueries();
  const { queryParams } = useURLParams();
  const { sessionId: querySessionId } = queryParams;

  const { successToast, errorToast, warningToast } = useDisplayToast();

  const [createChat, { isLoading: isChatting }] = usePost<AIChat>();
  const [deleteChat, { isLoading: isDeleting }] = useDel<AIChat>();

  const handleNewChatSession = () => {
    setCurrentChatId(undefined);
    setChatHistory([]);
    setPrompt(defaultQuery);
    inputRef?.current?.focus();
  };

  const handleSwitchSession = (sessionId: string) => {
    const chat = chats?.find((c) => c?.id === sessionId);
    setCurrentChatId(chat?.id);
    setTitle(chat?.title!);
    setPrompt("");
    const prevChatHistory = canParse(
      `[${chat?.embeddings.map((d) => d?.content)?.join(",")}]`
    ) as Dict[][];
    setChatHistory(
      (chat?.history?.length ? chat?.history : (prevChatHistory || [])?.flat()) as Dict[]
    );
  }

  const handleDefaultLastSession = () => {
    const orderedChats = orderBy(chats, "createdAt", "asc");
    const currentChat = querySessionId
      ? orderedChats?.find((c) => c?.id === querySessionId)
      : orderedChats?.slice(-1)[0];
    const lastHistory = canParse(
      `[${currentChat?.embeddings.map((d) => d?.content)?.join(",")}]`
    ) as Dict[][];
    setCurrentChatId(currentChat?.id);
    setTitle(currentChat?.title || 'Untitled Chat');
    setPrompt("");
    setChatHistory(
      (currentChat?.history?.length ? currentChat.history : (lastHistory || [])?.flat()) as Dict[]
    );
  }

  const handleDeleteSession = (sessionId: string) => {
    deleteChat(
      Entity.chats,
      { id: sessionId },
      {
        onSuccess: () => {
          fetchChats?.refetch()
            .then(() => {
              successToast("Conversation deleted");
              if (sessionId === currentChatId) handleNewChatSession();
            });
        },
        onError: (error) => {
          console.log(error);
          errorToast(error?.body?.message);
        },
      }
    );
  }

  const handleGetResponse = async () => {
    // const handleResponse = useCallback(async () => {
    createChat(
      `${Entity.chats}/ai`,
      {
        data: {
          input: chatHistory[chatHistory.length - 1].content,
          chatSessionId: currentChatId,
        },
      },
      {
        onSuccess: (resp) => {
          if (resp?.chatHistory) {
            setChatHistory(resp?.chatHistory);
          } else {
            setResponse(resp?.reply);
          }
          setCurrentChatId(resp?.id);
          fetchChats?.refetch();
          setResponseAdded(true);
          setPromptAdded(false);
        },
        onError: (error) => {
          console.log(error);
          errorToast(error?.body?.message);
        },
      }
    );
    // }, [chatHistory]);
  };

  const mockGetResponse = () => {
    setResponse(testResp);
    canType.current = true;
    setResponseAdded(true);
    setPromptAdded(false);
    setMockLoading(false);
  };

  const updateChatHistory = (message: Dict) => {
    setChatHistory((chatHistory) =>
      [...chatHistory, message].filter((m) => m?.content || m?.text)
    );
  };

  const handleSetPrompt = async (): Promise<void> => {
    const newPrompt = { role: "user", content: prompt, };
    updateChatHistory(newPrompt);
    setPromptAdded(true);
    setPrompt("");
  };

  const handleSetResponse = async () => {
    const newResponse = { role: "assistant", content: response, };
    canType.current = true;
    updateChatHistory(newResponse);
    setResponseAdded(false);
    setResponse("");
  };

  useEffect(() => {
    fetchChats.refetch();
    if (chats?.length) handleDefaultLastSession();
    return () => {
      canType.current = false;
    };
  }, []);

  // useEffect(() => {
  //   if (!currentChatId && chats?.length) {
  //     handleDefaultLastSession();
  //   }
  // }, [chats, querySessionId]);

  useEffect(() => {
    let timeout;
    if (chatHistory?.length && promptAdded) {
      handleGetResponse();
      // setMockLoading(true);
      // timeout = setInterval(() => { mockGetResponse(); }, 1000 * 3);
    }
    if (response && responseAdded) {
      handleSetResponse();
    }
    return () => timeout && clearInterval(timeout);
  }, [promptAdded, responseAdded]);

  useEffect(() => {
    setURLQuery({ sessionId: currentChatId });
  }, [currentChatId]);

  const isLoadingChat = isChatting || mockLoading;

  if (fetchChats?.isLoading || isDeleting) {
    return (
      <div className="w-full h-full bg-white flex justify-center">
        <Loader size={"xl"} />
      </div>
    );
  }

  // console.log("chats", chats);
  // console.log("chatHistory", chatHistory);

  return (
    <>
      {
        actionModal?.chatList &&
        <ModalSearch
          position={"top-[8rem] right-10"}
          // items={chats?.filter((c) => c?.history?.length) || []}
          items={chats || []}
          renderItem={(item) => (
            <p className="text-wrap flex items-center">
              <BsRobot className="mr-2 min-w-5" size={18} />
              {item?.title}
              <button
                className="justify-self-end ml-auto text-purple-500 hover:text-red-500 rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteSession(item.id!)
                  // console.log("delete", e);
                }}>
                <DeleteIcon />
              </button>
            </p>
          )}
          onDone={() =>
            setActionModal((prev) => ({ ...prev, chatList: false }))
          }
          onClick={(item) => handleSwitchSession(item?.id!)}
        />
      }
      <main className="flex h-full flex-col px-4 sm:px-24 gap-y-4 pb-4">
        <div className="flex mt-2 justify-between px-5 border-b">
          <p className="text-2xl font-bold -sm:text-sm">{currentChatId ? title : `New Conversation`}</p>
          <div className="flex-grow" />
          <div className="ml-4 mb-1">
            <button
              className="w-max h-max px-4 py-1 hover:bg-[#E9D8FD] text-[#101828] hover:text-[#553C9A] border rounded-md flex items-center"
              onClick={handleNewChatSession}
            >
              <BsFillChatQuoteFill />
              <span className="ml-2">New Chat</span>
            </button>
          </div>
          {chats?.length ?
            <ActionsBtn className=" hover:bg-secondary-light"
              onClick={() => setActionModal((prev) => ({ ...prev, chatList: true }))}>
              <BsChatSquareDots /> <p className="ml-2">More Chats</p>
            </ActionsBtn>
            : null
          }
        </div>

        <div className="grow overflow-y-auto">
          <ChatContainer
            chatHistory={chatHistory}
            canType={canType}
            isLoading={isLoadingChat}
            sessionId={currentChatId}
          />
        </div>
        <input
          ref={inputRef}
          type="text"
          className="placeholder-right w-full border-2 border-gray-300 rounded-md p-2"
          placeholder="Type your message .... Press Enter to send."
          value={prompt}
          disabled={isLoadingChat}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSetPrompt();
            }
          }}
        />
      </main>
    </>
  );
};

export default Conversations;

const testResp = `Certainly, here is another grant opportunity that may be relevant to the Shade Tree Project:

1. **Global Fund for Women**: The Global Fund for Women provides grants to organizations that advance the human rights of women and girls. The Shade Tree Project's focus on empowerment and health could resonate with the Global Fund for Women's mission, especially if there are specific programs targeting women and girls with albinism in Mozambique.

   For more information and to apply, visit the Global Fund for Women website:
   [Global Fund for Women Grants](https://www.globalfundforwomen.org/apply-for-a-grant/)

Please ensure to check the eligibility criteria and application deadlines for each grant opportunity before applying.`;
