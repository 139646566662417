import React from "react";
import Loader from "./Loader";
import { LOCAL_STORE_KEYS, isDev } from "@utils/index";
import { Outlet } from "react-router-dom";
import { withChakra } from "./ChakraWrapper";
import { Center } from "@chakra-ui/react";

// https://www.robinwieruch.de/react-router-authentication/
// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#creating-protected-routes

export const ProtectedRoute = withChakra(() => {
  if (process.env.VITE_UNAUTH_API) return <Outlet />;

  const refreshToken = localStorage.getItem(LOCAL_STORE_KEYS.refreshToken);

  if (!refreshToken) {
    setTimeout(() => {
      window.location.href = "/login";
    }, 5 * 1000);

    return (
      <>
        <Loader />
        <Center>Looks like you don't have access...please login!</Center>
      </>
    );
  }

  return <Outlet />;
});

export const DevOnlyRoute = withChakra(() => {
  if (isDev) return <Outlet />;

  setTimeout(() => {
    window.location.href = "/";
  }, 1 * 1000);

  return <Loader />;
});
