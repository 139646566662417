import { useEffect, useRef } from "react";

export const useAlwaysScrollToBottom = () => {
  const AlwaysScrollToBottom = () => {
    // https://stackoverflow.com/a/61266099
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => elementRef && elementRef?.current?.scrollIntoView());
    // https://stackoverflow.com/a/52266212
    return <div ref={elementRef} />;
  };
  return { AlwaysScrollToBottom };
};
