import { FiPaperclip, FiSettings } from "react-icons/fi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { BiX } from "react-icons/bi";
import CustomDropdown from "../CustomDropdown";
import { SetStateAction, useContext, useRef, useState } from "react";
import { useDisplayToast, usePlanFeatureCheck } from "@utils/hooks";
// import { result } from "lodash";
import { ContextStore } from "@components/utils/Context";
import Loader from "@components/utils/Loader";
import {
  PromptAttachment,
  IResponse,
  CreatePromptPayload,
  Dict,
  TDict,
} from "@utils/interface";
// import { uniqueObjArray } from "@utils/index";
// import { AiOutlineConsoleSql } from "react-icons/ai";
import { useOnClickOutside } from "usehooks-ts";
import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import SnippetSearchNovel from "./SnippetSearchNovel";
import { PLANS, includesString, isNullOrEmpty } from "@utils/index";
import { LuSettings } from "react-icons/lu";
import { MdGraphicEq } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";

interface Props {
  canCancel: boolean;
  isLoading: boolean;
  handleGenerate: (data: CreatePromptPayload, cb: () => void) => void;
  editor?: any;
  onClose?: () => void;
}

interface Payload {
  prompt: string;
  extraContext: string;
  options: {
    account: boolean;
    grant: boolean;
    creativity: number;
    wordCount: number;
  };
}

const NovelPanel = (props: Props) => {
  const defaultPayload = {
    prompt: "",
    extraContext: "",
    options: { account: true, grant: true, creativity: 0, wordCount: 0 },
  };
  const { canCancel, isLoading, handleGenerate, onClose, editor } = props;
  const { files, flags, globalState, setGlobalState } =
    useContext(ContextStore);
  // const { successToast, errorToast, warningToast } = useDisplayToast();
  const { needAccess } = usePlanFeatureCheck();

  const [tones, setTones] = useState<PromptAttachment[]>([]);
  const [docs, setDocs] = useState<PromptAttachment[]>([]);
  // const [payload, setPayload] = useState<Payload>(defaultPayload);
  const payload = globalState?.promptPayload || defaultPayload;
  const setPayload = (data: SetStateAction<Payload>) => {
    setGlobalState((prev) => ({ ...prev, promptPayload: data }));
  };
  const [options, setOptions] = useState<TDict<boolean>>({
    contextOpen: false,
    showSnippets: false,
    skipGenMode: false,
  });

  const voices =
    files
      // ?.filter((file) => file?.metadata.category === "voice")
      ?.filter((file) => file?.type.toLowerCase() === "voice")
      .map((file) => ({ id: file?.id, name: file?.name })) || [];
  const sources =
    files
      // ?.filter((file) => file?.metadata.category === "source")
      ?.filter((file) => file?.type.toLowerCase() !== "voice")
      .map((file) => ({ id: file?.id, name: file?.name })) || [];

  // console.log("files", files);
  // console.log("voices", voices);
  // console.log("sources", sources);

  // const handleRemoveTone = (id: string) => {
  //   setTones(tones.filter((tone) => tone?.id !== id));
  // };

  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);

  const handleAddAtt = (att: PromptAttachment) => {
    setIsAttachmentOpen(false);
    if (!docs?.some((obj) => obj?.id === att?.id)) {
      docs.length < 2 && setDocs([...docs, att]);
    }
  };

  const handleRemoveDoc = (id) => {
    setDocs(docs.filter((att) => att?.id !== id));
  };

  const contextRef = useRef<HTMLDivElement>(null);
  const modeRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  useOnClickOutside(dropdownRef, () => setIsAttachmentOpen(false));
  // useOnClickOutside(modeRef, () => setSkipGenMode(false));

  const handleContextToggle = () => {
    if (options?.skipGenMode) return;
    setOptions({ ...options, contextOpen: !options?.contextOpen });
    options?.contextOpen &&
      contextRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const clearPromptPanel = () => {
    setPayload(defaultPayload);
    setDocs([]);
    setTones([]);
    onClose && onClose();
  };

  const promptPayload = () => {
    return {
      ...payload,
      ...(skipAi && { skipAi: true }),
      ...(!!(docs.length || tones.length) && {
        files: {
          connect: [
            ...docs.map((doc) => ({ id: doc.id })),
            ...tones.map((tone) => ({ id: tone.id })),
          ],
        },
      }),
    };
  };

  const hasPrompt = !isNullOrEmpty(payload?.prompt);
  const skipAi = options?.showSnippets || options?.skipGenMode;
  const disableGenerate = skipAi || isLoading || !hasPrompt;

  // console.log("payload", isLoading, disableGenerate, payload);

  return (
    <div
      // className="novel-rounded-md novel-shadow-lg novel-border novel-border-red-500 novel-bg-red-500 "
      style={{
        width: "100%",
      }}
    >
      <div className="mb-4 pl-2 pr-1 bg-neutral-100 border-b text-sm flex justify-between items-center h-10">
        <div className="mr-4 flex h-max w-full no-scrollbar overflow-x-scroll">
          {tones[0] && (
            <div className=" mr-4 px-2 py-[2px] w-max flex-shrink-0 text-[#7A5AF8] bg-[#F4F3FF] flex items-center rounded-full">
              <span>{tones[0]?.name}</span>
              <button
                onClick={() => setTones([])}
                className="ml-1 rounded-full p w-max h-max"
              >
                <BiX />
              </button>
            </div>
          )}
        </div>

        {/* <div
          style={{ width: '8rem', flexShrink: 0, float: 'right', }}
          className="w-32 flex-shrink-0"
        >
          <Tooltip
            label={
              voices?.length === 0
                ? `You have 0 VOICE files. Visit Documents to upload your VOICE files first.`
                : ""
            }
            // style={{ zIndex: 10000, }}
            // className="z-1000"
          >
            <span>
              <CustomDropdown
                options={voices?.map((voice) => voice.name)}
                id="tones"
                value={"Tone of Voice"}
                color={`${skipAi ? "text-gray-300" : "#553C9A"}`}
                disabled={voices?.length === 0 || skipAi || false}
                onChange={(val) => {
                  // const newTones = uniqueObjArray([
                  //   ...tones,
                  //   voices.find((doc) => doc.name === val.target.value),
                  // ]);
                  // // console.log("newTones", newTones);
                  // setTones([...newTones]);
                  setTones([
                    voices?.find(
                      (doc) => doc.name === val.target.value
                    ) as PromptAttachment,
                  ]);
                }}
              />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="px-4 pb-4">
        <textarea
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "1px solid #B794F4",
            borderRadius: "0.375rem",
          }}
          className="h-full w-full mb-3 resize-none outline-none"
          placeholder="Enter a grant application prompt and let AI generate your first draft!"
          name="section-draft"
          id="section-draft"
          value={payload?.prompt || ""}
          autoFocus
          disabled={isLoading}
          onChange={(e) => setPayload({ ...payload, prompt: e.target.value })}
        />

        <div className="flex-1 relative sm:flex justify-between items-center">
          <div className="flex-y-center gap-4 flex-1">
            <Tooltip
            // label={
            //   !options?.contextOpen && !skipAi
            //     ? `Click to include any additional context below`
            //     : ""
            // }
            >
              <button
                onClick={() => handleContextToggle()}
                className={`${
                  skipAi ? "cursor-default text-gray-300" : "text-[#553C9A]"
                }`}
                disabled={skipAi}
              >
                <div>
                  {options?.contextOpen ? (
                    <FaAngleUp color={"purple"} />
                  ) : (
                    <FaAngleDown />
                  )}
                </div>
              </button>
            </Tooltip>

            {isAttachmentOpen && (
              <div className=" absolute left-3 bottom-9 z-50 rounded overflow-hidden border bg-white">
                <ul ref={dropdownRef} className=" w-max min-w-full rounded-b">
                  {sources?.map((source) => (
                    <li
                      className={` ${
                        docs.length === 2
                          ? "bg-neutral-100 cursor-default pointer-events-none"
                          : "hover:bg-[#B794F4] hover:text-white cursor-pointer"
                      } w-full px-2 py-[6px] list-none`}
                      key={source?.name}
                      onClick={() => handleAddAtt(source)}
                    >
                      {source?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <Tooltip
            // label={
            //   sources?.length === 0
            //     ? `You have 0 files. Visit Documents to upload your SOURCE files or drag a file into this box to attach.`
            //     : ""
            // }
            >
              <button
                className={`${skipAi ? "text-gray-300" : "text-[#553C9A]"}`}
                disabled={sources?.length === 0 || skipAi}
                onClick={() => {
                  if (sources?.length) {
                    setIsAttachmentOpen(!isAttachmentOpen);
                  }
                  // else{
                  //   warningToast(`You have 0 files. Visit Documents to upload your SOURCE files or drag a file into this box to attach.`)
                  // }
                }}
              >
                <FiPaperclip />
              </button>
            </Tooltip>

            <Tooltip
            // label={`Draft new response from existing responses`}
            >
              <button
                className={`${
                  !hasPrompt ? "text-gray-300 cursor-default" : "text-[#553C9A]"
                }`}
                onClick={() => {
                  // if (needAccess([PLANS.basic])) return;
                  setOptions({ ...options, showSnippets: true });
                }}
                disabled={!hasPrompt}
              >
                <PiClockCounterClockwiseBold />
              </button>
            </Tooltip>

            <Tooltip
            // label={`Control AI Settings`}
            >
              <span>
                <CustomDropdown
                  options={[
                    <span className="text-xs flex justify-center">
                      AI Settings
                    </span>,
                    <span className="w-full flex mr-2 text-xs justify-between">
                      Add Org Info:{" "}
                      <Switch
                        size="sm"
                        colorScheme="purple"
                        isChecked={payload?.options?.account}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            options: {
                              ...payload?.options,
                              account: e.target.checked,
                            },
                          })
                        }
                      />
                    </span>,
                    globalState?.currentDocument?.grantId ? (
                      <span className="w-full flex mr-2 text-xs justify-between">
                        Add Grant Info:
                        <Switch
                          size="sm"
                          colorScheme="purple"
                          isChecked={payload?.options?.grant}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              options: {
                                ...payload?.options,
                                grant: e.target.checked,
                              },
                            })
                          }
                        />
                      </span>
                    ) : null,
                    needAccess([PLANS.FREE, PLANS.BASIC], false) ? null : (
                      <>
                        <span className="text-xs flex justify-center">
                          Word Count: {payload?.options?.wordCount}
                        </span>
                        <span className=" text-xs justify-between w-full">
                          <Slider
                            aria-label="slider-ex-4"
                            defaultValue={payload?.options?.wordCount}
                            onChange={(val) =>
                              setPayload({
                                ...payload,
                                options: {
                                  ...payload?.options,
                                  wordCount: val,
                                },
                              })
                            }
                            min={100}
                            max={1500}
                            // max={!includesString([PLANS.pro, PLANS.premium],account?.planName) ? 1500 : 3000}
                            step={100}
                          >
                            <SliderTrack bg="#eee8f8">
                              <SliderFilledTrack bg="#553C9A" />
                            </SliderTrack>
                            <SliderThumb boxSize={5}>
                              <Box color="#553C9A" as={HiOutlineLightBulb} />
                            </SliderThumb>
                          </Slider>
                        </span>
                      </>
                    ),
                    // <span className="text-xs flex justify-center">
                    //   Creativity
                    // </span>,
                    flags?.v2?.isEnabled ? (
                      <span className=" text-xs justify-between w-full">
                        <Slider
                          aria-label="slider-ex-4"
                          defaultValue={payload?.options?.creativity}
                          onChange={(val) =>
                            setPayload({
                              ...payload,
                              options: { ...payload?.options, creativity: val },
                            })
                          }
                          min={0}
                          max={2}
                          step={0.5}
                        >
                          <SliderMark value={0} fontSize="xs">
                            Normal
                          </SliderMark>
                          {/* <SliderMark value={0.5} mt='1' ml='-2.5' fontSize='xs'>
                            25%
                          </SliderMark>
                          <SliderMark value={1} mt='1' ml='-2.5' fontSize='xs'>
                            50%
                          </SliderMark>
                          <SliderMark value={1.5} mt='1' ml='-2.5' fontSize='xs'>
                            75%
                          </SliderMark> */}
                          <SliderMark value={1} fontSize="xs">
                            Creative
                          </SliderMark>
                          <SliderTrack bg="#eee8f8">
                            <SliderFilledTrack bg="#553C9A" />
                          </SliderTrack>
                          <SliderThumb boxSize={5}>
                            <Box color="#553C9A" as={HiOutlineLightBulb} />
                          </SliderThumb>
                        </Slider>
                      </span>
                    ) : null,
                  ]}
                  value={<LuSettings />}
                  color="#553C9A"
                  showDropdown={false}
                  interactive={true}
                  emptyClass={true}
                  id="options"
                />
              </span>
            </Tooltip>

            <div className="mx-5 w-24 overflow-scroll no-scrollbar flex-1 flex">
              {docs?.map(({ id, name }, idx) => {
                return (
                  <div
                    key={idx}
                    className=" mr-4 h-max px-2 py-[3px] w-max flex-shrink-0 text-sm text-[#7A5AF8] bg-[#F4F3FF] flex items-center rounded-full"
                  >
                    <span>{name}</span>
                    <button
                      onClick={() => handleRemoveDoc(id)}
                      className="ml-1 rounded-full p w-max h-max"
                    >
                      <BiX />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              paddingTop: "1rem", // pt-4
              // height: '1.5rem', // h-10
              // width: 'max-content', // w-max
              // marginTop: '1rem', // mt-4
              display: "flex", // flex
              // justifyContent: 'space-between', // justify-between
              // '@media (min-width: 640px)': { // sm:w-full and sm:mt-0
              //    width: '100%',
              //    marginTop: '0',
              // },
              // float: 'right',
              justifyContent: "flex-end",
            }}
            className="h-10 w-max -sm:w-full mt-4 sm:mt-0 flex justify-between"
          >
            {canCancel && (
              <button
                style={{
                  marginRight: "0.5rem", // mr-2
                  paddingLeft: "1.5rem", // px-6
                  paddingRight: "1.5rem", // px-6
                  height: "100%", // h-full
                  backgroundColor: isLoading
                    ? "linear-gradient(to right, blue, green)"
                    : "#F3F4F6", // bg-white-200, assuming a common light gray
                  color: isLoading ? "white" : "black", // "#6B7280", // text-gray
                  // color: disableGenerate ? "black" : "#6B7280",
                  borderWidth: "1px", // border-[1px]
                  borderColor: isLoading ? "white" : "black", // border-black
                  borderRadius: "0.375rem", // rounded-lg
                }}
                className={` mr-2 px-6 h-full border-[1px] ${
                  isLoading
                    ? "bg-gradient text-white"
                    : " bg-white-200 text-gray"
                }  border-black rounded-lg`}
                // disabled={isLoading}
                onClick={clearPromptPanel}
              >
                {isLoading ? "Cancel" : "Close"}
              </button>
            )}

            <div style={{ flexGrow: 1 }} className="flex-grow" />
            <div
              style={{
                borderTopLeftRadius: options?.skipGenMode ? "0" : "0.375rem", // Assuming rounded-lg is 0.375rem
                backgroundColor: options?.skipGenMode ? "gray" : "transparent", // Assuming bg-gray-300 is gray
                display: "flex",
                alignItems: "center", // Centers children vertically
                borderRadius: "0.375rem", // Assuming rounded-lg is 0.375rem
                overflow: "hidden",
                position: "relative",
                float: "right",
              }}
              className={`${
                options?.skipGenMode ? "rounded-tl-none bg-gray-300" : ""
              } flex-y-center rounded-lg overflow-hidden relative`}
            >
              <Tooltip
              // label={"Generate via Ai"}
              >
                <button
                  style={{
                    cursor: disableGenerate ? "default" : "auto",
                    backgroundColor: disableGenerate
                      ? "#F3F4F6"
                      : "linear-gradient(to right, blue, green)",
                    color: disableGenerate ? "black" : "white",
                    paddingLeft: "1.5rem", // Assuming px-6 translates to 1.5rem
                    paddingRight: "1.5rem", // Assuming px-6 translates to 1.5rem
                    height: "100%",
                    marginRight: "0.5rem", // Assuming mr-2 translates to 0.5rem
                    borderWidth: "1px", // border-[1px]
                    borderColor: disableGenerate ? "black" : "white",
                    borderRadius: "0.375rem", // rounded-lg
                  }}
                  className={`${
                    disableGenerate
                      ? "cursor-default bg-gray-300"
                      : "bg-gradient"
                  } text-white px-6 h-full`}
                  disabled={disableGenerate}
                  onClick={(e) =>
                    handleGenerate(promptPayload(), clearPromptPanel)
                  }
                >
                  {isLoading && !skipAi ? <Loader size={"sm"} /> : "Generate"}
                </button>
              </Tooltip>
              {/* <button
                className=" px-2.5 h-full bg-gradient text-white"
                onClick={() =>
                  setOptions({ ...options, skipGenMode: !options?.skipGenMode })
                }
              >
                {options?.skipGenMode ? <FaAngleUp /> : <FaAngleDown />}
              </button> */}
            </div>

            {options?.skipGenMode && (
              <div
                ref={modeRef}
                className="w-28 absolute right-9 bottom-[40px] z-50 rounded-t overflow-hidden text-white bg-gray-300 transition-all duration-50"
              >
                <Tooltip
                // label={"Skip Ai Generate"}
                >
                  <button
                    disabled={!hasPrompt}
                    onClick={() =>
                      handleGenerate(promptPayload(), clearPromptPanel)
                    }
                    // className="h-10 flex-center cursor-pointer hover:[background:_var(--gradient-primary-80060045-deg,_linear-gradient(45deg,_#F98BCF_0%,_#7928CA_100%))]"
                    className={`h-10 flex-center ${
                      !hasPrompt
                        ? "bg-gray-300 cursor-default"
                        : " cursor-pointer bg-gradient"
                    } justify-center w-full`}
                  >
                    {isLoading && skipAi ? <Loader size={"sm"} /> : "Create"}
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        {options?.contextOpen && (
          <div
            style={{ paddingTop: "1rem" }}
            ref={contextRef}
            className="h-20 mt-4"
          >
            <textarea
              style={{
                width: "100%",
                padding: "0.5rem",
                border: "1px solid #B794F4",
                borderRadius: "0.375rem",
              }}
              name="context-input"
              id="context-input"
              placeholder="Put any extra context/info and/or commands here. Eg. 'Include a quote/statistic', etc."
              value={payload?.extraContext || ""}
              autoFocus
              disabled={isLoading}
              onChange={(e) =>
                setPayload({ ...payload, extraContext: e.target.value })
              }
              className="p-2 h-full w-full custom-scrollbar border rounded outline-[#B794F4] resize-none"
            />
          </div>
        )}
      </div>

      {options?.showSnippets && (
        <SnippetSearchNovel
          q={payload?.prompt}
          setShowModal={(e) => setOptions({ ...options, showSnippets: e })}
          handleGenerate={handleGenerate}
        />
      )}
    </div>
  );
};

export default NovelPanel;
