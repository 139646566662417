import { useContext, useState, useRef, useEffect, useMemo } from "react";
import { useGetList, useGetMany } from "react-admin";
import { useDisplayToast, usePlanFeatureCheck, usePost } from "@utils/hooks";
import Loader from "@components/utils/Loader";
import SearchResult from "@components/document/SearchResult";
import { IoEye } from "react-icons/io5";
import {
  ActionType,
  BoardItem,
  LinkType,
  Dict,
  Grant,
  GrantResponse,
  IResponse,
  Entity,
} from "@utils/interface";
import { FaFileImport } from "react-icons/fa";
import { ContextStore } from "@components/utils/Context";
import { PLANS, isNullOrEmpty } from "@utils/index";
import { GrantAddProps } from "../interfaces";

interface Props extends GrantAddProps {
  handleAddGrant: (data: GrantResponse) => void;
}

const SearchTab = (props: Props) => {
  const { grantInfo, setGrantInfo, setHasChanges, handleAddGrant } = props;
  const { grants, boardItems, appModal, setAppModal, account, hasChanges } =
    useContext(ContextStore);
  const { errorToast } = useDisplayToast();
  const [results, setResults] = useState<Grant[] | null>(
    appModal?.searchTab?.results || undefined
  );
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { needAccess } = usePlanFeatureCheck();

  const [linkGrant, { isLoading: isCreating }] = usePost<BoardItem>();

  //call api to get search data
  const { isFetching: isSearching, refetch: executeSearch } = useGetList(
    `${Entity.grants}/search`,
    {
      filter: { query: grantInfo },
    },
    {
      enabled: false,
      onError: (error: any) => {
        errorToast(error?.message || error?.body?.message);
      },
      onSuccess: (resp: any) => {
        setResults(resp?.data);
        if (resp?.data?.length) {
          setAppModal((prev) => ({
            ...prev,
            searchTab: { ...prev?.searchTab, results: resp?.data },
          }));
        }
      },
      onSettled(data, error) {
        setHasChanges(false);
      },
    }
  );

  const handleLinkGrant = async (grant: Grant) => {
    await linkGrant(
      `${Entity.boardItems}/link`,
      {
        data: {
          grantId: grant?.id,
          accountId: account?.id,
        } as any,
      },
      {
        onSuccess(boardItem, variables, context) {
          handleAddGrant({ grant, boardItem });
          setSelectedItem(null);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  useEffect(() => {
    if (!isNullOrEmpty(inputRef.current?.value) && hasChanges?.addGrant) {
      executeSearch();
    }
    if (inputRef.current && !inputRef.current?.value) {
      inputRef.current.value = grantInfo || appModal?.searchTab?.query || "";
      setHasChanges(false);
    }
  }, [grantInfo, inputRef]);

  return (
    <>
      <div className="text-sm px-5 pb-10">
        <div>
          <label htmlFor="grant-search">What grants are you looking for?</label>
          <input
            ref={inputRef}
            autoFocus
            className="h-10 py-2 px-3 mt-1 w-full block outline-none rounded border"
            placeholder="Search then Press Enter e.g Grants for minority business owners in the cleaning industry"
            name="grant-search"
            id="grant-search"
            onBlur={() => {
              setGrantInfo(inputRef.current?.value || "");
              setAppModal((prev) => ({
                ...prev,
                searchTab: {
                  ...prev?.searchTab,
                  query: inputRef.current?.value,
                },
              }));
            }}
            onFocus={() => {
              // console.log('grantInfo', grantInfo);
              // console.log('inputRef.current?.value', inputRef.current?.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setHasChanges(true);
                inputRef.current?.blur();
              }
            }}
          />
        </div>
      </div>
      <div className="pt-3 text-[#6C6685]">
        {isSearching ? (
          <Loader size={"lg"} />
        ) : !results ? null : !results?.length ? (
          <div className="text-center">
            <p className="font-bold italic">No results found</p>
          </div>
        ) : (
          <div className="-md:px-5 md:px-10 pb-8 mt-auto mb-5 h-[65vh] w-full overflow-y-auto custom-scrollbar bg-white rounded-lg">
            {results?.map((grant, idx) => {
              const handleLink = () => {
                // if (needAccess([PLANS.basic, PLANS.pro])) return;
                setSelectedItem(idx);
                handleLinkGrant(grant);
              };
              return (
                <SearchResult
                  key={idx}
                  title={grant?.name || ""}
                  description={grant?.description || ""}
                  action1Icon={<IoEye />}
                  action2Icon={
                    isCreating && idx === selectedItem ? (
                      <Loader size={"sm"} />
                    ) : (
                      <FaFileImport />
                    )
                  }
                  timestamp={grant?.updatedAt || ""}
                  action1Callback={() => {
                    // if (needAccess([PLANS.basic, PLANS.pro])) return;
                    setAppModal((prev) => ({
                      ...prev,
                      type: LinkType.grant,
                      views: { ...prev.views, [ActionType.view]: true },
                      item: grant,
                    }));
                  }}
                  action2Callback={
                    boardItems?.find((g) => g.grantId === grant.id)
                      ? null
                      : handleLink
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchTab;
