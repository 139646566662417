import { MdCancel, MdCheckCircle } from "react-icons/md";
import AppButton from "../AppButton";
// import { useOnClickOutside } from "usehooks-ts";
import { useEffect, useRef } from "react";
import useOutsideClickContains from "@hooks/useOutsideClickContains";
import Overlay from "../Overlay";
import CloseBtn from "../CloseButton";

interface Props {
  title?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  handleClose?: () => void;
  handleConfirm?: () => void;
  canClickOutside?: boolean;
  disableConfirm?: boolean;
  disableClose?: boolean;
  overlay?: boolean;
  card?: boolean;
  onDone?: () => void;
  isLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
  showClose?: boolean;
}

const ActionModal = ({
  title,
  icon,
  children,
  handleClose,
  handleConfirm,
  onDone,
  isLoading,
  confirmText = "Confirm",
  cancelText = "Close",
  canClickOutside = true,
  disableConfirm = false,
  disableClose = false,
  overlay = false,
  card = false,
  showClose = false,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  // useOnClickOutside(modalRef, () => handleClose && handleClose());

  const onClose = () => (handleClose && handleClose()) || (onDone && onDone());

  useOutsideClickContains(modalRef, () => canClickOutside && onClose());

  return (
    <Overlay showOverlay={overlay}>
      <div className="flex-center fixed left-0 top-0 w-screen h-screen z-[1001]">
        <div
          ref={modalRef}
          className={`mx-6 ${
            card
              ? "w-content"
              : "pb-[68px] w-full max-w-[380px] rounded-3xl border-[#6b4bbe] overflow-hidden"
          } border bg-white relative `}
        >
          {!card && (
            <div className="py-4 flex items-center flex-col text-white bg-[#6b4bbe]">
              {icon && <p className="text-[80px]">{icon}</p>}
              {title && <p className="text-2xl">{title}</p>}
            </div>
          )}

          <div
            className={`flex flex-col items-center p-5 h-full ${
              card
                ? "max-h-screen min-h-[180px] overflow-y-auto"
                : "min-h-[180px] max-h-[450px] overflow-y-auto"
            } custom-scrollbar relative`}
          >
            {showClose && (
              <div className="mb-1">
                <CloseBtn variant="full" closeModal={onClose} />
              </div>
            )}
            {children}
          </div>

          <div className="pb-3 w-full absolute z-10 bottom-0 flex-shrink-0 flex-x-center bg-white">
            {handleConfirm && (
              <AppButton
                icon={MdCheckCircle}
                text={confirmText}
                variant="secondary"
                iconPosition="left"
                onClick={handleConfirm}
                isDisabled={disableConfirm}
                isLoading={isLoading}
                marginTop="4"
              />
            )}
            {handleClose && (
              <AppButton
                icon={MdCancel}
                text={cancelText}
                variant="primary"
                iconPosition="left"
                onClick={handleClose}
                isDisabled={disableClose}
                marginTop="4"
              />
            )}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default ActionModal;
