import { withChakra } from "../components/utils/ChakraWrapper";
import careerCard from "./career-card";
import communityCard from "./community-card";
import featuresCard from "./features-card";
import simpleMenu from "./simple-menu";

// DESIGN TOOLS
// https://templateskart.com/components/features
// https://chakra-templates.dev/forms/authentication
// https://chakra-ui.com/docs/components/spinner
// https://react-icons.github.io/react-icons/search?q=park

export default withChakra(() => {
  return (
    <>
      {/* {careerCard()} */}
      {communityCard()}
      {featuresCard()}
      {simpleMenu()}
    </>
  );
});
