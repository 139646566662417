import InputForm from "@components/InputForm";
import { ContextStore } from "@components/utils/Context";
import { includesString, isDev, isWhiteListAgency } from "@utils/index";
import { Account, InputField } from "@utils/interface";
import { set } from "lodash";
import { useContext } from "react";

interface NewAccountProps {
  newAccount: Account;
  setNewAccount: React.Dispatch<React.SetStateAction<Account>>;
  customFields?: InputField[];
  customHeader?: React.ReactNode;
  customFooter?: React.ReactNode;
}

const NewAccountForm = (props: NewAccountProps) => {
  const { user } = useContext(ContextStore);
  const {
    newAccount,
    setNewAccount,
    customFields,
    customHeader,
    customFooter,
  } = props;

  const isSignup = window.location.pathname.includes("signup");

  return (
    <>
      {customHeader ? customHeader : null}
      <InputForm
        fields={[
          {
            label: "Organization Name",
            type: "text",
            key: "name",
            placeholder: "Enter Organization's Name",
            required: true,
          },
          {
            label: "Email",
            type: "email",
            key: "primaryContact.email",
            placeholder: "Point of Contact's Email Address",
            required: isSignup || !isWhiteListAgency(user?.accountId),
          },
          {
            label: "First Name",
            type: "text",
            key: "primaryContact.firstName",
            placeholder: "Point of Contact's First Name",
            required: isSignup || !isWhiteListAgency(user?.accountId),
          },
          {
            label: "Last Name",
            type: "text",
            key: "primaryContact.lastName",
            placeholder: "Point of Contact's Last Name",
            visible: !isSignup,
            required: isSignup || !isWhiteListAgency(user?.accountId),
          },
          // {
          //   label: "Address",
          //   type: "text",
          //   key: "address",
          //   placeholder: "123 Main St",
          // },
          // {
          //   label: "City",
          //   type: "text",
          //   key: "city",
          //   placeholder: "Seattle",
          // },
          // {
          //   label: "State",
          //   type: "text",
          //   key: "state",
          //   placeholder: "WA",
          // },
          // {
          //   label: "Zip / Postal",
          //   type: "text",
          //   key: "zip",
          //   placeholder: "98101",
          // },
          // {
          //   label: "Country",
          //   type: "text",
          //   key: "country",
          //   placeholder: "United States",
          // },
          ...(customFields || []),
          // {
          //   label: "Mission",
          //   type: "text",
          //   key: "mission",
          //   placeholder: "Enter Org Mission",
          // },
          // {
          //   label: "History",
          //   type: "text",
          //   key: "history",
          //   placeholder: "Enter Org History",
          // },
          // {
          //   label: "Mandate",
          //   type: "text",
          //   key: "mandate",
          //   placeholder: "Enter Org Mandate",
          // },
          // {
          //   label: "EIN",
          //   type: "text",
          //   key: "ein",
          //   placeholder: "XX-XXXXXX",
          // },
        ]?.filter((field) => field.visible !== false)}
        object={newAccount}
        onChange={(e) => {
          const account = set(
            { ...newAccount },
            e?.target?.id || e?.target?.name,
            e?.target?.value
          );
          setNewAccount(account);
        }}
      />
      {customFooter ? customFooter : null}
    </>
  );
};

export default NewAccountForm;
