// import { ChatCompletionRequestMessage } from "openai";
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CursorSVG from "./icons/CursorSVG";
// import ReactMarkdown from "react-markdown";
import { ChatMessage, processMarkdown } from "@utils/index";
import ChatLoader from "@components/ChatLoader";
import { useAlwaysScrollToBottom } from "@utils/helpers";
import { startsWith } from "lodash";

interface ChatContainerProps {
  canType: React.MutableRefObject<boolean>;
  chatHistory: ChatMessage[];
  isLoading?: boolean;
  sessionId?: string;
}

const TYPING_SPEED = 1;

export default function ChatContainer({
  chatHistory,
  canType,
  isLoading,
  sessionId,
}: ChatContainerProps) {
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);
  const { AlwaysScrollToBottom } = useAlwaysScrollToBottom();
  // const { flags } = useContext(ContextStore);
  // console.log("ChatContainer", chatHistory);

  useEffect(() => {
    setDisplayResponse("");
  }, [sessionId]);

  useEffect(() => {
    if (!chatHistory?.length || !canType?.current) {
      return;
    }
    setCompletedTyping(false);

    let i = 0;
    const lastMsg = chatHistory[chatHistory.length - 1];
    const stringResponse = lastMsg?.content || lastMsg?.text || "";

    const intervalId = setInterval(() => {
      setDisplayResponse((stringResponse || "")?.slice(0, i));
      i++;

      if (i > stringResponse?.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
        canType.current = false;
      }
    }, TYPING_SPEED);

    return () => clearInterval(intervalId);
  }, [chatHistory]);

  const shouldType = !!canType?.current && !completedTyping;
  const isAgentMsg = (chat: ChatMessage) => !!(startsWith((chat?.role || chat?.type), 'a') && (chat?.content || chat?.text));
  const isUserMsg = (chat: ChatMessage) => !!(startsWith((chat?.role || chat?.type), 'u') && (chat?.content || chat?.text));

  return (
    <div className="max-h-0">
      <div className="overflow-y-scroll">
        {chatHistory?.map((message, idx) => {
          const chatText = message?.content || message?.text || "";
          // console.log("msg", idx, chatText, displayResponse);
          const isUser = isUserMsg(message);
          const isAgent = isAgentMsg(message);
          const isLastMsg = idx === chatHistory.length - 1;
          const agentIsTyping = isAgent && isLastMsg;
          const agentIsNotTyping = isAgent && !isLastMsg;
          // console.log("msg", idx, isUser, isAgent, isAgentTyping, isAgentDoneTyping,);
          return (
            <div key={idx}>
              {isUser && (
                <div className="chat chat-end">
                  <span className="chat-bubble whitespace-pre-line">
                    {chatText}
                  </span>
                </div>
              )}
              {agentIsTyping && (
                <div className="chat chat-start">
                  <span className="chat-bubble whitespace-pre-line">
                    {shouldType && displayResponse && (
                      <div>
                        {displayResponse}
                        <CursorSVG />
                      </div>
                    )}
                    {!shouldType && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processMarkdown(displayResponse || chatText),
                        }}
                      />
                    )}
                  </span>
                </div>
              )}
              {agentIsNotTyping && (
                <div className="chat chat-start">
                  <span className="chat-bubble whitespace-pre-line">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: processMarkdown(chatText),
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
          );
        })}
        {isLoading && (
          <div className="mb-5">
            <ChatLoader type={"wave"} />
          </div>
        )}
        <AlwaysScrollToBottom />
      </div>
    </div>
  );
}
