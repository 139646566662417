import { useRef, useState } from "react";
import { BiX, BiPlus } from "react-icons/bi";
import { useOnClickOutside } from "usehooks-ts";

interface Props {
  setShowCard: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const ActionCard = (props: Props) => {
  const { setShowCard, children, header, footer } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => setShowCard(false));

  return (
    <div ref={ref} className=" px-2 w-full absolute z-10 top-[85px]">
      <div className="pt-3 w-full border-t shadow-md mx-auto text-sm bg-white rounded-md">
        <div className="pb-0.5 px-3 flex-y-center justify-between border-b">
          {header}
          <button
            onClick={() => setShowCard(false)}
            className="mr-3 h-max text-[13px] text-gray-400 hover:text-black flex-center border border-gray-400 hover:border-black rounded-full"
          >
            <BiX />
          </button>
        </div>

        <div className="pb-3 px-3 overflow-y-scroll">{children}</div>

        <div className="border-t pt-5 pb-3 px-3 flex justify-between text-xs text-[#475467]">
          {footer}
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
