import {
  Avatar,
  Button,
  Divider,
  Stack,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  GridItem,
  InputGroup,
  InputLeftAddon,
  Icon,
  Center,
  useToast,
} from "@chakra-ui/react";
import { FiSave } from "react-icons/fi";
import SectionHeader from "@components/SectionHeader";
import AppButton from "@components/AppButton";
import {
  APP_CONST,
  AccountType,
  Entity,
  HTTP_METHODS,
  LOCAL_STORE_KEYS,
  MAX_FILE_SIZE,
  User,
  appColors,
} from "@utils/index";
import { useDisplayToast, useGet, useLocalStorage, usePut } from "@utils/hooks";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { httpClient } from "../providers/client";
import { ContextStore } from "../components/utils/Context";
import { handleFileSelect } from "./file/helpers";
import ActionModal from "@/components/modal/ActionModal";
import Loader from "@components/utils/Loader";
import { IoWarning } from "react-icons/io5";

const UserProfile = () => {
  const {
    user,
    setUser,
    // account,
    globalState,
    hasChanges,
    setHasChanges,
    actionModal,
    setActionModal,
  } = useContext(ContextStore);
  // const [uploadFile, setUploadFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.id;
    const value = e?.target.value;
    setUser({ ...user, [key]: value });
    setHasChanges({ ...hasChanges, userProfile: true });
  };

  const { successToast, errorToast, warningToast } = useDisplayToast();

  const fetchUser = useGet<User>(
    Entity.users,
    {
      id: user?.id,
      // meta: {
      //   _embed: "",
      // },
    },
    (data) => setUser(data)
  );

  const [updateUser] = usePut<User>();
  const handleUpdate = () => {
    updateUser(
      Entity.users,
      {
        id: user?.id,
        data: user,
      },
      {
        onSuccess: (data) => {
          // console.log("response", response);
          setUser(data);
          successToast(
            "You're profile has been updated successfully.",
            "Profile updated!"
          );
        },
        onError: (error) => {
          console.log("error", JSON.stringify(error));
          warningToast(
            error?.body?.message || "Profile not updated!",
            APP_CONST.DEFAULT_ERROR
          );
        },
      }
    );
  };

  const handleSelectFile = (e: React.ChangeEvent<any>) => {
    const { overLimit, file } = handleFileSelect(e);

    // console.log("overLimit", overLimit);
    // console.log("file", file);

    if (overLimit) {
      setActionModal({ ...actionModal, profileError: true });
      return;
    }
    // setUploadFile(files[0]);
    handleFileUpload(file);
  };

  const handleUploadCancel = () => {
    setActionModal({ ...actionModal, profileError: false });
    // setUploadFile(null);
  };

  const handleFileUpload = (uploadFile: File) => {
    if (!uploadFile) return;
    const formData = new FormData();
    formData.append("file", uploadFile);
    setIsLoading(true);

    return httpClient(`/upload/profile`, {
      method: HTTP_METHODS.POST,
      body: formData,
    })
      .then((res) => {
        // console.log(JSON.stringify(res));
        successToast(res?.json?.message);
        // setUploadFile(null);
        handleChanges({
          target: { id: "imageUrl", value: res?.json?.imageUrl },
        } as ChangeEvent<HTMLInputElement>);
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        errorToast(err?.body?.message, "Error uploading profile");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUser.refetch();
  }, []);

  return (
    <>
      {actionModal?.profileError && (
        <ActionModal
          title={"Error"}
          icon={<IoWarning />}
          handleClose={handleUploadCancel}
        >
          <p>File size larger than {MAX_FILE_SIZE}MB.</p>
          <p>Please select a file smaller than {MAX_FILE_SIZE}MB.</p>
        </ActionModal>
      )}
      <div className="p-6">
        <Text fontSize="2xl" className="font-bold">
          Edit Profile
        </Text>

        <Divider />
        <br />
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
        >
          <Stack direction={"row"}>
            {isLoading ? (
              <Loader size={"xl"} />
            ) : (
              <Avatar
                size={"xl"}
                src={user?.imageUrl}
                mb={4}
                shadow={"2xl"}
                border={"5px solid white"}
                onClick={(e) => fileInputRef?.current?.click()}
                className="hover:cursor-pointer"
              />
            )}
            <div className="pt-10">
              <Text fontSize="xl" fontWeight="500">
                {user?.firstName || user?.email} {user?.lastName || ""}
              </Text>
              <Text color="gray.400" fontWeight="500">
                {user?.email}
              </Text>
            </div>
          </Stack>

          <Stack direction={"column"} justify={"flex-end"}>
            <Button
              border={`1px ${appColors.tertiaryText} solid`}
              bg={"white"}
              shadow={"2xl"}
              _hover={{ bg: appColors.primaryBg, color: appColors.primaryText }}
              onClick={(e) => fileInputRef?.current?.click()}
            >
              <Text fontSize="sm" fontWeight="500">
                Change Picture
              </Text>
            </Button>
            <input
              onChange={(e) => handleSelectFile(e)}
              ref={fileInputRef}
              type="file"
              accept="image/*"
              // accept="image/jpeg, image/png"
              id="upload-profile"
              className="hidden"
            />
          </Stack>
        </Stack>

        <SectionHeader text="User Information" />

        <div className="flex -sm:flex-col flex-row pb-5 -sm:gap-[1rem] sm:gap-[10%]">
          <div className="flex flex-col w-full">
            <label htmlFor="first-name" className="pb-2">
              {globalState.isAgency ? "Agency" : "Org"} Account ID
            </label>
            <input
              type="text"
              className="border border-gray-300 p-2 rounded-md"
              value={user?.accountId}
              disabled
            />
          </div>
          <div className="flex flex-col w-full">
            <label htmlFor="first-name" className="pb-2">
              Title
            </label>
            <input
              id="title"
              type="text"
              className="border border-gray-300 p-2 rounded-md"
              placeholder="Senior Grant Writer"
              value={user?.title}
              onChange={(e) => handleChanges(e)}
            />
          </div>
        </div>

        <Flex className="-sm:grid xs:grid-cols-2 gap-x-[5%] gap-y-6">
          <FormControl as={GridItem} colSpan={6} mr="5%">
            <FormLabel htmlFor="first-name" fontWeight={"normal"}>
              First Name
            </FormLabel>
            <Input
              id="firstName"
              value={user?.firstName || ""}
              onChange={(e) => handleChanges(e)}
            />
          </FormControl>

          <FormControl as={GridItem} colSpan={6}>
            <FormLabel htmlFor="last-name" fontWeight={"normal"}>
              Last Name
            </FormLabel>
            <Input
              id="lastName"
              value={user?.lastName || ""}
              onChange={(e) => handleChanges(e)}
            />
          </FormControl>
        </Flex>
        <br />
        <Flex className="-sm:grid xs:grid-cols-2  gap-x-[5%] gap-y-6">
          <FormControl as={GridItem} colSpan={6} mr="5%">
            <FormLabel htmlFor="phone" fontWeight={"normal"}>
              Phone Number
            </FormLabel>
            <InputGroup size="md">
              <InputLeftAddon
                bg="gray.50"
                _dark={{
                  bg: "gray.800",
                }}
                color="gray.500"
                // rounded="md"
                children={"+1"}
              />
              <Input
                type="tel"
                id="phone"
                value={user?.phone || ""}
                onChange={handleChanges}
                placeholder="123-456-7890"
                // focusBorderColor="brand.400"
                rounded="md"
              />
            </InputGroup>
          </FormControl>
          <FormControl as={GridItem} colSpan={6}>
            <FormLabel htmlFor="email" fontWeight={"normal"}>
              Email Address
            </FormLabel>
            <input
              id="email"
              type="email"
              className="border border-gray-300 p-2 rounded-md w-full"
              disabled={true}
              value={user?.email || ""}
              onChange={handleChanges}
            />
          </FormControl>
        </Flex>
        <br />
        <Center>
          <AppButton
            text="Save Changes"
            icon={FiSave}
            variant="primary"
            iconPosition="right"
            isDisabled={!hasChanges?.userProfile}
            onClick={handleUpdate}
          />
        </Center>
      </div>
    </>
  );
};

export default UserProfile;
