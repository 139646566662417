import {
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { OrgFormProps } from "./interface";
import { useContext, useEffect, useState } from "react";
import { Dict } from "@utils/interface";
import { startCase } from "lodash";
import { ContextStore } from "@components/utils/Context";

const FormOverview = (props: OrgFormProps) => {
  const { setHasChanges, handleSubmit } = props;
  const [formErrors, setFormErrors] = useState<Dict>({});
  const { account, setAccount } = useContext(ContextStore);

  const industryKey = "industry",
    otherKey = "other";
  const industryOptions = [
    "Nonprofit",
    "Educational",
    "Government",
    "Research",
    "Healthcare",
    "Arts and Cultural",
    "Small Business",
    "Environmental",
    "Social Enterprise",
    "Foundation",
    "Startup",
    "other",
  ];

  const orgSizeOptions = [
    "1-10",
    "11-50",
    "51-100",
    "101-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "10001+",
  ];

  const [isOther, setIsOther] = useState(
    (account?.industry && !industryOptions.includes(account?.industry)) || false
  );

  const handleChanges = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<HTMLSelectElement, Event>
  ) => {
    const target =
      (e?.target as HTMLInputElement) ||
      (e?.currentTarget as HTMLSelectElement);
    let key = target?.id;
    let value = target?.value;
    if (key === industryKey) {
      setIsOther(value === otherKey);
      if (value === otherKey) {
        value = "";
      }
    }
    if (key === otherKey) {
      key = industryKey;
    }
    setAccount({
      ...account,
      // [key]: key.includes("num") ? Number(value) : value,
      [key]: value,
    });
    setHasChanges({ orgProfile: true });
    setFormErrors((prev) => ({ ...prev, [target.id]: "" }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement, Element>) => {
    if (isValid(e)) {
      handleSubmit(false);
    }
  };

  const isValid = (
    e: React.ChangeEvent<HTMLElement> | React.SyntheticEvent<HTMLElement, Event>
  ) => {
    const target =
      (e?.target as HTMLInputElement) ||
      (e?.currentTarget as HTMLSelectElement);
    let result = true;

    switch (target.id) {
      case "other":
        if (!target.value.length) {
          setFormErrors((prev) => ({
            ...prev,
            industry: "Industry is missing",
          }));
          result = false;
        }
        break;
    }
    return result;
  };

  // console.log("account", account);

  return (
    <>
      <h1 className="pl-3 py-1 my-8 w-full text-lg text-[#475467] bg-[#EAECF0] ">
        Organization Overview
      </h1>

      <div className="grid xs:grid-cols-2 gap-x-[5%] gap-y-6">
        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="industry" fontWeight={"normal"}>
            Organization Type
          </FormLabel>
          <Select
            id="industry"
            autoComplete="industry"
            placeholder="Select option"
            focusBorderColor="brand.400"
            value={isOther ? otherKey : account?.industry || ''}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          >
            {industryOptions.map((option) => (
              <option key={option} value={option}>
                {startCase(option)}
              </option>
            ))}
          </Select>
          {isOther && (
            <input
              className="w-full h-content mt-2 p-2 rounded-md border border-1"
              type="text"
              id="other"
              placeholder="What type of organization are you?"
              value={account?.industry}
              onChange={(e) => handleChanges(e)}
              onBlur={handleBlur}
            />
          )}
          <p className={`text-red-500 text-sm`}>{formErrors?.industry}</p>
        </FormControl>

        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="yearFounded" fontWeight={"normal"}>
            Year Founded
          </FormLabel>
          <Input
            id="yearFounded"
            type="number"
            value={account?.yearFounded}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="orgSize" fontWeight={"normal"}>
            Organization Size
          </FormLabel>
          <Select
            id="orgSize"
            autoComplete="orgSize"
            placeholder="Select option"
            focusBorderColor="brand.400"
            value={account?.orgSize || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          >
            {orgSizeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="numOfFTEmployees" fontWeight={"normal"}>
            # of Full Time Staff
          </FormLabel>
          <Input
            id="numOfFTEmployees"
            type="number"
            value={account?.numOfFTEmployees || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="numOfPTEmployees" fontWeight={"normal"}>
            # of Part Time Staff
          </FormLabel>
          <Input
            id="numOfPTEmployees"
            type="number"
            value={account?.numOfPTEmployees || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="-xs:col-span-2">
          <FormLabel htmlFor="numOfVolunteers" fontWeight={"normal"}>
            # of Volunteers
          </FormLabel>
          <Input
            id="numOfVolunteers"
            type="number"
            value={account?.numOfVolunteers || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="-sm:col-span-2">
          <FormLabel htmlFor="ein" fontWeight={"normal"}>
            Registered Organization Number / EIN
          </FormLabel>
          <Input
            id="ein"
            placeholder={"XX-XXXXXXX"}
            value={account?.ein}
            onChange={(e) => {
              // if (!e.target.value.match(/^\d{2}-\d{7}$/)) {
              //   return;
              // } else if (e.target.value.match(/^\d{2}/)) {
              //   e.target.value += '-';
              // } else {
              handleChanges(e);
              // }
            }}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormLabel htmlFor="history" fontWeight={"normal"}>
            Organization History / Background
          </FormLabel>
          <Textarea
            id="history"
            rows={4}
            placeholder={`Enter your history... e.g: "Save the Walrus" is a nonprofit organization dedicated to the conservation of walruses, a species facing significant threats due to climate change and overfishing. The organization was founded by a group of passionate environmentalists, including its founder, a white woman named Jane Smith. Jane, a former marine biologist, was deeply moved by the plight of walruses and decided to dedicate her life to their protection. The organization has since grown, with volunteers and donors from all walks of life joining the cause. Their mission is to raise awareness, advocate for policy changes, and support research efforts to ensure the survival of these majestic creatures.`}
            value={account?.history || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormLabel htmlFor="mission" fontWeight={"normal"}>
            Mission
          </FormLabel>
          <Textarea
            id="mission"
            rows={4}
            placeholder={`Enter your mission... e.g: "Save the Walrus" is committed to preserving the walrus population, a species on the brink of extinction due to climate change and overfishing. Our mission is to raise awareness about the plight of these magnificent creatures, advocate for policy changes that protect them, and support research efforts to understand their needs and threats. We believe that with collective action and dedication, we can ensure the survival of the walrus and the health of our oceans.`}
            value={account?.mission || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormLabel htmlFor="mandate" fontWeight={"normal"}>
            Mandate
          </FormLabel>
          <Textarea
            id="mandate"
            rows={4}
            placeholder={`Enter your mandate... e.g: "Save the Walrus" is mandated to protect and conserve the walrus population, a species facing imminent extinction due to climate change and overfishing. Our mandate includes raising public awareness, advocating for policy changes that prioritize walrus conservation, and supporting scientific research to understand their needs and threats. We are committed to working with governments, researchers, and the public to ensure the survival of the walrus and the health of our oceans.`}
            value={account?.mandate || ""}
            onChange={(e) => handleChanges(e)}
            onBlur={handleBlur}
          />
        </FormControl>
      </div>
    </>
  );
};

export default FormOverview;
