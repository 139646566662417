import React, { MutableRefObject, useRef } from "react";
import { httpClient } from "../providers/client";
import { LOCAL_STORE_KEYS, APP_CONST } from "@/utils";

const UploadFile = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const upload = (files?: FileList | null | undefined) => {
    if (!files) return;
    const formData = new FormData();

    Array.from(files)?.map((file: File) => {
      formData.append("files", file);
    });

    return httpClient(`${APP_CONST.API_URL}/upload/files`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        alert(res?.json?.message);
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        alert(err?.body?.message);
      });
  };

  return (
    <div>
      <input type="file" multiple ref={fileInputRef} />
      <button
        onClick={() => {
          upload(fileInputRef?.current?.files);
        }}
      >
        Upload Files
      </button>
    </div>
  );
};

export default UploadFile;
