import { FaAngleRight } from "react-icons/fa";
import Loader from "./utils/Loader";
import { useState } from "react";
import { Tooltip } from "@chakra-ui/react";

interface Props {
  title?: string;
  tHeaders:
  | {
    title: string;
    isSortable?: boolean;
    fn?: (dir: "asc" | "desc" | undefined) => void;
    type?: string;
  }[]
  | string[];
  tBody: React.ReactNode;
  topBtnText?: string;
  topBtnIcon?: React.ReactNode;
  onClickTopBtn?: (...args) => void;
  handleSort?: (...args) => void;
  error?: Error | null;
  isLoading?: boolean;
  tActions?: React.ReactNode;
  className?: string;
}

const TableView = (props: Props) => {
  const {
    title,
    tHeaders,
    tBody,
    onClickTopBtn,
    topBtnText,
    topBtnIcon,
    isLoading,
    tActions,
    className,
  } = props;

  return (
    <div>
      {title && <h1 className="text-lg font-bold mt-5 mb-6">{title}</h1>}
      <div className="mb-5 text-sm flex justify-between">
        {tActions}
        {onClickTopBtn && (
          <button
            onClick={onClickTopBtn}
            className="px-5 py-2 rounded bg-[#e9d9fc] hover:bg-[#553C9A] text-[#553C9A] hover:text-white transition-colors duration-200 flex items-center"
          >
            <span className="mr-2 -sm:hidden">{topBtnText}</span>
            <span className=" text-lg">{topBtnIcon}</span>
          </button>
        )}
      </div>
      <div className={`w-full overflow-x-auto custom-scrollbar-x no-scrollbar ${className || 'pb-60'}`}>
        {isLoading && (
          <div className="flex items-center justify-center mb-2">
            <Loader size={"md"} />
          </div>
        )}
        <table className="text-left w-full text-sm">
          <thead className="bg-[#FCFAFF]">
            <tr className="relative">
              {tHeaders?.map((header, index) => {
                return (
                  <th
                    key={index}
                    className="py-1 first:bg-[#FCFAFF] first:sticky first:z-20 z-0 first:left-0"
                  >
                    {header?.isSortable ? (
                      <SortableHeader header={header} />
                    ) : (
                      <p>{header?.title || header}</p>
                    )}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          {props?.error || isLoading ? null : (
            <tbody className="text-gray-500">{tBody}</tbody>
          )}
        </table>
      </div>
    </div>
  );
};

interface SortableHeaderProps {
  header: {
    title: string;
    fn: (val: "asc" | "desc" | undefined) => void;
  };
}

const SortableHeader = ({ header }: SortableHeaderProps) => {
  const [isAscending, setIsAscending] = useState<boolean | undefined>(
    undefined
  );

  const handleSort = () => {
    switch (isAscending) {
      case true:
        setIsAscending(false);
        header?.fn("desc");
        break;
      default:
      case undefined:
        setIsAscending(true);
        header?.fn("asc");
        break;
    }
  };

  return (
    <div className="">
      <div onClick={handleSort} className=" w-max flex-y-center cursor-pointer">
        <span>{header?.title}</span>
        <Tooltip label={`Click to sort by ${header?.title}`}>
          <span
            className={`ml-1 text-xs mt-[3px] 
           ${isAscending == undefined
                ? ""
                : isAscending
                  ? "-rotate-90"
                  : "rotate-90"
              }`}
          >
            <FaAngleRight />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default TableView;
