import Loader from "@components/utils/Loader";
import { ChatMessage } from "@utils/interface";
import { BiPlusCircle, BiX } from "react-icons/bi";
import { BsHeartFill } from "react-icons/bs";
import ReactMarkdown from "react-markdown";

interface ChatActionProps {
  chat: ChatMessage;
  handleFavorite?: (id: string) => void;
  handleAccept?: (id: string) => void;
  handleDelete: (id: string) => void;
  isLoading?: boolean;
}

const ChatAction = (props: ChatActionProps) => {
  const { chat, handleFavorite, handleAccept, handleDelete, isLoading } = props;
  const showActions = handleFavorite || handleAccept;
  const chatText = (chat?.text || chat?.content);
  return (
    <div className={`flex flex-row w-content text-left ${showActions ? "mb-6" : ""}`}>
      <div className="py-1 px-2 text-[13px]">
        <ReactMarkdown>{chatText}</ReactMarkdown>

        {showActions && (
          <div className=" mt-4 text-xl text-gray-400">
            {handleAccept && (
              <button
                className="mr-2 hover:bg-[#6B46C1] hover:text-white rounded-full"
                onClick={() => handleAccept(chat?.id || chatText)}
              >
                <BiPlusCircle />
              </button>
            )}
            {handleFavorite && (
              <button
                className={`${
                  chat?.favorite ? "text-[#6B46C1]" : ""
                } hover:text-[#6B46C1]`}
                onClick={() => handleFavorite(chat?.id || chatText)}
              >
                {isLoading ? <Loader size={"sm"} /> : <BsHeartFill />}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="flex-1"></div>
      <div className="flex justify-end">
        <button
          onClick={() => handleDelete(chat?.id || chatText)}
          className="ml-1 rounded-full w-max h-max text-[#6B46C1] hover:bg-[#6B46C1] hover:text-white"
        >
          <BiX />
        </button>
      </div>
    </div>
  );
};

export default ChatAction;
