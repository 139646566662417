import ActionModal from "@/components/modal/ActionModal";
import Loader from "@components/utils/Loader";
import { useGet } from "@utils/hooks";
import { PLANS, appName, includesString, openInNewTab } from "@utils/index";
import { Affiliate, Entity, User, AffiliateLink } from "@utils/interface";
import { startCase } from "lodash";
import { useEffect, useRef } from "react";
import { GiPartyPopper } from "react-icons/gi";
import { useParams } from "react-router-dom";
// import Confetti from "react-confetti";
import Streamers from "@components/Streamers";

const Referral = () => {
  const { id } = useParams<{ id: string }>();

  // get affiliate data
  const affiliate = useGet<Affiliate & { links: AffiliateLink[]; user: User }>(
    `${Entity.affiliate}/share`,
    {
      id,
      // meta: {
      //   user: true
      // }
    },
    (data) => {
      return data;
    }
  );

  useEffect(() => {
    affiliate.refetch();
  }, []);

  // render options
  const isLoading = affiliate.isFetching;

  // console.log("affiliate", affiliate);

  return (
    <div className="w-full h-screen">
      <Streamers show={!isLoading && !affiliate?.isError} />
      <ActionModal
        overlay={true}
        disableConfirm={true}
        icon={<GiPartyPopper />}
      >
        <div className="flex flex-col justify-center w-full">
          {isLoading ? (
            <Loader size={"xl"} />
          ) : affiliate?.isError ? (
            <div className="text-center">
              <h1>Oops!</h1>
              <h2>
                Something went wrong. Please contact the person who shared this.
              </h2>
            </div>
          ) : (
            <div className="text-center">
              <h1>Welcome!</h1>
              <h2>
                Join {affiliate?.data?.user?.firstName} on {appName}
              </h2>
              {affiliate?.data?.links
                ?.filter((l) => includesString(Object.keys(PLANS), l.plan))
                ?.map((link, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      openInNewTab(link.paymentLinkUrl);
                    }}
                    className="btn btn-primary bg-purple-500 text-white mt-4 w-full"
                  >
                    Sign Up For{" "}
                    {startCase(
                      link?.plan === PLANS.AGENCY ? "Agency" : link?.plan
                    )}{" "}
                    Plan
                  </button>
                ))}
            </div>
          )}
        </div>
      </ActionModal>
    </div>
  );
};

export default Referral;
