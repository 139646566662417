import React from "react";
import { isDev, notifyError } from "@utils/index";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import AppButton from "@components/AppButton";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReactErrorBoundary
      onError={(error, info) => {
        if (!isDev)
          notifyError(`ErrorBoundary: ERROR:${JSON.stringify(error.stack)}\n`);
        // Update state to indicate an error has occurred
      }}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center max-w-[700px]">
            <div className="px-2">
              <h3 className="font-bold text-center text-3xl text-wrap">
                {isDev ? error?.message : "Something went wrong."}
              </h3>
              <p className="text-lg md:text-2xl text-center">
                Please, try reloading the application!
              </p>
            </div>
            <AppButton
              className="cursor-pointer rounded-2xl border-black-300 border-2 px-6 py-1 text-md"
              variant="secondary"
              text="Reload App"
              onClick={() => {
                resetErrorBoundary();
                window.location.reload();
              }}
            />
          </div>
        </div>
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
