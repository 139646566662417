import { ReactNode, useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useOnClickOutside } from "usehooks-ts";

interface OnChangeResponse {
  target: { id: string; value: string };
}

interface CustomDropdownProps extends Record<string, any> {
  id: string;
  options: string[] | ReactNode[];
  value: string | ReactNode;
  border?: boolean;
  color?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  showDropdown?: boolean;
  className?: string;
  disableOption?: (idx: number) => boolean;
  interactive?: boolean;
  emptyClass?: boolean;
}

const CustomDropdown = ({
  id,
  options,
  value,
  border,
  color,
  onChange,
  showDropdown = true,
  disableOption = (idx: number) => false,
  interactive,
  emptyClass,
  ...rest
}: CustomDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    if (rest?.disabled) return;
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: OnChangeResponse) => {
    // console.log('option', option)
    onChange && onChange(option as React.ChangeEvent<any>);
    toggleDropdown();
  };

  const dropdownRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div
      className={`h-max relative ${rest?.disabled ? "bg-gray-100" : ""}`}
      {...rest}
    >
      <div
        className={`${emptyClass ? "" : "h-10 flex items-center px-2 py-2"} ${
          rest?.disabled ? "cursor-default" : "cursor-pointer"
        } ${
          color ? (color?.includes("text-") ? color : `text-[${color}]`) : ""
        } ${border ? "border" : ""} relative rounded`}
        onClick={toggleDropdown}
      >
        {typeof value == "string" ? <span>{value}</span> : value}
        {showDropdown && (
          <div
            className={`${isOpen && "rotate-180"} absolute text-lg ${
              color ? `text-[${color}]` : "text-neutral-500"
            } right-2 top-[10px]`}
          >
            <FaAngleDown />
          </div>
        )}
      </div>
      {isOpen && (
        <ul
          id={id}
          ref={dropdownRef}
          className=" w-max min-w-full absolute z-50 top-10 left-0 rounded-b border bg-white"
        >
          {options?.map((option, idx) =>
            typeof option == "string" ? (
              <li
                // className=" w-full px-2 py-[6px] hover:bg-[#B794F4] hover:text-white list-none cursor-pointer"
                className={`w-full px-2 py-[6px] list-none ${
                  interactive
                    ? ""
                    : disableOption(idx)
                    ? "bg-gray-200 hover:bg-gray-200 cursor-default"
                    : "hover:bg-[#B794F4] hover:text-white cursor-pointer"
                }`}
                key={idx}
                onClick={() => {
                  if (interactive) return;
                  !disableOption(idx) &&
                    handleOptionClick({ target: { id, value: option } });
                }}
              >
                {option}
              </li>
            ) : (
              <div
                className={`w-full px-2 py-[6px] list-none ${
                  interactive
                    ? ""
                    : disableOption(idx)
                    ? "bg-gray-200 hover:bg-gray-200 cursor-default"
                    : "hover:bg-[#B794F4] hover:text-white cursor-pointer"
                }`}
                key={idx}
                onClick={() => {
                  if (interactive) return;
                  !disableOption(idx) &&
                    handleOptionClick({ target: { id, value: idx } });
                }}
              >
                {option}
              </div>
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
