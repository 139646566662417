import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

interface MonthToggleProps {
  currentPeriod: { year: number; month: number };
  setCurrentPeriod: React.Dispatch<
    React.SetStateAction<{ year: number; month: number }>
  >;
}

const MonthToggle = (props: MonthToggleProps) => {
  const { currentPeriod, setCurrentPeriod } = props;
  const { month: currentMonth, year: currentYear } = currentPeriod;

  const handleMonthChange = (val: number) => {
    if (val === 1 && currentMonth === 12) {
      setCurrentPeriod({ year: currentYear + 1, month: 1 });
    } else if (val === 1) {
      setCurrentPeriod({ ...currentPeriod, month: currentMonth + 1 });
    } else if (val === -1 && currentMonth === 1) {
      setCurrentPeriod({ year: currentYear - 1, month: 12 });
    } else if (val === -1) {
      setCurrentPeriod({ ...currentPeriod, month: currentMonth - 1 });
    }
  };

  return (
    <div className="flex-y-center justify-between">
      <button
        onClick={() => handleMonthChange(-1)}
        className="p-1 rounded-full hover:bg-[#6a54b1] hover:text-white text-[#6B46C1]"
      >
        <FaAngleLeft />
      </button>
      <span className="">{currentYear}</span>
      <button
        onClick={() => handleMonthChange(1)}
        className="p-1 rounded-full hover:bg-[#6a54b1] hover:text-white text-[#6B46C1]"
      >
        <FaAngleRight />
      </button>
    </div>
  );
};

export default MonthToggle;
