import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { BsArrowLeft, BsLink } from "react-icons/bs";
import { FiFile, FiMinus, FiPlus } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { ContextStore } from "@components/utils/Context";
import { useDisplayToast, usePlanFeatureCheck, usePost } from "@utils/hooks";
// import Loader from "@components/utils/Loader";
import {
  ActionType,
  AddTabType,
  AddTabTypes,
  Board,
  BoardItem,
  LinkType,
  Dict,
  Grant,
  GrantResponse,
  IResponse,
  PLANS,
  tailwindHelpers,
  isDev,
  Entity,
} from "@utils/index";
import AppButton from "@components/AppButton";
// import { MinusIcon, AddIcon } from "@chakra-ui/icons";
// import { httpClient } from "@providers/client";
import Overlay from "@components/Overlay";
import { addCard } from "@pages/board/helpers";
import { useUpdateBoard } from "@pages/board/hook";
import { ActionProps } from "./interfaces";
import { UrlTab, CopyPasteTab, FormTab } from "./Tabs";
import TextLoader from "@components/TextLoader";
// import { HiClipboardCopy } from "react-icons/hi";
import { ImSearch } from "react-icons/im";
import { LuClipboardCopy } from "react-icons/lu";
import { MdEditNote } from "react-icons/md";
import SearchTab from "./Tabs/Search";
import { useNavigate } from "react-router-dom";

const AddGrant = () => {
  //save grant data
  const {
    // user, account
    board,
    setBoard,
    // grants,
    setGrants,
    setBoardItems,
    appModal,
    setAppModal,
    hasChanges,
    setHasChanges,
    globalState,
    setGlobalState,
  } = useContext(ContextStore);
  const { needAccess } = usePlanFeatureCheck();

  const navigate = useNavigate();
  const [grantInfo, setGrantInfo] = useState({
    url: "",
    text: "",
    query: "",
  });
  const { successToast, errorToast } = useDisplayToast();
  const { handleUpdateBoard } = useUpdateBoard();
  const grant = globalState.addGrant;
  const setGrant = (grant: SetStateAction<Grant>) =>
    setGlobalState((prev) => ({ ...prev, addGrant: grant }));

  const onUpdate = (board: Board) => {
    setBoard(board);
    handleUpdateBoard(board);
  };

  const handleAddGrant = (data: GrantResponse) => {
    if (window.location.pathname !== "/board") {
      // if not at board navigate to it
      navigate("/board");
    }
    const { boardItem, grant } = data;
    if (boardItem) {
      setBoardItems((prev) => [...prev, boardItem]);
      addCard(board, boardItem, onUpdate);
    }
    if (grant) {
      setGrants((prev) => [...prev, grant]);
      setAppModal((prev) => ({
        ...prev,
        views: { ...prev.views, [ActionType.view]: true },
        type: LinkType.grant,
        grantId: grant?.id,
      }));
    }
  };

  // https://marmelab.com/react-admin/useCreate.html
  const [create, { isLoading: isCreating }] = usePost<GrantResponse>();

  const handleCreateGrant = () => {
    let endpoint, body;

    switch (appModal?.addTab) {
      case AddTabType.url:
        // if (needAccess([PLANS.basic])) return;
        if (!grantInfo?.url) {
          return errorToast("URL is required", "Error Creating Grant");
        }
        endpoint = `${Entity.grants}/url`;
        body = { url: grantInfo?.url };
        break;
      case AddTabType.copy:
        if (!grantInfo?.text) {
          return errorToast("Text is required", "Error Creating Grant");
        }
        endpoint = `${Entity.grants}/copy`;
        body = { text: grantInfo?.text };
        break;
      case AddTabType.form:
        if (!Object.values(grant).length) {
          return errorToast("Grant Data is required", "Error Creating Grant");
        }
        endpoint = `${Entity.grants}/`;
        body = { grant };
        break;
    }

    return create(
      endpoint,
      { data: { ...body } as Dict },
      {
        onSuccess(data, variables, context) {
          successToast(data?.message || "Grant Created");
          // console.log("handleCreateGrant", data);
          handleAddGrant(data);
          setGrant({} as Grant);
        },
        onError(error, variables, context) {
          // console.log("onError", error, variables, context);
          errorToast(error?.body?.message, "Error Creating Grant");
        },
      }
    );
  };

  const renderTab = (tabType: AddTabTypes) => {
    switch (tabType) {
      case AddTabType.search:
        return (
          <SearchTab
            grantInfo={grantInfo?.query}
            setGrantInfo={(e) =>
              setGrantInfo((prev) => ({ ...prev, query: e }))
            }
            handleAddGrant={handleAddGrant}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, addGrant: e })}
          />
        );
      case AddTabType.url:
        return (
          <UrlTab
            grantInfo={grantInfo?.url}
            setGrantInfo={(e) => setGrantInfo((prev) => ({ ...prev, url: e }))}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, addGrant: e })}
          />
        );
      case AddTabType.copy:
        return (
          <CopyPasteTab
            grantInfo={grantInfo?.text}
            setGrantInfo={(e) => setGrantInfo((prev) => ({ ...prev, text: e }))}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, addGrant: e })}
          />
        );
      case AddTabType.form:
        return (
          <FormTab
            grant={grant}
            setGrant={setGrant}
            setHasChanges={(e) => setHasChanges({ ...hasChanges, addGrant: e })}
          />
        );
    }
  };

  const handleClose = (clearData = true) => {
    if (clearData) setGrant({} as Grant);
    return (
      !isCreating &&
      setAppModal((prev) => ({
        // ...prev,
        views: { ...prev.views, [ActionType.add]: false },
      }))
    );
  };

  return (
    <Overlay onDone={() => handleClose(false)}>
      <div
        className={`${tailwindHelpers?.responsiveModal} pb-10 my-14 mx-8 rounded-lg bg-white`}
      >
        <div className="px-5 mb-5 h-14 bg-[#B794F4] text-white flex justify-between items-center rounded-tr-lg rounded-tl-lg">
          <h1 className="text-2xl font-semibold -sm:text-lg">Import New Grant To Board</h1>
          <button
            onClick={() => handleClose()}
            className={`${
              isCreating
                ? "cursor-default"
                : "hover:bg-white hover:text-[#B784F4]"
            } p-1 text-2xl rounded-full transition-colors duration-150 border-2`}
          >
            <IoCloseOutline />
          </button>
        </div>

        <div className="mb-8 flex rounded-t overflow-hidden">
          {isDev && (
            <button
              type="button"
              onClick={() =>
                !isCreating &&
                setAppModal({ ...appModal, addTab: AddTabType.search })
              }
              className={`block h-max w-full py-2 border-r-2 ${
                // appModal?.addTab === AddTabType.search &&
                // needAccess([PLANS.free], false)
                //   ? // needAccess([PLANS.basic, PLANS.pro], false)
                //     "border-t-4 border-t-gray bg-gray-200"
                //   :
                appModal?.addTab === AddTabType.search
                  ? "border-t-4 border-t-[#B794F4]"
                  : "border-t-4 border-t-white border-b-2"
              } outline-none`}
            >
              <span className="-sm:hidden">Search</span>
              <span className="w-max mx-auto hidden -sm:block text-2xl">
                <ImSearch />
              </span>
            </button>
          )}
          <button
            type="button"
            onClick={() =>
              !isCreating &&
              setAppModal({ ...appModal, addTab: AddTabType.url })
            }
            className={`block h-max w-full py-2 border-r-2 ${
              // appModal?.addTab === AddTabType.url &&
              // needAccess([PLANS.free], false)
              //   ? // && needAccess([PLANS.basic], false)
              //     "border-t-4 border-t-gray bg-gray-200"
              //   :
              appModal?.addTab === AddTabType.url
                ? "border-t-4 border-t-[#B794F4]"
                : "border-t-4 border-t-white border-b-2"
            } outline-none`}
          >
            <span className="-sm:hidden">URL</span>
            <span className="w-max mx-auto hidden -sm:block text-2xl">
              <BsLink />
            </span>
          </button>
          <button
            type="button"
            onClick={() =>
              !isCreating &&
              setAppModal({ ...appModal, addTab: AddTabType.copy })
            }
            className={`block h-max w-full py-2 border-r-2 ${
              appModal?.addTab === AddTabType.copy
                ? "border-t-4 border-t-[#B794F4]"
                : "border-t-4 border-t-white border-b-2"
            } outline-none`}
          >
            <span className="-sm:hidden">Copy and Paste</span>
            <div className="w-max mx-auto hidden -sm:block text-2xl">
              <LuClipboardCopy />
            </div>
          </button>
          <button
            type="button"
            onClick={() =>
              !isCreating &&
              setAppModal({ ...appModal, addTab: AddTabType.form })
            }
            className={`block h-max w-full py-2 ${
              appModal?.addTab === AddTabType.form
                ? "border-t-4 border-t-[#B794F4]"
                : "border-t-4 border-t-white border-b-2"
            } outline-none`}
          >
            <span className="-sm:hidden">Form Fill</span>
            <div className="w-max mx-auto hidden -sm:block text-2xl">
              <MdEditNote />
            </div>
          </button>
        </div>

        {renderTab(appModal?.addTab || AddTabType.form)}

        {isCreating && <TextLoader color={"#6b4bbe"} />}

        {appModal?.addTab !== AddTabType.search ? (
          <ActionButtons
            onCancel={handleClose}
            onSubmit={handleCreateGrant}
            canSubmit={hasChanges?.addGrant}
            isLoading={isCreating}
          />
        ) : null}
      </div>
    </Overlay>
  );
};

const ActionButtons = ({
  onCancel,
  onSubmit,
  canSubmit,
  isLoading,
}: ActionProps) => (
  <div className="w-full col-span-2 mt-16 flex justify-end">
    <button
      type="button"
      onClick={onCancel}
      disabled={isLoading}
      className={`${
        isLoading ? "cursor-default" : ""
      } ml-4 w-40 font-bold justify-center py-2 rounded mr-4 border border-neutral-400 flex items-center`}
    >
      <BsArrowLeft />
      <span className="ml-2">Cancel</span>
    </button>
    {/* <button
      type="button"
      onClick={onSubmit} 
      disabled={!canSubmit}     
      className={`w-40 font-bold justify-center py-2 rounded bg-[#e9d9fc] text-[#B794F4] ${canSubmit &&'hover:text-white hover:bg-[#6b4bbe]'}  flex items-center`}
    >
      <span className="mr-2">Create Grant</span>
      <FiFile />
    </button> */}
    <AppButton
      onClick={onSubmit}
      isDisabled={!canSubmit || isLoading}
      text="Create Grant"
      icon={FiFile}
      variant="secondary"
      iconPosition="right"
      isLoading={isLoading}
      mt={0}
      // className={`w-40 font-bold justify-center py-2 rounded bg-[#e9d9fc] text-[#B794F4] ${canSubmit &&'hover:text-white hover:bg-[#6b4bbe]'}  flex items-center`}
    />
  </div>
);

export default AddGrant;
