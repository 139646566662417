import { fetchUtils } from "react-admin";
import inMemoryJWT from "./auth/inMemoryJWT";
import { APP_CONST, LOCAL_STORE_KEYS } from "@/utils";
// https://marmelab.com/react-admin/Authentication.html#sending-credentials-to-the-api
// https://marmelab.com/react-admin/fetchJson.html#adding-the-authorization-header

// export const baseAPI_URL = import.meta.env.VITE_API_URL;

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {}
) => {
  const customHeaders = (options.headers ||
    new Headers({ Accept: "application/json" })) as Headers;

  // const token = localStorage.getItem('accessToken');
  // // const user = { token: `Bearer ${token}`, authenticated: !!token };
  // // add your own headers here
  // // customHeaders.set('X-Custom-Header', 'foobar');
  // if(token) customHeaders.set('Authorization', `Bearer ${token}`);

  const token =
    inMemoryJWT.getToken() ||
    localStorage.getItem(LOCAL_STORE_KEYS.accessToken);

  if (token) {
    customHeaders.set("Authorization", `Bearer ${token}`);
  } else {
    // inMemoryJWT.setRefreshTokenEndpoint('http://localhost:8001/refresh-token');
    const gotFreshToken = await inMemoryJWT.waitForTokenRefresh();
    //  .then((gotFreshToken) => {
    if (gotFreshToken) {
      customHeaders.set("Authorization", `Bearer ${inMemoryJWT.getToken()}`);
    }
    // });
  }

  options.headers = customHeaders;
  url = url?.includes(APP_CONST.API_URL) ? url : `${APP_CONST.API_URL}${url}`;
  return fetchUtils.fetchJson(url, {
    ...options,
    // user
  });
};

// /node_modules/prisma-query/src/utils/processQueryUtils.ts
// _embed,
// _expand,
// _sort,
// _order,
// _page,
// _start,
// _end,
// _limit,
