import Loader from "./Loader";

const CenterLoader = () => {
  return ( 
    <div className="fixed h-screen w-screen flex flex-col justify-center items-center">
        <Loader size={"xl"} />
      </div>
   );
}
 
export default CenterLoader;
