import Nav from "./Nav";
import AddEdit from "./events/AddEditEvent";
import View from "./events/ViewEvent";

export { Nav, AddEdit, View };

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//add prefix 0 to single digit month
export const currentMonthStr = (currentMonth: number) =>
  currentMonth < 10 ? `0${currentMonth}` : currentMonth;

export const getYearMonthStr = (month: number, year?: number) =>
  `${year || new Date().getFullYear()}-${currentMonthStr(month)}`;
