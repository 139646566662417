import { ContextStore } from "@components/utils/Context";
import { useDisplayToast, usePut } from "@utils/hooks";
import { IResponse, Board, Account } from "@utils/interface";
import { pick } from "lodash";
import { useContext } from "react";

export const useUpdateBoard = () => {
  const { account, setAccount } = useContext(ContextStore);
  const { errorToast } = useDisplayToast();

  // update account
  const [
    updateAccount,
    { data: result, isLoading: isUpdating, error: updateError },
  ] = usePut<Record<string, any> & { id: string }>();

  const handleUpdateBoard = async (board: Board) => {
    const payload = {
      id: account?.id,
      data: {
        columns: board?.columns?.map((column) => column?.title),
        grantBoard: board?.columns?.map((column) => ({
          ...column,
          cards: column?.cards?.map((card) => ({
            ...pick(card, ["id", "title", "column"]),
            column: column?.title,
            columnId: column?.id,
          })),
        })),
      },
    };

    // console.log("handleUpdateBoard", payload);
    updateAccount(`accounts`, payload, {
      onSuccess(data, variables, context) {
        // console.log("Board Updated");
        setAccount(data as Account);
      },
      onError(error, variables, context) {
        errorToast(error?.body?.message);
      },
    });
  };

  return { handleUpdateBoard, isUpdating, updateError };
};
