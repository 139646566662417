import { Editor } from "@tiptap/core";
import {
  ChevronDown, MailOpen, Sigma, Scroll, TextSelect, PauseCircle, PenLine
} from "lucide-react";
import React, { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Command } from "cmdk";
import { useCompletion } from "ai/react";
import { useOnClickOutside } from "usehooks-ts";
import { Dict, Entity, Grant, LinkType, getApiUrl, isDev } from "@/utils";
// import { getCompletionHeaders } from "../../helper";
import { NovelContextType } from "inkejs";
import { ContextStore } from "@/components/utils/Context";
import { Select } from "@chakra-ui/react";
import { startCase } from "lodash";
import { useStoreEntity, useToggleBubbleMenu } from "@/utils/hooks";
import { toast } from 'sonner';
import { tones } from "../../helper";
// import { LiaQuestionSolid } from "react-icons/lia";

interface AutoWriteProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  context: React.Context<NovelContextType>;
}

export const AutoWrite: FC<AutoWriteProps> = (props: AutoWriteProps) => {
  const { editor, isOpen, setIsOpen, context: NovelContext } = props;
  const { completionApi, additionalData: { body, headers } } = useContext(NovelContext);
  const { globalState, setGlobalState, setActionModal, } = useContext(ContextStore);

  const options = globalState.autoWriteOptions;
  const setOptions = (options: Dict) => setGlobalState({ ...globalState, autoWriteOptions: options });
  const grants = (useStoreEntity(LinkType.grant) as Grant[]);

  const { complete, isLoading, stop, } = useCompletion({
    id: "ai-write",
    api: `${completionApi}/write`,
    body: { ...(body || {}), },
    headers: { ...(headers || {}), },
    onFinish(prompt, completion) {
      handleToggleBubble(true);
    },
  });

  const { handleToggleBubble } = useToggleBubbleMenu(editor);

  useEffect(() => {
    if (!isLoading) handleToggleBubble(true);
  }, [isLoading]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!isOpen) return;
    setIsOpen(false);
  });

  const items = [
    {
      name: "Auto Write",
      icon: Scroll,
      command: 'auto-write',
      visible: true,
    },
    {
      name: "Outline",
      icon: TextSelect,
      command: 'auto-outline',
      visible: true,
    },
  ].filter(i => i.visible !== false);

  const hasSelection = editor?.state?.selection?.empty === false;

  // console.log("options: ", options);

  return (
    !hasSelection ?
      <div className="flex" ref={ref}>
        <div className={`flex h-full items-center gap-1 text-sm font-medium hover:bg-stone-100 active:bg-stone-200 ${isOpen ? 'text-purple-500' : 'text-stone-600'}`}>
          <button
            className="p-2 flex h-full items-center gap-2"
            onClick={() => {
              if (isLoading) {
                stop();
              }
              setIsOpen(!isOpen);
              editor.chain().blur().run();
            }}>
            <PenLine className="h-5 w-5" /> Write
            {isLoading ? (
              <PauseCircle
                onClick={stop}
                className="h-4 hover:text-stone-500 cursor-pointer w-4 text-stone-300"
              />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </button>
        </div>

        {isOpen && (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="fixed top-full z-[99999] mt-1 w-[60%] overflow-hidden rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1 flex flex-col">
              <Select
                id="grant"
                autoComplete="grant"
                placeholder="Select Grant*"
                value={options?.grantId == undefined ? '' : options?.grantId || ''}
                onChange={(e) => setOptions({ ...options, grantId: e.target.value })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {grants.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {startCase(option.name)}
                  </option>
                ))}
              </Select>
              <Select
                id="tone"
                autoComplete="tone"
                placeholder="Select Tone"
                value={options?.tone == undefined ? '' : options?.tone || tones[0]}
                onChange={(e) => setOptions({ ...options, tone: e.target.value })}
                className="text-slate-500 text-sm"
                size={'sm'}
              >
                {tones.map((option, idx) => (
                  <option key={idx} value={option}>
                    {startCase(option)}
                  </option>
                ))}
              </Select>
              <button
                onClick={() => {
                  handleToggleBubble(false);
                  setActionModal(prev => ({ ...prev, openAutoWriteQs: true }));
                }}
                className="flex items-center justify-center bg-purple-500 text-white p-1 rounded-md hover:bg-purple-600 active:bg-purple-700">
                Custom Questions ({options?.questions?.length || 'optional'})
              </button>
              <textarea
                placeholder="Enter additional info..."
                className="flex-1 bg-white p-1 text-sm border rounded text-slate-500"
                value={options?.info || ''}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setOptions({ ...options, info: value });
                }}                
              />
            </form>
            <Command className="fixed top-full z-[99999] mt-[10rem] w-60 overflow-hidden rounded border border-stone-200 bg-white p-2 shadow-xl animate-in fade-in slide-in-from-top-1">
              <Command.List>
                {items?.map((item, index) => (
                  <Command.Item
                    key={index}
                    onSelect={() => {
                      if (!options?.grantId) {
                        toast.error("Please select a grant.");
                        return;
                      }
                      if (!isLoading) {
                        const input = `${item.command}:\n`;
                        complete(input, { body: { ...options, command: item.command } });
                        setOptions({ grantId: options?.grantId, tone: options?.tone, questions: options?.questions }); //preserve user values
                        setIsOpen(false);
                        handleToggleBubble(false);
                        setGlobalState(prev => ({ ...prev, showGenAiLoader: true }));
                      }
                    }}
                    className="flex group cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-sm text-gray-600 active:bg-stone-200 aria-selected:bg-stone-100">
                    <div className="flex items-center space-x-2">
                      <item.icon className="h-4 w-4 text-purple-500" />
                      <span>{item.name}</span>
                    </div>
                    {/* <CornerDownLeft className="hidden h-4 w-4 group-hover:block" /> */}
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </>
        )}
      </div> : null
  );
};
