import { v4 as uuidv4 } from "uuid";
import { UpdateBoard } from "./interface";
import { BoardItem } from "@utils/interface";

export const GREEN_DEADLINE = 180,
  YELLOW_DEADLINE = 90,
  RED_DEADLINE = 30;

const renameColumn = (
  board: any,
  columnId: string,
  newTitle: string,
  setter: UpdateBoard
) => {
  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column.id === columnId ? { ...column, title: newTitle } : column
    ),
  };
  setter(newBoard);
};

const renameCard = (
  board: any,
  card: BoardItem,
  newTitle: string,
  setter: UpdateBoard
) => {
  // console.log("renameCard", card);
  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column.id === card?.columnId
        ? {
            ...column,
            cards: column?.cards?.map((c) =>
              c.id === card.id ? { ...c, title: newTitle } : c
            ),
          }
        : column
    ),
  };
  setter(newBoard);
};

const removeCard = (
  board: any,
  columnId: string,
  cardId: string,
  setter: UpdateBoard
) => {
  // console.log("removeCard", columnId, cardId);
  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column?.id === columnId
        ? {
            ...column,
            cards: column.cards?.filter((card) => card.id !== cardId),
          }
        : column
    ),
  };
  setter(newBoard, true);
};

const addCard = (board: any, card: BoardItem, setter: UpdateBoard) => {
  // console.log("addCard", card?.id);
  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column?.id === (card?.additionalData?.columnId || board?.columns[0]?.id)
        ? {
            ...column,
            cards: [...column.cards, card],
          }
        : column
    ),
  };
  setter(newBoard, true);
};

const dragCard = (
  board: any,
  movedCard: any,
  from: { fromColumnId: string; fromPosition: number },
  to: { toColumnId: string; toPosition: number },
  setter: UpdateBoard
) => {
  // console.log("dragCard", movedCard);
  // create new board
  // remove card from fromColumn

  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column?.id === from.fromColumnId
        ? {
            ...column,
            cards: column.cards?.filter((card) => card.id !== movedCard.id),
          }
        : column
    ),
  };
  // add card to toColumn at toPosition
  newBoard.columns = newBoard?.columns?.map((column) =>
    column?.id === to.toColumnId
      ? {
          ...column,
          cards: [
            ...column.cards.slice(0, to.toPosition),
            { ...movedCard, column: column?.title }, //add card and update column
            ...column.cards.slice(to.toPosition),
          ],
        }
      : column
  );

  setter(newBoard);
};

const dragColumn = (
  board: any,
  movedColumn: any,
  from: { fromPosition: number },
  to: { toPosition: number },
  setter: UpdateBoard
) => {
  // console.log("dragColumn", from, to);
  const newBoard = {
    ...board,
    columns: move(board.columns, from.fromPosition, to.toPosition),
  };
  setter(newBoard);
};

function move(arr: any[], from: number, to: number) {
  const item = arr[from];
  const newArr = arr.slice(0, from).concat(arr.slice(from + 1));
  newArr.splice(to, 0, item);
  return newArr;
}

const addEmptyCard = (board: any, setter: (data: any) => void) => {
  // console.log("addCard", newCard);
  const newCard = { id: uuidv4(), title: "Empty Card...Link me" };
  const newBoard = {
    ...board,
    columns: board?.columns?.map((col, idx) => {
      if (idx === 0) {
        return { ...col, cards: [...col.cards, newCard] };
      }
      return col;
    }),
  };
  setter(newBoard);
};

const removeColumn = (board: any, columnId: string, setter: UpdateBoard) => {
  // console.log("removeColumn", columnId);
  const newBoard = {
    ...board,
    columns: board?.columns?.filter((column) => column.id !== columnId),
  };
  setter(newBoard);
};

const updateCard = (
  board: any,
  oldCard: any,
  data: any,
  setter: UpdateBoard
) => {
  // console.log("updateCard", oldCard, data);

  const newBoard = {
    ...board,
    columns: board?.columns?.map((column) =>
      column?.id === oldCard?.columnId
        ? {
            ...column,
            cards: column.cards.map((card) =>
              card.id === oldCard?.id ? { ...oldCard, ...data } : card
            ),
          }
        : column
    ),
  };

  setter(newBoard, true);
};

export {
  renameColumn,
  renameCard,
  removeCard,
  addCard,
  dragCard,
  dragColumn,
  addEmptyCard,
  removeColumn,
  updateCard,
};
