import { useQueryParams } from "@utils/hooks";
import logo from "@assets/logo.svg";
import { APP_CONST } from "@/utils";
import { debounce } from 'lodash';

const Verify = () => {
  const { email, token, redirect } = useQueryParams();

  //make get request to backend to verify email
  const link = `${APP_CONST.API_URL}/verify?email=${encodeURIComponent(email)}&token=${token}${redirect ? "&redirect=" + encodeURIComponent(redirect) : ""}`;
  const debouncedHandleClick = debounce(() => window.open(link, "_self"), 300, { leading: true });

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      {/* <div className="absolute top-0 left-0 w-full h-full bg-purple-500 opacity-75"> */}
      <div className="absolute top-0 left-0">
        <img src={logo} className="w-12 h-12 mt-5 ml-5" />
      </div>

      <div className="w-full text-center">
        <h1 className="text-4xl font-bold">Email Verification</h1>
        <p className="text-lg pt-5">
          To complete the verification process, please click the button below:
        </p>
        <button
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mt-4 -sm:w-1/2 w-1/4"
          onClick={debouncedHandleClick}
        >
          Verify Email
        </button>
      </div>
    </div>
  );
};

export default Verify;
