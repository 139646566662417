interface Props {
  heading?: string;
  subheading?: string;
  children?: React.ReactNode;
}

const AppBanner = (props: Props) => {
  return (
    <div className="px-10 -sm:px-8 -xs:px-5 text-white text-center pt-4 pb-6 -sm:mx-2 sm:mx-20 mb-8 bg-[#af9ad3] rounded-lg">
      <p className="mb-0.5 text-sm text-[#6a54b1] font-bold uppercase">
        {props?.heading}
      </p>
      {props?.subheading && <p className="text-2xl">{props?.subheading}</p>}
      {props?.children}
    </div>
  );
};

export default AppBanner;
