import { ChatMessage } from "@utils/interface";
import ChatAction from "./Action";

interface ChatBubbleProps {
  isUser: boolean;
  chat: ChatMessage;
  handleFavorite?: (id: string) => void;
  handleAccept?: (id: string) => void;
  handleDelete: (id: string) => void;
  onClick: () => void;
  isLoading?: boolean;
}

const ChatBubble = (props: ChatBubbleProps) => {
  const {
    isUser,
    chat,
    handleFavorite,
    handleAccept,
    handleDelete,
    onClick,
    isLoading,
  } = props;
  return (
    <div
      onClick={onClick}
      className={`mt-2 last-of-type:mb-0 p-3 rounded-xl w-full max-w-[260px] ${
        isUser ? "bg-[#6B46C11A] ml-auto" : "bg-[#FCCEEE66] mr-auto"
      } ${isUser ? "cursor-pointer" : ""}`}
    >
      {
        // isUser ? (
        //   <p>{(chat?.text || chat?.content)}</p>
        // ) :
        <ChatAction
          handleFavorite={handleFavorite}
          handleAccept={handleAccept}
          handleDelete={handleDelete}
          isLoading={isLoading}
          chat={chat}
        />
      }
    </div>
  );
};

export default ChatBubble;
