import Novel from "@playground/editors/novel-1";
import { isMobile } from "react-device-detect";
// import AgentChat from "./AgentChat";
import {
  Entity,
  PromptSection,
  Document,
  SlateNode,
  AccountType,
} from "@utils/interface";
import {
  useCommonQueries,
  useDisplayToast,
  usePut,
  useURLParams,
} from "@utils/hooks";
import { ContextStore } from "@components/utils/Context";
import { useContext, useEffect, useRef } from "react";
import {
  PLANS,
  canParse,
  isDev,
  setURLQuery,
  shouldUpgrade,
} from "@utils/index";
import {
  getEditorContent,
  getNewDoc,
  slatePromptsToNovelDocument,
} from "@/playground/editors/helper";
// import { slateToString } from "@playground/editors/slate/exports";
import { TipTapEditor } from "@/playground/editors/interface";
import EditorWrapper from "@/playground/editors/inke/post/wrapper";
// import { IndexeddbPersistence } from "y-indexeddb";
import { HocuspocusProvider } from "@hocuspocus/provider";
import * as Y from "yjs";

interface Props {
  document: Document;
  setCurrentDocument: React.Dispatch<React.SetStateAction<Document>>;
  editorRef: React.MutableRefObject<TipTapEditor | undefined>;
}

const ydoc = new Y.Doc();

const EditDoc = (props: Props) => {
  const { document: doc, setCurrentDocument, editorRef } = props;
  // document.title = `${doc?.title || 'Untitled Document'}`;

  let provider = new HocuspocusProvider({
    url: isDev ? "ws://localhost:5001" : "wss://api.grantwriteai.com:5001", //wss://backend-production-6ad2.up.railway.app/
    name: doc.id,
    document: ydoc,
  });

  // if (canCollaborate) {
  //   // TODO: use IndexeddbPersistence
  //   // new IndexeddbPersistence(document?.documentId, ydoc);

  //   provider = new HocuspocusProvider({
  //     url: isDev ? "ws://localhost:5001" : "wss://api.grantwriteai.com:5001", //wss://backend-production-6ad2.up.railway.app/
  //     name: document?.documentId,
  //     document: ydoc,
  //   });

  //   // const provider = new WebrtcProvider(document?.documentId, ydoc, {
  //   //   maxConns: Number.POSITIVE_INFINITY,
  //   //   filterBcConns: false,
  //   // })

  //   // provider.awareness.setLocalStateField('user', {
  //   //   name: document?.userName + ' ' + Math.floor(Math.random() * 100),
  //   //   color: randomColor(),
  //   //   // colorLight: userColor.light
  //   // });
  // }


  // const { queryParams } = useURLParams();
  const {
    setDocuments,
    account,
    user,
    globalState,
    setGlobalState,
    setActionModal,
  } = useContext(ContextStore);
  const { errorToast } = useDisplayToast();
  // handle update Document

  // const { fetchAccount } = useCommonQueries();

  const [updateDocument] = usePut<Document>();

  const handleSaveDocument = async (content: string) => {
    // console.log("handleSaveDocument:content", content);
    console.log("saving to cloud...")
    // if (isDev) return;
    updateDocument(
      Entity.documents,
      {
        id: doc.id,
        data: { content },
      },
      {
        onSuccess: (data) => {
          setCurrentDocument(data);
          setDocuments((prev) => {
            const index = prev.findIndex((p) => p.id === doc.id);
            prev[index] = data;
            return prev;
          });
        },
        onError: (error) => {
          errorToast(error?.body?.message, "Error updating document");
        },
      }
    );
  };

  useEffect(() => {
    if (doc && globalState.currentDocument?.id !== doc?.id) {
      // setURLQuery({ ...queryParams, documentId: document?.id });
      setGlobalState((prev) => ({ ...prev, currentDocument: doc }));
    }
    return () => {
      setGlobalState((prev) => ({ ...prev, currentDocument: undefined }));
      localStorage?.removeItem("novel__content");
    };
  }, [doc]);

  const defaultValue = getEditorContent(doc);
  const isInke = true;

  return (
    <div className="flex flex-col w-full h-full">
      {isInke ?
        <EditorWrapper
          user={user}
          document={doc}
          customProvider={provider}
          defaultContent={defaultValue}
          onEditorUpdate={(editor) => {
            editorRef.current = editor;
            // console.log("onEditorUpdate:editor", editor);
            // https://tiptap.dev/docs/editor/guide/output#not-an-option-markdown
            const content = JSON.stringify(editor.getJSON());
            handleSaveDocument(content);
            // console.log("onEditorUpdate:content", content);
          }}
        /> :
        <Novel
          document={{
            userName: `${user?.firstName} ${user?.lastName?.slice(0) || ""}`,
            accountId: account?.id,
            documentId: doc?.id,
          }}
          customProvider={provider}
          defaultValue={defaultValue}
          onEditorUpdate={(editor) => {
            editorRef.current = editor;
            // console.log("onEditorUpdate:editor", editor);
            // https://tiptap.dev/docs/editor/guide/output#not-an-option-markdown
            const content = JSON.stringify(editor.getJSON());
            handleSaveDocument(content);
            // console.log("onEditorUpdate:content", content);
          }}
          showFeedback={() => {
            setActionModal((prev) => ({ ...prev, submitFeedback: true }));
          }}
        />}
    </div>
  );
};

export default EditDoc;
