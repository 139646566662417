// import useOutsideClick from "@hooks/useOutsideClick";
import { useRef } from "react";
import { BsCheck2, BsQuestionCircle } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { MdCheckCircleOutline } from "react-icons/md";
import Loader from "../utils/Loader";
import { useOnClickOutside } from "usehooks-ts";
import { Activity } from "@utils/interface";
import { getDateString, getDaysAgoString } from "@utils/index";
import { Avatar } from "@chakra-ui/react";
// https://thenounproject.com/icon/email-1048174/
import notificationIcon from "@/assets/empty-notifications.svg";

interface Props {
  isLoading?: boolean;
  onClose: () => void;
  activities: Activity[];
  handleMarkAsRead: (id: string) => void;
  handleMarkAllAsRead: () => void;
}

export const NotificationsModal = (props: Props) => {
  const {
    onClose,
    isLoading,
    activities: notifications,
    handleMarkAsRead,
    handleMarkAllAsRead,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  // const clickHandler = () => {
  //   setIsNotifications(false);
  // };

  // useOutsideClick({
  //   mainRef: notifRef,
  //   handler: clickHandler,
  // });

  useOnClickOutside(ref, onClose);

  return (
    <div className="flex h-screen w-screen overflow-hidden fixed z-[1001] top-0 left-0">
      <div
        ref={ref}
        className="-xs:mx-5 -xs:my-14 pb-1 h-[430px] w-[430px] -xs:w-full flex flex-col xs:absolute right-[60px] -sm:right-10 top-[50px] rounded-md bg-white [box-shadow:0px_9px_24px_0px_rgba(15,15,15,0.20),0px_3px_6px_0px_rgba(15,15,15,0.10),0px_0px_0px_1px_rgba(15,15,15,0.05)]"
      >
        <div className=" px-3 border-b flex-y-center justify-between">
          <div className="flex text-sm">
            <div className="flex-center py-2 border-[#553C9A] border-b-[3px]">
              <span>Notifications</span>
              <div className="ml-2 w-4 h-4 bg-red-500 text-xs text-white flex-center rounded">
                <span>{notifications?.length}</span>
              </div>
            </div>

            <button className="ml-8 text-neutral-500">
              <BsQuestionCircle />
            </button>
          </div>

          <button
            className={` ${
              notifications.length === 0 && "text-gray-300 cursor-default"
            } text-xs flex`}
            disabled={notifications.length === 0}
            onClick={handleMarkAllAsRead}
          >
            <span>Mark all as read</span>
            <span
              className={`ml-[6px] text-base ${
                notifications.length === 0 ? "text-gray-300" : "text-[#553C9A]"
              } `}
            >
              <MdCheckCircleOutline />
            </span>
          </button>
        </div>
        {isLoading ? (
          <Loader size={"lg"} />
        ) : (
          <div className="overflow-y-scroll custom-scrollbar h-full">
            {!notifications?.length ? (
              <div className="h-full pb-5 flex-center flex-col">
                <img
                  className="w-44 -sm:w-36"
                  src={notificationIcon}
                  alt="no-notifications"
                />
                <p className="mt-10 text-xl -sm:text-lg font-bold tracking-wide">
                  You have no new notifications!
                </p>
              </div>
            ) : (
              notifications.map((notification, idx) => {
                return (
                  <div
                    key={idx}
                    className="py-4 px-3 border-b last-of-type:border-none text-sm flex-y-center justify-between"
                  >
                    <div className="flex items-start">
                      <div className="mr-3 flex-shrink-0 flex-y-center">
                        <div
                          className={`w-2 h-2 mr-1 flex-shrink-0 ${
                            !notification?.read
                              ? "bg-green-500"
                              : "bg-neutral-300"
                          } rounded-full`}
                        ></div>
                        <Avatar
                          className="w-7 h-7 rounded-full"
                          src={notification.user?.imageUrl}
                        />
                      </div>

                      <div className="text-xs">
                        <p className="text-sm font-bold">
                          {notification?.content}
                        </p>

                        <div className="my-2 flex-y-center text-neutral-500">
                          {/* <p>{getDateString(notification?.createdAt)}</p>
                          <div className=" mx-1 w-1 h-1 bg-neutral-500 rounded-full"></div> */}
                          <p>{getDaysAgoString(notification?.createdAt)}</p>
                          <div className=" mx-1 w-1 h-1 bg-neutral-500 rounded-full"></div>
                          <p>Notification</p>
                        </div>
                        {/* {notification?.type === "ASSIGNMENT" && (
                          <div className="flex-y-center text-neutral-500">
                            <span>
                              <FaUserFriends />
                            </span>
                            <p className="ml-1 mr-4">Assigned to</p>
                            <Avatar
                              className="w-[18px] h-[18px] mr-2 rounded-full"
                              src={
                                notification?.additionalData?.assignedTo?.imageUrl
                              }
                            />
                            <p>
                              {
                                notification?.additionalData?.assignedTo
                                  ?.firstName
                              }
                            </p>
                          </div>
                        )} */}
                      </div>
                    </div>

                    <button
                      className="ml-3 text-lg text-[#553C9A]"
                      onClick={() => handleMarkAsRead(notification?.id)}
                    >
                      <BsCheck2 />
                    </button>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// const Notifications = () => {
//   return (
//     <div>
//       <div className="mb-5 mt-2">
//         <h1 className="text-2xl font-bold mb-2">Notifications</h1>
//       </div>

//       <div>
//         {notifications.map(notification => {
//           return (
//             <div className="h-24 max-w-[750px] border-b last-of-type:border-none flex-y-center">
//               {/* <img src="" alt="" /> */}
//               <div className="w-12 h-12 mr-3 flex-shrink-0 rounded-full bg-slate-200"></div>
//               <div>
//                 <p>{notification.details}</p>
//                 <p className="text-[13px]">{notification.date}</p>
//               </div>
//               <button className="ml-5 text-xl">
//                 <BiX />
//               </button>
//             </div>
//           )}
//         )}
//       </div>
//     </div>
//   )
// }

// export default Notifications
