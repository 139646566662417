import { ComponentProps } from "@components/utils/ChakraWrapper";

const MainContentArea = (props: ComponentProps) => {
  return (
    <div
      className={`h-full ${!props?.viewedOnboarding ? "overflow-hidden" : ""}`}
    >
      {props?.children}
    </div>
  );
};

export default MainContentArea;
