import React, { useContext, useState } from "react";
import { ControlledCardProps } from "./interface";
import {
  ActionType,
  BoardItem,
  LinkType,
  LinkTypes,
  Dict,
  IResponse,
  MenuAction,
  Entity,
} from "@utils/interface";
import { ContextStore } from "@components/utils/Context";
import { useDel, useDisplayToast, usePost, usePut } from "@utils/hooks";
import Actions from "@components/Actions";
// import CustomDropdown from "@components/CustomDropdown";
import Loader from "@components/utils/Loader";
import {
  getDateString,
  getDaysDiff,
  isNullOrEmpty,
  setURLQuery,
} from "@utils/index";
import { has, omit, pick } from "lodash";
import {
  BiArchiveOut,
  BiTrash,
  BiX,
  BiNote,
  BiTask,
  BiUnite,
  BiPlus,
} from "react-icons/bi";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { FiPaperclip } from "react-icons/fi";
import {
  GREEN_DEADLINE,
  RED_DEADLINE,
  YELLOW_DEADLINE,
  removeCard,
  renameCard,
  updateCard,
} from "./helpers";
import Task from "./Task";
import Note from "./Note";
import { Tooltip } from "@chakra-ui/react";
// import ModalSearch from "@components/ModalSearch";

const Card = ({ text, card, id, onUpdate }: ControlledCardProps) => {
  const { successToast, errorToast, warningToast } = useDisplayToast();
  const {
    account,
    grants,
    reports,
    projects,
    documents,
    board,
    actionModal,
    boardItems,
    globalState,
    setGlobalState,
    setActionModal,
    setAppModal,
    setBoardItems,
    tasks: _tasks,
    notes: _notes,
    files: _files,
  } = useContext(ContextStore);
  // console.log("Card", id, card);
  const [title, setTitle] = useState<string>(card?.title);

  const files = _files?.filter((x) => x?.boardItemId === id); //TODO: add files to cards
  const tasks = _tasks[id];
  const notes = _notes[id];

  const grant = grants?.find((x) => x?.id === card?.grantId);
  const project = projects?.find((x) => x?.id === card?.projectId);
  const document = documents?.find((x) => x?.id === card?.documentId);
  const report = reports?.find((x) => x?.id === card?.reportId);

  const [showTask, setShowTask] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const nextDeadline = !grant
    ? ""
    : grant?.deadlines?.find((deadline) => new Date(deadline) > new Date()) ||
      grant?.deadlines[0];

  // console.log("nextDeadline", card?.grant?.name, card?.grant?.deadlines,
  // (new Date(nextDeadline).getTime() - new Date().getTime()) / (24 * 3600 * 1000));

  const toggleDropdown = () => {
    setIsOpen((val) => !val);
  };

  const colorMode = (type: "date" | "amt" = "date") => {
    // https://colors.muz.li/color/e6e6e6
    if (grant) {
      if (type === "date") {
        const inputDate = new Date(nextDeadline || "");
        const currentDate = new Date();

        const timeDifference = inputDate.getTime() - currentDate.getTime();

        // Calculate the difference in days using seconds
        let daysDifference = timeDifference / (24 * 3600 * 1000);
        // daysDifference = Math.floor(Math.abs(daysDifference));
        daysDifference = Math.floor(daysDifference);
        if (daysDifference <= 0) {
          //gray
          return { bg: "#ECFDF3", text: "#027A48", barBg: "#808080" };
        } else if (daysDifference <= RED_DEADLINE) {
          //red
          return { bg: "#FEF3F2", text: "#B42318", barBg: "#F04438" };
        } else if (daysDifference <= YELLOW_DEADLINE) {
          //yellow
          return { bg: "#FFFAEB", text: "#B54708", barBg: "#F79009" };
        }
        // if (daysDifference <= GREEN_DEADLINE)
        else {
          //green
          return { bg: "#ECFDF3", text: "#027A48", barBg: "#12B76A" };
        }
      } else if (type === "amt") {
        // if (card?.amount?.max >= 250000)
        return { bg: "#ECFDF3", text: "#027A48", barBg: "#12B76A" }; //green
        // else if (card?.amount?.max >= 100000)
        //   return { bg: "#FFFAEB", text: "#B54708", barBg: "#F79009", }; //yellow
        // else
        //   return { bg: "#FEF3F2", text: "#B42318", barBg: "#F04438", }; //red
      }
    } else if (project) {
      return { bg: "#D6BCFA", text: "#9F7AEA", barBg: "#9F7AEA" }; //violet
    } else if (report) {
      return { bg: "#ECFDF3", text: "#027A48", barBg: "#12B76A" }; //green
    } else if (document) {
      return { bg: "#BCC2FA", text: "#7A8CEA", barBg: "#BCC2FA" }; //purple
    }
  };

  const [updateBoardItem, { isLoading: isUpdating }] = usePut<BoardItem>();
  const [deleteBoardItem, { isLoading: isDeleting }] = useDel<BoardItem>();
  //create task/note
  const [createOne, { isLoading: isCreating }] = usePost<Dict>();

  const handleArchive = async () => {
    // console.log("handleUpdateBoardItem", card?.id);
    if (!card.createdAt) {
      removeCard(board, card?.columnId, card?.id, onUpdate);
      return;
    } else if (!card?.id) {
      warningToast("Please refresh board and try again.");
      return;
    }
    updateBoardItem(
      Entity.boardItems,
      {
        id: card?.id,
        data: {
          archived: true,
          archivedAt: new Date().toISOString(),
          additionalData: {
            ...(boardItems?.find((x) => x.id === card?.id)?.additionalData ||
              {}),
            columnId: card?.columnId,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          setBoardItems((prev) =>
            prev?.map((x) => (x?.id === card?.id ? data : x))
          );
          removeCard(board, card?.columnId, card?.id, onUpdate);
          successToast("Card Archived");
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleDelete = async () => {
    if (!card.createdAt) {
      removeCard(board, card?.columnId, card?.id, onUpdate);
      return;
    } else if (!card?.id) {
      warningToast("Please refresh board and try again.");
      return;
    }
    deleteBoardItem(
      Entity.boardItems,
      { id: card?.id },
      {
        onSuccess(data, variables, context) {
          setBoardItems((prev) => prev?.filter((x) => x?.id !== card?.id));
          removeCard(board, card?.columnId, card?.id, onUpdate);
          successToast("Card Deleted");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleLink = (type: LinkTypes, item: Dict) => {
    // console.log("handleLink", card, item);
    if (!card?.id) {
      warningToast("Please refresh board and try again.");
      return;
    }
    // const item = getStoreEntity(type)?.find(x => x?.id == id);
    createOne(
      Entity.boardItems,
      {
        data: {
          ...omit(card, ["id", "column", "columnId"]),
          title: item?.title,
          [type]: { connect: { id: item?.id } },
          account: { connect: { id: account?.id } },
        },
      },
      {
        onSuccess(data, variables, context) {
          // console.log("data", data);
          successToast("Card Linked");
          updateCard(board, card, data, onUpdate);
          setBoardItems((prev) => [...prev, data as BoardItem]);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const cardActions: MenuAction = {
    top: [
      {
        label: "Link",
        iconBefore: <BiUnite />,
        items: [
          {
            label: "Document",
            iconBefore: <BiTask />,
            onSelect: () =>
              setActionModal({
                ...actionModal,
                modalSearch: true,
                modalSearchType: LinkType.document,
                modalSearchAction: (item) =>
                  handleLink(LinkType.document, pick(item, ["title", "id"])),
              }),
            visible: !!documents?.length,
          },
          {
            label: "Project",
            iconBefore: <BiTask />,
            onSelect: () =>
              setActionModal({
                ...actionModal,
                modalSearch: true,
                modalSearchType: LinkType.project,
                modalSearchAction: (item) =>
                  handleLink(LinkType.project, pick(item, ["title", "id"])),
              }),
            visible: !!projects?.length,
          },
          {
            label: "Report",
            iconBefore: <BiTask />,
            onSelect: () =>
              setActionModal({
                ...actionModal,
                modalSearch: true,
                modalSearchType: LinkType.report,
                modalSearchAction: (item) =>
                  handleLink(LinkType.report, pick(item, ["title", "id"])),
              }),
            visible: !!reports?.length,
          },
        ],
        visible: !grant && !document && !project && !report,
      },
    ],
    bottom: [
      {
        label: "Archive",
        iconBefore: <BiArchiveOut />,
        onSelect: () =>
          setActionModal({
            ...actionModal,
            archiveCard: true,
            cardId: id,
            cb: handleArchive,
          }),
        visible: !!grant || !!document || !!project || !!report,
      },
      {
        label: "Remove",
        iconBefore: <BiTrash />,
        onSelect: () =>
          setActionModal({
            ...actionModal,
            deleteCard: true,
            cardId: id,
            cb: handleDelete,
          }),
      },
    ],
  };

  const renderTitle = () => {
    const hasGrant = grant;
    const hasProject = project;
    const hasDocument = document;
    const hasReport = report;

    const isLinked =
      (hasProject && !hasGrant) ||
      (hasReport && !hasGrant) ||
      (hasDocument && !hasGrant) ||
      hasGrant;

    return isCreating || isUpdating || isDeleting ? (
      <Loader size={"sm"} />
    ) : globalState?.editCardTitleId === card?.id ? (
      renderEditTitle(card)
    ) : (
      <p
        className={`text-sm px-3 line-clamp-1 ${
          isLinked ? "hover:cursor-pointer" : ""
        }`}
        onClick={() => {
          // console.log("isLinked", isLinked, card);
          if (!isLinked) {
            //set isEdit
            setGlobalState((prev) => ({ ...prev, editCardTitleId: card?.id }));
            return;
          }
          setURLQuery(
            hasGrant
              ? { grantId: grant?.id }
              : hasProject
              ? { projectId: project?.id }
              : hasReport
              ? { reportId: report?.id }
              : hasDocument
              ? { documentId: document?.id }
              : {}
          );
          setAppModal((prev) => ({
            ...prev,
            views: { ...prev.views, [ActionType.view]: true },
            ...(hasProject && !hasGrant
              ? { type: LinkType.project, projectId: project?.id }
              : {}),
            ...(hasReport && !hasGrant
              ? { type: LinkType.report, reportId: report?.id }
              : {}),
            ...(hasDocument && !hasGrant
              ? { type: LinkType.document, documentId: document?.id }
              : {}),
            ...(hasGrant ? { type: LinkType.grant, grantId: grant?.id } : {}),
          }));
        }}
      >
        {text}
      </p>
    );
  };

  const renderTop = () => {
    const column = board?.columns?.find((x) =>
      x?.cards?.find((c) => c?.id === id)
    )?.title;
    const ignoreDeadline =
      column === "Awarded" || column === "Received" || column === "Declined";
    const showIndicator =
      document || project || report
        ? true
        : ignoreDeadline
        ? false
        : nextDeadline;

    const daysDifference = getDaysDiff(nextDeadline);

    switch (text) {
      // case "Awarded":
      // case "Received":
      // case "Declined":

      default:
        return (
          <>
            <div
              className={`mb-1 px-3 flex ${
                nextDeadline ? "justify-between" : ""
              } items-center`}
            >
              {showIndicator ? (
                <Tooltip
                  label={`${
                    daysDifference <= 0
                      ? "Deadline has past"
                      : daysDifference <= RED_DEADLINE
                      ? "Deadline less than 30 Days away"
                      : daysDifference <= YELLOW_DEADLINE
                      ? "Deadline less than 90 Days away"
                      : "Deadline more than 90 Days away"
                  }`}
                >
                  <div
                    style={{ backgroundColor: colorMode()?.barBg }}
                    className="w-16 h-2 rounded-full"
                  />
                </Tooltip>
              ) : null}
              {!showIndicator && renderTitle()}
              <div className="flex-grow" />
              <button
                onClick={() => toggleDropdown()}
                className={`p-[3px] mr-1 text-[13px] rounded-full hover:bg-[#B794F4] hover:text-white ${
                  isOpen
                    ? "bg-[#B794F4] text-white"
                    : "bg-[#F2F4F7] text-[#101828]"
                }`}
              >
                {isOpen ? <FaAngleUp /> : <FaAngleDown />}
              </button>
              <Actions
                isVertical={true}
                actions={cardActions}
                emptyClass={true}
              />
            </div>

            {showIndicator && renderTitle()}
          </>
        );
    }
  };

  const renderExpansion = () => {
    switch (text) {
      default:
        return (
          <>
            {nextDeadline ? (
              <div
                style={{
                  backgroundColor: colorMode()?.bg,
                  color: colorMode()?.text,
                }}
                className="line-clamp-1 bg-green-600 mb-1 px-4 py-1 w-max rounded-[20px]"
              >
                Next Deadline:{" "}
                <span>{getDateString(nextDeadline, "MMM DD, YYYY")}</span>
              </div>
            ) : null}
            {!isNullOrEmpty(grant?.amount) ? (
              <div
                style={{
                  backgroundColor: colorMode("amt")?.bg,
                  color: colorMode("amt")?.text,
                }}
                className="overflow-x-hidden px-4 py-1 w-max max-w-[100%] rounded-[20px]"
              >
                {`Grant size: $${grant?.amount?.min || "?"} - $${
                  grant?.amount?.max || grant?.amount?.min || "?"
                }`}
              </div>
            ) : null}
          </>
        );
    }
  };

  const renderEditTitle = (card: BoardItem) => {
    const updateTitle = () => {
      if (!title) return;
      renameCard(board, card, title, onUpdate);
      setGlobalState((prev) => ({ ...prev, editCardTitleId: undefined }));
    };

    return (
      <input
        autoFocus={true}
        className={
          "text-sm font-bold outline-none rounded border p-1 pl-2 focus:border-blue-500 focus:outline-none w-full"
        }
        placeholder="Card Title"
        value={title || ""}
        id="title"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        onBlur={() => {
          updateTitle();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateTitle();
          }
        }}
      />
    );
  };

  // console.log("tasks", id, tasks);

  return (
    <div draggable>
      <div className="mb-2 rounded-md py-2 w-[265px] bg-white relative">
        {renderTop()}
        <div className="px-3 mt-1 flex text-xs text-[#101828]">
          {
            <div
              onClick={() => setShowTask(true)}
              className="flex items-center mr-5 cursor-pointer"
            >
              <BiTask />
              {/* <span className="ml-2">{`0/${card?.grant?.checklists?.length}`}</span> */}
              <span className="ml-1.5">
                {!tasks?.length
                  ? "Tasks"
                  : `${tasks?.filter((x) => x?.completed)?.length}/${
                      tasks?.length || 0
                    }`}
              </span>
            </div>
          }
          {
            <div
              onClick={() => setShowNote(true)}
              className="flex items-center mr-5 cursor-pointer"
            >
              <BiNote />
              <span className="ml-1.5">
                {!notes?.length ? "Notes" : `${notes?.length || 0}`}
              </span>
            </div>
          }
          {!!files?.length && (
            <div className="flex items-center">
              <span className="mr-1 p-[2px] flex-center rounded hover:bg-neutral-200 cursor-pointer">
                <FiPaperclip />
              </span>
              <span>{`${files?.length}`}</span>
            </div>
          )}
        </div>
        {isOpen && <div className="mt-2 px-3 text-xs">{renderExpansion()}</div>}
        {showTask && <Task itemId={id} setShowTasks={setShowTask} />}
        {showNote && <Note itemId={id} setShowNote={setShowNote} />}
      </div>
    </div>
  );
};

export default Card;
