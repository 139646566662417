import { CloseIcon } from "@chakra-ui/icons";
import React from "react";
import CloseBtn from "./CloseButton";

interface BannerProps {
  children?: React.ReactNode;
  text?: string | React.ReactNode;
  showOffer?: React.ReactNode;
  onClose?: () => void;
}

const Banner = (props: BannerProps) => {
  const { showOffer, text, onClose } = props;
  return (
    <div
      className={`block py-3 -sm:py-2 flex-center gap-x-2 gap-y-1 -sm:flex-col -sm:text-wrap -sm:text-xs text-lg text-center text-white bg-gradient`}
    >
      {/* close button  */}

      {showOffer && (
        <div className=" h-max mr-2 px-2 py-1 text-xs font-semibold bg-[#f2f4f791] rounded-lg border border-[#E9D8FD]">
          <span>Limited offer</span>
        </div>
      )}

      <div className="flex px-4 justify-evenly">
        <div className={`-sm:p-3 mr-3`}>
          {text ? (
            text
          ) : (
            <>
              Join early! And get your subscription at a{" "}
              <span className="font-bold">30% discount</span> for a lifetime!
            </>
          )}
        </div>
        {onClose ?
          <div className="flex-center w-6 h-6 rounded-full bg-[#f2f4f791] cursor-pointer">
            <CloseBtn variant="outline" closeModal={onClose} />
          </div> : null
        }
      </div>
    </div>
  );
};

export default Banner;
