// https://github.com/steven-tey/novel/blob/main/apps/docs/snippets/text-buttons.mdx
// https://github.com/ZanyuanYang/Text-Editor-Tiptap/blob/master/src/pages/TextEditor/components/Tiptap.tsx
import { TipTapEditor } from "@/playground/editors/interface";
import * as Icons from "./Icons";
import { useCallback } from "react";
import classNames from "classnames";
import {
  FiAlignCenter,
  FiAlignJustify,
  FiAlignLeft,
  FiAlignRight,
  FiTable,
} from "react-icons/fi";
import { MdOutlineTableRows } from "react-icons/md";
import { TbTableColumn, TbTableRow } from "react-icons/tb";
import { GoColumns, GoRows } from "react-icons/go";

interface ToolbarProps {
  editor?: TipTapEditor;
  isCollab?: boolean;
}

const Toolbar = ({ editor, isCollab = false }: ToolbarProps) => {
  if (!editor) {
    return null;
  }
  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);

  const toggleCode = useCallback(() => {
    editor.chain().focus().toggleCode().run();
  }, [editor]);

  // https://github.com/ZanyuanYang/Text-Editor-Tiptap/blob/master/src/pages/TextEditor/components/Tiptap.tsx#L311
  const toggleTable = useCallback(() => {
    editor
      .chain()
      .focus()
      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
      .run();
  }, [editor]);

  const toggleTableAddRow = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);

  const toggleTableAddCol = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const toggleTableRmRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  const toggleTableRmCol = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  const toggleAlignLeft = useCallback(() => {
    (editor as any).chain().focus().setTextAlign("left").run();
  }, [editor]);

  const toggleAlignRight = useCallback(() => {
    (editor as any).chain().focus().setTextAlign("right").run();
  }, [editor]);

  const toggleAlignCenter = useCallback(() => {
    (editor as any).chain().focus().setTextAlign("center").run();
  }, [editor]);

  const toggleAlignJustify = useCallback(() => {
    (editor as any).chain().focus().setTextAlign("justify").run();
  }, [editor]);

  // Add more handlers for other formatting options

  return (
    <div className="menu sticky top-[12rem]">
      {!isCollab && (
        <>
          <button
            className="menu-button hover:text-purple-500"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor?.can()?.undo()}
          >
            <Icons.RotateLeft />
          </button>
          <button
            className="menu-button hover:text-purple-500"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor?.can()?.redo()}
          >
            <Icons.RotateRight />
          </button>
        </>
      )}
      {/* <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("link")
          })}
          onClick={openModal}
        >
          <Icons.Link />
        </button> */}
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("bold"),
        })}
        onClick={toggleBold}
      >
        <Icons.Bold />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("underline"),
        })}
        onClick={toggleUnderline}
      >
        <Icons.Underline />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("intalic"),
        })}
        onClick={toggleItalic}
      >
        <Icons.Italic />
      </button>
      <button
        className={classNames("menu-button  hover:text-purple-500", {
          "is-active": editor.isActive("strike"),
        })}
        onClick={toggleStrike}
      >
        <Icons.Strikethrough />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive({ textAlign: "left" }),
        })}
        onClick={toggleAlignLeft}
      >
        <FiAlignLeft />
      </button>
      <button
        className={classNames("menu-button  hover:text-purple-500", {
          "is-active": editor.isActive({ textAlign: "center" }),
        })}
        onClick={toggleAlignCenter}
      >
        <FiAlignCenter />
      </button>
      <button
        className={classNames("menu-button  hover:text-purple-500", {
          "is-active": editor.isActive({ textAlign: "right" }),
        })}
        onClick={toggleAlignRight}
      >
        <FiAlignRight />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive({ textAlign: "justify" }),
        })}
        onClick={toggleAlignJustify}
      >
        <FiAlignJustify />
      </button>
      {/* <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("code"),
        })}
        onClick={toggleCode}
      >
        <Icons.Code />
      </button> */}
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("table"),
        })}
        onClick={toggleTable}
      >
        <FiTable />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("table"),
        })}
        onClick={toggleTableAddRow}
      >
        <GoRows />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("table"),
        })}
        onClick={toggleTableAddCol}
      >
        <GoColumns />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("table"),
        })}
        onClick={toggleTableRmRow}
      >
        <TbTableRow />
      </button>
      <button
        className={classNames("menu-button hover:text-purple-500", {
          "is-active": editor.isActive("table"),
        })}
        onClick={toggleTableRmCol}
      >
        <TbTableColumn />
      </button>
    </div>
  );
};

export default Toolbar;
