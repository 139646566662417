import { Dict, ID, IDict, SearchCategory, isNullOrEmpty } from "@utils/index";
import { useContext, useEffect, useRef, useState } from "react";
import { BsFileEarmarkText, BsArrowReturnRight } from "react-icons/bs";
import { getDateString } from "@utils/index";
import { truncate } from "lodash";

interface ResultSectionProps {
  category: SearchCategory;
  results: IDict[];
  action: (result: IDict) => void;
}

const SearchResults = ({ category, results, action }: ResultSectionProps) => {
  // console.log("ResultSection", results);

  const TRUNC_LEN = 103;
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div className="mt-7 first-of-type:mt-0 border-b last-of-type:border-b-0">
      <h2 className="mb-1 text-xs uppercase">{category}</h2>
      <div>
        {results
          .slice(0, results?.length >= 2 ? 2 : results.length)
          .map((result, index) => {
            const title =
              category === "prompts"
                ? result?.prompt?.title
                : result?.title || result?.name || result?.content;
            // const title = result?.title || result?.name || result?.content;
            return (
              <div
                className="flex cursor-pointer mb-4"
                key={index}
                onClick={() => action(result)}
              >
                <div className=" w-8 h-8 mr-3 flex-center flex-shrink-0 text-lg bg-[#FAF5FF] rounded-full">
                  <BsFileEarmarkText />
                </div>
                <div className="w-full">
                  <p className="mb-[2px] text-sm line-clamp-2">
                    {truncate(title, { length: TRUNC_LEN })}
                  </p>
                  <p className="text-xs text-[#6C6685]">
                    Last Modified • {getDateString(result?.updatedAt)}
                  </p>
                </div>
                <div
                  className="ml-10 my-auto text-2xl flex-shrink-0"
                  onClick={() => action(result)}
                >
                  <BsArrowReturnRight />
                </div>
              </div>
            );
          })}
      </div>

      {results?.length > 2 && (
        <button
          onClick={() => (seeMore ? setSeeMore(false) : setSeeMore(true))}
          className="mb-3 text-xs text-[#6B46C1] font-bold"
        >
          {seeMore ? "Hide" : `See ${results?.length - 2} more documents`}
        </button>
      )}

      {seeMore && (
        <div>
          {results.slice(2, results?.length).map((result, index) => {
            const title =
              category === "prompts"
                ? result?.prompt?.title
                : result?.title || result?.name || result?.content;
            return (
              <div
                className="flex cursor-pointer mb-4"
                key={index}
                onClick={() => action(result)}
              >
                <div className=" w-8 h-8 mr-3 flex-center flex-shrink-0 text-lg bg-[#FAF5FF] rounded-full">
                  <BsFileEarmarkText />
                </div>
                <div className="w-full">
                  <p className="text-sm line-clamp-2 mb-1">
                    {truncate(title, { length: TRUNC_LEN })}
                  </p>
                  <p className="text-xs text-[#6C6685]">
                    Last Modified • {getDateString(result?.updatedAt)}
                  </p>
                </div>
                <div
                  className="ml-10 my-auto text-2xl flex-shrink-0"
                  onClick={() => action(result)}
                >
                  <BsArrowReturnRight />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
