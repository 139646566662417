import logo from "@assets/logo.svg";
import {
  FiAward,
  FiBriefcase,
  FiCalendar,
  FiFilePlus,
  FiFolder,
  FiLayers,
  FiPenTool,
  FiPlus,
  FiTrello,
} from "react-icons/fi";
import AppButton from "@components/AppButton";
import { LuLayoutDashboard } from "react-icons/lu";
import { Flags, IResponse, SideBarMenuTitle, User } from "@utils/interface";
import { Icon } from "@chakra-ui/react";
import { appName } from "@utils/index";

const CustomSidebar = ({
  currentStep,
  flags,
}: {
  currentStep: number;
  flags: Flags;
}) => {
  const pages = [
    {
      name: SideBarMenuTitle.Dashboard,
      icon: LuLayoutDashboard,
      visible: true,
    },
    { name: SideBarMenuTitle.Organization, icon: FiBriefcase, visible: true },
    { name: SideBarMenuTitle.Files, icon: FiFilePlus, visible: true },
    { name: SideBarMenuTitle.Board, icon: FiTrello, visible: true },
    { name: SideBarMenuTitle.Folders, icon: FiFolder, visible: true },
    { name: SideBarMenuTitle.Calendar, icon: FiCalendar, visible: true },
    {
      name: SideBarMenuTitle.Projects,
      icon: FiLayers,
      visible: !!flags?.v2?.isEnabled,
    },
    {
      name: SideBarMenuTitle.Funders,
      icon: FiAward,
      visible: !!flags?.v2?.isEnabled,
    },
  ];

  const handleColors = (tab: string) => {
    switch (currentStep) {
      case 7:
        return tab === SideBarMenuTitle.Organization;
      case 4:
        return tab === SideBarMenuTitle.Board;
      case 9:
        return tab === SideBarMenuTitle.Folders;
    }
  };

  return (
    <div
      className={`
        w-60 h-full pb-10 flex flex-col bg-[#fcfaff] flex-shrink-0 border-r 
        ${[0, 1, 2].includes(currentStep) ? "-md:hidden" : ""}
      `}
    >
      <div className=" px-4 py-5 flex-y-center">
        <img className="h-8 w-8" src={logo} alt="grant-write-logo" />
        <span className=" ml-2 text-2xl font-semibold">{appName}</span>
      </div>

      {currentStep !== 1 && currentStep !== 2 && (
        <div className="mt-[15px] h-full flex-1 flex flex-col justify-between text-md ">
          <div>
            {pages
              ?.filter((x) => x?.visible !== false)
              ?.map((page, idx) => {
                return (
                  <div
                    key={idx}
                    className={`px-4 py-3 flex-y-center 
                  ${
                    handleColors(page?.name)
                      ? "bg-[#eee8f8] text-[#6a54b1]"
                      : "text-gray-400"
                  }
                `}
                  >
                    <Icon mx="2" boxSize="4" as={page?.icon} />
                    <span>{page?.name}</span>
                  </div>
                );
              })}
          </div>

          <AppButton
            text="Import New Grant"
            icon={FiPlus}
            variant="secondary"
            iconPosition="left"
            // onClick={handleAddGrant}
          />
        </div>
      )}
    </div>
  );
};

export default CustomSidebar;
