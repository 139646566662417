import "./grant-board.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  // useGetOne,
  useGetList,
} from "react-admin";
// import ReactDOM from "react-dom";
import { ContextStore } from "@components/utils/Context";
import { useCommonQueries, useDisplayToast, useURLParams } from "@utils/hooks";
import {
  Account,
  ActionType,
  Board,
  BoardCard,
  LinkType,
  IResponse,
  isDev,
  // MenuAction,
  // getDateString,
  isNullOrEmpty,
  BoardItem,
} from "@utils/index";
import { v4 as uuidv4 } from "uuid";
import Loader from "@components/utils/Loader";
// import { IoEllipsisHorizontal, IoEllipsisVertical } from "react-icons/io5";
// import UncontrolledBoard from "@pages/board/UncontrolledBoard";
import ControlledBoard from "./ControlledBoard";
import { PiPlus } from "react-icons/pi";
import Actions from "@components/Actions";
import { BiArchiveOut } from "react-icons/bi";
import UnArchive from "./Unarchive";
import { useUpdateBoard } from "./hook";
import { useEffectOnce, useUpdateEffect } from "usehooks-ts";
import VideoUpdate from "@components/VideoUpdate";
// import Actions from "@components/Actions";
// import { useDebounce } from 'usehooks-ts';
// https://github.com/asseinfo/react-kanban/blob/main/src/styles.scss

// https://codesandbox.io/s/nvjp3
// https://github.com/asseinfo/react-kanban#-api

const GrantBoard = () => {
  //get account boards
  const {
    // user,
    flags,
    account,
    boardItems,
    // setBoardItems,
    documents,
    projects,
    reports,
    grants,
    board,
    setBoard,
    actionModal,
    setActionModal,
    setAppModal,
    globalState,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const ref = useRef<HTMLDivElement>(null);
  const { fetchBoardItems, fetchGrants } = useCommonQueries();

  const {
    queryParams: { grantId, documentId },
  } = useURLParams();

  useEffect(() => {
    if (grantId) {
      setAppModal((prev) => ({
        ...prev,
        views: { ...prev.views, [ActionType.view]: true },
        type: LinkType.grant,
        grantId,
      }));
    }
    if (documentId) {
      setAppModal((prev) => ({
        ...prev,
        views: { ...prev.views, [ActionType.view]: true },
        type: LinkType.document,
        documentId,
      }));
    }
  }, [grantId, documentId]);

  useEffect(() => {
    fetchGrants.refetch();
    fetchBoardItems.refetch();
  }, [globalState?.rerenderBoard]);

  const actions = {
    top: [
      {
        label: "Unarchive",
        iconBefore: <BiArchiveOut />,
        onSelect: () => setActionModal({ ...actionModal, showUnarchive: true }),
        // visible: boardItems?.filter((x) => x?.archived).length,
        visible: true,
      },
    ],
    bottom: [],
  };

  const createBoard = (rerender?: boolean): Board => {
    if (account?.grantBoard && account?.grantBoard?.length > 0) {
      const renderFromScratch = board === null || rerender;

      const _columns = renderFromScratch ? account?.grantBoard : board?.columns;

      const allCards = _columns?.map((col) => col?.cards).flat();
      // console.log("allCards", allCards.length, allCards);
      const missingCards = renderFromScratch
        ? boardItems?.filter(
            (x) => !x?.archived && !allCards?.find((c) => c?.id == x?.id)
          )
        : [];
      // console.log("missingCards", missingCards.length, missingCards);

      const existingBoard = {
        columns: _columns?.map((column) => {
          // console.log("existingBoard:column", column);

          const cards: BoardCard[] = [];

          for (const card of column.cards) {
            const grant = grants?.find((x) => x.id === card?.grantId);
            const document = documents?.find((x) => x.id === card?.documentId);
            const project = projects?.find((x) => x.id === card?.projectId);
            const report = reports?.find((x) => x.id === card?.reportId);

            cards.push({
              ...boardItems?.find((item) => item.id === card?.id),
              ...card,
              columnId: column?.id,
              title: grant
                ? grant?.name
                : document
                ? document?.title
                : project
                ? project?.title
                : report
                ? report?.title
                : card?.title,
            });
          }

          // .filter((item) => item?.column),

          // const newItem = boardItems?.filter(item => JSON.stringify(item?.additionalData) == '{}');
          // console.log("newItem", newItem);

          return {
            ...column,
            cards: [
              ...cards,
              ...(column?.id == board?.columns?.[0]?.id ? missingCards : []),
            ],
          };
        }),
      };
      // console.log("existingBoard", existingBoard);
      return existingBoard;
    } else {
      const boardItemColumns = Array.from(
        new Set(boardItems?.map((item) => item?.column))
      );
      // console.log("boardItemColumns", boardItemColumns);

      const _columns =
        (account?.columns?.length > 0 && account?.columns) ||
        boardItemColumns ||
        [];

      const newBoard = {
        columns: _columns?.map((title, id) => {
          const cards: BoardCard[] = [];

          for (const item of boardItems) {
            if (item?.column === title || title === _columns[0]) {
              cards.push({ ...item, columnId: id.toString() });
            }
          }

          return { id: uuidv4(), title, cards };
        }),
      };
      // console.log("newBoard", newBoard);
      return newBoard;
    }
  };

  const { handleUpdateBoard } = useUpdateBoard();

  const onUpdate = (board: Board, refetch = false) => {
    setBoard(board);
    handleUpdateBoard(board); // updates account which triggers useEffect below
    if (refetch) fetchBoardItems.refetch(); // used by src/pages/board/helpers.ts
  };

  const handleNewColumn = async () => {
    const newColumn = { id: uuidv4(), title: "New Column", cards: [] };
    const newBoard = { ...board, columns: [...board.columns, newColumn] };
    onUpdate(newBoard);
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderTop = () => (
    <div className="flex justify-between mb-5 px-5 border-b">
      <p className="text-2xl font-bold ">Board</p>
      <div className="flex-grow" />
      <div className="ml-4 mb-1">
        <button
          className="w-max h-max px-4 py-1 hover:bg-[#E9D8FD] text-[#101828] hover:text-[#553C9A] border rounded-md flex items-center"
          onClick={handleNewColumn}
        >
          <PiPlus />
          <span className="ml-2">New Column</span>
        </button>
      </div>
      <Actions actions={actions} />
    </div>
  );

  // console.log("boardItems", boardItems);
  // console.log("board", board);
  // console.log("grants", grants);
  // console.log(
  //   "board?.columns",
  //   // board?.columns,
  //   board?.columns?.map((x) => x?.id),
  //   board?.columns?.map((x) => x?.id)[0] == account?.grantBoard?.map((x) => x?.id)[0]
  // );
  // console.log(
  //   "account?.grantBoard",
  //   // account?.grantBoard,
  //   account?.grantBoard?.map((x) => x?.id)
  // );

  useEffect(() => {
    if (account) {
      setBoard({
        ...createBoard(),
      });
    }
  }, [
    account,
    grants?.length,
    documents?.length,
    projects?.length,
    reports?.length,
  ]);

  useEffect(() => {
    setBoard({ ...createBoard(true) });
  }, [boardItems]);

  // console.log(board?.columns[0]?.cards?.length, boardItems?.length)

  return (
    <>
      <VideoUpdate videoKeys={["Deadlines"]} />
      {actionModal?.showUnarchive && <UnArchive onUpdate={onUpdate} />}
      <div className=" pt-8 h-full flex flex-col">
        {renderTop()}
        {!board?.columns?.length ? (
          // || !board?.columns?.find((x) => x?.cards?.length > 0)
          <div className="flex justify-center w-full h-full">
            <Loader size={"xl"} />
          </div>
        ) : (
          <div className="h-full px-2 overflow-y-hidden flex-1">
            <ControlledBoard onUpdate={onUpdate} scrollRef={ref} />
          </div>
        )}
      </div>
    </>
  );
};

export default GrantBoard;

// // https://horizon-ui.com/documentation/docs/components/kanban
// // https://1v4hzb.csb.app/kanban
// // https://codesandbox.io/s/1v4hzb?file=/src/components/kanban.js
