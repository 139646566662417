export default {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: {
        id: "638af329-b4c6-4b16-a318-af7d14bef142",
        textAlign: "left",
        level: 1,
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(0, 0, 0)",
              },
            },
          ],
          text: "Organization Information",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "0461990a-907e-428d-a2d3-274c25d3a32d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Legal Name of Organization:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "4c9156d7-4d0c-402a-b4a3-22848fada74c",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "774ddc39-9a2e-4c97-9b85-50a03c63fc8d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mailing Address:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "f44fb072-558e-440f-babe-feda7bbe7442",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "a8003b6a-e4b8-46cc-90c8-3a3d75abf916",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Phone:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "a6a8839d-e842-4437-bfe2-d5613fbe0089",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "6ce4c74c-1b42-4342-bb83-1ffa8711c3e6",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Website:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "7a170a12-5ff7-48e3-a0ca-72272ad4dbdc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "79417dc8-b0d2-412a-a214-1ed4c2e8db5b",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Year Established:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "df73c4f6-b188-4d50-ad92-b1767b06cbdc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "2bc8c862-b782-4acd-a100-7c63e9bddbf1",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Registered Charity Number:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "f1b50924-9258-478d-a1f4-af8ef83962fd",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "12db1462-dfda-4441-8ba4-a6018c584b75",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "# FT Employees:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "5839355f-4c14-44b8-b76d-bed24afbb2dc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "dfb4ebab-e7a4-4988-a1bf-69d82c1c578d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "# PT Employees:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "fcb97574-96a1-4d12-a6ff-56c84c6046de",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "ef19f4b3-76a0-4688-9bf2-7c132df1193d",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "#Volunteers:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "9b386862-a516-46d4-a0e5-272f1dfcd9d5",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "dccf169a-4840-47e6-9d9e-7fc4dc0bf08f",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Volunteer hours:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "13ad07a3-d97a-4cf9-a394-30cd4973cb17",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "c2366f47-d6cc-46a7-bb59-ff922e004170",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Primary Contact",
        },
      ],
    },
    {
      type: "bulletList",
      attrs: {
        tight: true,
      },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "69cc3991-6684-48a0-abc3-5064f16fabad",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Name",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "a82666bf-a3ae-4fe5-8a60-863c9a4d519c",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Email",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "cf086ee1-6bd6-41c4-9336-ee9da74bc6fd",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Phone",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "24e29672-1564-4dfa-9028-942f79c766a2",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "0941dcb8-a40b-4e09-b507-3baafca00f03",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Other Contacts",
        },
      ],
    },
    {
      type: "bulletList",
      attrs: {
        tight: true,
      },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "4ac1de77-59ee-40d7-aa18-733f4d8f2cb3",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Name",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "b6fee50f-a75f-49b4-911a-e8e2e6ca7134",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Email",
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                id: "579a17b1-f823-4ff5-8626-59df89be8aea",
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  marks: [
                    {
                      type: "textStyle",
                      attrs: {
                        color: "rgb(102, 102, 102)",
                      },
                    },
                  ],
                  text: "Phone",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "6ad9c18f-68a2-4dff-b298-b04b5a0d7dfc",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "a655b342-fffa-488d-997c-03ba7064a25b",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mission:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "52edf74c-36b5-4732-a519-8306626bc816",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "97911fb1-b3f2-4be6-b5e0-f4068bf4ba90",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "Mandate:",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        id: "b75154af-7f1e-43a2-bb08-5ac56fac84ad",
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        id: "5167d7e1-e37a-471c-8efc-29fdea7f6beb",
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "textStyle",
              attrs: {
                color: "rgb(67, 67, 67)",
              },
            },
          ],
          text: "History:",
        },
      ],
    },
  ],
};
