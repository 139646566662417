import { Document, PromptSection, SlateNode } from "@utils/interface";
import { orderBy } from "lodash";
import { createSlateNode, stringToSlate } from "..";
import slateToHtml from "./slateToHtml";

export * from "./docx";
export * from "./pdf";

export const getExportPayload = (
  document: Document,
  sections?: PromptSection[]
): SlateNode[] => {
  const payload = [
    ...createSlateNode(document?.title, "heading-one"),
    ...createSlateNode(" "), //new line
    ...createSlateNode(" "), //new line
    //prompt sections
    ...orderBy(sections || document?.prompts, "createdAt") // TODO: allow user to define order
      .map((x) =>
        [
          ...createSlateNode(x?.title || "Untitled Prompt", "heading-two"), //prompt
          ...createSlateNode(" "), //new line
        ].concat(
          ...stringToSlate(
            x?.currentResponse || x?.response?.embeddings?.[0]?.content || ""
          ),
          ...createSlateNode(" ") //new line
        )
      )
      .flat(),
  ];

  return payload;
};

export const getHtmlString = (document: Document) => {
  const payload = getExportPayload(document);
  const html = payload.map(slateToHtml).join("");
  return html;
};

export const slateToString = (nodes: SlateNode[]) => {
  return nodes.map((n) => n?.children[0].text).join("\n");
};
