import { useContext, useEffect, useRef, useState } from "react";
import { FiPaperclip } from "react-icons/fi";
import { IoPaperPlaneOutline } from "react-icons/io5";
// import ChatAction from "./chat/Action";
import ChatBubble from "./chat/Bubbles";
import {
  ChatMessage,
  Dict,
  Entity,
  IDict,
  PromptAttachment,
  SlateNode,
  TDict,
} from "@utils/interface";
import { ContextStore } from "@components/utils/Context";
import {
  useCommonQueries,
  useDel,
  useDisplayToast,
  usePost,
  usePut,
} from "@utils/hooks";
import { IResponse, ellipsis } from "@utils/index";
import Loader from "@components/utils/Loader";
import { createSlateNode, slateToPlainString } from "@playground/editors/slate";
import { BiX } from "react-icons/bi";
import { useOnClickOutside } from "usehooks-ts";
import { v4 as uuid } from "uuid";
import { debug } from "@utils/logging";
import Actions from "@components/Actions";
import { BsArrowCounterclockwise, BsRobot } from "react-icons/bs";
import { startsWith } from "lodash";

interface AssistantChatProps {
  entity: Dict & Partial<{ id: string; $kind: string }>;
  history?: ChatMessage[];
  context?: string;
  handleCb?: (data: Dict) => void;
}

const AssistantChat = (props: AssistantChatProps) => {
  const { entity, context, handleCb } = props;
  let { history } = props;
  const { id, $kind } = entity;
  const { user, account, chats, files } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  const [prompt, setPrompt] = useState("");
  const [isAttDropdown, setIsAttDropdown] = useState(false);
  const [attachment, setAttachment] = useState<PromptAttachment | null>(null);
  const entityChat = chats?.find((c) => c?.entityId === entity.id);
  const [currentChatId, setCurrentChatId] = useState<string | undefined>(
    entityChat?.id
  );
  history = history?.length ? history : entityChat?.history;
  const { fetchChats } = useCommonQueries();

  const [updateChat] = usePut<ChatMessage>();

  useEffect(() => {
    fetchChats.refetch();
  }, []);

  // const [activeItem, setActiveItem] = useState('');

  const handleOptionClick = (option) => {
    // console.log('option', option)
    // onChange && onChange(option);
    setAttachment(option);
    setIsAttDropdown(false);
  };

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>(history || []);
  const [canSaveChat, setCanSaveChat] = useState(
    chatHistory?.length !== history?.length
  );

  const hints = [
    // "Make it more conversational",
    // "I want to rewrite current draft",
    // "I want to reduce the length",
    // "Write a project summary...",
    "Summarize the ...",
    "I need help with ...",
    "What is this about?",
    "Tell me about the ...",
  ];
  const placeholderHint = useRef(
    hints[Math.floor(Math.random() * hints.length)]
  );

  //create new chat
  const [createChatSession,] = usePost<Dict>();
  const [createChat, { isLoading: isChatting }] = usePost<Dict>();
  // const [favorite, { isLoading: isLiking }] = usePost<Dict>();
  // const [unFavorite, { isLoading: isUnLiking }] = useDel<Dict>();

  const handleChat = () => {
    setCanSaveChat(true);
    setChatHistory((chats) => [
      ...chats,
      { id: uuid(), text: prompt, type: "user" },
    ]);
    createChat(
      `${Entity.ai}/chat`,
      {
        data: {
          // chatId: currentChatId,
          entity: { id, $kind },
          userPrompt: prompt,
          history: chatHistory,
          context,
          ...(attachment && {
            files: {
              connect: [{ id: attachment.id }],
            },
          }),
        },
      },
      {
        onSuccess(data, variables, context) {
          setPrompt("");
          setAttachment(null);
          setChatHistory((chats) => [
            ...chats,
            { ...data, role: "assistant" } as ChatMessage,
          ]);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const handleCreateSession = () => {
    createChatSession(
      Entity.chats,
      {
        data: {
          title: `Chat with ${$kind}: ${entity?.name || entity?.title}`,
          entityId: id,
          entity: $kind,
          accountId: account?.id,
          userId: user?.id,
        },
      },
      {
        onSuccess(data, variables, context) {
          // setPrompt("");
          // setAttachment(null);
          // setChatHistory((chats) => [
          //   ...chats,
          //   { ...data, type: "agent" } as ChatMessage,
          // ]);
          handleResetChatSession();
          setCurrentChatId(data.id);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const checkIdOrText = (value: string, chat: ChatMessage) => {
    return chat?.id === value || (chat?.text || chat?.content) === value;
  };

  // const handleFavorite = (id: string) => {
  //   // create BE response item
  //   const chat = chats?.find((x) => checkIdOrText(id, x));
  //   if (!chat?.text || !startsWith((chat?.role || chat?.type), 'a')) return;
  //   setCanSaveChat(true);
  //   setCurrentItem(id);
  //   if (!chat?.favorite) {
  //     favorite(
  //       "responses",
  //       {
  //         data: {
  //           content: chat?.text,
  //           favorite: true,
  //         },
  //       },
  //       {
  //         onSuccess(data, variables, context) {
  //           successToast("Response added to favorites");
  //           setChats((chats) =>
  //             chats?.map((x) =>
  //               checkIdOrText(id, x) ? ({ ...x, ...data } as ChatMessage) : x
  //             )
  //           );
  //         },
  //         onError(error, variables, context) {
  //           errorToast(error?.body?.message);
  //         },
  //         onSettled(data, error, variables, context) {
  //           setCurrentItem(null);
  //         },
  //       }
  //     );
  //   } else {
  //     //and DELETE if unfavorited
  //     unFavorite(
  //       "responses",
  //       { id: chat?.id },
  //       {
  //         onSuccess(data, variables, context) {
  //           successToast("Response removed from favorites");
  //           setChats((chats) =>
  //             chats?.map((x) =>
  //               checkIdOrText(id, x)
  //                 ? ({ ...x, favorite: false } as ChatMessage)
  //                 : x
  //             )
  //           );
  //         },
  //         onError(error, variables, context) {
  //           errorToast(error?.body?.message);
  //         },
  //         onSettled(data, error, variables, context) {
  //           setCurrentItem(null);
  //         },
  //       }
  //     );
  //   }
  // };

  const handleDelete = (id: string) => {
    setCanSaveChat(true);
    const newChats = chatHistory.filter((x) => !checkIdOrText(id, x));
    setChatHistory(newChats);
  };

  // const handleAccept = (id: string) => {
  //   const chat = chats?.find((x) => checkIdOrText(id, x));
  //   if (!chat?.text || !startsWith((chat?.role || chat?.type), 'a')) return;
  //   // console.log("handleAccept", chat);
  //   handleCb(chat?.text);
  // };

  const handlePromptInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const dropdownRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(dropdownRef, () => setIsAttDropdown(false));

  const handleResetChatSession = () => {
    // setCurrentChatId(undefined);
    setChatHistory([]);
    setPrompt("");
    // inputRef?.current?.focus();
  };

  const handleSaveChat = (chatHistory: ChatMessage[]) => {
    if (!chatHistory.length) return;
    updateChat(
      Entity.chats,
      {
        id: currentChatId,
        data: { history: chatHistory },
      },
      {
        onSuccess(data, variables, context) {
          debug("handleSaveChat", "Chat Saved");
          // successToast("Chat Saved");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const chatActions = {
    top: [
      {
        label: "Reset Chat", // can reset chat instead
        iconBefore: <BsArrowCounterclockwise />,
        onSelect: handleResetChatSession,
        visible: true,
      },
    ],
    // bottom: chats?.map((c) => ({
    //   label: c?.title,
    //   iconBefore: <BsRobot />,
    //   onSelect: () => setCurrentChatId(c?.id),
    //   visible: true,
    // })),
  };

  useEffect(() => {
    // debug("AssistantChat", chats?.length, canSaveChat);
    if (chatHistory?.length && canSaveChat) {
      handleSaveChat(chatHistory);
    }
  }, [chatHistory]);

  useEffect(() => {
    if (!entityChat) {
      handleCreateSession();
    }
  }, [chats]);

  const disableSend = isChatting || prompt == "";

  // console.log("AssistantChat", history, chatHistory);

  return (
    <div
      className={`w-[350px] relative h-full overflow-y-scroll px-2 pt-7 border flex flex-col justify-between flex-shrink-0 bg-white`}
    >
      <div className="flex justify-end">
        {ellipsis(entityChat?.title || `Chat with ${$kind}`, 35)}
        <Actions actions={chatActions} />
      </div>

      <div className="px-3 pb-16 overflow-y-scroll">
        <div className="mb-10">
          {chatHistory?.map((chat, idx) => {
            const isUser = startsWith((chat?.role || chat?.type), 'u');
            const chatText = chat?.content || chat?.text || "";
            return (
              <div key={idx}>
                <ChatBubble
                  isUser={isUser}
                  chat={chat}
                  handleDelete={handleDelete}
                  // handleFavorite={handleFavorite}
                  // handleAccept={handleAccept}
                  // isLoading={
                  //   chat?.id == currentItChat& (isLiking || isUnLiking)
                  // }
                  onClick={() => {
                    isUser && setPrompt(chatText?.trim());
                  }}
                />
              </div>
            );
          })}
        </div>

        <div>
          {hints?.map((hint, idx) => {
            return (
              <div
                key={idx}
                className="bg-gradient w-max mb-4 p-[1px] pb-[0.5px] mx-auto rounded-lg"
              >
                <button
                  onClick={() => setPrompt(hint?.trim())}
                  className="px-3 py-1 bg-white hover:bg-inherit hover:text-white rounded-lg"
                >
                  {hint?.trim()}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <div className="sticky w-full bottom-0">
        <div className="flex flex-col h-[120px] overflow-hidden border rounded-lg">
          <textarea
            className={`${isChatting ? "bg-white" : ""
              } h-full pr-8 pl-3 py-2 w-full custom-scrollbar outline-none resize-none`}
            placeholder={placeholderHint?.current}
            name="prompt"
            id="prompt"
            onChange={handlePromptInput}
            value={prompt}
            disabled={isChatting}
            onKeyDown={(e) => {
              // handle shift enter
              if (e.key === "Enter" && e.shiftKey) {
                e.preventDefault();
                handleChat();
              } else if (e.key === "Enter") {
                e.preventDefault();
                setPrompt((prev) => prev + "\n");
              }
            }}
          />

          <div className="py-1 px-2 bg-white opacity-100 flex justify-between">
            <button
              className={`w-6 h-6 bg-[#eee8f8] text-[#6a54b1] ${disableSend
                ? "cursor-default"
                : "hover:bg-[#6a54b1] hover:text-white"
                } rounded flex justify-center items-center`}
              disabled={disableSend}
              onClick={handleChat}
            >
              {isChatting ? <Loader size={"md"} /> : <IoPaperPlaneOutline />}
            </button>

            {isAttDropdown && (
              <ul
                className=" w-[250px] absolute z-[10] bottom-8 mt-6 bg-white border rounded overflow-hidden"
                ref={dropdownRef}
              >
                {files
                  ?.filter((x) => x.type.toLowerCase() !== "voice")
                  ?.map((file, idx) => (
                    <li
                      className=" w-full px-3 py-[6px] line-clamp-1 hover:bg-[#B794F4] hover:text-white list-none cursor-pointer"
                      key={idx}
                      onClick={() => handleOptionClick(file)}
                    >
                      {file?.name}
                    </li>
                  ))}
              </ul>
            )}

            {attachment && (
              <div className=" mr-4 h-max px-2 py-[3px] w-max flex-shrink-0 text-sm text-[#7A5AF8] bg-[#F4F3FF] flex items-center rounded-full">
                <span>{ellipsis(attachment?.name)}</span>
                <button
                  onClick={() => setAttachment(null)}
                  className="ml-1 rounded-full p w-max h-max"
                >
                  <BiX />
                </button>
              </div>
            )}

            <button
              className={`w-6 h-6 bg-[#eee8f8] text-[#6a54b1] ${isChatting
                ? "cursor-default"
                : "hover:bg-[#6a54b1] hover:text-white"
                } rounded flex justify-center items-center`}
              onClick={() => setIsAttDropdown(!isAttDropdown)}
              disabled={isChatting}
            >
              <FiPaperclip />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantChat;
