import React, { useEffect, useState, useRef } from "react";

const PageView = ({ src }) => {
  const [url, setUrl] = useState(src);
  const iframe = useRef<HTMLIFrameElement>(
    null as unknown as HTMLIFrameElement
  );
  const IFRAME_ID = "customIframe";

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!iframe.current) return;
    if (url?.startsWith("http")) {
      iframe.current.src = url;
    }
  };

  // const onLoad = () => {
  //   if (!iframe.current) return;
  //   if (window.location.href.includes(url)) return;
  //   const newUrl = iframe.current.contentDocument?.referrer || '';
  //   console.log('newUrl', newUrl);
  //   setUrl(frameUrl);
  // }

  // const getNewUrl = () => {
  //   const frameUrl = iframe?.current?.contentWindow?.location.href;
  //   console.log('frameUrl', frameUrl);
  //   setUrl(frameUrl);
  // }

  // const contentWindowClickHandler = () => {
  //   setTimeout(getNewUrl, 5);
  // }

  // const getNewUrl = () => {
  //   const frameUrl = iframe?.current?.contentWindow?.location.href;
  //   console.log('frameUrl', frameUrl);
  //   setUrl(frameUrl);

  //   // Send the new URL to the parent window via postMessage
  //   iframe.current?.contentWindow?.postMessage(frameUrl, '*');
  // };

  // const contentWindowClickHandler = (event) => {
  //   // Listen for messages from the iframe
  //   window.addEventListener('message', (event) => {
  //     console.log('Received message:', event);
  //     // if (event.origin!== 'http://localhost:3000') return; // Ensure the message is coming from the expected origin
  //     if (window.location.href.includes(event.origin)) return;
  //     const newUrl = event.data;
  //     console.log('Received new URL:', newUrl);
  //     setUrl({ target: { value: newUrl } });
  //   }, false);

  //   setTimeout(() => {
  //     // Trigger the click handler again to attempt to get the new URL
  //     getNewUrl();
  //   }, 5000); // Adjust timing as needed
  // };

  //   const onFrameLoad = () => {
  //     iframe.current?.contentWindow?.addEventListener('click', contentWindowClickHandler);
  //   }

  // Function to handle messages from the iframe
  const handleMessage = (event) => {
    console.log("Received message:", event);
    if (event.origin !== window.location.origin) return; // Security check
    if (event.data.startsWith("navigate:")) {
      setUrl(event.data.slice(9));
    }
  };

  useEffect(() => {
    // if (!iframe.current) return;
    iframe.current = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col  w-full">
        <div className="flex w-full items-center justify-center gap-4 p-4 ">
          {/* <button
          // onClick={() => iframe?.current?.contentWindow?.history?.back()}
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          Back
        </button> */}

          <input
            type="text"
            value={url}
            autoFocus
            onChange={handleChange}
            placeholder="Enter URL"
            className="border border-purple-300 rounded px-3 py-2 w-full max-w-lg"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          <button
            onClick={handleSubmit}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Go
          </button>
        </div>
        <iframe
          id="customIframe"
          // onLoad={onFrameLoad}
          src={url}
          className="w-full h-screen border border-gray-300 rounded"
          title="Web Browser"
          // sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms"
        />
      </div>
    </>
  );
};

export default PageView;
