// import { Avatar } from "@chakra-ui/react";
import { FiPlus, FiFileText, FiFolder, FiFile, FiEdit } from "react-icons/fi";
import { IoCheckmark, IoWarning } from "react-icons/io5";
import Table from "@components/Table";
import {
  ActionType,
  BoardItem,
  Dict,
  Entity,
  Folder,
  FolderItemType,
  IDict,
  IFile,
  LinkType,
  MenuAction,
  Document,
  AddTabType,
} from "@utils/interface";
import Loader from "@components/utils/Loader";
import {
  ellipsis,
  getDateString,
  getFolderItemType,
  handleSort,
  includesString,
  isDev,
  queryEmbeds,
  setURLQuery,
} from "@utils/index";
import {
  useCommonQueries,
  useConfetti,
  useDel,
  useDisplayToast,
  useGet,
  usePlanFeatureCheck,
  usePost,
  usePut,
  useURLParams,
} from "@utils/hooks";
import { useContext, useState, useEffect, useRef } from "react";
import { ContextStore } from "@components/utils/Context";
import Actions from "@components/Actions";
import { BiSolidFilePdf, BiTrash, BiUndo } from "react-icons/bi";
import ActionModal from "@/components/modal/ActionModal";
import propsIcon from "@assets/empty-proposal.png";
import AppButton from "@components/AppButton";
import { Avatar } from "@chakra-ui/react";
import { GrCertificate, GrDocument } from "react-icons/gr";
import IFrameViewer from "@components/IFrameViewer";
import ModalSearch from "@/components/modal/ModalSearch";
import { useNavigate } from "react-router";
import { AddIcon } from "@chakra-ui/icons";
import { startCase } from "lodash";
import TextLoader from "@components/TextLoader";
import TableLoader from "@components/TableLoader";
import {
  MdAudioFile,
  MdFolderShared,
  MdImage,
  MdVideoFile,
} from "react-icons/md";
import { FaFileExcel, FaFilePowerpoint, FaFileWord } from "react-icons/fa";
import Upload from "@pages/file/Upload";
import Overlay from "@components/Overlay";
import TitleBox from "@components/TitleBox";
import VideoModal from "@/components/modal/VideoModal";
import VideoUpdate from "@components/VideoUpdate";
import { useOnClickOutside } from "usehooks-ts";
import { PiPlugsBold } from "react-icons/pi";

const Folders = () => {
  const {
    user,
    account,
    appModal,
    globalState,
    setGlobalState,
    actionModal,
    setActionModal,
    setAppModal,
    documents,
    setDocuments,
    boardItems,
    setBoardItems,
    files,
    setFiles,
    folders,
    setFolders,
    hasChanges,
    setHasChanges,
  } = useContext(ContextStore);
  const { successToast, errorToast } = useDisplayToast();
  // const { needAccess } = usePlanFeatureCheck();
  const [folderData, setFolderData] = useState<IDict[]>([]);
  const currentView = globalState.folderView;
  const setCurrentView = (view: Dict) => {
    setGlobalState((prev) => ({ ...prev, folderView: view }));
  };
  const navigate = useNavigate();

  const { fetchFolders, fetchDocuments, fetchFiles, fetchBoardItems } =
    useCommonQueries();
  const { isFetching: isLoading } = fetchFolders;

  useEffect(() => {
    refetchFolders();
  }, []);

  const [activeItem, setActiveItem] = useState<Dict | undefined>(undefined);
  const isTransitioning = currentView?.folderId && !currentView?.Folder;

  // const { showConfetti } = useConfetti();
  // const { queryParams } = useURLParams();
  // const { documentId } = queryParams;

  const [createDoc] = usePost<Document>();

  const [create, { isLoading: isCreating }] = usePost<Folder>();
  const handleNewFolder = () => {
    create(
      Entity.folders,
      {
        data: {
          title: actionModal?.newTitle || "New Folder",
          ...(currentView?.Folder && {
            parent: {
              connect: {
                id: currentView?.Folder?.id,
              },
            },
          }),
          user: {
            connect: {
              id: user?.id,
            },
          },
          account: {
            connect: {
              id: account?.id,
            },
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          refetchFolders();
          successToast("New Folder Created!");
          handleResetAddTitle();
        },
        onError(error, variables, context) {
          // console.log("error", JSON.stringify(error));
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const [update, { isLoading: isUpdating }] = usePut<Folder>();
  const handleUpdateFolder = (payload: Dict, refreshType?: FolderItemType) => {
    update(
      Entity.folders,
      {
        id: activeItem?.id || currentView?.folderId,
        data: payload,
      },
      {
        onSuccess(data, variables, context) {
          currentView?.Folder
            ? getFolderItems.refetch()
            : fetchFolders.refetch();
          switch (refreshType) {
            case FolderItemType.docs:
              fetchDocuments.refetch();
              break;
            case FolderItemType.files:
              fetchFiles.refetch();
              break;
            case FolderItemType.grants:
              fetchBoardItems.refetch();
              break;
          }
          successToast("Folder Updated");
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const [deleteItem, { isLoading: isDeleting }] = useDel<Dict>();
  const handleDelete = (item?: Dict) => {
    if (!item) return;
    deleteItem(
      `${item.$kind.toLowerCase()}s`,
      { id: item?.id },
      {
        onSuccess(data, variables, context) {
          successToast(`${item?.$kind} Deleted`);
          refetchFolders();
          setActiveItem(undefined);
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const refetchFolders = () => {
    fetchFolders.refetch();
    if (currentView?.folderId) {
      getFolderItems.refetch();
    }
  };

  const getFolderItems = useGet<Folder>(
    Entity.folders,
    {
      id: currentView?.folderId,
      meta: {
        _embed: queryEmbeds.folders,
      },
    },
    (data) => {
      // lazy load folders
      setFolders((prev) => [
        ...prev.map((f) => (f.id === currentView?.folderId ? data : f)),
      ]);
      setCurrentView({ ...currentView, Folder: data });
    }
  );

  const EmptyState = () => {
    return (
      <div className="py-8">
        <div className="mb-5 mx-4 py-7 px-5 lg:flex-between cursor-pointer rounded-xl bg-[#FAF5FF]">
          <div className="flex -sm:items-center -sm:flex-col">
            <FiFolder size={80} className="text-[#8B5CF6] mr-5" />
            <div>
              <h2 className="mb-1 text-lg font-bold">
                Welcome to your Folders Workspace!
              </h2>
              <p className="text-xs">
                Use folders to manage your proposals. With the ability to create
                documents (e.g application, letter of support, LOI, etc), files,
                and link grants, our platform makes managing proposals easier
                and more effective than ever before. Are you ready to take the
                first step toward crafting a winning proposal?
              </p>
            </div>
          </div>

          <div className="flex-shrink-0 lg:ml-14 -lg:mt-6 -lg:flex-x-center">
            <AppButton
              icon={FiPlus}
              text="New Folder"
              width="250px"
              variant="secondary"
              iconPosition="right"
              marginTop="0"
              onClick={newFolder}
            />
          </div>
        </div>
      </div>
    );
  };

  const newFolder = () => {
    setActionModal((prev) => ({
      ...prev,
      addTitle: true,
      newTitleType: Entity.folders,
    }));
  };

  const newDoc = () => {
    setActionModal({
      ...actionModal,
      addTitle: true,
      newTitleType: Entity.documents,
    });
  };

  const viewFolder = (item: Folder) => {
    setCurrentView({ ...currentView, folderId: item.id, Folder: null });
  };

  const viewGrant = (item: BoardItem) => {
    setAppModal((prev) => ({
      ...prev,
      views: { ...prev.views, [ActionType.view]: true },
      type: LinkType.grant,
      grantId: item?.grantId,
    }));
  };

  const handleViewItem = (item: Folder | BoardItem | IFile | Document) => {
    const itemType = item.$kind;

    switch (itemType) {
      case "Folder":
        viewFolder(item as Folder);
        break;
      case "BoardItem":
        viewGrant(item as BoardItem);
        break;
      case "File":
        setCurrentView({ ...currentView, File: item as IFile });
        break;
      case "Document":
        // fwd user to /documents/:id
        navigate(`/documents/${item.id}`);
        break;
    }
  };

  const handleAddNewItem = (itemType: string) => {
    setActionModal((prev) => ({ ...prev, addItemToFolder: false }));
    switch (itemType) {
      case Entity.grants:
        setAppModal((prev) => ({
          ...prev,
          views: { ...prev.views, [ActionType.add]: true },
          type: LinkType.grant,
          addTab: AddTabType.url,
        }));
        break;
      case Entity.files:
        setActionModal((prev) => ({ ...prev, fileUpload: true }));
        break;
      case "docs":
        newDoc();
        break;
    }
  };

  const handleCreateDoc = () => {
    createDoc(
      Entity.documents,
      {
        data: {
          title: actionModal?.newTitle || "Untitled Document",
          user: { connect: { id: user?.id } },
          account: { connect: { id: account?.id } },
          folder: { connect: { id: currentView?.folderId } },
        },
      },
      {
        onSuccess(data, variables, context) {
          successToast("New Document Created!");
          navigate(`/documents?documentId=${data.id}`);
          handleResetAddTitle();
        },
        onError(error, variables, context) {
          errorToast(error?.body?.message);
        },
      }
    );
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(inputRef, () => {
    setActionModal((prev) => ({ ...prev, renameFolder: false }));
  });

  const updateTitle = () => {
    // console.log("updateTitle", hasChanges.renameFolder);
    if (!hasChanges.renameFolder) return;
    setActiveItem(undefined);
    setActionModal((prev) => ({ ...prev, renameFolder: false }));
    if (
      !activeItem?.title ||
      activeItem?.title === "Untitled Folder" ||
      folders.find((f) => f.id === activeItem.id)?.title === activeItem.title
    )
      return;
    handleUpdateFolder({ title: activeItem?.title, });
  };

  const handleEditTitle = () => {
    return (
      <input
        ref={inputRef}
        // autoFocus={true}  // TODO: figure why not working
        className={
          "text-md font-bold outline-none rounded border p-2 focus:border-blue-500 focus:outline-none w-full"
        }
        placeholder="Folder Name"
        value={activeItem?.title || ""}
        id="title"
        onFocus={(e) => {
          setHasChanges((prev) => ({ ...prev, renameFolder: true }));
        }}
        onChange={(e) => {
          setActiveItem((prev) => ({
            ...prev,
            title: e.target.value,
          }));
        }}
        onBlur={() => {
          updateTitle();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateTitle();
          }
        }}
      />
    );
  };

  const handleViewFolder = () => {
    if (isTransitioning) {
      return (
        <TableLoader>
          <TextLoader messages={["Loading..."]} />
        </TableLoader>
      );
    }

    return (
      currentView?.Folder ? folderData : folders
    )?.map((item, index) => {
      const isRenameFolder =
        actionModal?.renameFolder && item.id === activeItem?.id;

      const actions: MenuAction = {
        top: [
          {
            label: "Rename",
            iconBefore: <FiEdit />,
            onSelect: () => {
              setActionModal({
                ...actionModal,
                renameFolder: true,
              });
              setActiveItem(item);
            },
            visible: item.$kind === "Folder" && !isRenameFolder,
          },
          {
            label: "Unlink",
            iconBefore: <PiPlugsBold />,
            onSelect: () => {
              const itemType = getFolderItemType(item);
              handleUpdateFolder(
                {
                  [itemType]: {
                    disconnect: [{ id: item.id }],
                  },
                },
                itemType
              );
            },
            visible: item.$kind !== "Folder",
          },
          {
            label: "Delete",
            iconBefore: <BiTrash />,
            onSelect: () => {
              setActionModal({
                ...actionModal,
                deleteItem: true,
              });
              setActiveItem(item);
            },
            visible: true,
          },
        ],
        bottom: [],
      };

      return (
        <tr className="h-16 border-b border-neutral-300" key={index}>
          <td className="-md:w-[45%] w-[40%] min-w-[150px] pr-1 bg-white sticky left-0">
            <div className="flex h-full children:text-left">
              <button
                className="flex"
                onClick={() => {
                  if (isRenameFolder) return;
                  handleViewItem(
                    item as unknown as Folder | BoardItem | IFile | Document
                  );
                }}
              >
                <span className="mr-2 text-xl">{getItemIcon(item)}</span>
                <span>
                  {isRenameFolder
                    ? handleEditTitle()
                    : `${ellipsis(item?.title || item?.name || "No Title", 40)}`}
                </span>
              </button>
            </div>
          </td>
          <td className="w-[40%] min-w-[120px] pr-1">
            <div className="flex items-center">
              <span> {getDateString(item?.createdAt)} </span>
            </div>
          </td>
          {/* <td className="w-[19%]"></td> */}
          <td>
            {(isUpdating || isDeleting) && item?.id === activeItem?.id ? (
              <Loader size={"md"} />
            ) : (
              <Actions actions={actions} isTableActions={true} />
            )}
          </td>
        </tr>
      );
    });
  };

  const handleGoBack = () => {
    setCurrentView((prev) => ({
      ...prev,
      folderId: currentView?.Folder?.parentId,
      Folder: folders?.find((x) => x.id === currentView?.Folder?.parentId),
    }));
  };

  const handleNavigation = () => {
    return (
      <>
        <span
          onClick={handleGoBack}
          className={`cursor-pointer ${currentView?.Folder && "underline"}`}
        >
          {currentView?.Folder?.parent?.title || "Folders"}
        </span>
        <span>
          {currentView?.Folder ? ` ﹥ ${currentView?.Folder.title}` : ""}
        </span>
      </>
    );
  };

  const getLinkItems = () => {
    const results = (
      actionModal.folderItem === FolderItemType.files
        ? files
        : actionModal.folderItem === FolderItemType.docs
          ? documents
          : boardItems?.filter((b) => b.grantId)
    ) as IDict[];
    return results?.filter((f) => !f.folderId);
  };

  const getItemIcon = (item: IDict) => {
    switch (item?.$kind) {
      case "File":
        item = item as IFile;
        if (item?.contentType?.includes("image")) {
          return <MdImage className={"text-red-500"} />;
        } else if (item?.contentType.includes("video")) {
          return <MdVideoFile className={"text-red-500"} />;
        } else if (item?.contentType.includes("audio")) {
          return <MdAudioFile className={"text-red-500"} />;
        } else if (item?.contentType.includes("pdf")) {
          return <BiSolidFilePdf className={"text-red-500"} />;
        } else if (includesString(["excel", "sheet"], item?.contentType)) {
          return <FaFileExcel className={"text-green-500"} />;
        } else if (item?.contentType.includes("word")) {
          return <FaFileWord className={"text-blue-500"} />;
        } else if (item?.contentType.includes("presentation")) {
          return <FaFilePowerpoint className={"text-red-500"} />;
        } else {
          return <FiFile className={"text-red-500"} />;
        }
      case "Document":
        return <FiFileText className={"text-blue-500"} />;
      case "BoardItem":
        return <GrCertificate className={"text-yellow-500"} />;
      default:
        return <MdFolderShared />;
    }
  };

  const actions: MenuAction = {
    top: [
      {
        label: "New Folder",
        iconBefore: <FiFolder />,
        onSelect: () => newFolder(),
        visible: true,
      },
      {
        label: "Add File",
        iconBefore: <FiFile />,
        onSelect: () =>
          setActionModal((prev) => ({
            ...prev,
            addItemToFolder: true,
            folderItem: FolderItemType.files,
          })),
        visible: !!currentView?.Folder,
      },
      {
        label: "Add Document",
        iconBefore: <GrDocument />,
        onSelect: () =>
          setActionModal((prev) => ({
            ...prev,
            addItemToFolder: true,
            folderItem: FolderItemType.docs,
          })),
        visible: !!currentView?.Folder,
      },
      {
        label: "Add Grant",
        iconBefore: <GrCertificate />,
        onSelect: () =>
          setActionModal((prev) => ({
            ...prev,
            addItemToFolder: true,
            folderItem: FolderItemType.grants,
          })),
        visible: !!currentView?.Folder,
      },
    ],
    bottom: [],
  };

  const handleResetAddTitle = () => {
    setActionModal({
      ...actionModal,
      addTitle: false,
      newTitle: undefined,
      newTitleType: undefined,
    });
  };

  useEffect(() => {
    // order is key
    if (currentView?.folderId && !currentView?.Folder) {
      getFolderItems.refetch();
    }
    if (currentView?.Folder) {
      setFolderData(
        [
          ...currentView.Folder.docs,
          ...currentView.Folder.files,
          ...currentView.Folder.grants,
          ...currentView.Folder.subFolders,
        ]
      )
    }
  }, [currentView]);

  if (isLoading || getFolderItems?.isLoading) {
    return (
      <div className="flex justify-center w-full h-full ">
        <Loader size="xl" />
      </div>
    );
  }

  // console.log("currentView", currentView);
  // console.log("folders", folders);

  return (
    <>
      <VideoUpdate videoKeys={["Folders"]} />
      {actionModal?.fileUpload && (
        <Overlay
          onDone={() =>
            setActionModal((prev) => ({ ...prev, fileUpload: false }))
          }
        >
          <Upload
            dragNDropOnly={true}
            fileData={{
              user: { connect: { id: user?.id } },
              account: { connect: { id: account?.id } },
              folder: { connect: { id: currentView?.folderId } },
            }}
            onDone={() => {
              getFolderItems.refetch();
              setActionModal((prev) => ({ ...prev, fileUpload: false }));
            }}
          />
        </Overlay>
      )}
      {currentView?.File && (
        <IFrameViewer
          onDone={() => setCurrentView({ ...currentView, File: undefined })}
          file={currentView?.File}
        />
      )}
      {actionModal?.addItemToFolder && (
        <ModalSearch
          position={"top-[8.4rem] right-[1rem]"}
          items={getLinkItems()}
          onDone={() =>
            setActionModal((prev) => ({ ...prev, addItemToFolder: false }))
          }
          onClick={(item) =>
            handleUpdateFolder(
              {
                [actionModal.folderItem]: {
                  connect: [{ id: item.id }],
                },
              },
              getFolderItemType(item)
            )
          }
          icon={
            <>
              <button
                className="ml-1 rounded-lg border border-purple-500 text-purple-500 px-2 py-1 font-bold text-xs hover:bg-purple-500 hover:text-white transition-all duration-300 ease-in-out"
                onClick={() => {
                  handleAddNewItem(actionModal.folderItem);
                }}
              >
                <AddIcon /> New {startCase(actionModal.folderItem)}
              </button>
            </>
          }
        />
      )}
      {actionModal?.deleteItem && (
        <ActionModal
          icon={<IoWarning />}
          handleConfirm={() => {
            handleDelete(activeItem);
            setActionModal({ ...actionModal, deleteItem: false });
          }}
          handleClose={() =>
            setActionModal({ ...actionModal, deleteItem: false })
          }
          title={"Confirm"}
        >
          <p>Are you sure?</p>
        </ActionModal>
      )}
      {actionModal?.addTitle && (
        <TitleBox
          type={actionModal.newTitleType}
          actionModal={actionModal}
          isLoading={isCreating}
          setActionModal={setActionModal}
          onConfirm={() =>
            actionModal.newTitleType == Entity.folders
              ? handleNewFolder()
              : handleCreateDoc()
          }
        />
      )}

      {!folders.length && !currentView?.Folder ? (
        <EmptyState />
      ) : (
        <div className="pb-8 relative">
          <div className="pb-2 mb-5 pt-[30px] px-5 sticky z-30 top-14 flex justify-between items-end border-b bg-white">
            <h1 className="text-2xl font-bold">{handleNavigation()} </h1>
            <Actions
              emptyClass
              className={
                "px-5 py-2 rounded bg-[#e9d9fc] hover:bg-[#553C9A] text-[#553C9A] hover:text-white transition-colors duration-200 flex items-center"
              }
              actions={actions}
              isLoading={isCreating || isDeleting || isUpdating}
              icon={
                <>
                  <span className="text-lg">
                    <FiPlus />
                  </span>
                  <span className="mr-2 -sm:hidden">New</span>
                </>
              }
            />
          </div>

          <div className="px-5">
            <Table
              tHeaders={[
                {
                  title: "Name",
                  isSortable: true,
                  fn: (dir) => {
                    if (currentView?.Folder) {
                      handleSort(dir, 'title', folderData, setFolderData);
                    } else {
                      handleSort(dir, "title", folders, setFolders);
                    }
                  }
                },
                {
                  title: "Created On",
                  isSortable: true,
                  fn: (dir) => {
                    if (currentView?.Folder) {
                      handleSort(dir, "createdAt", folderData, setFolderData);
                    } else {
                      handleSort(dir, "createdAt", folders, setFolders);
                    }
                  },
                },
                { title: "Actions", isSortable: false },
              ]}
              isLoading={isLoading}
              tBody={handleViewFolder()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Folders;
