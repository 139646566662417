import kanbanBoard from "@assets/onboarding/kanban-board.png";
import { StepProps, totalCards } from "./interface";
import triangleIcon from "./triangle";

const GrantBoard = ({ setCurrentStep, currentStep }: StepProps) => {
  return (
    <div className="bg-black bg-opacity-40 w-screen h-screen fixed top-0 left-0 z-[1100]">
      <div className=" md:flex absolute left-[170px] -md:left-[50px] top-[73px] -md:top-[278px] overflow-hidden">
        <div className="md:mt-[165px] md:ml-0 ml-10 w-[32px] h-[23px] md:-rotate-90 md:text-white text-[#D6BCFA]">
          {triangleIcon}
        </div>

        <div className=" w-[360px] -xs:w-[300px] overflow-hidden rounded-lg">
          <div className="h-40 pt-7 flex-x-center items-end bg-[#D6BCFA]">
            <img className="w-64 h-28" src={kanbanBoard} alt="bubble-text" />
          </div>
          <div className=" px-5 pt-5 pb-8 bg-white text-[#6B46C1]">
            <p className="mb-1 text-lg font-bold">
              Track all your grant progress here
            </p>
            <p className="text-sm">
              Stay on top of every grant's progress with ease. From opportunity
              to applied and beyond.
            </p>
            <div className=" mt-8 flex-y-center justify-between">
              <span className="">
                {currentStep - 2}/{totalCards}
              </span>
              <button
                className=" h-9 w-24 py-1 text-[#553C9A] hover:text-white bg-[#E9D8FD] hover:bg-[#553C9A] transition-colors duration-100 rounded-lg"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantBoard;
