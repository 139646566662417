import { Box, Text } from "@chakra-ui/react";

const SectionHeader = (props: { text: string }) => {
  const { text, ...rest } = props;
  return (
    <h1
      className="my-8 pl-3 py-1 text-lg w-full text-[#475467] bg-[#EAECF0]"
      {...rest}
    >
      {text}
    </h1>
  );
};

export default SectionHeader;
