import { Element } from "slate";
export type SlateNode = Element;

export type Dict = Record<string, any>;
export type TDict<T> = Record<string, T>; // { [key: string]: Note[] }

export interface ChatMessage extends Dict {
  // node_modules/openai/dist/api.d.ts 
  role?: "user" | 'assistant' | "system" | 'function';
  content?: string;
  name?: string;
  // type?: "user" | "agent" | "system";
  // text?: string;
  favorite?: boolean;
}

export type IDict = Dict & ID;
// export type Part<T> = {
//   [P in keyof T]?: T[P];
// };

export interface ID extends ConnectObject {
  $kind?: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  additionalData?: Dict;
  // https://www.prisma.io/docs/orm/prisma-client/queries/aggregation-grouping-summarizing#count
  _count?: TDict<number>;
}

export interface ConnectObject extends Dict {
  connect?: {
    id: string;
  };
  disconnect?: boolean;
}

export interface ObjectProps {
  [key: string]: any;
}

export interface IResponse {
  body: { message: string };
}

export interface IFile extends IDict {
  publicUrl: string;
  name: string;
  url: string;
  key: string;
  size: number;
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  contentType: string;
  metadata: {
    etag: string;
    user: string; //userId
    category: string;
    mimetype: string;
    originalName: string;
  };
  userId: string;
  accountId: string;
  folderId: string;
  documentId: string;
  type: FileType;
}

export enum FileType {
  SOURCE = "SOURCE",
  VOICE = "VOICE",
}

export interface Account extends ID {
  seats?: number;
  planName?: string;
  users: User[];
  subscriptionId: string;
  active: boolean;
  embeddings: Dict[];
  boardItems: BoardItem[];
  files: IFile[];
  columns: string[];
  documents: Document[];
  notes: Note[];
  tasks: Task[];
  folders: Folder[];

  //org profile
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  websiteUrl: string;
  imageUrl: string;
  yearFounded: string;
  orgSize: string;
  numOfFTEmployees: number;
  numOfPTEmployees: number;
  numOfVolunteers: number;
  ein: string;
  orgType: string;
  industry: string;
  mission: string;
  history: string;
  mandate: string;
  primaryContact: Partial<User>;
  primaryContactId: string;
  activities: Dict[];
  events: Event[];
  grantBoard: BoardColumn[];

  linkedAccounts: Account[];
  linkedTo?: Partial<Account>;
  linkedToId?: string;
  type?: AccountType;
}

export enum AccountType {
  REGULAR = "REGULAR",
  AGENCY = "AGENCY",
}

export interface User extends ID {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl?: string;
  status?: string;
  accountId: string;
  onBehalfOfId?: string;
  phone?: string;
  title?: string;
  // account?: ConnectObject;
  // documents?: ConnectObject[];
  // prompts?: ConnectObject[];
  // assignedTo?: ConnectObject[];
  // activities?: ConnectObject[];
  affiliate: Affiliate;
  // onBehalfOf?: ConnectObject;
}

export interface Affiliate extends ID {
  stripeAccountId: string;
  links: AffiliateLink[];
}

export interface AffiliateLink extends ID {
  paymentLinkUrl: string;
  paymentLinkId: string;
  plan: string;
  priceId: string;
}

export interface PromptSection extends ID {
  title?: string;
  currentResponse: string;
  embeddings: {
    id: string;
    content: string;
  }[];
  response?: PromptResponse;
  chatHistory: ChatMessage[];
  document?: ConnectObject;
  assignedTo?: User[];
}

export interface PromptResponse {
  id: string;
  // current: boolean;
  favorite: boolean;
  embeddings: {
    id: string;
    content: string;
  }[];
}

export interface Document extends ID {
  title: string;
  content?: string;
  status: string;
  prompts?: PromptSection[];
  user?: Partial<User>;
  account?: Partial<Account>;
  // grant?: Grant;
  grantId?: string;
  reportId?: string;
  projectId?: string;
  folderId?: string;
}

export interface ChangeViewProps {
  currentView: DocumentView;
  setCurrentView: (view: DocumentView) => void;
}

export interface PromptAttachment extends ID {
  name: string;
}

export enum DocumentView {
  HOME,
  // EDIT,
  DRAFT,
}

export interface Grant extends ID {
  name: string;
  type: string;
  status: string;
  visible: boolean;
  accountId: string;
  amount: { max: string; min?: string };
  applicationType: string[];
  checklists: string[];
  column: string;
  deadlines: string[];
  description: string;
  eligibility: string;
  fieldsOfWork: string[];
  fundingUses: string[];
  guidelines: string[];
  ineligibility: string;
  instructions: string[];
  location: string;
  outcome: string;
  overview: string;
  priorities: string[];
  restrictions: string[];
  selectionProcess: string[];
  websiteUrl: string;
  contributorId: string;
  contributor: User;
  funder?: Dict;
}

export interface Project extends ID {
  id: string;
  title: string;
  boardItem?: BoardItem;
}

export interface Report extends ID {
  id: string;
  title: string;
  boardItem?: BoardItem;
}

export interface Board {
  columns: BoardColumn[];
}

export interface BoardColumn extends Dict {
  id: string;
  title: string;
  cards: BoardCard[];
}

export interface BoardCard extends Dict {
  id: string;
  title: string;
  columnId: string;
  column: string;
}

export interface BoardItem extends ID {
  column: string;
  columnId: string;
  title: string;
  // grant: Grant;
  grantId?: string;
  archived: boolean;
  archivedAt: string;
  // document: Document;
  documentId?: string;
  // project: Dict; //TODO
  projectId?: string;
  // report: Dict; //TODO
  reportId?: string;
  folderId?: string;
  fileId?: string;
}

export interface Event extends ID {
  title: string;
  description?: string;
  start: string;
  end: string;
  completed: boolean;
  reminder: boolean;
  users?: User[];
  account: Account;
  accountId: string;
  boardItem: BoardItem;
  boardItemId: string;
  colors?: { bg: string; main: string };
  organizer?: User;
  organizerId?: string;
}

export interface Activity extends ID {
  content: string;
  type: string;
  read: boolean;
  user: User;
  userId: string;
  account: Account;
  accountId: string;
}

export interface Notification {
  user: Activity[];
  account: Activity[];
}

export interface MenuActionItem {
  label: string;
  iconBefore?: React.ReactNode;
  items?: MenuActionItem[];
  onSelect?: () => void;
  visible?: boolean;
}

export interface MenuAction {
  top: MenuActionItem[];
  bottom?: MenuActionItem[];
}

export interface FeatureFlag {
  flagName: string;
  isEnabled: boolean;
}

export enum LinkType {
  grant = "grant",
  document = "document",
  project = "project",
  report = "report",
  boardItem = "boardItem",
  file = "file",
}

export type LinkTypes = keyof typeof LinkType;

export enum ActionType {
  add = "add",
  edit = "edit",
  view = "view",
}

export type ActionTypes = keyof typeof ActionType;

export enum AddTabType {
  form = "form",
  copy = "copy",
  url = "url",
  search = "search",
}

export type AddTabTypes = keyof typeof AddTabType;

export interface AppModal extends Dict {
  // show?: boolean;
  views: { [key: string]: boolean };
  type?: LinkTypes;
  grantId?: string;
  documentId?: string;
  projectId?: string;
  reportId?: string;
  addTab?: AddTabTypes;
  item?: Dict;
}

export type AccountGrant = Grant & BoardItem;

export interface CreatePromptPayload {
  prompt: string;
  extraContext?: string;
  skipAi?: boolean;
  currentResponse?: string;
  files?: {
    connect: { id: string }[];
  };
}

export interface Task extends ID {
  title: string;
  content: string;
  completed: boolean;
  boardItem?: ConnectObject;
  boardItemId?: string;
}

export interface Note extends ID {
  title: string;
  content: string;
  boardItem?: ConnectObject;
  boardItemId?: string;
}

export interface GetList<T> {
  data: T[];
  total: number;
  pageInfo: Dict;
}

export interface Snippet extends ID {
  content: string;
  title: string
}

export enum SideBarMenuTitle {
  Dashboard = "Dashboard",
  Organization = "Organization",
  Files = "Files",
  Board = "Board",
  Documents = "Documents",
  Folders = "Folders",
  Calendar = "Calendar",
  Projects = "Projects",
  Funders = "Funders",
  Reports = "Reports",
  Chats = "Conversations",
  Recommendations = "Recommendations",
}

export interface SearchResult {
  id: string;
  files: IFile[];
  grants: IDict[];
  documents: IDict[];
  prompts: IDict[];
  responses: IDict[];
  // snippets: results,
  snippets: IDict[];
}

// type from keys of SearchResult
export type SearchCategory = keyof Omit<SearchResult, "id">;

export interface Flags {
  [key: string]: FeatureFlag;
}

export type GrantResponse = {
  message?: string;
  grant: Grant;
  boardItem: BoardItem;
};

export enum ExportType {
  PDF = "PDF",
  DOCX = "DOCX",
}

export type ExportTypes = keyof typeof ExportType;

export interface Chat extends ID {
  title: string;
  accountId: string;
  userId: string;
  embeddings: { content?: string }[];
  history: ChatMessage[];
  entityId?: string;
  entity?: string;
}

export interface UTMParams {
  utm_source?: string; //origin
  utm_medium?: string; //channel
  utm_campaign?: string; //campaign
  utm_term?: string; //keyword
  utm_content?: string; //ad
}

export enum DocumentStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}

export interface Folder extends ID {
  title: string;
  parent?: Folder;
  parentId?: string;
  account?: Partial<Account>;
  accountId: string;
  user?: Partial<User>;
  userId?: string;
  subFolders: Folder[];
  files: IFile[];
  docs: Document[];
  grants: BoardItem[];
}

export enum FolderItemType {
  docs = "docs",
  grants = "grants",
  files = "files",
  subFolders = "subFolders",
}

export enum Entity {
  ai = "ai",
  accounts = "accounts",
  users = "users",
  grants = "grants",
  documents = "documents",
  embeddings = "embeddings",
  prompts = "prompts",
  responses = "responses",
  projects = "projects",
  reports = "reports",
  boardItems = "boardItems",
  events = "events",
  activity = "activity",
  tasks = "tasks",
  notes = "notes",
  files = "files",
  folders = "folders",
  chats = "chats",
  flags = "flags",
  affiliate = "affiliate",
  proposals = "proposals",
  recommendations = "recommendations",
  snippets = "snippets",
}

export interface InputField {
  key: string;
  type: string;
  label: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  visible?: boolean;
  h?: string;
}

export interface Recommendation extends ID {
  tags: string;
  deadline: string;
  name: string;
  amount: string;
  description: string;
  link: string;
  viewed: boolean;
  liked: boolean;
}

/** Account
 "_count": {
      "embeddings": 20,
      "boardItems": 0,
      "files": 0,
      "documents": 1,
      "grants": 0,
      "activities": 5,
      "events": 0,
      "users": 1,
      "projects": 0,
      "reports": 0,
      "links": 0,
      "tasks": 0,
      "notes": 0,
      "chats": 0,
      "prompts": 10,
      "responses": 10,
      "recommendations": 0,
      "linkedAccounts": 0,
      "agencyUsers": 1,
      "folders": 1
  },
 */
